import React, { useState, useContext, useEffect, useRef } from "react";
import styles from "./Advanced.module.css";
import { Typography } from "@material-ui/core";
import tether_icon from "./../../../../components/assets/Group 140.svg";
import NumberFormat from "react-number-format";
import moment from "moment";
import ngn_icon from "./../../../../components/assets/ngn.svg";
import { Scrollbars } from "react-custom-scrollbars";
import img_icon from "./../../../../components/assets/Group 1401.svg";
import scan_icon from "./../../../../components/assets/Group.svg";
import copy_icon from "./../../../../components/assets/Group 1750.svg";
import AppBackdrop from "../../../../components/backdrop/Backdrop";
import CameraModal from "../Camera/CameraModal";
import FormData from "form-data";
import { io } from "socket.io-client";
import { useForm } from "react-hook-form";
import LabeledSelects from "../../../../components/selects/labeledSelects/LabeledSelects";
import LabeledInputs from "../../../../components/inputs/labeledInputs/LabeledInputs";
import ModalFrame from "../../../../components/frames/modalFrame/ModalFrame";
import P2PSnackbar from "../../../../components/AppSnackbars/P2PSnackbar";
import useOpenSnackbar from "../../../../custom-hooks/useOpenSnackbar";
import { MiscContextAPI } from "../../../../Context/misc/MiscContext";
import { AuthContextAPI } from "../../../../Context/auth/AuthContext";
import { UserContextAPI } from "../../../../Context/user/UserContext";
import { useMutation } from "react-query";
import { RESOLVE_ADVANCED_ERROR } from "../../../../API/mutations/support/Support";

const AdvancedModal = ({ expiryTime, handleCloseModal, issue }) => {
  const { banks, channels } = useContext(MiscContextAPI);
  const { user } = useContext(UserContextAPI);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { openSnackbar, severity, message, openToast, setOpenToast, duration } =
    useOpenSnackbar();
  const channel = watch("channel", "");
  const bank_name = watch("bank_name", "");
  const [accountNumberChecked, setAccountNumberChecked] = useState(false);
  const [markAsPaidOnBinanceChecked, setMarkAsPaidOnBinanceChecked] =
    useState(false);
  const [file, setFile] = useState("");
  const [image, setImage] = useState("");
  const [bankID, setBankID] = useState("");
  const [openCamera, setOpenCamera] = useState(false);
  const [manual, setManual] = useState(false);

  const socket = useRef();

  const { mutate, isLoading } = useMutation(RESOLVE_ADVANCED_ERROR, {
    onSuccess: (response) => {
      const data = {
        event: "transaction_resolved",
        data: {
          id: issue?.id,
          order_no: issue?.order_no,
          user: user?.firstname,
        },
      };
      socket.current.emit("p2pcollect", data);
    },
  });

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    handleSelectBankCode(bank_name);
  }, [bank_name]);


  useEffect(() => {
    if (issue?.error === "Resolution") {
      setMarkAsPaidOnBinanceChecked(true);
    }
  }, []);

  useEffect(() => {
    handleSwitchForm();
  }, [channel]);

  const handleSwitchForm = () => {
    if (channel?.includes("Manual")) {
      setManual(true);
      setValue("bank_name", "");
      setValue("account_number", "");
    } else {
      setManual(false);
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    setFile(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const handleCheckboxChange = (type) => {
    if (type === "payment") {
      setAccountNumberChecked(!accountNumberChecked);
    } else {
      if (issue?.error === "Resolution") {
      } else {
        setMarkAsPaidOnBinanceChecked(!markAsPaidOnBinanceChecked);
      }
    }
  };

  const handlePasteBtn = () => {
    window.navigator.clipboard
      .readText()
      .then((text) => {
        setValue("secure_code", text);
      })
      .catch(() => {});
  };

  const handleSelectBankCode = (bank_name) => {
    const selectedBank = banks.find((bank) => {
      if (bank.name === bank_name) {
        return bank;
      }
    });

    if (selectedBank) {
      setBankID(selectedBank.code);
    } else {
      setBankID("");
    }
  };

  const onSubmit = async ({
    account_number,
    bank_name,
    channel,
    secure_code,
  }) => {
    let formData = new FormData();
    formData.append("proof", image);
    formData.append("bank_code", bankID);
    formData.append("channel", channel);
    formData.append("account_number", account_number);
    formData.append("token", secure_code);

    mutate({
      data: formData,
      id: issue.id,
      markAsPaidOnBinanceChecked,
      accountNumberChecked,
    });

  };

  const handleOpenCameraModal = () => {
    setOpenCamera(true);
  };

  const handleCloseCameraModal = () => {
    setOpenCamera(false);
  };

  const handleScan = (data) => {
    if (data) {


      openSnackbar("success", "successfully scanned the QR code");
      setValue("secure_code", data);
      handleCloseCameraModal();
    }
  };
  const handleError = (err) => {};

  return (
    <ModalFrame
      handleCancel={() => {
        handleCloseModal();
      }}
      handleAction={handleSubmit(onSubmit)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt="I have paid"
      child={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.advanced_modal}
        >
          <Scrollbars style={{ minHeight: 440 }}>
            <div className={styles.modal_heading}>
              <Typography>Advanced</Typography>
              <div className={styles.expires_in}>
                <Typography variant="h6">EXPIRES IN</Typography>
                <Typography
                  style={{
                    color: expiryTime < 420000 ? "red" : "black",
                  }}
                >
                  {Math.floor(
                    (expiryTime <= 0 ? 0 : expiryTime / 1000 / 60) % 60
                  )}{" "}
                  : {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000) % 60)}
                </Typography>
              </div>
            </div>
            <div className={styles.modal_details}>
              <div className={styles.crypto_icon_details}>
                <img
                  src={
                    issue.crypto_currency === "USDT" ? tether_icon : ngn_icon
                  }
                  alt=""
                />
                <div>
                  <Typography variant="h6">{issue.order_no}</Typography>
                  <Typography>
                    {moment(issue.created_at).format("Do MMM YYYY")}
                  </Typography>
                </div>
              </div>
              <div className={styles.amount}>
                <Typography variant="h6">
                  {" "}
                  <NumberFormat
                    value={issue.fiat_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                  />
                </Typography>
                <Typography>{issue.fiat_currency}</Typography>
              </div>
            </div>
            <div className={styles.checkboxes}>
              <div className={styles.checkbox_item}>
                <label
                  onClick={() => {
                    handleCheckboxChange("payment");
                  }}
                  htmlFor=""
                >
                  <input
                    type="checkbox"
                    name="payment"
                    checked={accountNumberChecked}
                  />
                  Pay to bank account
                </label>
                {accountNumberChecked && (
                  <div className={styles.checkbox_bank_accounts_selects}>
                    <LabeledSelects
                      required={true}
                      register={register}
                      name="channel"
                      label=" Select payment channel"
                      placeholder="Payment Channel"
                      data={channels}
                    />

                    {manual ? (
                      <label htmlFor="" className={styles.upload_proof}>
                        Upload proof of payment
                        <div className={styles.input_box}>
                          <div>
                            <img src={file ? file : img_icon} alt="" />
                            <Typography>
                              Drag and drop and image, or <a href="">Browse</a>{" "}
                            </Typography>
                          </div>
                          <input
                            type="file"
                            name="image"
                            onChange={handleImageChange}
                          />
                        </div>
                      </label>
                    ) : (
                      <>
                        <LabeledSelects
                          required={true}
                          register={register}
                          name="bank_name"
                          label="Choose Bank"
                          placeholder="Bank"
                          data={banks}
                        />

                        <LabeledInputs
                          label="Enter account number"
                          register={register}
                          type="text"
                          placeholder="Account Number"
                          required={true}
                          name="account_number"
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.checkbox_item}>
                <label
                  onClick={() => {
                    handleCheckboxChange("binance");
                  }}
                  htmlFor=""
                >
                  <input
                    type="checkbox"
                    name="binance"
                    checked={markAsPaidOnBinanceChecked}
                  />
                  Mark as paid on Binance
                </label>
              </div>
              <div className={styles.checkbox_item}>
                <label htmlFor="">
                  <input checked={true} type="checkbox" name="" id="" />
                  Mark as paid on P2P Collect
                </label>
              </div>
            </div>
            <div className={styles.enter_security_verification}>
              <div className={styles.enter_security_verification_heading}>
                <Typography variant="h6">
                  Enter security verification
                </Typography>
                <Typography>
                  Paste security pin from mobile app or scan QR code to proceed
                </Typography>
              </div>
              <div className={styles.enter_security_verification_input_box}>
                <input
                  {...register("secure_code", {
                    required: true,
                    maxLength: 80,
                  })}
                  name="secure_code"
                  type="text"
                />
                <div>
                  <img onClick={handlePasteBtn} src={copy_icon} alt="" />
                  <img onClick={handleOpenCameraModal} src={scan_icon} alt="" />
                </div>
              </div>
            </div>
          </Scrollbars>

          {openCamera && (
            <AppBackdrop
              child={
                <CameraModal
                  handleScan={handleScan}
                  handleError={handleError}
                  handleCloseModal={handleCloseCameraModal}
                />
              }
              openModal={openCamera}
              handleCloseModal={handleCloseCameraModal}
            />
          )}
          <P2PSnackbar
            severity={severity}
            message={message}
            open={openToast}
            setOpen={setOpenToast}
            duration={duration}
          />
        </div>
      }
    />
  );
};

export default AdvancedModal;
