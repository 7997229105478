import { Typography } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useQueryClient } from "react-query";
import { AuthContextAPI } from "../../../Context/auth/AuthContext";
import { UserContextAPI } from "../../../Context/user/UserContext";
import EditInfo from "./editInfo/EditInfo";
import SavedInfo from "./savedInfo/SavedInfo";
import styles from "./UserInfo.module.css";

function UserInfo() {
  const [profileState, setProfileState] = useState(true);
  const [savingProfile, setSavingProfile] = useState(false);
  const { user} = useContext(UserContextAPI);
  const queryClient = useQueryClient()
  const { baseURL } = useContext(AuthContextAPI);

  const handleUpdateProfile = (data) => {
    setSavingProfile(true);

    axios
      .put(`${baseURL}/api/v1/auth/update/profile`, {
        lastname: data.lastname,
        firstname: data.firstname,
        job_title: data.job_title,
        department: data.department,
        phone_number: data.phone_number,
        slack_profile: data.slack_profile,
      })
      .then((response) => {
    
        setSavingProfile(false);
        setProfileState(true);
        queryClient.invalidateQueries(['user'])
      })
      .catch((error) => {
        setSavingProfile(false);
      });
  };

  return (
    <div className={styles.user_info}>
      <div className={styles.heading}>
        <Typography>User Info</Typography>
      </div>
      <div className={styles.body}>
        {profileState ? (
          <SavedInfo user={user} setProfileState={setProfileState} />
        ) : (
          <EditInfo
            savingProfile={savingProfile}
            user={user}
            handleUpdateProfile={handleUpdateProfile}
          />
        )}
      </div>
    </div>
  );
}

export default UserInfo;
