import { Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import NumberFormat from "react-number-format";
import styles from "./SupportTable.module.css";
import icon from "./../../../components/assets/Group 83.svg";
import tether from "./../../../components/assets/Group 140.svg";
import ngn_icon from "./../../../components/assets/ngn.svg";
import { useHistory } from "react-router-dom";
import EmptyState from "../../../components/null state/EmptyState";

const tableHead = [
  "order no",
  "quantity",
  "amount",
  "expires in",
  "error",
  "Bank",
];
function SupportTable({ handleRefresh, support, expiryTimes }) {
  const history = useHistory();

  const handleGoToEdit = (id, error) => {
    history.push(`/support/${error}/${id}`);
  };
  return (
    <div className={styles.support_data_table}>
      {support?.length === 0 || support === undefined ? (
        <EmptyState
          primaryText="No Transactions yet"
          secondaryText={
            "it looks like you dont have any transactions yet, No need to refresh me, i will cease to exist once transaction arrives"
          }
          handleRefresh={handleRefresh}
        />
      ) : (
        <table>
          <thead>
            <tr>
              {tableHead.map((item) => {
                if (item === "order no") {
                  return (
                    <div key={item} className={styles.grid_header}>
                      <Typography variant="h6">{item} </Typography>
                      <img src={icon} alt="" />
                    </div>
                  );
                } else {
                  return <td>
                  <div key={item} className={styles.grid_header}>
                    <Typography variant="h6">{item} </Typography>
                  </div>
                </td>;
                  
                }
              })}
            
            </tr>
          </thead>
          <tbody>
            {support?.map((support) => {
              return (
                <tr
                  onClick={() => {
                    handleGoToEdit(support.id, support.error);
                  }}
                  key={support.id}
                >
                  <td>
                    <div className={styles.order_no}>
                      <img
                        src={
                          support.crypto_currency === "USDT" ? tether : ngn_icon
                        }
                        alt=""
                      />
                      <div>
                        <Typography variant="h6">
                          {support.order_no}{" "}
                        </Typography>
                        <Typography>
                          {moment(support.created_at).format(
                            "Do MMM YYYY, h:mm a"
                          )}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.quantity}>
                      {" "}
                      <Typography variant="h6">
                        <NumberFormat
                          value={support.crypto_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />{" "}
                      </Typography>
                      <Typography>{support.crypto_currency}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.amount}>
                      {" "}
                      <Typography variant="h6">
                        <NumberFormat
                          value={support.fiat_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      </Typography>
                      <Typography>{support.fiat_currency}</Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.expires}>
                      {" "}
                      <Typography variant="h6">
                        {expiryTimes.map((expiryTime) => {
                          if (support.id === expiryTime.id) {
                            return (
                              <p
                                style={{
                                  color:
                                    expiryTime.expiry_time < 420000
                                      ? "red"
                                      : "unset",
                                }}
                                key={expiryTime.id}
                              >
                                {Math.floor(
                                  (expiryTime.expiry_time <= 0
                                    ? 0
                                    : expiryTime.expiry_time / 1000 / 60) % 60
                                )}{" "}
                                :{" "}
                                {Math.floor(
                                  (expiryTime.expiry_time <= 0
                                    ? 0
                                    : expiryTime.expiry_time / 1000) % 60
                                )}
                              </p>
                            );
                          }
                        })}
                      </Typography>
                      <Typography variant="h6"> Time Left </Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.error}>
                      {" "}
                      {}
                      <div className={styles.error_chip}>
                        {support.error === "Resolution" ? (
                          <button>{support.error}</button>
                        ) : null}
                        {support.error === "Processor" ? (
                          <button className={styles.processor_error}>
                            {support.error}
                          </button>
                        ) : null}
                        {support.error === "Payment" ? (
                          <button className={styles.payment_error}>
                            {support.error}
                          </button>
                        ) : null}
                        {support.error === "Manual" ? (
                          <button className={styles.manual_error}>
                            {support.error}
                          </button>
                        ) : null}
                        {support.error === "Bank" ? (
                          <button className={styles.bank_error}>
                            {support.error}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className={styles.bank}>
                      {" "}
                      <Typography variant="h6">{support.acc_no}</Typography>
                      <Typography>{support.bank_name}</Typography>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default SupportTable;
