// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransactionProcessed_dashboard_transaction_heading__Kytp6 {\n    margin-bottom: 1rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n\n\n.TransactionProcessed_dashboard_transaction_heading__Kytp6 p {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #1A1F36;\n}\n\n\n\n.TransactionProcessed_dashboard_transaction_chart__2TXTF {\n    border-right: 0.3px solid #a2a2a23f;\n    padding-right: 16px;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://src/pages/home/userHome/components/charts/TransactionProcessed.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;;;AAIA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;;;;AAIA;IACI,mCAAmC;IACnC,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".dashboard_transaction_heading {\n    margin-bottom: 1rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n\n\n.dashboard_transaction_heading p {\n    font-size: 16px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 20px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #1A1F36;\n}\n\n\n\n.dashboard_transaction_chart {\n    border-right: 0.3px solid #a2a2a23f;\n    padding-right: 16px;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_transaction_heading": "TransactionProcessed_dashboard_transaction_heading__Kytp6",
	"dashboard_transaction_chart": "TransactionProcessed_dashboard_transaction_chart__2TXTF"
};
export default ___CSS_LOADER_EXPORT___;
