import { Typography } from "@mui/material";
import React from "react";
import styles from './LabeledInputs.module.css'


const LabeledInputs = ({
  
  type,
  register,
  redOutline,
  name,
  placeholder,
  label,
  pattern,
  required,
  readOnly
}) => {
  return (
    <label className={styles.labeled_input} htmlFor="">
      <Typography>{label}</Typography>
      <input
      readOnly={readOnly}
        className={`${redOutline ? styles.red_outline : null} ${readOnly ? styles.read_only: null}`}
        {...register(name, { required: required, maxLength: 80, pattern: pattern })}
        type={type ? type : "text"}
        name={name}
     
        placeholder={placeholder}
    
      />
    </label>
  );
};

export default LabeledInputs;