import React from "react";
import styles from "./AppealProgress.module.css";
import { Container, Typography } from "@material-ui/core";
import Scrollbars from "react-custom-scrollbars";

const AppealProgress = () => {
  return (
    <div className={styles.appeal_progress}>
      <div className={styles.appeal_progress_header}>
        <Container>
          <div className={styles.appeal_header_content}>
            <Typography>Appeal Progress</Typography>
            <button>Cancel order</button>
          </div>
        </Container>
      </div>
      <div className={styles.appeal_progress_body}>
        <Container>
          <div className={styles.appeal_progress_body_content}>
            <Scrollbars style={{ height: 550 }}>
              <div className={styles.roadmap}>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
                <div className={styles.roadmap_info}>
                  <div className={styles.roadmap_info_icon}></div>
                  <Typography variant="h5">12-06-2021 - 12:13pm</Typography>
                  <Typography variant="h6">
                    Customer support opened chat
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Libero vitae cursus eu metus, mauris. Velit quam eu tellus
                    rhoncus egestas. Egestas bibendum ornare lacinia enim
                    maecenas elit nunc. Libero aliquam aenean egestas
                    consectetur diam, egestas.
                  </Typography>
                </div>
              </div>
            </Scrollbars>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default AppealProgress;
