import { CircularProgress, LinearProgress, Typography } from "@material-ui/core";
import React from "react";
import styles from "./QrCode.module.css";
import { Link } from "react-router-dom";
import ButtonMain from "../../../../../components/button/Button";

const SecurityModal = ({
  qrData,
  loading,
  
  handleNext,
  setShowEnable2faModal,
}) => {
  const handleCancel = (e) => {
    e.preventDefault();
    setShowEnable2faModal(false);
  };

  const handleShowOtpModal = () => {
    handleNext();
  };

 
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.security_modal_main_content}
    >
      {loading ? (
        <CircularProgress/>
      ) : (
        <>
          <div className={styles.security_modal_heading}>
            <Typography variant="h6">
              Enable Two Factor Authentication
            </Typography>
            <Typography>
              Scan this QR code with the authentication app on your trusted
              device.
            </Typography>
          </div>
          <div className={styles.security_qr}>
            <img src={qrData.qr_code} alt="" />
            <Typography variant="h6">
              Or copy this code into the app:
            </Typography>
            <Typography>{qrData.manual_code}</Typography>
            <button>copy</button>
          </div>
          <div className={styles.security_modal_btns}>
            <ButtonMain
              fullLength
              bgColor="#1A2CCE"
              textColor="white"
              btnText="I have done this"
              handleClick={handleShowOtpModal}
            />

            <Link onClick={handleCancel}>Cancel</Link>
          </div>
        </>
      )}
    </div>
  );
};

export default SecurityModal;
