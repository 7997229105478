import { LinearProgress, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import styles from "./TrxTable.module.css";
import ngn_icon from "./../../assets/ngn.svg";
import tether from "./../../assets/Group 140.svg";
import moment from "moment";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import { UserContextAPI } from "../../../Context/user/UserContext";
import Pagination from "../../pagination/Pagination";
import EmptyState from "../../null state/EmptyState";

function TrxTable({
  handleRefresh,
  auditLogs,
  tableHeadData,
  loading,
  handlePageChange,
}) {
  const { user } = useContext(UserContextAPI);
  const history = useHistory();

  const handleGoToEdit = (id) => {
    history.push(`/transactions/${id}`);
  };


 
  
  return (
    <div className={styles.audit_table}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          {(auditLogs?.items?.length === 0 || auditLogs === undefined) ? (
            <EmptyState
              primaryText="Transactions not found"
              secondaryText={"it looks like you dont have any transactions yet"}
              handleRefresh={handleRefresh}
            />
          ) : (
            <table>
              <thead>
                <tr>
                  {tableHeadData?.map((item) => {
                    if (item === "error") {
                      if (user?.partner) {
                        return null;
                      } else {
                        return (
                          <td key={item}>
                            <div className={styles.grid_header}>
                              {" "}
                              <Typography variant="h6">{item} </Typography>
                            </div>
                          </td>
                        );
                      }
                    } else {
                      return (
                        <td key={item}>
                          <div className={styles.grid_header}>
                            {" "}
                            <Typography variant="h6">{item} </Typography>
                          </div>
                        </td>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {auditLogs?.items?.map((auditLog) => {
                  return (
                    <tr
                      key={auditLog.id}
                      onClick={() => {
                        handleGoToEdit(auditLog.id);
                      }}
                    >
                      <td>
                        <div className={styles.order_no}>
                          <img
                            src={
                              auditLog.crypto_currency === "USDT"
                                ? tether
                                : ngn_icon
                            }
                            alt=""
                          />
                          <div>
                            <Typography variant="h6">
                              {auditLog.order_no}{" "}
                            </Typography>
                            <Typography>
                              {" "}
                              {moment(auditLog.created_at).format(
                                "Do MMM YYYY, h:mm a"
                              )}{" "}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={styles.quantity}>
                          {" "}
                          <Typography variant="h6">
                            <NumberFormat
                              value={auditLog.crypto_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </Typography>
                          <Typography>{auditLog.crypto_currency}</Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.amount}>
                          {" "}
                          <Typography variant="h6">
                            {" "}
                            <NumberFormat
                              value={auditLog.fiat_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Typography>
                          <Typography>{auditLog.fiat_currency}</Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.expires}>
                          {" "}
                          <Typography> {auditLog.status} </Typography>
                        </div>
                      </td>
                      {user?.partner ? null : (
                        <td>
                          <div className={styles.error}>
                            {" "}
                            {}
                            <div className={styles.error_chip}>
                              {auditLog.error === "Resolution" ? (
                                <button>{auditLog.error}</button>
                              ) : null}
                              {auditLog.error === "Processor" ? (
                                <button className={styles.processor_error}>
                                  {auditLog.error}
                                </button>
                              ) : null}
                              {auditLog.error === "Payment" ? (
                                <button className={styles.payment_error}>
                                  {auditLog.error}
                                </button>
                              ) : null}
                              {auditLog.error === "Manual" ? (
                                <button className={styles.manual_error}>
                                  {auditLog.error}
                                </button>
                              ) : null}
                              {auditLog.error === "Bank" ? (
                                <button className={styles.bank_error}>
                                  {auditLog.error}
                                </button>
                              ) : null}
                               {auditLog.error === 'None' && (
                                <button className={styles.none_error}>
                                   {auditLog.error}
                                </button>
                              ) }

                            </div>
                          </div>
                        </td>
                      )}

                      <td>
                        <div className={styles.bank}>
                          {" "}
                          <Typography variant="h6">
                            {auditLog.acc_no}
                          </Typography>
                          <Typography>{auditLog.bank_name}</Typography>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          {(auditLogs?.items?.length === 0 || auditLogs?.meta === undefined) ? null : (
            <Pagination
              meta={auditLogs?.meta}
              type=""
              handlePageChange={handlePageChange}
            />
          )}
        </>
      )}
    </div>
  );
}

export default TrxTable;
