import axios from "axios";

export const GET_PLATFORMS = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/settings/platform`
    )
    .then((response) => {
      return response.data;
    });
};