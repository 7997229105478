
export const support_data = [
  {
    id: 14459,
    fiat_amount: 294447,
    price: 1,
    crypto_amount: 300456,
    fiat_currency: "NGN",
    crypto_currency: "NGN",
    order_no: "20313205405562359808",
    pay_id: 12005147,
    seller_nickname: "otjay092",
    status: "Paid",
    completed_at: "2022-01-08T18:55:01.274Z",
    acc_name: "JACKSON WISDOM AMEN",
    acc_no: "2010229666",
    bank_name: "Kuda Micro-finance Bank",
    bank_code: "50211",
    trans_ref: "20313205405562359808",
    trans_fee: 100,
    user_id: null,
    create_time: "1641667993000",
    expiry_time: "1641668893000",
    error: "Resolution",
    proof_of_payment: null,
    proof_of_payment_file_name: null,
    error_resolved_by: null,
    error_resolved_at: null,
    channel: "API/Korapay",
    platform: "Binance",
    platform_user: "Yemz",
    note: null,
    created_at: "2022-01-08T18:55:01.274Z",
  },
  {
    id: 14458,
    fiat_amount: 294447,
    price: 1,
    crypto_amount: 300456,
    fiat_currency: "NGN",
    crypto_currency: "NGN",
    order_no: "20313205405562359808",
    pay_id: 12005147,
    seller_nickname: "otjay092",
    status: "Paid",
    completed_at: "2022-01-08T18:55:01.274Z",
    acc_name: "JACKSON WISDOM AMEN",
    acc_no: "2010229666",
    bank_name: "Kuda Micro-finance Bank",
    bank_code: "50211",
    trans_ref: "20313205405562359808",
    trans_fee: 100,
    user_id: null,
    create_time: "1641667993000",
    expiry_time: "1641668893000",
    error: "Processor",
    proof_of_payment: null,
    proof_of_payment_file_name: null,
    error_resolved_by: null,
    error_resolved_at: null,
    channel: "API/Korapay",
    platform: "Binance",
    platform_user: "Yemz",
    note: null,
    created_at: "2022-01-08T18:55:01.274Z",
  },
  {
    id: 14457,
    fiat_amount: 294447,
    price: 1,
    crypto_amount: 300456,
    fiat_currency: "NGN",
    crypto_currency: "NGN",
    order_no: "20313205405562359808",
    pay_id: 12005147,
    seller_nickname: "otjay092",
    status: "Paid",
    completed_at: "2022-01-08T18:55:01.274Z",
    acc_name: "JACKSON WISDOM AMEN",
    acc_no: "2010229666",
    bank_name: "Kuda Micro-finance Bank",
    bank_code: "50211",
    trans_ref: "20313205405562359808",
    trans_fee: 100,
    user_id: null,
    create_time: "1641667993000",
    expiry_time: "1641668893000",
    error: "Payment",
    proof_of_payment: null,
    proof_of_payment_file_name: null,
    error_resolved_by: null,
    error_resolved_at: null,
    channel: "API/Korapay",
    platform: "Binance",
    platform_user: "Yemz",
    note: null,
    created_at: "2022-01-08T18:55:01.274Z",
  },
  {
    id: 14456,
    fiat_amount: 294447,
    price: 1,
    crypto_amount: 300456,
    fiat_currency: "NGN",
    crypto_currency: "NGN",
    order_no: "20313205405562359808",
    pay_id: 12005147,
    seller_nickname: "otjay092",
    status: "Paid",
    completed_at: "2022-01-08T18:55:01.274Z",
    acc_name: "JACKSON WISDOM AMEN",
    acc_no: "2010229666",
    bank_name: "Kuda Micro-finance Bank",
    bank_code: "50211",
    trans_ref: "20313205405562359808",
    trans_fee: 100,
    user_id: null,
    create_time: "1641667993000",
    expiry_time: "1641668893000",
    error: "Bank",
    proof_of_payment: null,
    proof_of_payment_file_name: null,
    error_resolved_by: null,
    error_resolved_at: null,
    channel: "API/Korapay",
    platform: "Binance",
    platform_user: "Yemz",
    note: null,
    created_at: "2022-01-08T18:55:01.274Z",
  },
  {
    id: 14455,
    fiat_amount: 294447,
    price: 1,
    crypto_amount: 300456,
    fiat_currency: "NGN",
    crypto_currency: "NGN",
    order_no: "20313205405562359808",
    pay_id: 12005147,
    seller_nickname: "otjay092",
    status: "Paid",
    completed_at: "2022-01-08T18:55:01.274Z",
    acc_name: "JACKSON WISDOM AMEN",
    acc_no: "2010229666",
    bank_name: "Kuda Micro-finance Bank",
    bank_code: "50211",
    trans_ref: "20313205405562359808",
    trans_fee: 100,
    user_id: null,
    create_time: "1641667993000",
    expiry_time: "1641668893000",
    error: "Manual",
    proof_of_payment: null,
    proof_of_payment_file_name: null,
    error_resolved_by: null,
    error_resolved_at: null,
    channel: "API/Korapay",
    platform: "Binance",
    platform_user: "Yemz",
    note: null,
    created_at: "2022-01-08T18:55:01.274Z",
  }
];



export const audit_data = [
  {
    order_no: '20394657389402074908',
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "resolution",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'usdt',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2039446570389402074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "payment",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Favour Nnamdi',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'btc',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2039446571389402074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "processor",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'btc',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2039446573829402074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "resolution",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'usdt',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2039446573894032074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "payment",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'btc',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2039446573894020574,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "processor",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'btc',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 20394465738940207,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "resolution",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'usdt',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2034657389402074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "payment",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'btc',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 20394465788389402074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "processor",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'usdt',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2044657302074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "payment",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'usdt',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
  {
    order_no: 2039446573402074,
    quantity: 12475482,
    amount: 120874,
    expires_in: "12:02:31",
    error: "processor",
    bank: "Access Bank",
    price: 500.99,
    platform: "Binance",
    channel: "korapay API",
    account_no: '0034779764',
    account_name: 'Christain Jombo',
    date: '25 Jun 2021 12:00 PM',
    crypto_name: 'usdt',
    fiat_name: 'NGN',
      txn_ref: "27385744940846572329",
    fees: 200,
    payment_method: 'bank transfer',
    amount_received: 2348,
    amount_paid: 50000,
    rate: 508,
    currency_pair: 'ngn/usdt',
    status: 'pending',
    description: 'Payout to merchant',
    payout: 12475482,
    paid_to: 'Christain Jombo'


    
  },
];

export const columns = [
  {
    id: "order_no",
    label: "ORDER NO",
    minWidth: 170,
    align: "left",
  },

  {
    id: "quantity",
    label: "QUANTITY",
    minWidth: 100,
    align: "left",
  },
  {
    id: "amount",
    label: "AMOUNT",
    minWidth: 170,
    align: "left",
  },
  {
    id: "expires_in",
    label: "EXPIRES IN",
    minWidth: 170,
    align: "left",
  },
  {
    id: "error",
    label: "ERROR",
    minWidth: 170,
    align: "left",
  },
  {
    id: "bank",
    label: "BANK",
    minWidth: 170,
    align: "left",
  },
];