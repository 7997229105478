import { Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react'
import { Link } from 'react-router-dom';
import styles from './Profile.module.css'


function Profile({anchorEl, handleClose, handleLogout, user, partner}) {
  return (
    <Menu
    className={styles.my_menu}
    id="menu-appbar"
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    open={Boolean(anchorEl)}
    onClose={handleClose}
  >
    <MenuItem className={styles.my_menu_item}>
      <Typography variant="h6">
        {user?.name}
        {partner ? null : user?.lastname}
      </Typography>
      <Typography variant="h6">
        {partner ? null : user?.email}{" "}
      </Typography>
      <Typography>
        {partner ? user?.group?.platform : user?.role?.name}{" "}
      </Typography>
    </MenuItem>
    <MenuItem
      onClick={() => {
        handleClose();
      }}
    >
      {" "}
      <Link to="/profile">My Profile</Link>{" "}
    </MenuItem>
    <MenuItem
      onClick={() => {
        handleClose();
        handleLogout();
      }}
    >
      Log out
    </MenuItem>
  </Menu>
  )
}

export default Profile