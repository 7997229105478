import React, { useContext, useEffect, useRef, useState } from "react";
import ModalFrame from "../../../../components/frames/modalFrame/ModalFrame";
import UploadScrenshootModal from "../uploadScreenshot/UploadScreenshotModal";
import ConfirmTransactionModal from "./components/ConfirmTransactionModal";
import { useForm } from "react-hook-form";
import { io } from "socket.io-client";
import { UserContextAPI } from "../../../../Context/user/UserContext";
import { useMutation } from "react-query";
import { RESOLVE_ERROR } from "../../../../API/mutations/support/Support";

function ConfirmTransaction({ channels, expiryTime, setOpen, issue }) {
 
  const { user } = useContext(UserContextAPI);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [image, setImage] = useState("");
  const [screen, setScreen] = useState("confirm");
  const socket = useRef();

  const { mutate, isLoading } = useMutation(RESOLVE_ERROR, {
    onSuccess: (response) => {
      const data = {
        event: "transaction_resolved",
        data: {
          id: issue.id,
          order_no: issue.order_no,
          user: user.firstname,
        },
      };
      socket.current.emit("p2pcollect", data);
    },
  });

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    return () => socket.current.disconnect();
  }, []);

  const handleUpload = async ({ channel }) => {
    let formData = new FormData();
    formData.append("proof", image);
    formData.append("type", issue.error);
    formData.append("channel", channel);

    mutate({data: formData, id: issue.id});
  };

  switch (screen) {
    case "next":
      return (
        <ModalFrame
          handleCancel={() => {
            setScreen("confirm");
            setOpen(false);
          }}
          handleAction={handleSubmit(handleUpload)}
          loading={isLoading}
          showCancelBtn
          showConfirmBtn
          btnTxt="Confirm"
          child={
            <UploadScrenshootModal
              channels={channels}
              expiryTime={expiryTime}
              issue={issue}
              setOpen={setOpen}
              register={register}
              setImage={setImage}
            />
          }
        />
      );
    case "confirm":
      return (
        <ModalFrame
          handleCancel={() => {
            setScreen("confirm");
            setOpen(false);
          }}
          handleAction={() => {
            setScreen("next");
          }}
          showCancelBtn
          showConfirmBtn
          btnTxt="I have paid"
          child={
            <ConfirmTransactionModal
              channels={channels}
              expiryTime={expiryTime}
              setOpen={setOpen}
              issue={issue}
            />
          }
        />
      );
    default:
      return (
        <ConfirmTransactionModal
          channels={channels}
          expiryTime={expiryTime}
          setOpen={setOpen}
          issue={issue}
        />
      );
  }
}

export default ConfirmTransaction;
