import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContextAPI } from "./Context/user/UserContext";

function ProtectedRoute({
  route,
  component: Component,
  auth,
  path,
  ...restOfProps
}) {
  const { user } = useContext(UserContextAPI);
  //const isAuthenticated =  localStorage.hasOwnProperty("p2ptoken") ? true : false

  const handleCheckPermission = () => {
    return user.permissions.some((permission) => {
      return permission === route;
    });
  };

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        auth ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
}

export default ProtectedRoute;
