import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "./pages/auth/login/Login";
import ForgotPassword from "./pages/auth/forgot-password/ForgotPassword";
import ResetPassword from "./pages/auth/reset-password/ResetPassword";
import SideNav from "./components/sidenav/SideNav";
import Dashboard from "./pages/home/Dashboard";
import Ads from "./pages/ads/Ads";
import Navbar from "./components/navbar/Navbar";
import EditAds from "./pages/ads/EditAds/EditAds";
import Support from "./pages/support/Support";
import AuditLogs from "./pages/transaction-logs/Audit";
import Log from "./pages/transaction-logs/Log";
import Settings from "./pages/settings/Settings";
import Default from "./pages/default/default";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRoute2 from "./ProtectedRoute2";
import Appeals from "./pages/appeals/Appeals";
import ChatBoard from "./pages/appeals/chat/Chat";
import PostAds from "./pages/ads/postAds/PostAds";
import ErrorTab from "./pages/supportErrorTab/ErrorTab";
import Balances from "./pages/balances/Balances";
import Balance from "./pages/balance/Balance";
import Profile from "./pages/profile/Profile";
import TwoFa from "./pages/auth/2fa/TwoFa";
import Banks from "./pages/settings/tabs/banks/Banks";



function Router({ auth }) {
 

  return (
    <>
      {auth && <SideNav />}
      {auth && <Navbar />}
      <Switch>
        <ProtectedRoute2 auth={auth} path="/" exact component={Login} />
        <ProtectedRoute2
          auth={auth}
          path="/2fa"
          component={TwoFa}
        />
        <ProtectedRoute2
          auth={auth}
          path="/forgot-password"
          component={ForgotPassword}
        />
        <ProtectedRoute2
          auth={auth}
          path="/reset-password"
          component={ResetPassword}
        />

        {/* <Route path="/chat" component={ChatBoard} /> */}

        <ProtectedRoute
          auth={auth}
          path="/dashboard"
          exact
          component={Dashboard}
        />
        <ProtectedRoute auth={auth} path="/profile" exact component={Profile} />
        {/* <ProtectedRoute
          auth={auth}
          path="/ads/:exchange"
          exact
          component={Ads}
        />
        <ProtectedRoute auth={auth} path="/edit-ads/:id" component={EditAds} />
        <ProtectedRoute
          auth={auth}
          path="/post-ads/:platform"
          component={PostAds}
        /> */}
        <ProtectedRoute auth={auth} path="/support" exact component={Support} />
        {/* <ProtectedRoute
          auth={auth}
          path="/balances"
          exact
          component={Balances}
        /> */}
        {/* <ProtectedRoute auth={auth} path="/appeals" exact component={Appeals} /> */}

        <ProtectedRoute
          auth={auth}
          path="/support/:error/:id"
          component={ErrorTab}
        />
        <ProtectedRoute auth={auth} path="/balances/:account_name/:id" component={Balance} />
        <ProtectedRoute
          route={'transaction'}
          auth={auth}
          path="/transactions"
          exact
          component={AuditLogs}
        />
        <ProtectedRoute auth={auth} path="/transactions/:id" component={Log} />
        <ProtectedRoute
          auth={auth}
          path="/banks"
          exact
          component={Banks}
        />
        <ProtectedRoute
          auth={auth}
          path="/settings"
          exact
          component={Settings}
        />

        <Route component={Default} />
      </Switch>
      
    </>
  );
}

export default Router;






// const pages = [
//   {
//     name: 'login',
//     protected: true,
//     protectedType: 'in',
//     component: Login,
//     route: '/'
//   },
//   {
//     name: 'forgot_password',
//     protected: true,
//     protectedType: 'in',
//     component: ForgotPassword,
//     route: '/forgot-password'
//   },
//   {
//     name: 'reset_password',
//     protected: true,
//     protectedType: 'in',
//     component: ResetPassword,
//     route: '/reset-password'
//   },
//   {
//     name: 'dashboard',
//     protected: true,
//     protectedType: 'out',
//     component: Dashboard,
//     route: '/dashboard'
//   },
//   {
//     name: 'ads',
//     protected: true,
//     protectedType: 'out',
//     component: Ads,
//     route: '/ads/:exchange'
//   },
//   {
//     name: 'edit ads',
//     protected: true,
//     protectedType: 'out',
//     component: EditAds ,
//     route: '/edit-ads/:id'
//   },
//   {
//     name: 'post ads',
//     protected: true,
//     protectedType: 'out',
//     component: PostAds ,
//     route: '/post-ads/:platform'
//   },
//   {
//     name: 'support',
//     protected: true,
//     protectedType: 'out',
//     component: Support ,
//     route: '/support'
//   },
//   {
//     name: 'audit logs',
//     protected: true,
//     protectedType: 'out',
//     component: AuditLogs ,
//     route: '/audit-logs'
//   },
//   {
//     name: 'logs',
//     protected: true,
//     protectedType: 'out',
//     component: Log ,
//     route: '/audit-logs/:id'
//   },
//   {
//     name: 'settings',
//     protected: true,
//     protectedType: 'out',
//     component: Settings ,
//     route: '/settings'
//   },
//   {
//     name: 'appeals',
//     protected: true,
//     protectedType: 'out',
//     component: Appeals ,
//     route: '/appeals'
//   },
//   {
//     name: 'error tabs',
//     protected: true,
//     protectedType: 'out',
//     component:  ErrorTab ,
//     route: '/support/:error/:id'
//   },
//   {
//     name: 'balances',
//     protected: true,
//     protectedType: 'out',
//     component:  Balances ,
//     route: '/balances'
//   },
//   {
//     name: 'balance',
//     protected: true,
//     protectedType: 'out',
//     component:  Balance ,
//     route: '/balances/:id'
//   },
//   {
//     name: 'profile',
//     protected: true,
//     protectedType: 'out',
//     component:  Profile ,
//     route: '/profile'
//   },
  
// ]