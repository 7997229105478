import React from "react";
import { useState } from "react";
export const ActiveLinkContextAPI = React.createContext();

const ActiveLinkContext = (props) => {
  const [activeLink, setActiveLink] = useState("dashboard");

  return (
    <ActiveLinkContextAPI.Provider
      value={{
        activeLink,
        setActiveLink,
      }}
    >
      {props.children}
    </ActiveLinkContextAPI.Provider>
  );
};

export default ActiveLinkContext;
