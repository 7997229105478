import { Typography, Grid } from "@material-ui/core";
import React from "react";
import styles from "./EditAds.module.css";

const Edit3 = () => {
  return (
    <div className={styles.edit3}>
      <div className={styles.trade_remark}>
        <label htmlFor="">
          Trade Remarks (Optional)
          <textarea
            placeholder="Give more information about your trade guidelines"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </label>
      </div>
      <div className={styles.trade_remark}>
        <label htmlFor="">
          Trade Remarks (Optional)
          <textarea
            placeholder="This is an auto reply message"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </label>
      </div>
      <div className={styles.condition}>
        <Typography>Conditions</Typography>
        <div className={styles.checkboxes}>
          <Grid container spacing={0}>
            <Grid item md={4}>
              <label htmlFor="">
                <input type="checkbox" name="" id="" />
                Completed KYC
              </label>
            </Grid>
            <Grid item md={4}>
              <label htmlFor="">
                <input type="checkbox" name="" id="" />
                Registered <input type="text" /> days ago
              </label>
            </Grid>
            <Grid item md={4}>
              <label htmlFor="">
                <input type="checkbox" name="" id="" />
                Holding more than <input type="text" /> BTC
              </label>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Edit3;
