
import React, { useEffect,  } from "react";
import { useForm } from "react-hook-form";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import UserForm from "../../components/userForm/UserForm";
import { EDIT_USER } from "../../../../../../API/mutations/settings/userRoles/UserRoles";
import { useMutation, useQueryClient } from "react-query";

const EditUserModal = ({
  setShowEditModal,
  userRoles,
  setUser,
  user,
  type
}) => {

  const queryClient = useQueryClient();
  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation(EDIT_USER, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      queryClient.invalidateQueries(["user-roles"]);
      setShowEditModal(false);
      setUser({});
    },
    onError: () => {},
  });

  useEffect(() => {
    handlePopulation();
  
  }, [user]);

 

  

  const handlePopulation = () => {
    setValue("firstname", user.firstname);
    setValue("lastname", user.lastname);
    setValue("email", user.email);
    setValue('phone_number', user.phone_number)
    setValue('slack', user.slack_profile)
    setValue("role", user.role.name);
  };

 
  
  const handleEditUser = async (data) => {
    mutate({
      data: {
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        role: data.role,
        slack_profile: data.slack,
        phone_number: data.phone_number
      },
      id: user.id
    });
   
  };



  return (
    <ModalFrame
      handleCancel={() => {
        setShowEditModal(false);
      }}
      handleAction={handleSubmit(handleEditUser)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt={"Edit user"}
      child={
        <UserForm type={type} userRoles={userRoles} register={register}/>
       
      }
    />
  );
};

export default EditUserModal;
