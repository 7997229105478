import React from "react";
import styles from './Camera.module.css'
import { memo } from "react";
import QrReader from 'react-qr-reader'

const CameraModal = ({handleCloseModal, handleScan, handleError}) => {
 
   
  return (
    <div className={styles.camera_modal}>
      <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
      
    </div>
  );
};

export default memo(CameraModal);
