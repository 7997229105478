import { Grid, Typography, } from "@material-ui/core";
import React from "react";
import styles from "./ViewUser.module.css";
import moment from "moment";
const ViewUser = ({ user }) => {
 

  return (
    <div className={styles.view_user_content}>
      <div className={styles.view_user}>
        <div className={styles.view_user_heading}>
          <Typography>User info</Typography>
        </div>
        <div className={styles.view_user_details}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className={styles.view_user_details_item}>
                <Typography variant="h6">Full Name</Typography>
                <Typography>
                  {user.firstname} {user.lastname}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.view_user_details_item}>
                <Typography variant="h6">Email</Typography>
                <Typography>{user.email}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.view_user_details_item}>
                <Typography variant="h6">Role type</Typography>
                <Typography>{user.role.name}</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.view_user_details_item}>
                <Typography variant="h6">Date added</Typography>
                <Typography>
                  {moment(user.created_at).format("Do MMM YYYY")}{" "}
                </Typography>
              </div>
            </Grid>
            {user.total_tnx_resolved && (
              <Grid item xs={6}>
                <div className={styles.view_user_details_item}>
                  <Typography variant="h6">Total tnxs resolved</Typography>
                  <Typography>{user.total_tnx_resolved}</Typography>
                </div>
              </Grid>
            )}
            {user.avg_resolution_time && (
              <Grid item xs={6}>
                <div className={styles.view_user_details_item}>
                  <Typography variant="h6">Avg. resolution time</Typography>
                  <Typography>{user.avg_resolution_time}</Typography>
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default ViewUser;
