import React, { useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import styles from "./Chatroom.module.css";
import Scrollbars from "react-custom-scrollbars";
import image from "./../../../../components/assets/proofimg.jpg";
import attachment from './../../../../components/assets/attach.svg'
import send from './../../../../components/assets/send.svg'



const Chatroom = () => {
  const [own, setOwn] = useState(true);
  return (
    <div className={styles.chatroom}>
      <div className={styles.chatroom_header}>
        <Container>
          <div className={styles.chatroom_header_content}>
            <div className={styles.chatroom_header_details}>
              <div className={styles.chatroom_header_initials}>
                <Typography>FS</Typography>
              </div>
              <div className={styles.chatroom_header_details_text}>
                <Typography variant="h6">Faith Samuel Okon</Typography>
                <Typography>Fai*********.com</Typography>
              </div>
            </div>
            <div className={styles.chatroom_header_btns}>
              <button>Appeal</button>
              <button>Cancel order</button>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.chatroom_field}>
        <Container>
          <Scrollbars style={{ height: 550 }}>
            <div className={styles.chatroom_field_content}>
              <div className={styles.chatroom_top_msg}>
                <div>
                  <Typography>12-06-2021 - 12:13pm</Typography>
                  <Typography>
                    user ste******.com has filed for an appeal for order
                    203945786849495858. You will be contacted by support anytime
                    soon
                  </Typography>
                </div>
              </div>
              <div className={styles.chatroom_msg_div}>
                <div className={styles.chatroom_msg}>
                  <Typography>Hi, I have not seen an alert</Typography>
                </div>
                <Typography>12:13pm</Typography>
              </div>

              <div
                className={`${styles.chatroom_msg_div} ${
                  own ? styles.chatroom_msg_div_own : null
                }`}
              >
                <div
                  className={`${styles.chatroom_msg} ${
                    own ? styles.chatroom_msg_own : null
                  } `}
                >
                  <Typography>
                    I have sent you a screenshot. Check your bank app
                  </Typography>
                </div>
                <Typography>12:13pm</Typography>
              </div>

              <div className={styles.chatroom_msg_div}>
                <div className={styles.chatroom_msg}>
                  <Typography>Can’t see a screenshot</Typography>
                </div>
                <Typography>12:13pm</Typography>
              </div>
              <div
                className={`${styles.chatroom_msg_div} ${
                  own ? styles.chatroom_msg_div_own : null
                }`}
              >
                <div
                  className={`${styles.chatroom_msg} ${
                    own ? styles.chatroom_msg_own : null
                  } `}
                >
                  <img src={image} alt="" />
                </div>
                <Typography>12:13pm</Typography>
              </div>
              
            </div>
          </Scrollbars>
          <div className={styles.chatroom_input_btns}>
                <div className={styles.message_input_box}>
                  <input type="text"  placeholder='Type your message' />
                  <img src={attachment} alt="" />
                </div>
                <button> <img src={send} alt="" /></button>
              </div>
        </Container>
      </div>
    </div>
  );
};

export default Chatroom;
