// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.PaymentFields_form_field__gt73P {\n    margin-bottom: 20px;\n}\n.PaymentFields_form_field__gt73P p{\n     \n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 19px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #000A62;\n}", "",{"version":3,"sources":["webpack://src/pages/settings/tabs/platform/components/paymentFields/PaymentFields.module.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;AACvB;AACA;;IAEI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":["\n.form_field {\n    margin-bottom: 20px;\n}\n.form_field p{\n     \n    font-size: 16px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: 19px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #000A62;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form_field": "PaymentFields_form_field__gt73P"
};
export default ___CSS_LOADER_EXPORT___;
