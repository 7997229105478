import React from "react";
import ButtonMain from "../../button/Button";
import styles from "./ModalFrame.module.css";

const ModalFrame = ({
  child,
  style,
  btnTxt,
  loading,
  handleCancel,
  showCancelBtn,
  showConfirmBtn,
  handleAction,
  
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={style}
      className={styles.modal_frame}
    >
      <div className={styles.modal_frame_upper}>{child}</div>
      {
          (showCancelBtn || showConfirmBtn) &&   <div className={styles.modal_frame_btns}>
          {showCancelBtn && (
            <ButtonMain
              bgColor="white"
              textColor="#404040"
              loading={loading}
              btnText="Cancel"
              handleClick={handleCancel}
              hideLoader
            />
          )}
          {showConfirmBtn && (
            <ButtonMain
             
              bgColor=""
              textColor="white"
              loading={loading}
              btnText={btnTxt}
              handleClick={handleAction}
            />
          )}
        </div>
      }
    
    </div>
  );
};

export default ModalFrame;
