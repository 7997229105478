import { Container } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import styles from "./Support.module.css";
import LinearProgress from "@mui/material/LinearProgress";
import { useGetRequest } from "../../custom-hooks/api";
import PageLayout from "../../components/Layout/PageLayout";
import SupportFilter from "./support-component/supportFilter/SupportFilter";
import SupportTable from "./table/SupportTable";
import { SupportContextAPI } from "../../Context/support/SupportContext";


const Support = () => {
 
  // const { data: trxSum, isLoading: trxSumLoading } = useQuery(
  //   ["trx-total-bal"],
  //   () => GET_TRX_SUM(),
  //   {
  //     enabled: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const { support, loadingSupportData } = useContext(SupportContextAPI);
  const [expiryTimes, setExpiryTimes] = useState([]);
  const { getRequest } = useGetRequest();



  useEffect(() => {
    if (support !== undefined) {
      const expiryTimes = support?.map((item) => ({
        id: item.id,
        expiry_time: item.expiry_time - Date.now(),
      }));
      setExpiryTimes(expiryTimes);
    }
  }, [support]);


 



  const handleGetFilterQueries = async (data) => {
    getRequest(
      `/api/v1/transactions?error=${data.error}&crypto_currency=${data.crypto}&limit=10`
    );
  };

  const handleSearchOrderNoSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(() => {
    const time = [...expiryTimes];
    const timer = setTimeout(() => {
      const tempTime = time.map((item) => ({
        expiry_time:
          item.expiry_time < 0 ? item.expiry_time : item.expiry_time - 1000,
        id: item.id,
      }));
      setExpiryTimes(tempTime);
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [expiryTimes]);

  const handleRefresh =()=>{
    
  }


  return (
    <PageLayout
      bgColor="#ffffff"
      child={
        <div className={styles.support}>
   
          <Container>
            <div className={styles.support_content}>
              {loadingSupportData ? (
                <LinearProgress />
              ) : (
                <>
                  <SupportFilter
                    handleGetFilterQueries={handleGetFilterQueries}
                    handleSearchOrderNoSubmit={handleSearchOrderNoSubmit}
                  />
                  <SupportTable handleRefresh={handleRefresh} support={support} expiryTimes={expiryTimes} />
                </>
              )}
            </div>
          </Container>
        </div>
      }
    />
  );
};

export default Support;
