import React from "react";
import styles from "./TrxSummary.module.css";
import { Grid } from "@material-ui/core";

import BlueCardTrxSummary from "./blueCard/BlueCardTrxSummary";
import WhiteCardTrxSummary from "./whiteCard/WhiteCardTrxSummary";

function TrxSummary({tempSummaryData, trxSum, trxSumLoading,trxSummary, trxSummaryLoading, type,  }) {
  return (
    <div className={styles.audit_overview}>
      <div className={styles.audit_overview_content}>
        <Grid container spacing={3}>
          <Grid item sm={12} md={12} lg={6}>
            <BlueCardTrxSummary tempSummaryData={tempSummaryData} data={trxSum} isLoading={trxSumLoading} type={type} />
          </Grid>
          <Grid item sm={12} md={12} lg={6}>
            <WhiteCardTrxSummary tempSummaryData={tempSummaryData} data={trxSummary} isLoading={trxSummaryLoading} type={type} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default TrxSummary;
