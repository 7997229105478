import { LinearProgress } from "@material-ui/core";

import React, {  useState, useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UPDATE_GROUP } from "../../../../../../API/mutations/settings/userGroups/UserGroups";
import {
  GET_AVAILABLE_CRYPTOS,
  GET_AVAILABLE_FIATS,
  GET_AVAILABLE_PERMISSIONS,
} from "../../../../../../API/queries/settings/userGroups/UserGroup";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import Manage3rdPartyAccess from "../../components/permissions/Manage3rdPartyAccess";
import styles from "./ManagePermissions.module.css";

function ManagePermissions({
  setShowManagePermissionsModal,
  group,
}) {
  const [permission_controls, setPermiossionControl] = useState([]);
  const [fiat_currencies, setFiatCurrencies] = useState([]);
  const [crypto_currencies, setCryptoCurrencies] = useState([]);
  const queryClient = useQueryClient()
  const { data: availableCryptos, isLoading: isLoadingAvailableCryptos } =
    useQuery(["available-cryptos"], () => GET_AVAILABLE_CRYPTOS(), {
      refetchOnWindowFocus: false,
    });

  const {
    data: availablePermissions,
    isLoading: isLoadingAvailablePermissions,
  } = useQuery(["available-permissions"], () => GET_AVAILABLE_PERMISSIONS(), {
    refetchOnWindowFocus: false,
  });

  const { data: availableFiats, isLoading: isLoadingAvailableFiats } = useQuery(
    ["available-fiats"],
    () => GET_AVAILABLE_FIATS(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(UPDATE_GROUP, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user-groups"]);
      setShowManagePermissionsModal(false);
    },
    onError: () => {},
  });

  useEffect(() => {
    const { crypto_currencies, permissions, fiat_currencies } = group;

    const tempCryptos = crypto_currencies.map((currency) => {
      return currency.name;
    });
    const tempFiat = fiat_currencies.map((currency) => {
      return currency.name;
    });
    const tempPermissions = permissions.map((permission) => {
      return permission.name;
    });
    setCryptoCurrencies(tempCryptos);
    setFiatCurrencies(tempFiat);
    setPermiossionControl(tempPermissions);
  }, [group]);


  const handleUpdateGroup = async (e) => {
    e.preventDefault();
  
      let formData = new FormData();
      for (let i = 0; i < permission_controls.length; i++) {
        formData.append("permission_controls[]", permission_controls[i]);
      }
      for (let i = 0; i < crypto_currencies.length; i++) {
        formData.append("crypto_currencies[]", crypto_currencies[i]);
      }

      for (let i = 0; i < fiat_currencies.length; i++) {
        formData.append("fiat_currencies[]", fiat_currencies[i]);
      }

     mutate({
       id: group.id,
       data: formData
     })
    
   
  };

  return (
    <ModalFrame
      handleCancel={() => {
        setShowManagePermissionsModal(false);
      }}
      handleAction={handleUpdateGroup}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn={!(isLoadingAvailableCryptos || isLoadingAvailableFiats || isLoadingAvailablePermissions )}
      btnTxt="Update partner group"
      child={
        <div className={styles.manage_permissions}>
          {isLoadingAvailableCryptos || isLoadingAvailableFiats || isLoadingAvailablePermissions ? (
            <LinearProgress />
          ) : (
            <Manage3rdPartyAccess
              group={group}
              type="edit"
              setUserGroupModal={setShowManagePermissionsModal}
              availableCryptos={availableCryptos?.data}
              availableFiats={availableFiats?.data}
              availablePermissions={availablePermissions?.data}
              crypto_currencies={crypto_currencies}
              permission_controls={permission_controls}
              fiat_currencies={fiat_currencies}
              setPermiossionControl={setPermiossionControl}
              setFiatCurrencies={setFiatCurrencies}
              setCryptoCurrencies={setCryptoCurrencies}
            />
          )}
        </div>
      }
    />
  );
}

export default ManagePermissions;
