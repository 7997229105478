import useSound from 'use-sound';
import sound from './../components/assets/alert.mpeg'


const BoopButton = ({soundref}) => {
 
  const [play] = useSound(sound);

  return <button ref={soundref} style={{display: 'none'}}  onClick={play}>Boop!</button>;
};

export default BoopButton