// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.DeletePrompt_delete_prompt_content__2LpqJ {\n    background: #FFFFFF !important;\n    \n    display: flex;\n    flex-direction: column;\n  \n    width: 463px;\n    z-index: 1 !important;\n\n}\n\n\n\n.DeletePrompt_form_heading__tTHXp {\n  margin-bottom: 18px;\n}\n\n.DeletePrompt_form_heading__tTHXp p{\n   \nfont-size: 18px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 21px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #000A62;\n}\n\n\n\n\n.DeletePrompt_form_text__2yk-R p{\n   \nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 21px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #061F39;\n}\n", "",{"version":3,"sources":["webpack://src/components/delete/DeletePrompt.module.css"],"names":[],"mappings":";AACA;IACI,8BAA8B;;IAE9B,aAAa;IACb,sBAAsB;;IAEtB,YAAY;IACZ,qBAAqB;;AAEzB;;;;AAIA;EACE,mBAAmB;AACrB;;AAEA;;AAEA,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;AACd;;;;;AAKA;;AAEA,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;AACd","sourcesContent":["\n.delete_prompt_content {\n    background: #FFFFFF !important;\n    \n    display: flex;\n    flex-direction: column;\n  \n    width: 463px;\n    z-index: 1 !important;\n\n}\n\n\n\n.form_heading {\n  margin-bottom: 18px;\n}\n\n.form_heading p{\n   \nfont-size: 18px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 21px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #000A62;\n}\n\n\n\n\n.form_text p{\n   \nfont-size: 14px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 21px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #061F39;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete_prompt_content": "DeletePrompt_delete_prompt_content__2LpqJ",
	"form_heading": "DeletePrompt_form_heading__tTHXp",
	"form_text": "DeletePrompt_form_text__2yk-R"
};
export default ___CSS_LOADER_EXPORT___;
