import React, { useState } from "react";
import styles from "./TrxFilter.module.css";
import searchIcon from "./../../assets/Vector (7).svg";
import ButtonMain from "../../button/Button";
import LabeledSelects from "../../selects/labeledSelects/LabeledSelects";
import { Typography } from "@material-ui/core";
import filter_icon from "../../assets/filter_icon.svg";
import LabeledInputs from "../../inputs/labeledInputs/LabeledInputs";
import SearchInput from "../../search/SearchInput";

function TrxFilter({
  register,
  handleSearchOrderNoSubmit,
  handleGetFilterQueries,
  banks,
  handleSubmit,
  handleClearFilterData,
}) {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div
      className={`${styles.audit_filter} ${
        showFilter ? styles.animate_height : null
      }`}
    >
      <div className={styles.audit_filter_upper}>
        <form onSubmit={handleSubmit(handleSearchOrderNoSubmit)} action="">
          <SearchInput
          handleSubmit={handleSubmit}
            register={register}
            name="trans_ref"
            handleSearch={handleSearchOrderNoSubmit}
            placeholder="Search Trx ref"
          />
        
        </form>
        <div
          onClick={() => {
            setShowFilter(!showFilter);
          }}
          className={styles.filter_btn}
        >
          <img src={filter_icon} alt="" />
          {showFilter ? (
            <Typography>Hide filters</Typography>
          ) : (
            <Typography>Show filters</Typography>
          )}
        </div>
      </div>

      {showFilter && (
        <div className={styles.audit_filter_lower}>
          <div className={`${styles.select_calender}`}>
            <div className={styles.filter_field}>
              <LabeledInputs
                type="date"
                name="from"
                register={register}
                label="From:"
              />
            </div>
            <div className={styles.filter_field}>
              <LabeledInputs
                type="date"
                name="to"
                register={register}
                label="To:"
              />
            </div>
          </div>
          <div className={`${styles.filter_field}`}>
            <LabeledSelects
              required={false}
              name="crypto"
              placeholder="crypto"
              register={register}
              data={[{ name: "USDT" }, { name: "NGN" }]}
            />
          </div>
          <div className={`${styles.filter_field}`}>
            <LabeledSelects
              required={false}
              name="bank"
              placeholder="Banks"
              register={register}
              data={banks}
            />
          </div>

          <div className={`${styles.filter_field}`}>
            <LabeledSelects
              required={false}
              name="error"
              placeholder="Errors"
              register={register}
              data={[
                { name: "Resolution" },
                { name: "Payment" },
                { name: "Processor" },
                { name: "Manual" },
                { name: "Bank" },
              ]}
            />
          </div>

          <div className={`${styles.select_filter_btns} `}>
            <div className={styles.select_filter_btn}>
              <ButtonMain
                fullLength
                border="0.4px solid #1A2CCE"
                length="105px"
                bgColor="#E7F1FF"
                textColor="#1A2CCE"
                btnText={"Apply Filters"}
                handleClick={handleSubmit(handleGetFilterQueries)}
                hideLoader
              />
            </div>
            <div className={styles.select_filter_btn}>
              <ButtonMain
                fullLength
                border="0.4px solid #1A2CCE"
                length="105px"
                bgColor="transparent"
                textColor="#1A2CCE"
                btnText={"Clear Filters"}
                handleClick={handleClearFilterData}
                hideLoader
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default TrxFilter;
