import React from "react";
import NumberFormat from "react-number-format";
import styles from "./TransactionProcessedSummary.module.css";
import { Typography } from "@material-ui/core";
import arrow from "./../../../../../components/assets/Polygon 1.svg";
import { Skeleton } from "@mui/material";
import { useQuery } from "react-query";
import { GET_TRX_SUMMARY } from "../../../../../API/queries/transactions/Transactions";

function TransactionProcessedSummary({loading, trxStats}) {
  // const { data: trxStats, isLoading } = useQuery(
  //   ["trx-summary"],
  //   GET_TRX_SUMMARY,
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: false
  //   }
  // );


 
  return (
    <div className={styles.dashboard_transaction_details}>
      <div className={styles.dashboard_transaction_detail}>
        <Typography variant="h5">No. of transactions</Typography>
        {loading ? (
          <Skeleton
            sx={{
              marginTop: "20px",
            }}
            width={"100%"}
            height={"70%"}
          />
        ) : (
          <Typography variant="h6">
            <NumberFormat
              value={trxStats?.data?.transaction?.total_trans}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
          </Typography>
        )}

        {loading && (
          <div>
            <img src={arrow} alt="" />{" "}
            <Typography>+23% from last week </Typography>
          </div>
        )}
      </div>
      <div className={styles.dashboard_transaction_detail}>
        {" "}
        <Typography variant="h5">Successful transactions</Typography>
        {loading ? (
          <Skeleton
            sx={{
              marginTop: "20px",
            }}
            width={"100%"}
            height={"70%"}
          />
        ) : (
          <Typography variant="h6">
            <NumberFormat 
              value={trxStats?.data?.transaction?.total_successful}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
          </Typography>
        )}
        {loading && (
          <div>
            <img src={arrow} alt="" />{" "}
            <Typography>+23% from last week </Typography>
          </div>
        )}
      </div>
      <div className={styles.dashboard_transaction_detail}>
        {" "}
        <Typography variant="h5">FAILED transactions</Typography>
        {false ? (
          <Skeleton
            sx={{
              marginTop: "20px",
            }}
            width={"100%"}
            height={"70%"}
          />
        ) : (
          <Typography variant="h6">
            <NumberFormat
              value={trxStats?.data?.transaction?.total_failed}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
          </Typography>
        )}
        {false && (
          <div>
            <img src={arrow} alt="" />{" "}
            <Typography>+23% from last week </Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default TransactionProcessedSummary;
