import React, { useContext, useEffect, useState } from "react";
import styles from "./PageLayout.module.css";
import AppBackdrop from "../backdrop/Backdrop";
import LowBalanceModal from "../lowBalanceModal/LowBalanceModal";
import { ActiveLinkContextAPI } from "../../Context/activeLink/ActiveLinkContext"
import {useHistory} from 'react-router-dom'


const links = [
  "dashboard",
  "ads",
  "support",
  "balances",
  "appeals",
  "transactions",
  "banks",
  "settings",
  'profile'
];

function PageLayout({ child, bgColor }) {
  const [openAlert, setOpenAlert] = useState(false);
  const { setActiveLink} = useContext(ActiveLinkContextAPI)
  const history = useHistory()


 
  useEffect(() => {
    links.map((link) => {
      if (history.location.pathname.includes(link)) {
        setActiveLink(link);
      }
    });
  }, [history.location.pathname]);

  return (
    <div style={{ background: bgColor }} className={styles.page_layout}>
      {child}
      {openAlert && (
        <AppBackdrop
          child={
            <LowBalanceModal
              handleCloseModal={() => {
                setOpenAlert(false);
              }}
            />
          }
          openModal={openAlert}
          handleCloseModal={() => {
            setOpenAlert(false);
          }}
        />
      )}
    </div>
  );
}

export default PageLayout;
