import React from "react";
import styles from "./SearchInput.module.css";
import searchIcon from "./../../components/assets/Vector (7).svg";

function SearchInput({handleSubmit, register, handleSearch, name, placeholder }) {
  return (
    <div className={`${styles.search} ${styles.filter_field}`}>
      <div>
        <img onClick={handleSubmit(handleSearch)} src={searchIcon} alt="" />
      </div>
      <input
        type="text"
        placeholder={placeholder}
        {...register(name)}
      />
    </div>
  );
}

export default SearchInput;
