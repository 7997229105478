import { Typography, Grid, Tooltip, Button } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./UserRoles.module.css";
import see from "./../../../../components/assets/Vector (15).svg";
import edit from "./../../../../components/assets/Vector (16).svg";
import delete_icon from "./../../../../components/assets/Vector (17).svg";
import super_adm from "./../../../../components/assets/Group 1423.svg";
import admin from "./../../../../components/assets/Group 1423 (1).svg";
import managers from "./../../../../components/assets/Group 1423 (2).svg";
import accountant from "./../../../../components/assets/Group 1423 (3).svg";
import staff from "./../../../../components/assets/Group 1429.svg";
import LinearProgress from "@mui/material/LinearProgress";
import ManageRoleModal from "./ManageRoleModal";
import AddUserModal from "./modals/addUser/AddUserModal";
import EditUserModal from "./modals/editUser/EditUserModal";
import TryAgain from "../../../../components/tryAgain/TryAgain";
import AppBackdrop from "../../../../components/backdrop/Backdrop";
import ViewUser from "./modals/viewUser/ViewUser";
import ButtonMain from "../../../../components/button/Button";
import AppDeletePrompt from "../../../../components/delete/DeletePrompt";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  GET_USERS,
  GET_USER_ROLES,
  GET_USER_ROLES_LIST,
} from "../../../../API/queries/settings/userRoles/UserRoles";
import { DELETE_USER } from "../../../../API/mutations/settings/userRoles/UserRoles";

const UserRoles = () => {
  const queryClient = useQueryClient();
  const [user, setUser] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showRoleAccessModal, setRoleAccessModal] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [displayTryAgain, setDisplayTryAgain] = useState(false);
  const [viewUserModal, setViewUserModal] = useState(false);

  const { data: users, isLoading } = useQuery(["users"], () => GET_USERS(), {
    refetchOnWindowFocus: false,
  });

  const {
    data: userRolesForDropDown,
    isLoading: isLoadingUserRolesForDropdown,
  } = useQuery(["user-roles-list"], () => GET_USER_ROLES_LIST(), {
    refetchOnWindowFocus: false,
  });

  const { data: userRoles, isLoading: isLoadingUserRoles } = useQuery(
    ["user-roles"],
    () => GET_USER_ROLES(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isLoadingDeleteUser } = useMutation(DELETE_USER, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user-roles"]);
      queryClient.invalidateQueries(["users"]);
   
      setOpenDeleteModal(false);
      setUser({});
    },
    onError: () => {},
  });

  useEffect(() => {
    if (userRoles?.data?.length === 0) {
      setDisplayTryAgain(true);
    } else {
      setDisplayTryAgain(false);
    }
  }, [userRoles]);

  const handleDeleteUser = async () => {
    mutate(user.id);
  };
  const handleAddUserModal = () => {
    setShowModal(true);
  };

  const handleGoToEdit = (user) => {
    setUser(user);
    setShowEditModal(true);
  };
  const handleOpenDeleteModal = (user) => {
    setUser(user);
    setOpenDeleteModal(true);
  };

  const handleOpenViewUserModal = (user) => {
    setUser(user);
    setViewUserModal(true);
  };
  const handleCloseViewUserModal = () => {
    setUser({});
    setViewUserModal(false);
  };
  const handleTryAgain = () => {};

  return (
    <div className={styles.user_roles}>
      {isLoading || isLoadingUserRoles || isLoadingUserRolesForDropdown ? (
        <LinearProgress />
      ) : displayTryAgain ? (
        <TryAgain handleTryAgain={handleTryAgain} />
      ) : (
        <>
          <div className={styles.user_roles_types}>
            <div className={styles.user_types_heading}>
              <div className={styles.user_types_text}>
                <Typography variant="h6">User Roles</Typography>
                <Typography>
                  This allows access to predefined menus depending on the
                  assigned role
                </Typography>
              </div>
              <ButtonMain
                handleClick={handleAddUserModal}
                border="1px solid #BEBEBE"
                textColor="black"
                bgColor="white"
                btnText="Add new"
                hideLoader
              />
            </div>
            <div className={styles.user_types_list}>
              <Grid container spacing={3}>
                {userRoles?.data?.map((userRole) => {
                  return (
                    <Grid key={userRole.role} item sm={12} md={6} lg={4}>
                      <div className={styles.user_type_name1}>
                        <div className={styles.user_type_icon1}>
                          {userRole.role === "Super" ? (
                            <img src={super_adm} alt="" />
                          ) : null}
                          {userRole.role === "Administrators" ? (
                            <img src={admin} alt="" />
                          ) : null}
                          {userRole.role === "Managers" ? (
                            <img src={managers} alt="" />
                          ) : null}
                          {userRole.role === "Accountants" ? (
                            <img src={accountant} alt="" />
                          ) : null}
                          {userRole.role === "Staff" ? (
                            <img src={staff} alt="" />
                          ) : null}
                        </div>
                        <div className={styles.user_type_text1}>
                          <Typography variant="h6">{userRole.role}</Typography>
                          <Typography>{userRole.count} Users</Typography>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
          <div className={styles.user_list}>
            <div className={styles.user_list_heading}>
              <Typography variant="h6">Manage User roles</Typography>
              <Typography>
                Edit access and manage roles for everyone on your team
              </Typography>
            </div>
            <div className={styles.user_role_table_grid}>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">account</Typography>
              </div>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">email address </Typography>
              </div>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">role </Typography>
              </div>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">access </Typography>
              </div>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6"></Typography>
              </div>

              {users?.data?.map((user) => {
                return (
                  <React.Fragment key={user.id}>
                    <div className={styles.account_name}>
                      {" "}
                      <Typography>{user.firstname} </Typography>
                    </div>
                    <div className={styles.email}>
                      {" "}
                      <Typography>{user.email}</Typography>
                    </div>
                    <div className={styles.role}>
                      {" "}
                      <Typography> {user.role.name} </Typography>
                    </div>
                    <div className={styles.access}>
                      {" "}
                      <Typography>{user.role.description} </Typography>
                    </div>

                    <div className={styles.actions}>
                      {" "}
                      <Tooltip
                        onClick={() => {
                          handleOpenViewUserModal(user);
                        }}
                        title="View"
                        arrow
                        placement="top"
                      >
                        <Button>
                          {" "}
                          <img src={see} alt="" />{" "}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        onClick={() => {
                          handleGoToEdit(user);
                        }}
                        title="Edit"
                        arrow
                        placement="top"
                      >
                        <Button>
                          {" "}
                          <img src={edit} alt="" />{" "}
                        </Button>
                      </Tooltip>
                      <Tooltip
                        onClick={() => {
                          handleOpenDeleteModal(user);
                        }}
                        title="Delete"
                        arrow
                        placement="top"
                      >
                        <Button>
                          {" "}
                          <img src={delete_icon} alt="" />{" "}
                        </Button>
                      </Tooltip>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </>
      )}

      {}
      {showModal && (
        <AppBackdrop
          openModal={showModal}
          handleCloseModal={() => {
            setShowModal(false);
          }}
          child={
            <AddUserModal
              userRoles={userRolesForDropDown?.data}
              setShowModal={setShowModal}
              type="add"
            />
          }
        />
      )}
      {showEditModal && (
        <AppBackdrop
          openModal={showEditModal}
          handleCloseModal={() => {
            setShowEditModal(false);
          }}
          child={
            <EditUserModal
              user={user}
              setUser={setUser}
              userRoles={userRolesForDropDown?.data}
              setShowEditModal={setShowEditModal}
              type="edit"
            />
          }
        />
      )}
      {showRoleAccessModal && (
        <ManageRoleModal setRoleAccessModal={setRoleAccessModal} />
      )}

      {openDeleteModal && (
        <AppBackdrop
          openModal={openDeleteModal}
          handleCloseModal={() => {
            setOpenDeleteModal(false);
          }}
          child={
            <AppDeletePrompt
              body={`Deleting ${user.email} will revoke user access from this
              application`}
              title={"Delete user"}
              handleDelete={handleDeleteUser}
              handleCloseModal={() => {
                setOpenDeleteModal(false);
              }}
              loading={isLoadingDeleteUser}
            />
          }
        />
      )}
      {viewUserModal && (
        <AppBackdrop
          openModal={viewUserModal}
          handleCloseModal={handleCloseViewUserModal}
          child={<ViewUser user={user} />}
        />
      )}
    </div>
  );
};

export default UserRoles;
