import { Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import Switch from "../../../../../../../components/switch/Switch";
import styles from "./BotGrid.module.css";
import stripe_icon from "./../../../../../../../components/assets/Group 1513.svg";

function BotGrid({ bot, register, setValue, watch, name, status }) {
  const checkedStatus = watch(String(bot.id), status);
  useEffect(() => {
    setValue(String(bot.id), status);
  }, []);

  return (
    <div key={bot.id} className={styles.bot_grid}>
      <img src={stripe_icon} alt="" />
      <Typography variant="h6">{bot.name}</Typography>
      <div className={styles.bot_width}>
        <Switch checkedStatus={checkedStatus} register={register} name={String(bot.id)} />
      </div>
    </div>
  );
}

export default BotGrid;
