// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalFrame_modal_frame__27M1Z {\n\tbackground-color: white;\n\tposition: relative;\n\tborder: 0.5px solid #d1d1d1;\n\tborder-radius: 5px;\n\tjustify-content: space-between;\n\tdisplay: flex;\n\tflex-direction: column;\n\t\n}\n\n.ModalFrame_modal_frame_upper__29XqD {\n\tpadding: 50px 30px;\n}\n\n.ModalFrame_modal_frame_btns__HpErJ {\n\tpadding: 14px 30px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tbackground: #f3f3f3;\n\tborder: 1px solid #ebebeb;\n}\n\n.ModalFrame_modal_frame_btns__HpErJ button {\n\tmargin-left: 1rem;\n}\n\n@media (max-width: 457px) {\n\t.ModalFrame_modal_frame__27M1Z {\n\t\twidth: 95% !important;\n\t}\n\t.ModalFrame_modal_frame_upper__29XqD {\n\t\tpadding: 30px 20px;\n\t}\n}\n", "",{"version":3,"sources":["webpack://src/components/frames/modalFrame/ModalFrame.module.css"],"names":[],"mappings":"AAAA;CACC,uBAAuB;CACvB,kBAAkB;CAClB,2BAA2B;CAC3B,kBAAkB;CAClB,8BAA8B;CAC9B,aAAa;CACb,sBAAsB;;AAEvB;;AAEA;CACC,kBAAkB;AACnB;;AAEA;CACC,kBAAkB;CAClB,aAAa;CACb,yBAAyB;CACzB,mBAAmB;CACnB,yBAAyB;AAC1B;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC;EACC,qBAAqB;CACtB;CACA;EACC,kBAAkB;CACnB;AACD","sourcesContent":[".modal_frame {\n\tbackground-color: white;\n\tposition: relative;\n\tborder: 0.5px solid #d1d1d1;\n\tborder-radius: 5px;\n\tjustify-content: space-between;\n\tdisplay: flex;\n\tflex-direction: column;\n\t\n}\n\n.modal_frame_upper {\n\tpadding: 50px 30px;\n}\n\n.modal_frame_btns {\n\tpadding: 14px 30px;\n\tdisplay: flex;\n\tjustify-content: flex-end;\n\tbackground: #f3f3f3;\n\tborder: 1px solid #ebebeb;\n}\n\n.modal_frame_btns button {\n\tmargin-left: 1rem;\n}\n\n@media (max-width: 457px) {\n\t.modal_frame {\n\t\twidth: 95% !important;\n\t}\n\t.modal_frame_upper {\n\t\tpadding: 30px 20px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal_frame": "ModalFrame_modal_frame__27M1Z",
	"modal_frame_upper": "ModalFrame_modal_frame_upper__29XqD",
	"modal_frame_btns": "ModalFrame_modal_frame_btns__HpErJ"
};
export default ___CSS_LOADER_EXPORT___;
