import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import ButtonMain from "../../../../components/button/Button";
import UserInfoCard from "../../../../components/userInfoCard/UserInfoCard";
import { AuthContextAPI } from "../../../../Context/auth/AuthContext";
import styles from "./SavedInfo.module.css";

const details =[
  {
    title: 'Job Title',
    value: 'job_title'
  },
  {
    title: 'Department',
    value: 'department'
  },
  {
    title: 'Email',
    value: 'email'
  },
  {
    title: 'Phone Number',
    value: 'phone_number'
  },
  {
    title: 'Slack Profile',
    value: 'slack_profile'
  },
]
function SavedInfo({setProfileState, user}) {
  const {baseURL}= useContext(AuthContextAPI)

  return (
    <>
      <div className={styles.body_heading}>
        <div className={styles.profile_pic_post}>
          <div className={styles.profile_pic}>
            <img src={`${baseURL}/${user?.avatar}`} alt="profile-pic" />
            <div className={styles.green_icon}></div>
          </div>
          <div className={styles.user_name_post}>
            <Typography variant="h6">{user?.firstname} {user?.lastname}</Typography>
            <Typography>{user?.role?.name}</Typography>
          </div>
        </div>
        <div>
          <ButtonMain
            handleClick={()=>{
              setProfileState(false)
            }}
            btnText="Edit info"
            bgColor="white"
            border="1px solid #1A2CCE"
            textColor="#1A2CCE"
          />
        </div>
      </div>
      <div className={styles.details}>
        {details.map((detail)=>{
          return   <div key={detail.value} className={styles.detail}>
          <UserInfoCard title={detail?.title} value={user[detail?.value]} />
        </div>
        })}
      </div>
    </>
  );
}

export default SavedInfo;
