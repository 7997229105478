import React, { useState, useEffect } from "react";
import styles from "./Modal.module.css";
import { Container, Typography } from "@material-ui/core";

const AdDeleteModal = ({ setShowDeleteModal, selectedAd }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (selectedAd === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [selectedAd]);

  const handleCancel = () => {
    setShowDeleteModal(false);
  };
  return (
    <div onClick={handleCancel} className={styles.my_modal}>
      <Container>
        <div  className={styles.my_modal_content}>
          {loading ? (
            <Typography>Loading</Typography>
          ) : (
            <div onClick={(e)=>{e.stopPropagation()}} className={styles.ad_delete_modal}>
              <div className={styles.ad_delete_upper}>
                <Typography variant="h6">
                  Delete {selectedAd.account_name} ad from this list?
                </Typography>
                <Typography>
                  Deleting this ad will delete this from the designated exchange
                  and trade feed
                </Typography>
              </div>
              <div className={styles.ad_delete_lower}>
                <button onClick={handleCancel}>Cancel</button>
                <button>Delete</button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
};

export default AdDeleteModal;
