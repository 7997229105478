import { Typography } from "@material-ui/core";
import { Container } from "@mui/system";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import ButtonMain from "../../../components/button/Button";
import LabeledInputs from "../../../components/inputs/labeledInputs/LabeledInputs";
import styles from "./TwoFa.module.css";
import { useMutation } from "react-query";
import { AuthContextAPI } from "../../../Context/auth/AuthContext";
import { LOGIN_2FA } from "../../../API/mutations/auth/Login/Login";
import { useHistory } from "react-router-dom";

function TwoFa() {
  const { login, baseURL } = useContext(AuthContextAPI);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const { mutate, isLoading } = useMutation(LOGIN_2FA, {
    onSuccess: (response) => {
      login(response.data);
    },
    onError: (error) => {
      if (error.response.data.statusCode === 401) {
        history.push("/");
      } else {
      }
    },
  });

  const onSubmit = (data) => {
    mutate({
      code: data.otp,
    });
  };

  return (
    <div className={styles.otp}>
      <Container maxWidth="sm">
        <div className={styles.otp_content}>
          <div className={styles.otp_heading}>
            <Typography variant="h6">Account Login</Typography>
            <Typography>Welcome back! Log In with your 2FA code</Typography>
          </div>
          <div className={styles.otp_form}>
            <form action="">
              <LabeledInputs
                label="2FA code"
                name="otp"
                type="text"
                required={true}
                register={register}
              />

              <div className={styles.login_btn}>
                <ButtonMain
                  fullLength={true}
                  textColor="white"
                  bgColor="#1a2cce"
                  btnText="Login to account"
                  loading={isLoading}
                  handleClick={handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default TwoFa;
