import React from "react";
import { useContext } from "react";
import axios from "axios";
import useOpenSnackbar from "./custom-hooks/useOpenSnackbar";
import Router from "./Router";
import { CircularProgress } from "@material-ui/core";
import { AuthContextAPI } from "./Context/auth/AuthContext";
import { UserContextAPI } from "./Context/user/UserContext";
import P2PSnackbar from "./components/AppSnackbars/P2PSnackbar";

const App = () => {
  const { auth, logout } = useContext(AuthContextAPI);
  const { loadingUser } = useContext(UserContextAPI);

  const { openSnackbar, openToast, duration, message, severity, setOpenToast } =
    useOpenSnackbar();

  axios.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("p2ptoken");
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      openSnackbar("success", response?.data?.message || 'Successful', 3000);
      return response;
    },
    function (error) {
      if (error.response) {

        openSnackbar(
          "error",
          `${error?.response?.status}, ${error?.response?.data?.message || 'Something went wrong, please try again'}`
        );

        if (error.response.status === 401) {
          logout();
        }
      } else if (error.request) {
        openSnackbar(
          "error",
          `oops something went wrong, check internet connectivity`
        );
      } else {
        openSnackbar("error", `oops something went wrong, please try again`);
      }

      return Promise.reject(error);
    }
  );

  return (
    <>
      {loadingUser ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={80} />
        </div>
      ) : (
        <Router auth={auth} />
      )}
      <P2PSnackbar
        open={openToast}
        setOpen={setOpenToast}
        severity={severity}
        message={message}
        duration={duration}
      />
    </>
  );
};

export default App;
