import {
  Typography,
  FormControl,
  Input,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import React from "react";
import styles from "./EditAds.module.css";

const Edit2 = () => {
  return (
    <div className={styles.edit2}>
      <div className={styles.edit2_trading_amount}>
        <div className={styles.edit2_trading_amount_text}>
          <Typography>Total trading amount</Typography>
          <Typography>This is the total amount you wish to trade</Typography>
        </div>
        <div>
          <FormControl className={styles.textfield} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={458.5}
              endAdornment={
                <InputAdornment position="end">USDT</InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
      <div className={styles.edit2_order_limit}>
        <div className={styles.edit2_order_limit_text}>
          <Typography>Total trading amount</Typography>
          <Typography>This is the total amount you wish to trade</Typography>
        </div>
        <div>
          <FormControl className={styles.textfield} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={458.5}
              endAdornment={
                <InputAdornment position="end">USDT</InputAdornment>
              }
            />
          </FormControl>
        </div>
        <span>~</span>
        <div>
          <FormControl className={styles.textfield} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={458.5}
              endAdornment={
                <InputAdornment position="end">USDT</InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
   <div className={styles.edit2_trading_amount}>
        <div className={styles.edit2_trading_amount_text}>
          <Typography>Total trading amount</Typography>
          <Typography>This is the total amount you wish to trade</Typography>
        </div>
        <div>
          <FormControl className={styles.textfield} variant="outlined">
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              value={458.5}
              endAdornment={
                <InputAdornment position="end">USDT</InputAdornment>
              }
            />
          </FormControl>
        </div>
      </div>
    </div>
  );
};

export default Edit2;
