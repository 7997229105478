import { Typography } from "@material-ui/core";
import React, { useState } from "react";
import styles from "./Barcode.module.css";
import copy from "copy-text-to-clipboard";
import QRCode from "react-qr-code";
import ButtonMain from "../../../../components/button/Button";
import useOpenSnackbar from "../../../../custom-hooks/useOpenSnackbar";
import P2PSnackbar from "../../../../components/AppSnackbars/P2PSnackbar";

const BarcodeModal = ({ handleCloseModal, issue }) => {
  const {
    openSnackbar,
    severity,
    message,
    openToast,
    setOpenToast,
    duration,
  } = useOpenSnackbar();
 
 

  const handleCopyToClipBoard = () => {
    const response = copy(issue.order_no);
    if (response) {
     
      openSnackbar('success',  "copied to clipboard")
    }
  };


  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.barcode}
    >
      <div className={styles.barcode_upper}>
        <Typography variant="h6">Enable Two Factor Authentication</Typography>
        <Typography>
          Scan this QR code with the authentication app on your trusted device.
        </Typography>
      </div>
      <div className={styles.barcode_qr}>
        <QRCode value={issue.order_no} />
      </div>
      <div className={styles.barcode_lower}>
        <Typography variant="h6">Or copy this code into the app:</Typography>
        <Typography>{issue.order_no}</Typography>
        <button onClick={handleCopyToClipBoard}>Copy</button>
      </div>
      <div className={styles.barcode_actions}>
        <ButtonMain
          fullLength
          bgColor="#1A2CCE"
          textColor="white"
          btnText="I have done this"
          handleClick={handleCloseModal}
        />
        <ButtonMain
          fullLength
          bgColor="white"
          textColor="black"
          btnText="Cancel"
          handleClick={handleCloseModal}
        />
      </div>
      <P2PSnackbar
            severity={severity}
            message={message}
            open={openToast}
            setOpen={setOpenToast}
            duration={duration}
          />
    
    </div>
  );
};

export default BarcodeModal;
