import axios from "axios";
import { endpointFilterParser } from "../../../utils";

//USER
export const GET_TRX_SUMMARY = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/summary?math=count`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_TRX_SUM = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/summary?math=sum`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_TRX_LOGS = async (data) => {

  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/?page=${data.queryKey[1]}&search=${data.queryKey[2]}${endpointFilterParser(data.queryKey[3].error,'error')}${endpointFilterParser(data.queryKey[3].crypto,'crypto_currency')}${endpointFilterParser(data.queryKey[3].bank, 'bank_name')}`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_FILTERED_TRX_LOGS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/?error=${data.queryData.error}&crypto_currency=${data.queryData.crypto}&bank_name=${data.queryData.bank}&page=${data.page}&search=${data.trans_ref}`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_SINGLE_LOG = async (id) => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/transactions/find/${id}`)
    .then((response) => {
      return response.data;
    });
};

//PARTNER GROUPS

export const GET_PARTNER_TRX_SUMMARY = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/summary?math=count`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_PARTNER_TRX_SUM = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/summary?math=sum`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_PARTNER_TRX_LOGS = async () => {
  return axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/transactions/group}`)
    .then((response) => {
      return response.data;
    });
};

export const GET_PARTNER_FILTERED_TRX_LOGS = async (data) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/group/?error=${data.queryData.error}&crypto_currency=${data.queryData.crypto}&bank_name=${data.queryData.bank}&page=${data.page}&order_no=${data.trans_ref}`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_SINGLE_LOG_PARTNER = async (id) => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/group/find/${id}}`
    )
    .then((response) => {
      return response.data;
    });
};


export const GET_REPORT = async (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/download/file`, data
      
    )
    .then((response) => {
      return response.data;
    });
};

