// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Default_default_page__3ufNq {\n      padding-left: 253px;\n    background: #ffffff;\n    padding-bottom: 5rem;\n}\n.Default_default_page_content__1IxQy {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.Default_default_page__3ufNq img {\n    height: 500px;\n    width: 500px;\n}\n\n.Default_default_page__3ufNq h3{\ncolor: #1A2CCE;\nmargin-bottom: 8px;\n}\n\n.Default_default_page_content__1IxQy p{\n    color: #1A2CCE;\n}", "",{"version":3,"sources":["webpack://src/pages/default/Default.module.css"],"names":[],"mappings":"AAAA;MACM,mBAAmB;IACrB,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,YAAY;AAChB;;AAEA;AACA,cAAc;AACd,kBAAkB;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".default_page {\n      padding-left: 253px;\n    background: #ffffff;\n    padding-bottom: 5rem;\n}\n.default_page_content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n.default_page img {\n    height: 500px;\n    width: 500px;\n}\n\n.default_page h3{\ncolor: #1A2CCE;\nmargin-bottom: 8px;\n}\n\n.default_page_content p{\n    color: #1A2CCE;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"default_page": "Default_default_page__3ufNq",
	"default_page_content": "Default_default_page_content__1IxQy"
};
export default ___CSS_LOADER_EXPORT___;
