import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { DISABLE_2FA } from "../../../../../API/mutations/profile/security/Security";

import AppDeletePrompt from "../../../../../components/delete/DeletePrompt";
import OtpModal from "../../components/OtpCode/OtpModal";

function Disable2FA({ handleCloseModal }) {
  const [screen, setScreen] = useState("prompt");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState({});
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(DISABLE_2FA, {
    onSuccess: (response) => {

      handleCloseModal();
      queryClient.invalidateQueries(["user"]);
    },
  });

  const handleChange = (otp) => {
    setOtp(otp);
    validateOtp(otp);
  };

  const validateOtp = (otp) => {
    if (otp.length < 6) {
    } else {
      delete otpError.otp;
    }
  };

  const handleAuthenticateOTP = async (type) => {
    if (otp.length < 6) {
      setOtpError({
        otp: {
          message: "",
          type: "required",
        },
      });
    } else {
      mutate({
        code: otp,
      });
    }
  };

  const handleTurnOff2fa = () => {
    mutate();
  };

  switch (screen) {
    case "otp":
      return (
        <OtpModal
          otp={otp}
          otpError={otpError}
          isLoading={isLoading}
          handleChange={handleChange}
          handleAuthenticateOTP={handleAuthenticateOTP}
          handleCloseModal={() => {
            handleCloseModal();
          }}
        />
      );
    case "prompt":
      return (
        <AppDeletePrompt
          loading={isLoading}
          body={`  Turning off 2FA will make your account less secure. Do you
            wish to proceed?`}
          title={"Disable Two-factor authentication?"}
          handleDelete={handleTurnOff2fa}
          handleCloseModal={() => {
            handleCloseModal();
          }}
        />
      );
    default:
      return (
        <AppDeletePrompt
          loading={isLoading}
          body={`  Turning off 2FA will make your account less secure. Do you
        wish to proceed?`}
          title={"Disable Two-factor authentication?"}
          handleDelete={handleTurnOff2fa}
          handleCloseModal={() => {
            handleCloseModal();
          }}
        />
      );
  }
}

export default Disable2FA;
