import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import TrxFilter from "../../components/transaction/trxFilter/TrxFilter";
import TrxSummary from "../../components/transaction/trxSummary/TrxSummary";
import TrxTable from "../../components/transaction/trxTable/TrxTable";
import { MiscContextAPI } from "../../Context/misc/MiscContext";
import AppBackdrop from "../backdrop/Backdrop";
import ReportDetails from "./modals/ReportDetails";
import TrxSummaryHeading from "./trxSummaryHeading/TrxSummaryHeading";

const Transactions = ({
  tempSummaryData,
  setFilterData,
  handleGetFilterQueries,
  trxSum,
  trxSumLoading,
  trxSummary,
  trxSummaryLoading,
  tableHeadData,
  match,
  type,
  auditLogs,
  isTrxLoading,
  handlePageChange,
  handleSearchOrderNoSubmit,
  handleRefresh,
}) => {
  const { handleGetBanks, banks } = useContext(MiscContextAPI);
  const { register, watch, handleSubmit, setValue } = useForm();
  const [showReportDetailsModal, setShowReportDetailsModal] = useState(false);
  const crypto = watch("crypto", "");
  const bank_name = watch("bank", "");
  const error = watch("error", "");

  useEffect(() => {
    const controller = new AbortController();
    handleGetBanks();
    return () => {
      controller.abort();
    };
  }, []);

  const handleShowReportDetailsModal = (e) => {
    e.preventDefault();
    setShowReportDetailsModal(true);
  };

  const handleClearFilterData = () => {
    setValue("crypto", "");
    setValue("bank", "");
    setValue("error", "");
    setFilterData({});
  };

  return (
    <>
      <TrxSummaryHeading
        type={type}
        match={match}
        handleShowReportDetailsModal={handleShowReportDetailsModal}
      />
      {true && (
        <TrxSummary
        tempSummaryData={ tempSummaryData}
          trxSum={trxSum}
          trxSumLoading={trxSumLoading}
          trxSummary={trxSummary}
          trxSummaryLoading={trxSummaryLoading}
          type={type}
        />
      )}

      <TrxFilter
        handleClearFilterData={handleClearFilterData}
        handleSubmit={handleSubmit}
        register={register}
        banks={banks}
        handleGetFilterQueries={handleGetFilterQueries}
        handleSearchOrderNoSubmit={handleSearchOrderNoSubmit}
      />

      <TrxTable
        handleRefresh={handleRefresh}
        handlePageChange={handlePageChange}
        loading={isTrxLoading}
        tableHeadData={tableHeadData}
        auditLogs={auditLogs}
      />
      {showReportDetailsModal && (
        <AppBackdrop
          openModal={showReportDetailsModal}
          handleCloseModal={() => {
            setShowReportDetailsModal(false);
          }}
          child={
            <ReportDetails
              error={error}
              crypto_currency={crypto}
              bank_name={bank_name}
              setShowReportDetailsModal={setShowReportDetailsModal}
            />
          }
        />
      )}
    </>
  );
};

export default Transactions;
