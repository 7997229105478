import axios from "axios";

export const GET_SUPPORTS = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/support`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_SINGLE_SUPPORT = async (id) => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/find/${id}`
      )
      .then((response) => {
        return response.data;
      });
  };