import React, { useRef, useEffect, useContext } from "react";
import { useState } from "react";
import useOpenSnackbar from "../../custom-hooks/useOpenSnackbar";
import { UserContextAPI } from "../user/UserContext";
export const FEpermissionsContextAPI = React.createContext();

const FEpermissionsContext = (props) => {
  const { user } = useContext(UserContextAPI);
  const [FEpermissions, setFEpermissions] = useState([]);

  useEffect(() => {
    if(user){
      handleSetPermissions(user);
    }
  }, [user]);

  const handleSetPermissions = (user) => {
    if (user?.partner) {
    } else {
      if (Object.keys(user)?.length > 0) {
        if (user?.role?.name === "Staff") {
        } else if (user?.role?.name === "Accountants") {
        } else {
          setFEpermissions([...FEpermissions, "save-bots"]);
        }
      }
    }
  };

  return (
    <FEpermissionsContextAPI.Provider
      value={{
        FEpermissions,
      }}
    >
      {props.children}
    </FEpermissionsContextAPI.Provider>
  );
};

export default FEpermissionsContext;
