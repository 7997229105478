import React from "react";
import styles from "./BlueCardTrxSummary.module.css";
import group163 from "./../../../assets/Group 163.svg";
import group164 from "./../../../assets/Group 164.svg";
import NumberFormat from "react-number-format";
import { Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import arrow from "./../../../assets/Polygon 1.svg";

function BlueCardTrxSummary({tempSummaryData, type, data, isLoading }) {

  const blueContent = [
    {
      title: "Total crypto collected",
      value: type === "balances" ? "payout_amount" : "totalCrypto",
      trend: null,
    },
    {
      title: "Fiat paid out",
      value: type === "balances" ? "payout_amount" : "totalFiat",
      trend: null,
    },
  ];

  return (
    <div className={styles.overview_blue_card}>
      <img className={styles.group163} src={group163} alt="" />
      <img className={styles.group164} src={group164} alt="" />
      {blueContent.map((item) => {
        if(type === 'balances'){
          return (
            <div key={item?.title} className={styles.blue_card_content}>
              <div className={styles.title_heading}>
                {" "}
                <Typography variant="h4">{item?.title}</Typography>{" "}
              </div>
              {isLoading ? (
                <Skeleton
                  sx={{ bgcolor: "rgb(187, 183, 183)" }}
                  width={"90%"}
                  height={"40px"}
                />
              ) : (
                <div className={styles.value}>
                  <Typography variant="h5">
                    {" "}
                    {tempSummaryData[item?.value] === undefined ? (
                      "NA"
                    ) : (
                      <NumberFormat
                        value={tempSummaryData[item?.value]}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    )}
                  </Typography>
                  <Typography variant="h6">
                    {tempSummaryData[item?.value] === undefined ? null : item.value === 'crypto' ? "USDT" : 'NGN'}
                  </Typography>
                  {tempSummaryData[item?.trend] === undefined ? null : (
                    <div className={styles.trend}>
                      <img src={arrow} alt="" />
                      <Typography>{tempSummaryData[item?.trend]} </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }else {
          return (
            <div key={item.title} className={styles.blue_card_content}>
              <div className={styles.title_heading}>
                {" "}
                <Typography variant="h4">{item?.title}</Typography>{" "}
              </div>
              {isLoading ? (
                <Skeleton
                  sx={{ bgcolor: "rgb(187, 183, 183)" }}
                  width={"90%"}
                  height={"40px"}
                />
              ) : (
                <div className={styles.value}>
                  <Typography variant="h5">
                    {" "}
                    {tempSummaryData[item?.value] === undefined ? (
                      "NA"
                    ) : (
                      <NumberFormat
                        value={tempSummaryData[item?.value]}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    )}
                  </Typography>
                  <Typography variant="h6">
                    {tempSummaryData[item?.value] === undefined ? null : item?.value === 'crypto' ? "USDT" : 'NGN'}
                  </Typography>
                  {tempSummaryData[item?.trend] === undefined ? null : (
                    <div className={styles.trend}>
                      <img src={arrow} alt="" />
                      <Typography>{tempSummaryData[item?.trend]} </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }
       
      })}
    </div>
  );
}

export default BlueCardTrxSummary;
