import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./AvailableBots.module.css";

import AppBackdrop from "../../../../../../../components/backdrop/Backdrop";
import RunningBots from "../../../../modals/runningbots/RunningBots";
import { FEpermissionsContextAPI } from "../../../../../../../Context/FEpermissions/FEpermissions";
import { Skeleton } from "@mui/material";

function AvailableBots({bots}) {
  const { FEpermissions } = useContext(FEpermissionsContextAPI);
 
  const [displayedBots, setDisplayedBots] = useState([]);
  const [showRunningBots, setShowRunningBots] = useState(false);
  const [botsStatus, setBotsStatus] = useState([]);

  useEffect(() => {
    if(bots){
      handleGetProcessBots(bots?.data);
    }
   
  }, [bots]);


  useEffect(() => {
    if(bots){
      const size = 3;
      const items = bots?.data?.platform?.slice(0, size);
      setDisplayedBots(items);
    }
   
  }, [bots]);

  const handleGetProcessBots = (bots) => {
   
    const summary = bots?.platform?.map((item) => ({
      id: item.id,
      status: item.status,
      name: item.name,
    }));

    setBotsStatus(summary);
  };

  const handleManageAll = (e) => {
    e.preventDefault();
    setShowRunningBots(true);
  };

  return (
    <div className={styles.running_bots}>
      <div className={styles.running_bots_heading}>
        <Typography>AVAILABLE BOTS</Typography>
        <Link to="" onClick={handleManageAll}>
          Manage All
        </Link>
      </div>
      {false? (
        <>
          <Skeleton width={"100%"} height={"40px"} />
          <Skeleton width={"100%"} height={"40px"} />
          <Skeleton width={"100%"} height={"40px"} />
        </>
      ) : (
        <div className={styles.running_bots_table}>
          <table>
            <tbody>
              {displayedBots?.map((bot) => {
                return (
                  <tr key={bot.id}>
                    <td>{bot.name}</td>
                    <td
                      className={
                        bot.status ? styles.status_green : styles.status_red
                      }
                    >
                      {bot.status ? "ACTIVE" : "DISABLED"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {showRunningBots && (
        <AppBackdrop
          openModal={showRunningBots}
          handleCloseModal={() => {
            setShowRunningBots(false);
          }}
          child={
            <RunningBots
              FEpermissions={FEpermissions}
              botsStatus={botsStatus}
              setBotsStatus={setBotsStatus}
              platformBots={bots}
              handleGetPaymentPlatformsHighlights={
                ""
                //handleGetPaymentPlatformsHighlights
              }
              setShowRunningBots={setShowRunningBots}
            />
          }
        />
      )}
    </div>
  );
}

export default AvailableBots;
