import React, {useContext, useEffect } from "react";
import styles from "./General.module.css";
import { Typography } from "@material-ui/core";
import LinearProgress from "@mui/material/LinearProgress";
import ButtonMain from "../../../../components/button/Button";
import { MiscContextAPI } from "../../../../Context/misc/MiscContext";
import InputWithText from "../../../../components/inputs/inputsWithText/InputWithText";
import { useForm } from "react-hook-form";
import LabeledSelects from "../../../../components/selects/labeledSelects/LabeledSelects";
import Switch from "../../../../components/switch/Switch";
import { GET_GENERAL_SETTINGS } from "../../../../API/queries/settings/general/General";
import { useMutation, useQuery } from "react-query";
import { SAVE_GENERAL_SETTINGS } from "../../../../API/mutations/settings/general/General";
const General = () => {
  const { handleGetChannels, channels } = useContext(MiscContextAPI);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();


  const system_status = watch("system_status", false);
  const operations_mode = watch("operations_mode", false);
  const auto_market_rate = watch("auto_market_rate", false);

  const { data, isLoading } = useQuery(
    ["general"],
    () => GET_GENERAL_SETTINGS(),
    {
      refetchOnWindowFocus: false,
    }
  );


  const {isLoading: mutating, mutate} = useMutation(SAVE_GENERAL_SETTINGS, {
    onSuccess: (data)=>{
    
    },
    onError: (error)=>{

    }
  })


  useEffect(() => {
    handleGetChannels()   
  }, []);


  useEffect(() => {
    if (data != null) {
      setValue("frequency", data?.data?.market_frequency);
      setValue("payment_channel", data?.data?.default_channel);
      setValue("system_status", data?.data?.system_status);
      setValue("operations_mode", data?.data.auto_bot);
      setValue("auto_market_rate", data?.data?.auto_market_rate);

     
    } else {
    
    }
  }, [data]);

  const onSubmit = (data) => {
    mutate ({
      system_status: data?.system_status,
      market_frequency: Number(data?.frequency),
      bot_frequency: Number(data?.frequency),
      default_channel: data?.payment_channel,
      auto_bot: data?.operations_mode,
      auto_market_rate: data?.auto_market_rate,
    })
  };

 

  return (
    <div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div className={styles.general}>
          <div className={styles.general_content}>
            <div
              className={`${styles.general_content_item} ${styles.general_content_item_flex}`}
            >
              <div className={styles.general_content_item_up}>
                <div className={styles.general_content_item_left}>
                  <Typography variant="h6">System status</Typography>
                  <Typography>
                    If turned off, none of the bots would work.
                  </Typography>
                </div>
                <div className={styles.general_content_item_right}>
                  <Switch
                    register={register}
                    name="system_status"
                    checkedStatus={system_status}
                  />
                </div>
              </div>
              <div className={styles.general_content_item_down}>
                <div className={styles.general_content_item_left}>
                  <Typography variant="h6">Operations mode</Typography>
                  <Typography>
                    If turned off, all transactions and resolutions would be
                    handled manually
                  </Typography>
                </div>
                <div className={styles.general_content_item_right}>
                  <Switch
                    register={register}
                    name="operations_mode"
                    checkedStatus={operations_mode}
                  />
                </div>
              </div>
            </div>
            <div className={styles.general_content_item}>
              <div className={styles.general_content_item_left}>
                <Typography variant="h6">Default payment processor </Typography>
                <Typography>
                  Set payment processor for resolving errors
                </Typography>
              </div>
              <div className={styles.general_content_item_right}>
                <LabeledSelects
                  placeholder="Payment channels"
                  register={register}
                  data={channels}
                  name="payment_channel"
                  required={true}
                />
              </div>
            </div>
            <div
              className={`${styles.general_content_item} ${styles.general_content_item_flex}`}
            >
              <div className={styles.general_content_item_up}>
                <div className={styles.general_content_item_left}>
                  <Typography variant="h6">Auto market rate</Typography>
                  <Typography>Fetch market rates automatically</Typography>
                </div>
                <div className={styles.general_content_item_right}>
                  <Switch
                    register={register}
                    name="auto_market_rate"
                    checkedStatus={auto_market_rate}
                  />
                </div>
              </div>
              <div className={styles.general_content_item_down}>
                <div className={styles.general_content_item_left}>
                  <Typography variant="h6">Market frequency</Typography>
                  <Typography>Automate market rate refresh feed</Typography>
                </div>
                <div className={styles.general_content_item_right}>
                  <InputWithText
                    text="Mins"
                    name="frequency"
                    type="text"
                    register={register}
                    required={true}
                  />
                </div>
              </div>
            </div>
            <div className={styles.general_content_item_btn}>
              <ButtonMain
                bgColor="#1A2CCE"
                textColor="white"
                btnText="Save changes"
                handleClick={handleSubmit(onSubmit)}
                loading={mutating}
              />
            
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default General;
