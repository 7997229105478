import React from 'react';


const Edit4 = () => {
    return (
        <div>
            the edit 4
        </div>
     );
}
 
export default Edit4;