import { Typography, Grid } from "@material-ui/core";
import React from "react";
import styles from "./PostAds.module.css";

const Post3 = ({autoReplyMsg, handleChange, registered, holding, handleRadioChange, handleConditionCheckboxChange , remarks}) => {
  return (
    <div className={styles.edit3}>
      <div className={styles.remark_auto}>
      <div className={styles.trade_remark}>
        <label htmlFor="">
          Trade Remarks (Optional)
          <textarea
            placeholder="Give more information about your trade guidelines"
            name="remarks"
            value={remarks}
            onChange={handleChange}
            cols="30"
            rows="10"
          ></textarea>
        </label>
      </div>
      <div className={styles.trade_remark}>
        <label htmlFor="">
        Auto reply (Optional)
          <textarea
            placeholder="This is an auto reply message"
            name="auto-reply"
            value={autoReplyMsg}
            onChange={handleChange}
            cols="30"
            rows="10"
          ></textarea>
        </label>
      </div>
      </div>
    
      <div className={styles.conditions_status}>
      <div className={styles.condition}>
        <Typography>Conditions</Typography>
        <div className={styles.checkboxes}>
          <label htmlFor="">
            <input type="checkbox"  onChange={handleConditionCheckboxChange} name="Completed KYC" />
            Completed KYC
          </label>

          <label htmlFor="">
            {/* <input type="checkbox" onChange={handleConditionCheckboxChange} name="Registered " /> */}
            Registered <input type="text" name='registered' value={registered}  onChange={handleChange}  /> days ago
          </label>

          <label htmlFor="">
            {/* <input type="checkbox" onChange={handleConditionCheckboxChange} name="Holding more than" /> */}
            Holding more than <input name='holding' value={holding}  onChange={handleChange}  type="text" /> BTC
          </label>
        </div>
      </div>
      <div className={styles.status}>
        <Typography>Status</Typography>
        <div className={styles.status_radios}>
          <label htmlFor="">
            <input type="radio" onChange={handleRadioChange}   value={true} name="status" id="" />
            Online right now
          </label>
          <label htmlFor="">
            <input type="radio" onChange={handleRadioChange}  value={false} name="status" id="" />
            Offline, Manually later
          </label>
        </div>
      </div>
      </div>
     
    </div>
  );
};

export default Post3;
