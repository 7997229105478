import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { CREATE_GROUP } from "../../../../../../API/mutations/settings/userGroups/UserGroups";
import {
  GET_AVAILABLE_CRYPTOS,
  GET_AVAILABLE_FIATS,
  GET_AVAILABLE_PERMISSIONS,
  GET_PLATFORMS,
} from "../../../../../../API/queries/settings/userGroups/UserGroup";
import P2PSnackbar from "../../../../../../components/AppSnackbars/P2PSnackbar";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";

import useOpenSnackbar from "../../../../../../custom-hooks/useOpenSnackbar";
import AddGroupModal from "../../components/addGroup/AddGroup";
import Manage3rdPartyAccess from "../../components/permissions/Manage3rdPartyAccess";

function CreateGroup({
  setShowMangeGroupModal,
  setUserGroupModal,

  handleCloseModal,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { openSnackbar, setOpenToast, message, duration, severity, openToast } =
    useOpenSnackbar();
  const queryClient = useQueryClient();
  const [screen, setScreen] = useState("add");

  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [platformName, setPlatformName] = useState("");
  const [groupName, setGroupName] = useState("");
  const [permission_controls, setPermiossionControl] = useState([]);
  const [fiat_currencies, setFiatCurrencies] = useState([]);
  const [crypto_currencies, setCryptoCurrencies] = useState([]);

  const { data: availableCryptos, isLoading: isLoadingAvailableCryptos } =
    useQuery(["available-cryptos"], () => GET_AVAILABLE_CRYPTOS(), {
      refetchOnWindowFocus: false,
    });

  const {
    data: availablePermissions,
    isLoading: isLoadingAvailablePermissions,
  } = useQuery(["available-permissions"], () => GET_AVAILABLE_PERMISSIONS(), {
    refetchOnWindowFocus: false,
  });

  const { data: availableFiats, isLoading: isLoadingAvailableFiats } = useQuery(
    ["available-fiats"],
    () => GET_AVAILABLE_FIATS(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: paymentPlatforms, isLoading: isLoadingPlatforms } = useQuery(
    ["platform-lists"],
    () => GET_PLATFORMS(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(CREATE_GROUP, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user-groups"]);
      handleCloseModal();
    },
    onError: () => {},
  });

  const handleFileChange = (event) => {
    const { name } = event.target;

    if (name === "groupImage") {
      if (event.target.files.length > 0) {
        setImage(URL.createObjectURL(event.target.files[0]));
        setFile(event.target.files[0]);
      } else {
      }
    }
  };

  const handleNext = (data) => {
    if (file !== undefined) {
      setPlatformName(data.platform);
      setGroupName(data.group_name);
      setScreen("permission");
    } else {
      openSnackbar("error", "please fill all fields before proceeding");
    }
  };

  const handleAddNewGroup = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append("image", file);
    formData.append("name", groupName);
    formData.append("platform", platformName);

    for (var i = 0; i < permission_controls.length; i++) {
      formData.append("permission_controls[]", permission_controls[i]);
    }
    for (var i = 0; i < crypto_currencies.length; i++) {
      formData.append("crypto_currencies[]", crypto_currencies[i]);
    }

    for (var i = 0; i < fiat_currencies.length; i++) {
      formData.append("fiat_currencies[]", fiat_currencies[i]);
    }

    mutate({
      formData,
    });
  };

  switch (screen) {
    case "permission":
      return (
        <ModalFrame
          handleCancel={() => {
            setCryptoCurrencies([]);
            setPermiossionControl([]);
            setFiatCurrencies([]);
            setScreen("add");
          }}
          handleAction={handleAddNewGroup}
          loading={isLoading}
          showCancelBtn
          showConfirmBtn
          btnTxt="Confirm"
          child={
            <Manage3rdPartyAccess
           
              availableCryptos={availableCryptos?.data}
              availableFiats={availableFiats?.data}
              availablePermissions={availablePermissions?.data}
              crypto_currencies={crypto_currencies}
              permission_controls={permission_controls}
              fiat_currencies={fiat_currencies}
              setPermiossionControl={setPermiossionControl}
              setFiatCurrencies={setFiatCurrencies}
              setCryptoCurrencies={setCryptoCurrencies}
            />
          }
        />
      );
    case "add":
      return (
        <ModalFrame
          handleCancel={() => {
            handleCloseModal();
          }}
          handleAction={handleSubmit(handleNext)}
          showCancelBtn
          showConfirmBtn={
            !(
              isLoadingAvailableCryptos ||
              isLoadingAvailableFiats ||
              isLoadingAvailablePermissions ||
              isLoadingPlatforms
            )
          }
          btnTxt="Next"
          child={
            <>
             <AddGroupModal
                loading={
                  isLoadingAvailableCryptos ||
                  isLoadingAvailableFiats ||
                  isLoadingAvailablePermissions ||
                  isLoadingPlatforms
                }
               
                paymentPlatforms={paymentPlatforms?.data}
                handleFileChange={handleFileChange}
                image={image}
                register={register}
              />
              {openToast && (
                <P2PSnackbar
                  open={openToast}
                  setOpen={setOpenToast}
                  severity={severity}
                  message={message}
                  duration={duration}
                />
              )}
            </>
          }
        />
      );
    default:
      return (
        <ModalFrame
          handleCancel={() => {
            handleCloseModal();
          }}
          handleAction={handleSubmit(handleNext)}
          showCancelBtn
          showConfirmBtn={
            !(
              isLoadingAvailableCryptos ||
              isLoadingAvailableFiats ||
              isLoadingAvailablePermissions ||
              isLoadingPlatforms
            )
          }
          btnTxt="Next"
          child={
            <>
              <AddGroupModal
                loading={
                  isLoadingAvailableCryptos ||
                  isLoadingAvailableFiats ||
                  isLoadingAvailablePermissions ||
                  isLoadingPlatforms
                }
           
                paymentPlatforms={paymentPlatforms?.data}
                handleFileChange={handleFileChange}
                image={image}
                register={register}
              />
              {openToast && (
                <P2PSnackbar
                  open={openToast}
                  setOpen={setOpenToast}
                  severity={severity}
                  message={message}
                  duration={duration}
                />
              )}
            </>
          }
        />
      );
  }
}

export default CreateGroup;
