import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { UserContextAPI } from "../../../Context/user/UserContext";
import styles from "./TrxSummaryHeading.module.css";

function TrxSummaryHeading({type, match, handleShowReportDetailsModal }) {
  const { user } = useContext(UserContextAPI);
  return (
    <div className={styles.audit_overview_heading}>
      <Typography>{type === 'balances' ? match.params.account_name : ''}</Typography>
      {user?.partner ? (
        user?.group?.permissions?.some((permission) => {
          return permission?.name === "Can download report";
        }) ? (
          <a onClick={handleShowReportDetailsModal} href="">
            Download Report
          </a>
        ) : null
      ) : user?.permissions?.includes("download") ? (
        <a onClick={handleShowReportDetailsModal} href="">
          Download Report
        </a>
      ) : null}
    </div>
  );
}

export default TrxSummaryHeading;
