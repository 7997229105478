import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import AuthContext from "./Context/auth/AuthContext";
import UserContext from "./Context/user/UserContext";
import FEpermissionsContext from "./Context/FEpermissions/FEpermissions";
import ActiveLinkContext from "./Context/activeLink/ActiveLinkContext";
import SupportContext from "./Context/support/SupportContext";
import MiscContext from "./Context/misc/MiscContext";

import { QueryClient, QueryClientProvider } from "react-query";
// console.log = console.warn = console.error = () => {};

// console.error('Something bad happened.');

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContext>
        <UserContext>
          <FEpermissionsContext>
            <ActiveLinkContext>
              <SupportContext>
       
                  <MiscContext>
                    <Router>
                      <App />
                    </Router>
                  </MiscContext>
            
              </SupportContext>
            </ActiveLinkContext>
          </FEpermissionsContext>
        </UserContext>
      </AuthContext>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
