// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrxSummary_audit_overview__qtd3a {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n\n", "",{"version":3,"sources":["webpack://src/components/transaction/trxSummary/TrxSummary.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".audit_overview {\n  display: flex;\n  flex-direction: column;\n  margin-top: 20px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"audit_overview": "TrxSummary_audit_overview__qtd3a"
};
export default ___CSS_LOADER_EXPORT___;
