import axios from "axios";

export const RESOLVE_ERROR = async (data) => {
  return axios
    .post(
      `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/resolve/error/${data.id}`,
      data.data
    )
    .then((res) => {
      return res.data;
    });
};

export const RESOLVE_ADVANCED_ERROR = async (data) => {
  return axios({
    method: "post",
    url: `${
      process.env.REACT_APP_BASE_URL
    }/api/v1/transactions/advanced/error/${data.id}?actions[]=${
      data.markAsPaidOnBinanceChecked ? "binance" : null
    }&actions[]=${data.accountNumberChecked ? "payment" : null}`,
    data: data.data,
    headers: { "Content-Type": "multipart/form-data" },
  }).then((res) => {
    return res.data;
  });
};
