// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TransactionProcessed_dashboard_transaction__3WwK3 {\n    width: 100%;\n    background-color: white;\n    margin-top: 20px;\n    height: 470px;\n    padding: 30px 43px;\n    display: flex;\n    overflow: hidden;\n}", "",{"version":3,"sources":["webpack://src/pages/home/userHome/components/transaction/TransactionProcessed.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,uBAAuB;IACvB,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".dashboard_transaction {\n    width: 100%;\n    background-color: white;\n    margin-top: 20px;\n    height: 470px;\n    padding: 30px 43px;\n    display: flex;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_transaction": "TransactionProcessed_dashboard_transaction__3WwK3"
};
export default ___CSS_LOADER_EXPORT___;
