// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BotGrid_bot_width__16fWA {\n}\n\n.BotGrid_bot_grid__1_LZI {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.BotGrid_bot_grid__1_LZI img {\n    margin-right: 10px;\n}\n.BotGrid_bot_grid__1_LZI h6 {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #1a1f36;\n  cursor: pointer;\n  text-transform: capitalize;\n  margin-right: 10px;\n}\n\n", "",{"version":3,"sources":["webpack://src/pages/home/userHome/modals/runningbots/components/botGrid/BotGrid.module.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;IACI,kBAAkB;AACtB;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,cAAc;EACd,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;AACpB","sourcesContent":[".bot_width {\n}\n\n.bot_grid {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n.bot_grid img {\n    margin-right: 10px;\n}\n.bot_grid h6 {\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 14px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: #1a1f36;\n  cursor: pointer;\n  text-transform: capitalize;\n  margin-right: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bot_width": "BotGrid_bot_width__16fWA",
	"bot_grid": "BotGrid_bot_grid__1_LZI"
};
export default ___CSS_LOADER_EXPORT___;
