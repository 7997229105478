import axios from "axios";

export const GET_USER_GROUPS = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/settings/groups`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_SINGLE_GROUP = async (id) => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/groups/${id}/view`
      )
      .then((response) => {
        return response.data;
      });
  };




export const GET_AVAILABLE_CRYPTOS = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/crypto/currencies`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const GET_AVAILABLE_FIATS = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/fiat/currencies`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const GET_AVAILABLE_PERMISSIONS = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/permissions`
      )
      .then((response) => {
        return response.data;
      });
  };

  


  export const GET_PLATFORMS = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/platform`
      )
      .then((response) => {
        return response.data;
      });
  };
 