import React from "react";
import scan_icon from "./../../../../components/assets/Group 1761.svg";
import styles from "../../Resolve.module.css";
import { Typography } from "@material-ui/core";
import arrowIcon from "./../../../../components/assets/Polygon 6.svg";
import ButtonMain from "../../../../components/button/Button";

const ResolveActions = ({
  handleOpenBarcodeModal,
  openActions,
  handleCloseActions,
  handleOpenAdvancedModal,
  openModal,
  handleOpenModal2,
  handleOpenActions,
  issue,
}) => {
  return (
    <>
      <img onClick={handleOpenBarcodeModal} src={scan_icon} alt="" />
      <ButtonMain
        border="0.4px solid #1A2CCE"
        length="183px"
        bgColor="#1A2CCE"
        textColor="white"
        btnText={" View Actions"}
        handleClick={(e) => {
          handleOpenActions();
          e.stopPropagation();
        }}
        hideLoader
      />

      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={`${styles.resolve_tab_actions} ${
          openActions ? styles.resolve_tab_actions_active : null
        }`}
      >
        <img src={arrowIcon} alt="" />
        {issue?.error === "Resolution" ? (
          <Typography
            onClick={() => {
              openModal();
              handleCloseActions();
            }}
          >
            {" "}
            Resolve Now
          </Typography>
        ) : (
          <Typography
            onClick={() => {
              openModal();
              handleCloseActions();
            }}
          >
            {" "}
            Mark as paid
          </Typography>
        )}

        <Typography
          onClick={() => {
            handleOpenModal2();
            handleCloseActions();
          }}
        >
          Retry Payment
        </Typography>
        <Typography
          onClick={() => {
            handleOpenAdvancedModal();
            handleCloseActions();
          }}
        >
          Advanced
        </Typography>
      </div>
    </>
  );
};

export default ResolveActions;
