import React from "react";
import { Redirect, Route } from "react-router-dom";

function ProtectedRoute2({ component: Component,auth, path, ...restOfProps }) {
  const isAuthenticated = auth
  

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Redirect to="/dashboard" /> : <Component {...props} /> 
      }
    />
  );
}

export default ProtectedRoute2;