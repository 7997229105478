import React from 'react'
import styles from './Switch.module.css'


function Switch({ checkedStatus, register, name}) {
  
  return (
    <div
    
    className={
      checkedStatus ? styles.my_switch : styles.my_switch_active
    }
  >
    <div className={styles.input_checkbox}>
    <input type="checkbox" {...register(name, {})}  />
    </div>
   
    <div className={styles.switch_ball}></div>
  </div>
  )
}

export default Switch