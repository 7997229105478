import React from "react";
import { useContext } from "react";
import UserDashboard from "./userHome/UserDashboard";
import PartnersDashboard from "./PartnerHome/PartnersDashboard";
import PageLayout from "../../components/Layout/PageLayout";
import { UserContextAPI } from "../../Context/user/UserContext";

const Dashboard = () => {
  const { user } = useContext(UserContextAPI);

  return (
    <PageLayout
      bgColor="#F3F6FB"
      child={
        <>
          {user?.partner ? (
            <PartnersDashboard />
          ) : (
            //run if staff
            <UserDashboard />
          )}
        </>
      }
    />
  );
};

export default Dashboard;
