import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  GET_PARTNER_FILTERED_TRX_LOGS,
  GET_PARTNER_TRX_LOGS,
  GET_PARTNER_TRX_SUM,
  GET_PARTNER_TRX_SUMMARY,
} from "../../../API/queries/transactions/Transactions";
import Transactions from "../../../components/transaction/Transaction";

const tableHeadData = [
  "order no",
  "received",
  "paid",
  "status",
  "error",
  "bank",
];

const PartnersDashboard = () => {
  const queryClient = useQueryClient();
  const [transaction, setTransaction] = useState({});
  const [loadingFilteredData, setLoadingFilteredData] = useState(false);
  const [filterData, setFilterData] = useState({});

  const { data: trxSum, isLoading: trxSumLoading } = useQuery(
    ["trx-total-bal"],
    () => GET_PARTNER_TRX_SUM(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: trxSummary, isLoading: trxSummaryLoading } = useQuery(
    ["trx-summary"],
    () => GET_PARTNER_TRX_SUMMARY(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: auditLogs, isLoading: isTrxLoading } = useQuery(
    [`audit-logs`],
    GET_PARTNER_TRX_LOGS,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setTransaction(auditLogs);
  }, [auditLogs]);

  const handleGetFilterQueries = async (data) => {
    setLoadingFilteredData(true);
    const { bank, crypto, error } = data;
    setFilterData(data);
    queryClient
      .fetchQuery("filtered-data", () =>
        GET_PARTNER_FILTERED_TRX_LOGS({
          page: "",
          queryData: {
            bank: bank ? bank : "",
            crypto: crypto ? crypto : "",
            error: error ? error : "",
          },
          trans_ref: "",
        })
      )
      .then((data) => {
   
        setTransaction(data);
        setLoadingFilteredData(false);
      })
      .catch((error) => {
        setTransaction({});
        setLoadingFilteredData(false);
      });
  };

  const handlePageChange = async (page) => {
    setLoadingFilteredData(true);
    queryClient
      .fetchQuery("filtered-data", () =>
        GET_PARTNER_FILTERED_TRX_LOGS({
          page,
          queryData: {
            bank: filterData.bank ? filterData.bank : "",
            crypto: filterData.crypto ? filterData.crypto : "",
            error: filterData.error ? filterData.error : "",
          },
          trans_ref: "",
        })
      )
      .then((data) => {
        setTransaction(data);
        setLoadingFilteredData(false);
      })
      .catch((error) => {
        setTransaction({});
        setLoadingFilteredData(false);
      });
  };

  const handleSearchOrderNoSubmit = async (data) => {
    const { trans_ref } = data;
    setLoadingFilteredData(true);
    queryClient
      .fetchQuery("filtered-data", () =>
        GET_PARTNER_FILTERED_TRX_LOGS({
          trans_ref,
          queryData: {
            bank: filterData.bank ? filterData.bank : "",
            crypto: filterData.crypto ? filterData.crypto : "",
            error: filterData.error ? filterData.error : "",
          },
          page: "",
        })
      )
      .then((data) => {
        setTransaction(data);
        setLoadingFilteredData(false);
      })
      .catch((error) => {
        setTransaction({});
        setLoadingFilteredData(false);
      });
  };

  const handleRefresh = async () => {
    setFilterData({});
    queryClient.resetQueries(["audit-logs"]);
    queryClient.resetQueries(["trx-total-bal"]);
    queryClient.resetQueries(["trx-summary"]);
  };

  return (
    <Container>
      <Transactions
      setFilterData={ setFilterData}
        handleSearchOrderNoSubmit={handleSearchOrderNoSubmit}
        handlePageChange={handlePageChange}
        handleGetFilterQueries={handleGetFilterQueries}
        handleRefresh={handleRefresh}
        type="partner-logs"
        trxSum={trxSum?.data?.transactions}
        trxSumLoading={trxSumLoading}
        trxSummary={trxSummary?.data?.transactions}
        trxSummaryLoading={trxSummaryLoading}
        auditLogs={transaction?.data}
        isTrxLoading={isTrxLoading || loadingFilteredData}
        tableHeadData={tableHeadData}
        title={<Typography variant="h4">TOTAL CRYPTO COLLECTED</Typography>}
      />
    </Container>
  );
};

export default PartnersDashboard;
