import { LinearProgress, Typography } from "@material-ui/core";
import React from "react";
import styles from "./AddGroup.module.css";
import upload from "./../../../../../../components/assets/Vector (26).svg";
import default_img from "./../../../../../../components/assets/Group 1472.svg";

import LabeledSelects from "../../../../../../components/selects/labeledSelects/LabeledSelects";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";


const AddGroupModal = ({
  paymentPlatforms,
  loading,
  handleFileChange,
  image,
  register,
}) => {
  



 

  return (
    <>
      <div className={styles.user_group_modal_content}>
        <form action="">
          {loading ? (
            <LinearProgress />
          ) : (
            <>
              <div className={styles.form_heading}>
                <Typography>Add a new group</Typography>
              </div>
              <div className={styles.form_field}>
                <div className={styles.input_file}>
                  <div className={styles.input_file_content}>
                    {image === undefined ? (
                      <img src={upload} alt="" />
                    ) : (
                      <img src={image ? image : default_img} alt="" />
                    )}
                    <Typography>
                      Upload group image (JPG,PNG,WEBP,JPEG)
                    </Typography>
                  </div>
                  <input
                    onChange={handleFileChange}
                    type="file"
                    name="groupImage"
                    id=""
                  />
                </div>
              </div>

              <div className={styles.form_field}>
                <LabeledInputs
                  register={register}
                  label="Name of group"
                  name="group_name"
                  type="text"
                  required={true}
                />
              </div>

              <div className={styles.form_field}>
                <LabeledSelects
                  register={register}
                  placeholder="Choose platform"
                  name="platform"
                  label="Select platform"
                  data={paymentPlatforms}
                />
              </div>

              {/* <div className={styles.new_group_btns}>
                    <button onClick={handleCancel}>Cancel</button>
                    <button onClick={handleSubmit(onSubmit)}>Next</button>
                  </div> */}
            </>
          )}
        </form>
      </div>

    
    </>
  );
};

export default AddGroupModal;
