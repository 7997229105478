import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import styles from "./OtpCode.module.css";
import OtpInput from "react-otp-input";
import ButtonMain from "../../../../../components/button/Button";

const OtpModal = ({
  otp,
  otpError,
  handleChange,
  isLoading,

  handleAuthenticateOTP,
  handleCloseModal,
}) => {
  const handleOtpModalConfirm = () => {
    handleAuthenticateOTP();
    //setAuthenticated(true);
    //setShowEnable2faModal(false);
  };

  const handleOtpModalClose = () => {
    handleCloseModal();
  };
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.otp_modal_content}
    >
      <div className={styles.otp_modal_heading}>
        <Typography variant="h6">Enter your OTP</Typography>
        <Typography>
          Enter the 6-digit code generated on your authenicator app
        </Typography>
      </div>
      <div
        className={`${styles.otp_modal_inputs} ${
          otpError?.otp ? styles.red_outline : null
        }`}
      >
        <OtpInput
          value={otp}
          onChange={handleChange}
          numInputs={6}
          separator={<span></span>}
        />
        {otpError?.otp ? (
          <Typography style={{ color: "red", marginTop: "10px" }}>
            6 digit OTP code is required
          </Typography>
        ) : null}
      </div>
      <div className={styles.otp_modal_btns}>
        <ButtonMain
          loading={isLoading}
          fullLength
          bgColor="#1A2CCE"
          textColor="white"
          btnText="Confirm"
          handleClick={handleOtpModalConfirm}
        />
        <ButtonMain
          fullLength
          bgColor="white"
          textColor="#5B5B5B"
          btnText="Back to scan"
          handleClick={handleOtpModalClose}
        />
      </div>
    </div>
  );
};

export default OtpModal;
