// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MarketRate_current_rate__3QLv1 {\n    padding: 10px 30px 10px 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.MarketRate_current_rate_heading__29YWi {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.MarketRate_current_rate_heading__29YWi p {\n  font-size: 12px;\n\n  font-weight: 700;\n \n}\n\n.MarketRate_current_market_table__3991A table {\n    width: 100%;\n}\n\n.MarketRate_current_market_table__3991A td {\n  font-size: 14px;\n  font-weight: 400;\n  padding: 8px 0;\n  text-transform: capitalize;\n}\n\n.MarketRate_rate__1EPLd {\n  text-align: right;\n  font-weight: 400 !important;\n}\n", "",{"version":3,"sources":["webpack://src/pages/home/userHome/components/overviewCards/components/rates/MarketRate.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,eAAe;;EAEf,gBAAgB;;AAElB;;AAEA;IACI,WAAW;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,iBAAiB;EACjB,2BAA2B;AAC7B","sourcesContent":[".current_rate {\n    padding: 10px 30px 10px 0;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.current_rate_heading {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.current_rate_heading p {\n  font-size: 12px;\n\n  font-weight: 700;\n \n}\n\n.current_market_table table {\n    width: 100%;\n}\n\n.current_market_table td {\n  font-size: 14px;\n  font-weight: 400;\n  padding: 8px 0;\n  text-transform: capitalize;\n}\n\n.rate {\n  text-align: right;\n  font-weight: 400 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"current_rate": "MarketRate_current_rate__3QLv1",
	"current_rate_heading": "MarketRate_current_rate_heading__29YWi",
	"current_market_table": "MarketRate_current_market_table__3991A",
	"rate": "MarketRate_rate__1EPLd"
};
export default ___CSS_LOADER_EXPORT___;
