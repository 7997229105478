import {
  Container,

  Stepper,
  Typography,
  Step,
  StepLabel,

} from "@material-ui/core";
import React from "react";
import styles from "./EditAds.module.css";
import { useState } from "react";
import Edit1 from "./Edit1";
import Edit2 from './Edit2';
import Edit3 from './Edit3';
import Edit4 from './Edit4';
import GoBack from "../../../components/goBack/GoBack";

function getSteps() {
  return [
    "Set type/price",
    "Set trading amount & payment method",
    "Set remarks & Automatic remarks",
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Set type/price";
    case 1:
      return "Set trading amount & payment method";
    case 2:
      return "Set remarks & Automatic remarks";
    default:
      return "Unknown stepIndex";
  }
}

const EditAds = ({ match }) => {
  const steps = getSteps();
  const [checkedB, setCheckedB] = useState(true);
 
  const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        if (activeStep < 2) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        } else {
            
      }
  
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const handleChange = (event) => {
    setCheckedB(event.target.checked);
  };


   const handleSwitch = () => {
    setCheckedB(!checkedB);
  };


  return (
    <div className={styles.edit_ads}>
      <Container>
        <div className={styles.edit_ads_content}>
        <GoBack text='Go Back'/>
          <div className={styles.edit_ads_heading}>
            <Typography>Edit Ad</Typography>
          </div>
          <div className={styles.steppers}>
            <Stepper
              className={styles.my_stepper}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step className={styles.my_step} key={label}>
                  <StepLabel className={styles.my_step_label}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
                  </div>
                  {
                     
                      activeStep == 0 ? <Edit1 checkedB={checkedB} handleSwitch={handleSwitch} handleChange={handleChange} /> : null
                      
                  }
                  {
                       activeStep== 1 ?  <Edit2 checkedB={checkedB} handleChange={handleChange} /> : null
                  }
                  {
                       activeStep== 2 ?  <Edit3 checkedB={checkedB} handleChange={handleChange} /> : null
                  }
                  {
                       activeStep== 3 ?  <Edit4 checkedB={checkedB} handleChange={handleChange} /> : null
                  }
                
       
          <div className={styles.stepper_btns}>
            <button disabled={activeStep === 0} onClick={handleBack}>
              Previous
            </button>
            <button  variant="contained" color="primary" onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Save changes" : "Next"}
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EditAds;
