import axios from "axios";

export const GET_BANKS = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/bank`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_CHANNELS = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/transactions/channel`
      )
      .then((response) => {
        return response.data;
      });
  };

  export const GET_PAIRS = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/market/pair`
      )
      .then((response) => {
        return response.data;
      });
  };