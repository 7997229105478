import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styles from "./Notifications.module.css";
// import Balances from "./balances/Balances";


function Notifications() {
  return (
    <div className={styles.notifications}>
      <Accordion expanded={true} className={styles.my_accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={styles.my_accordion_summary}
        >
          <Typography>Balances</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {/* <Balances/> */}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Notifications;
