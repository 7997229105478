// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchInput_search__2GcR- {\n    height: 46px;\n    border-radius: 3px;\n    border: 0.7px solid #dfdfdf;\n    box-sizing: border-box;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);\n    border-radius: 3px;\n    display: flex;\n  }\n  .SearchInput_search__2GcR-:hover {\n    border: 2px solid #c6ddff !important;\n  }\n  .SearchInput_search__2GcR- input:hover {\n    border: none !important;\n  }\n  \n  .SearchInput_search__2GcR- div {\n    height: 100%;\n    width: 41px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #ffffff;\n    cursor: pointer;\n  }\n  \n  .SearchInput_search__2GcR- input {\n    width: 100%;\n    border: none;\n    background: #ffffff;\n    outline: none;\n  }", "",{"version":3,"sources":["webpack://src/components/search/SearchInput.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;IAClB,2BAA2B;IAC3B,sBAAsB;IACtB,4CAA4C;IAC5C,kBAAkB;IAClB,aAAa;EACf;EACA;IACE,oCAAoC;EACtC;EACA;IACE,uBAAuB;EACzB;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,aAAa;EACf","sourcesContent":[".search {\n    height: 46px;\n    border-radius: 3px;\n    border: 0.7px solid #dfdfdf;\n    box-sizing: border-box;\n    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);\n    border-radius: 3px;\n    display: flex;\n  }\n  .search:hover {\n    border: 2px solid #c6ddff !important;\n  }\n  .search input:hover {\n    border: none !important;\n  }\n  \n  .search div {\n    height: 100%;\n    width: 41px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: #ffffff;\n    cursor: pointer;\n  }\n  \n  .search input {\n    width: 100%;\n    border: none;\n    background: #ffffff;\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": "SearchInput_search__2GcR-"
};
export default ___CSS_LOADER_EXPORT___;
