import { Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { GET_STATISTICS } from "../../../../../API/queries/dashboard/Dashboard";
import LabeledSelects from "../../../../../components/selects/labeledSelects/LabeledSelects";
import styles from "./TransactionProcessed.module.css";

const TransactionProccessedChart = () => {
  const {
    register,
    setValue,

    watch,
    formState: { errors },
  } = useForm();
  const { data: statistics, isLoading } = useQuery(
    ["statistics"],
    GET_STATISTICS,
    {
      retry: false,
      retryOnMount: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const period = watch("period", "");
  const [displayedChart, setDisplayedChart] = useState([]);
  const [yearlyChart, setYearlyChart] = useState([]);
  const [weeklyChart, setWeeklyChart] = useState([]);
  const [DailyChart, setDailyChart] = useState([]);

  useEffect(() => {
    setValue("period", "Weekly");
  }, []);

  useEffect(() => {
    handleGetDashboardChart(statistics?.data);
  }, [statistics]);

  useEffect(() => {
    setDisplayedChart(weeklyChart);
  }, [weeklyChart]);

  useEffect(() => {
    handleChangeChartAccordingToTimeframe(period);
  }, [period]);

  const handleGetDashboardChart = async (data) => {
    const tempYearlyChart = data?.yearlyAnalytics?.map((item) => {
      return { x: item.month, y: parseInt(item.count) };
    });
    setYearlyChart(tempYearlyChart);

    const tempWeeklyChart = data?.weeklyAnalytics?.map((item) => {
      return { x: item.weekDay, y: parseInt(item.count) };
    });
    setWeeklyChart(tempWeeklyChart);
    setDisplayedChart(tempWeeklyChart);
    const tempDailyChart = data?.dailyAnalytics?.map((item) => {
      return { x: item.day, y: parseInt(item.count) };
    });
    setDailyChart(tempDailyChart);
  };

  const handleChangeChartAccordingToTimeframe = (period) => {
    if (period === "") {
      setDisplayedChart(weeklyChart);
    }
    if (period === "Daily") {
      setDisplayedChart(DailyChart);
    }
    if (period === "Weekly") {
      setDisplayedChart(weeklyChart);
    }
    if (period === "Yearly") {
      setDisplayedChart(yearlyChart);
    }
  };

  const options = {
    chart: {
      id: "basic-bar",
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [
            {
              icon: '<img src="/public/logo512.png" width="20">',
              index: 2,
              title: "tooltip of the icon",
              class: "custom-icon",
              click: function (chart, options, e) {
             
              },
            },
          ],
        },
      },
      // xaxis: {
      //   type: "category",
      //   categories: [
      //     "22th july",
      //     "23rd july",
      //     "24th july",
      //     "25th july",
      //     "26th july",
      //     "27th july",
      //   ],
      // },
    },

    dataLabels: {
      enabled: false,
      enabledOnSeries: undefined,
      formatter: function (val, opts) {
        return val;
      },
      textAnchor: "middle",
      distributed: false,
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "14px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: "bold",
        colors: undefined,
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        padding: 4,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: "#fff",
        opacity: 0.9,
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    series: [
      {
        data: displayedChart,
      },
    ],
    xaxis: {
      type: "category",
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: false,
        minHeight: undefined,
        maxHeight: 120,
        style: {
          colors: "#8A8A8A",
          fontSize: "12px",
          fontFamily: "SF UI Display",
          fontWeight: 600,
          cssClass: "apexcharts-xaxis-label",
        },
      },
    },

    grid: {
      show: true,
      borderColor: "#E9E9E9",
      strokeDashArray: 10,
      position: "back",
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.1,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
    },
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
  };
 
  return (
    <div className={styles.dashboard_transaction_chart}>
      <div className={styles.dashboard_transaction_heading}>
        <Typography>TRANSACTIONS PROCESSED</Typography>
        <div>
          <LabeledSelects
            register={register}
            name="period"
            label=""
            placeholder="Time frame"
            data={[{ name: "Daily" }, { name: "Weekly" }, { name: "Yearly" }]}
          />
        </div>
      </div>
      {isLoading ? (
        <Skeleton width={"100%"} height={"100%"} />
      ) : (
        <div>
          <Chart
            options={options}
            series={options.series}
            type="area"
            height="370px"
          />
        </div>
      )}
    </div>
  );
};

export default TransactionProccessedChart;
