import React, { useContext, useState } from "react";
import styles from "./Navbar.module.css";
import { Container, IconButton, Badge, Typography } from "@material-ui/core";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useHistory } from "react-router";
import Notifications from "./notifications/Notifications";
import Profile from "./profile/Profile";
import { AuthContextAPI } from "../../Context/auth/AuthContext";
import { UserContextAPI } from "../../Context/user/UserContext";
import { ActiveLinkContextAPI } from "../../Context/activeLink/ActiveLinkContext";
import {useQueryClient} from 'react-query'


const Navbar = () => {
  const { logout } = useContext(AuthContextAPI);
  const { user } = useContext(UserContextAPI);
  const { activeLink } = useContext(ActiveLinkContextAPI);
  const [support] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const queryClient = useQueryClient()
  const [anchorEl2, setAnchorEl2] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenu2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleLogout = () => {
    queryClient.removeQueries(['user'])
    logout();
  };

  const handleGoToEdit = (id, error) => {
    history.push(`/support/${error}/${id}`);
  };

  return (
    <div className={styles.navbar}>
      <Container>
        <div className={styles.navbar_content}>
          <div className={styles.navbar_page_title}>
            <Typography>{activeLink}</Typography>
          </div>
          <div className={styles.navbar_right_content}>
            <div className={styles.notification}>
              {!user?.partner &&
                (user?.permissions?.includes("support") ? (
                  <>
                    <IconButton
                      onClick={handleMenu2}
                      aria-label=""
                      color="inherit"
                    >
                      <Badge
                      overlap='rectangular'
                        className={styles.my_badge}
                        badgeContent={support.length}
                      >
                        <NotificationsIcon />
                      </Badge>
                    </IconButton>
                    <Notifications
                      handleGoToEdit={handleGoToEdit}
                      support={support}
                      handleClose2={handleClose2}
                      anchorEl2={anchorEl2}
                    />
                  </>
                ) : null)}
              {/* <Notifications openNotification={openNotification} /> */}
            </div>

            <IconButton
              className={styles.profile}
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
              <ExpandMoreIcon className={styles.arrow_down} />
            </IconButton>
            <Profile
              user={user}
              partner={user?.partner}
              anchorEl={anchorEl}
              handleClose={handleClose}
              handleLogout={handleLogout}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Navbar;
