// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.Camera_camera_modal__21laZ {\n    width: 340px;\n    height: 480px;\n}", "",{"version":3,"sources":["webpack://src/pages/supportErrorTab/modals/Camera/Camera.module.css"],"names":[],"mappings":";;AAEA;IACI,YAAY;IACZ,aAAa;AACjB","sourcesContent":["\n\n.camera_modal {\n    width: 340px;\n    height: 480px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"camera_modal": "Camera_camera_modal__21laZ"
};
export default ___CSS_LOADER_EXPORT___;
