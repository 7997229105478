import { Container, Typography, Grid } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Log.module.css";
import copy from "copy-text-to-clipboard";
import LinearProgress from "@mui/material/LinearProgress";
import NumberFormat from "react-number-format";
import moment from "moment";
import LogDrawer from "./drawer/Drawer";
import { useHistory } from "react-router-dom";
import PageLayout from "../../components/Layout/PageLayout";
import ButtonMain from "../../components/button/Button";
import P2PSnackbar from "../../components/AppSnackbars/P2PSnackbar";
import useOpenSnackbar from "../../custom-hooks/useOpenSnackbar";
import GoBack from "../../components/goBack/GoBack";
import { UserContextAPI } from "../../Context/user/UserContext";
import { useQuery } from "react-query";
import {
  GET_SINGLE_LOG,
  GET_SINGLE_LOG_PARTNER,
} from "../../API/queries/transactions/Transactions";

const Log = ({ match }) => {
  const { user } = useContext(UserContextAPI);

  const { openSnackbar, severity, message, openToast, setOpenToast, duration } =
    useOpenSnackbar();
  const [log, setLog] = useState({});
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const toggleDrawer = (anchor, open) => {
    setOpen(!open);
  };

  const { data: auditLog, isLoading: isTrxLoading } = useQuery(
    [`audit-log`, match.params.id],
    () => GET_SINGLE_LOG(match.params.id),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(!user?.partner),
    }
  );

  const { data: partnerLog, isLoading: isPartnerTrxLoading } = useQuery(
    [`partner-log`, match.params.id],
    GET_SINGLE_LOG_PARTNER,
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(user?.partner),
    }
  );

  useEffect(() => {
    if (auditLog) {
      setLog(auditLog?.data);
    }
  }, [auditLog]);

  useEffect(() => {
    if (partnerLog) {
      setLog(partnerLog?.data);
    }
  }, [partnerLog]);

  useEffect(() => {
    const controller = new AbortController();
    if (user?.partner) {
      //getParticularDataForGroup();
    } else {
      //getParticularData();
    }

    return () => {
      controller.abort();
    };
  }, []);

  

  const handleCopyToClipBoard = () => {
    const response = copy(log.trans_ref);
    if (response) {
      openSnackbar("success", "copied to clipboard");
    }
  };

  const handleViewTrxInSupport = () => {
    history.push(`/support/${log.error}/${log.id}`);
  };

  return (
    <PageLayout
      bgColor="#ffffff"
      child={
        <div className={styles.log}>
          <Container className={styles.my_container}>
            {isTrxLoading || isPartnerTrxLoading ? (
              <LinearProgress />
            ) : (
              <div className={styles.log_content}>
                <div className={styles.go_back}>
                  <GoBack text="Go Back" />
                  {user?.partner ? null : (
                    <div>
                      {log.error === "None" ? null : (
                        <ButtonMain
                          bgColor=""
                          textColor="white"
                          btnText="View transaction in support"
                          handleClick={handleViewTrxInSupport}
                          hideLoader
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className={styles.log_header}>
                  <div className={styles.amount_status}>
                    <div className={styles.amount}>
                      <Typography>
                        <NumberFormat
                          value={log.fiat_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={log.fiat_currency}
                        />{" "}
                      </Typography>
                    </div>
                    <div
                      className={
                        log.status === "Paid"
                          ? styles.status
                          : styles.status_red
                      }
                    >
                      <Typography>{log.status}</Typography>
                    </div>
                  </div>
                  {log.proof_of_payment === null ? null : (
                    <div className={styles.view_proof}>
                      <ButtonMain
                        bgColor=""
                        textColor="white"
                        btnText="View payment receipt"
                        handleClick={toggleDrawer}
                        hideLoader
                      />
                    </div>
                  )}
                </div>

                <div className={styles.txn}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <div className={styles.txn_title}>
                        <Typography>Date</Typography>
                      </div>
                      <div className={styles.txn_result}>
                        {" "}
                        <Typography>
                          {moment(log.created_at).format("Do MMM YYYY, h:mm a")}
                        </Typography>{" "}
                      </div>
                    </Grid>
                    <Grid
                      className={styles.my_border}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={3}
                    >
                      <div className={styles.txn_title}>
                        <Typography>tnx reference</Typography>
                      </div>
                      <div className={styles.txn_result_bold}>
                        <Typography>{log.trans_ref}</Typography>{" "}
                        {log.trans_ref ? (
                          <button
                            onClick={handleCopyToClipBoard}
                            className={styles.copy_chip}
                          >
                            copy
                          </button>
                        ) : null}
                      </div>
                    </Grid>
                    <Grid   className={styles.my_border} item xs={12} sm={12} md={6} lg={3}>
                      <div className={styles.txn_title}>
                        <Typography>payment method</Typography>
                      </div>
                      <div className={styles.txn_result}>
                        <Typography>{log.payment_method}</Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3}>
                      <div className={styles.txn_title}>
                        <Typography>Error</Typography>
                      </div>
                      <div className={styles.txn_result}>
                        {" "}
                        <Typography>
                         {log?.error}
                        </Typography>{" "}
                      </div>
                    </Grid>
                  </Grid>
                </div>

                <div className={styles.txn_details}>
                  <div className={styles.txn_details_heading}>
                    <Typography>TRANSACTION DETAILS</Typography>
                  </div>

                  <table className={styles.txn_details_table}>
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            {" "}
                            <Typography>amount received</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>
                              <NumberFormat
                                value={log.crypto_amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={log.crypto_currency}
                              />
                            </Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {" "}
                            <Typography>amount paid</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>
                              <NumberFormat
                                value={log.fiat_amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={log.fiat_currency}
                              />
                            </Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div>
                            {" "}
                            <Typography>currency</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>{log.fiat_currency}</Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div>
                            {" "}
                            <Typography>transaction reference</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>{log.trans_ref}</Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {" "}
                            <Typography>rate</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>
                              {log?.price} {log.fiat_currency}{" "}
                            </Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {" "}
                            <Typography>date</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>
                              {moment(log.created_at).format("Do MMM YYYY")}
                            </Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {" "}
                            <Typography>status</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>{log.status}</Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            {" "}
                            <Typography>fees(VAT inclusive)</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>
                              {log.trans_fee} {log.fiat_currency}
                            </Typography>{" "}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <div>
                            {" "}
                            <Typography>description</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div className={styles.text_right}>
                            {" "}
                            <Typography>{log.description}</Typography>{" "}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          <div className={styles.txn_details_footer}>
                            {" "}
                            <Typography>total payout</Typography>{" "}
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${styles.txn_details_footer} ${styles.text_right}`}
                          >
                            {" "}
                            <Typography>
                              <NumberFormat
                                value={log.fiat_amount}
                                displayType={"text"}
                                thousandSeparator={true}
                              />
                              {log.fiat_currency}
                            </Typography>{" "}
                          </div>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
                <div className={styles.payment_info}>
                  <div className={styles.payment_info_heading}>
                    <Typography>PAYMENT INFO</Typography>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <div className={styles.payment_table_title}>
                            <Typography>bank</Typography>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${styles.payment_table_result} ${styles.text_right}`}
                          >
                            <Typography variant="h6">{log.acc_no}</Typography>
                            <Typography>{log.bank_name}</Typography>
                          </div>
                        </td>
                      </tr>
                      {user?.partner ? null : (
                        <>
                          <tr>
                            <td>
                              <div className={styles.payment_table_title}>
                                <Typography>channel</Typography>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${styles.payment_table_result} ${styles.text_right}`}
                              >
                                <Typography variant="h6">
                                  {log.channel}
                                </Typography>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className={styles.payment_table_title}>
                                <Typography>note</Typography>
                              </div>
                            </td>
                            <td>
                              <div
                                className={`${styles.payment_table_result} ${styles.text_right}`}
                              >
                                <Typography variant="h6">{log.note}</Typography>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}

                      <tr>
                        <td>
                          <div className={styles.payment_table_title}>
                            <Typography>paid to</Typography>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${styles.payment_table_result} ${styles.text_right}`}
                          >
                            <Typography variant="h6">{log.acc_name}</Typography>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className={styles.payment_table}></div>
                </div>
              </div>
            )}
          </Container>

          <P2PSnackbar
            severity={severity}
            message={message}
            open={openToast}
            setOpen={setOpenToast}
            duration={duration}
          />

          <LogDrawer
            log={log}
            setOpen={setOpen}
            open={open}
            toggleDrawer={toggleDrawer}
          />
        </div>
      }
    />
  );
};

export default Log;
