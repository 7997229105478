// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.UserInfoCard_user_info_card__1k8No h6 {  \nfont-size: 14px;\nfont-weight: 400;\ncolor: #777777;\nmargin-bottom: 8px;\ntext-transform: capitalize;\n}\n\n.UserInfoCard_user_info_card__1k8No p{\nfont-size: 16px;\nfont-weight: 500;\ncolor: #272727;\n}", "",{"version":3,"sources":["webpack://src/components/userInfoCard/UserInfoCard.module.css"],"names":[],"mappings":";AACA;AACA,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd,kBAAkB;AAClB,0BAA0B;AAC1B;;AAEA;AACA,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd","sourcesContent":["\n.user_info_card h6 {  \nfont-size: 14px;\nfont-weight: 400;\ncolor: #777777;\nmargin-bottom: 8px;\ntext-transform: capitalize;\n}\n\n.user_info_card p{\nfont-size: 16px;\nfont-weight: 500;\ncolor: #272727;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_info_card": "UserInfoCard_user_info_card__1k8No"
};
export default ___CSS_LOADER_EXPORT___;
