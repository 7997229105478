// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserInfo_user_info__1PnUL {\n  box-shadow: 0px 0px 4px 0px #00000012;\n  border: 0.4px solid #bdbdbd;\n  width: 100%;\n}\n\n.UserInfo_heading__2xTsv {\n  width: 100%;\n  padding: 20px 40px;\n  background: #f8f9fd;\n}\n\n.UserInfo_heading__2xTsv p {\n  color: #1a1f36;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.UserInfo_body__vld5J {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  \n}\n", "",{"version":3,"sources":["webpack://src/pages/profile/userInfo/UserInfo.module.css"],"names":[],"mappings":"AAAA;EACE,qCAAqC;EACrC,2BAA2B;EAC3B,WAAW;AACb;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;;AAExB","sourcesContent":[".user_info {\n  box-shadow: 0px 0px 4px 0px #00000012;\n  border: 0.4px solid #bdbdbd;\n  width: 100%;\n}\n\n.heading {\n  width: 100%;\n  padding: 20px 40px;\n  background: #f8f9fd;\n}\n\n.heading p {\n  color: #1a1f36;\n  font-size: 16px;\n  font-weight: 700;\n}\n\n.body {\n  padding: 40px;\n  display: flex;\n  flex-direction: column;\n  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user_info": "UserInfo_user_info__1PnUL",
	"heading": "UserInfo_heading__2xTsv",
	"body": "UserInfo_body__vld5J"
};
export default ___CSS_LOADER_EXPORT___;
