import { Typography, Button, Tooltip, LinearProgress } from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import styles from "./Market.module.css";
import edit from "./../../../../components/assets/Vector (16).svg";
import delete_icon from "./../../../../components/assets/Vector (17).svg";
import AppBackdrop from "../../../../components/backdrop/Backdrop";
import MarketForm from "./Modals/marketform/MarketForm";
import { usePostRequest, useDeleteRequest } from "../../../../custom-hooks/api";
import { useGetRequest } from "../../../../custom-hooks/api";
import AppDeletePrompt from "../../../../components/delete/DeletePrompt";
import ButtonMain from "../../../../components/button/Button";

const Market = () => {
  const { postRequest, posted, processing } = usePostRequest();
  const {deleteRequest, deleted} = useDeleteRequest()
  const { getRequest, loading, data } = useGetRequest();
  const [openEdit, setOpenEdit] = useState(false);
  const [openAddNewMarketModal, setOpenAddNewMarketModal] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [market, setMarket] = useState({})
  const [loadingMarket, setLoadingMarket] = useState(true)
  const [loadingDeleteMarket, setLoadingDeleteMarket] = useState(false)

  

// const handleGetMarket= async(id)=>{
//   setLoadingMarket(true)
//   try {
//     const response = await axios.get(`${baseURL}/api/v1/market/get/${id}`)

//     const {data} = response.data
//     setMarket(data)
//     setLoadingMarket(false)
//   } catch (error) {
//     handleCloseEdit()
//     handleCloseDelete()
//     setLoadingMarket(false)
    
//   }
// }
const handleGoToEdit = (market) => {
  setMarket(market)
 
  //handleGetMarket(id)
  setOpenEdit(true);
};
  const handleCloseEdit = () => {
setMarket({})
    setOpenEdit(false);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleOpenDeleteModal = (market) => {
    setMarket(market)
    //handleGetMarket(id)
    setOpenDelete(true);
   
  };

  useEffect(() => {
    const controller = new AbortController()
    handleGetMarketData();

    return ()=>{
      controller.abort()
    }
  }, []);

    useEffect(() => {
    if (deleted) {
      handleGetMarketData();
      setLoadingDeleteMarket(false)
      handleCloseDelete()
      setMarket({});
    }else{
      setLoadingDeleteMarket(false)
    }
  }, [deleted]);

  const handleGetMarketData = () => {
    getRequest("/api/v1/market/list");
  };

  const handleDeleteMarket=()=>{
    setLoadingDeleteMarket(true)
     deleteRequest(`/api/v1/market/delete/${data.id}`);
  }
  const handleCloseAddNewMarketModal = () => {
    setOpenAddNewMarketModal(false);
  };

  const handleOpenAddNewMarketModal = () => {
    setOpenAddNewMarketModal(true);
  };

  return (
    <div className={styles.market}>
      {loading ? (
        <LinearProgress />
      ) : (
        <>
          <div className={styles.market_heading}>
            <Typography>Available market rates</Typography>
            <ButtonMain handleClick={handleOpenAddNewMarketModal} border='1px solid #BEBEBE' textColor='black' bgColor='white' btnText='Add new' hideLoader />
  
          </div>

          <div className={styles.market_body}>
            <table>
              <thead>
                <tr>
                  <td>pair</td>
                  <td>offset</td>
                  <td>buying</td>
                  <td>selling</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {data.map((market) => {
                  return (
                    <tr key={market.id}>
                      <td>
                        <div className={styles.market_table_pair}>
                          <div className={styles.icon_indicator_green}></div>
                          <div className={styles.market_table_pair_text}>
                            <Typography variant="h6">{market.pair}</Typography>
                            <Typography>
                              {market.market_rate} {market.pair}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td>{market.offset}</td>
                      <td>
                        <div className={styles.market_table_buying}>
                          <Typography variant="h6">buy</Typography>
                          <Typography>
                            {market.buying} {market.pair}
                          </Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.market_table_selling}>
                          <Typography variant="h6">sell</Typography>
                          <Typography>
                            {market.selling} {market.pair}
                          </Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.market_table_actions}>
                          <Tooltip
                            onClick={() => {
                              handleGoToEdit(market);
                            }}
                            title="Edit"
                            arrow
                            placement="top"
                          >
                            <Button>
                              {" "}
                              <img src={edit} alt="" />{" "}
                            </Button>
                          </Tooltip>
                          <Tooltip
                            onClick={() => {
                              handleOpenDeleteModal(market);
                            }}
                            title="Delete"
                            arrow
                            placement="top"
                          >
                            <Button>
                              {" "}
                              <img src={delete_icon} alt="" />{" "}
                            </Button>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {openAddNewMarketModal && (
            <AppBackdrop
              child={
                <MarketForm
                  type="add"
                  handleCloseModal={handleCloseAddNewMarketModal}
                  handleFetchList={handleGetMarketData}
                
                />
              }
              openModal={openAddNewMarketModal}
              handleCloseModal={handleCloseAddNewMarketModal}
            />
          )}
          {openEdit && (
            <AppBackdrop
              child={
                <MarketForm
                  handleFetchList={handleGetMarketData}
                  handleCloseModal={handleCloseEdit}
                  type="edit"
                  market={market}
                  setMarket={setMarket}
                
                />
              }
              openModal={openEdit}
              handleCloseModal={handleCloseEdit}
            />
          )}
          {openDelete && (
            <AppBackdrop
              child={
                <AppDeletePrompt
                  
                  body={
                    `Deleting market ${market.pair} will remove pair and its properties from this application`
                  }
            
                  title={"Delete market"}
                
                  handleCloseModal={handleCloseDelete}
                  handleDelete={handleDeleteMarket}
                 
                
                  loading ={loadingDeleteMarket}
                />
              }
              openModal={openDelete}
              handleCloseModal={handleCloseDelete}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Market;
