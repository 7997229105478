import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styles from "./ManageGroup.module.css";
import close_icon from "./../../../../../../components/assets/Vector (27).svg";
import FormData from "form-data";
import default_img from "./../../../../../../components/assets/Group 1472.svg";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import { useForm } from "react-hook-form";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import { AuthContextAPI } from "../../../../../../Context/auth/AuthContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GET_SINGLE_GROUP } from "../../../../../../API/queries/settings/userGroups/UserGroup";
import {
  DELETE_ALL_GROUP_MEMBER,
  DELETE_GROUP_MEMBER,
  UPDATE_GROUP,
} from "../../../../../../API/mutations/settings/userGroups/UserGroups";
import AppBackdrop from "../../../../../../components/backdrop/Backdrop";
import AppDeletePrompt from "../../../../../../components/delete/DeletePrompt";
import { Skeleton } from "@mui/material";

const ManageGroupModal = ({
  setGroup,
  group,
  setShowManageGroupModal,
}) => {
  const { baseURL } = useContext(AuthContextAPI);
  const queryClient = useQueryClient();
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [file, setFile] = useState(undefined);
  const [, setImageName] = useState("");
  const [image, setImage] = useState();
  const [openDeleteMemberFromGroupPrompt, setOpenDeleteMemberFromGroupPrompt] =
    useState(false);
  const [
    openDeleteAllMemberFromGroupPrompt,
    setOpenDeleteAllMemberFromGroupPrompt,
  ] = useState(false);
  const [member, setMember] = useState({});

  const { data, isLoading: isLoadingSingleGroup } = useQuery(
    ["single-group"],
    () => GET_SINGLE_GROUP(group.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading } = useMutation(UPDATE_GROUP, {
    onSuccess: () => {
      queryClient.invalidateQueries(["user-groups"]);
      setShowManageGroupModal(false);
    },
  });

  const {
    mutate: _handleGroupMemberDelete,
    isLoading: isLoadingDeleteGroupMember,
  } = useMutation(DELETE_GROUP_MEMBER, {
    onSuccess: () => {
      queryClient.invalidateQueries(["single-group"]);
      queryClient.invalidateQueries(["user-groups"]);
      setOpenDeleteMemberFromGroupPrompt(false);
    },
  });

  const {
    mutate: _handleAllGroupMemberDelete,
    isLoading: isLoadingDeleteAllGroupMember,
  } = useMutation(DELETE_ALL_GROUP_MEMBER, {
    onSuccess: () => {
      queryClient.invalidateQueries(["single-group"]);
      queryClient.invalidateQueries(["user-groups"]);
      setOpenDeleteAllMemberFromGroupPrompt(false)
    },
  });

  useEffect(() => {
    handlePrepopulation(group);
  }, [group]);

  const handlePrepopulation = (group) => {
    setValue("group_name", group?.name);
    setImageName(group?.image);
    setFile(group?.image);
  };

  const handleUpdateGroup = async (data) => {
    let formData = new FormData();
    formData.append("image", file);
    formData.append("name", data.group_name);
    mutate({
      data: formData,
      id: group.id,
    });
  };

  const handleGroupMemberDelete = async (member, group_id) => {
    setOpenDeleteMemberFromGroupPrompt(true);
    setMember(member);
  };

  const handleConfirmGroupMemberDelete = () => {
    _handleGroupMemberDelete({
      group_id: group.id,
      member_id: member.id,
    });
  };

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    if (name === "groupImage") {
      setFile(files[0]);
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleRemoveAllMembers = async () => {
    setOpenDeleteAllMemberFromGroupPrompt(true);
  };

  const handleConfirmAllGroupMemberDelete = () => {
    _handleAllGroupMemberDelete({
      group_id: group.id,
    });
  };

  return (
    <ModalFrame
      handleCancel={() => {
        setGroup({});
        queryClient.removeQueries(["single-group"]);
        setShowManageGroupModal(false);
      }}
      handleAction={handleSubmit(handleUpdateGroup)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt="Confirm"
      child={
        <div className={styles.manage_group_content}>
          <form action="">
            <div className={styles.manage_group_heading}>
              <Typography>Manage group</Typography>
            </div>
            <div className={styles.form_field}>
              <div className={styles.manage_group_img_change}>
                <div className={styles.manage_im_info}>
                  {typeof file === "string" ? (
                    <img src={`${baseURL}/${group.image}`} alt="" />
                  ) : (
                    <img src={image ? image : default_img} alt="" />
                  )}
                  <a href="">Change image "PNG,JPG,JPEG"</a>
                </div>
                <input
                  onChange={handleFileChange}
                  type="file"
                  name="groupImage"
                />
              </div>
            </div>

            <div className={styles.form_field}>
              <LabeledInputs
                register={register}
                label="Name of group"
                name="group_name"
                placeholder=""
                required={true}
              />
            </div>
            <div className={styles.members}>
            {isLoadingSingleGroup ? (
              <Skeleton width='100%' height={40} />
            ) : data?.data?.members?.length === 0 ? (
              <div className={styles.no_member}>
                <Typography>You haven't added a group member yet</Typography>
              </div>
            ) : (
              <div className={styles.edit_members}>
                <div className={styles.edit_members_heading}>
                  <Typography>Group members</Typography>
                  <Typography variant="h6" onClick={handleRemoveAllMembers}>
                    Remove all
                  </Typography>
                </div>
                <div className={styles.edit_members_body}>
                  {data?.data?.members?.map((groupMember) => {
                    return (
                      <div key={groupMember.id} className={styles.member_edit}>
                        <Typography>{groupMember.email}</Typography>
                        <img
                          onClick={() => {
                            handleGroupMemberDelete(groupMember, group.id);
                          }}
                          src={close_icon}
                          alt=""
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            </div>
           
          </form>

          {openDeleteAllMemberFromGroupPrompt && (
            <AppBackdrop
              openModal={openDeleteAllMemberFromGroupPrompt}
              handleCloseModal={() => {
                setOpenDeleteAllMemberFromGroupPrompt(false);
              }}
              child={
                <AppDeletePrompt
                  body={`Deleting ${data?.data?.members?.length} member from ${group?.name} will revoke group access from this
             application`}
                  title={"Delete member"}
                  handleDelete={handleConfirmAllGroupMemberDelete}
                  handleCloseModal={() => {
                    setOpenDeleteAllMemberFromGroupPrompt(false);
                  }}
                  loading={isLoadingDeleteAllGroupMember}
                />
              }
            />
          )}

          {openDeleteMemberFromGroupPrompt && (
            <AppBackdrop
              openModal={openDeleteMemberFromGroupPrompt}
              handleCloseModal={() => {
                setMember({});
                setOpenDeleteMemberFromGroupPrompt(false);
              }}
              child={
                <AppDeletePrompt
                  body={`Deleting ${member?.name} from ${group?.name} will revoke group access from this
             application`}
                  title={"Delete member"}
                  handleDelete={handleConfirmGroupMemberDelete}
                  handleCloseModal={() => {
                    setMember({});
                    setOpenDeleteMemberFromGroupPrompt(false);
                  }}
                  loading={isLoadingDeleteGroupMember}
                />
              }
            />
          )}
        </div>
      }
    />
  );
};

export default ManageGroupModal;
