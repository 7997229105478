import { Typography, Grid, LinearProgress } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Settings.module.css";
import group_logo from "./../../components/assets/Group 1451.png";
import icon2 from "./../../components/assets/Group 1489.svg";
import TryAgain from "./../../components/tryAgain/TryAgain";
import { usePostRequest } from "../../custom-hooks/api";
import ChangePasswordModal from "../profile/security/modals/changePassword/ChangePasswordModal";
import AppBackdrop from "../../components/backdrop/Backdrop";
import AppDeletePrompt from "../../components/delete/DeletePrompt";
import { UserContextAPI } from "../../Context/user/UserContext";
import { AuthContextAPI } from "../../Context/auth/AuthContext";

const PartnerSettings = () => {
  const { user, loadingUser, getUser } = useContext(UserContextAPI);
  const {logout} = useContext(AuthContextAPI)
  const { posted, postRequest, processing } = usePostRequest();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [openDeleteMemberFromGroupPrompt, setOpenDeleteMemberFromGroupPrompt] =
    useState(false);
  const [displayTryAgain, setDisplayTryAgain] = useState(false);

  useEffect(() => {
    if (Object.keys(user).length > 0) {
      setDisplayTryAgain(false);
    } else {
      setDisplayTryAgain(true);
    }
  }, [user]);

  useEffect(() => {
    if (posted) {
      logout();
    }
    return () => {
      //cleanup
    };
  }, [posted]);

  const handleOpenChangePasswordModal = () => {
    setShowChangePasswordModal(true);
  };

  const handleOpenMemberDeletePrompt = (e) => {
    e.preventDefault();
    setOpenDeleteMemberFromGroupPrompt(true);
  };

  const handleGroupMemberDelete = async (e) => {
    e.preventDefault();
    postRequest(`/api/v1/settings/group/${user.group.id}/member/delete`, {
      member_id: user.id,
    });
  };

  const handleTryAgain = () => {
    getUser();
  };

  return (
    <div className={styles.partner_settings}>
      {loadingUser ? (
        <LinearProgress />
      ) : displayTryAgain ? (
        <TryAgain handleTryAgain={handleTryAgain} />
      ) : (
        <Grid container spacing={2}>
          <Grid item lg={4}>
            <div className={styles.settings_account}>
              <div className={styles.settings_account_heading}>
                <Typography>Account</Typography>
              </div>
              <div className={styles.account_details}>
                <div className={styles.account_details_img_info}>
                  <img src={group_logo} alt="" />
                  <div className={styles.account_details_img_info_text}>
                    <Typography varian="h6"> {user.group.name}</Typography>
                    <Typography>{user.email}</Typography>
                  </div>
                </div>
                <div className={styles.account_deactivate}>
                  <a
                    href="/"
                    onClick={(e) => {
                      handleOpenMemberDeletePrompt(e);
                    }}
                  >
                    Deactivate account
                  </a>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item lg={8}>
            <div className={styles.security_content}>
              <div className={styles.security_content_heading}>
                <Typography>Security</Typography>
              </div>
              <div className={styles.security_body}>
                <div className={styles.security_text_btn}>
                  <img src={icon2} alt="" />
                  <div className={styles.security_body_text}>
                    <Typography variant="h6">Change password</Typography>
                    <Typography>
                      Add extra security on your account to authenticate your
                      login and transactions.
                    </Typography>
                  </div>
                </div>
                <div className={styles.security_btn}>
                  <button onClick={handleOpenChangePasswordModal}>
                    {" "}
                    Change password
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      )}

      {showChangePasswordModal && (
        <AppBackdrop
          openModal={showChangePasswordModal}
          handleCloseModal={() => {
            setShowChangePasswordModal(false);
          }}
          child={
            <ChangePasswordModal
              setShowChangePasswordModal={setShowChangePasswordModal}
            />
          }
        />
      )}

      {openDeleteMemberFromGroupPrompt && (
        <AppBackdrop
          openModal={openDeleteMemberFromGroupPrompt}
          handleCloseModal={() => {
            setOpenDeleteMemberFromGroupPrompt(false);
          }}
          child={
            <AppDeletePrompt
              body={`Deleting ${user?.name} from ${user?.group?.name} will revoke group access from this
             application`}
              title={"Delete member"}
              handleDelete={handleGroupMemberDelete}
              handleCloseModal={() => {
                setOpenDeleteMemberFromGroupPrompt(false);
              }}
              loading={processing}
            />
          }
        />
      )}
    </div>
  );
};

export default PartnerSettings;
