import React, { useEffect, useState } from "react";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import styles from "./PlatformAccordionDetails.module.css";
import { useForm } from "react-hook-form";
import { Typography } from "@material-ui/core";
import PaymentFields from "../paymentFields/PaymentFields";
import ButtonMain from "../../../../../../components/button/Button";

function PlatformAccordionDetails({
  setPlatform,
  handleFieldChange,
  processing,
  handleUpdatePlatform,
  paymentPlatform,
  handleShowPlatformDeleteModal,
  handleSwitch,
  handleChange,
}) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const platform_user = watch("platform_user", "");

  useEffect(() => {
    setValue("platform_user", paymentPlatform.platform_user);
  }, [paymentPlatform]);

  useEffect(() => {
    handleChange(paymentPlatform.id, platform_user);
  }, [platform_user]);

  const onSubmit = async (data) => {
    handleUpdatePlatform(paymentPlatform.id);
  };
  return (
    <div className={styles.accordion_grid}>
      <div className={styles.platform_status_switch}>
        <div className={styles.platform_status}>
          <Typography variant="h6">Platform Status</Typography>
          <Typography>
            If turned off, platform will not be used to process transactions
          </Typography>
        </div>
        <div>
          <div
            onClick={() => {
              handleSwitch(paymentPlatform.id);
            }}
            className={
              paymentPlatform.status
                ? styles.my_switch
                : styles.my_switch_active
            }
          >
            <div onClick={handleSwitch} className={styles.switch_ball}></div>
          </div>
        </div>
      </div>

      <div className={styles.form_field}>
        <LabeledInputs
          label={"Platform User"}
          placeholder={""}
          // redOutline={errors.Website ? true : false}
          register={register}
          name={"platform_user"}
          handleChangeAuto={true}
          required={true}
          type="text"
        />
      </div>

      {paymentPlatform?.fields?.map((field) => {
        return (
          <PaymentFields
            paymentPlatformID={paymentPlatform.id}
            handleFieldChange={handleFieldChange}
            key={field.id}
            field={field}
          />
        );
      })}
      <div className={styles.platform_btns}>
        <div className={styles.platform_btn}>
          <ButtonMain
            bgColor="#C6C6C6"
            textColor="#515151"
            loading={processing}
            btnText="Remove platform"
            handleClick={() => {
              setPlatform(paymentPlatform);
              handleShowPlatformDeleteModal(paymentPlatform.id);
            }}
            hideLoader
          />
        </div>
        <div className={styles.platform_btn}>
          <ButtonMain
            bgColor="#1A2CCE"
            textColor="white"
            loading={processing}
            btnText="Update Platform"
            handleClick={handleSubmit(onSubmit)}
          />
        </div>
      </div>
    </div>
  );
}

export default PlatformAccordionDetails;
