import React from "react";
import { Link } from "react-router-dom";
import arrow from "./../../components/assets/Vector (8).svg";
import { useHistory } from "react-router-dom";
import styles from "./GoBack.module.css";

function GoBack({text}) {
  const history = useHistory();

  const handleGoBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  return (
    <div className={styles.go_back}>
      <Link to="/" onClick={handleGoBack}>
        <img src={arrow} alt="" /> {text || 'Go Back'} 
      </Link>
    </div>
  );
}

export default GoBack;
