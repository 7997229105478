import axios from "axios";

export const UPDATE_PLATFORM_SETTINGS = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/platform/create`, data)
    .then((res) => {
      return res.data;
    });
};

export const DELETE_PLATFORM = async (id) => {
    return axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/platform/delete/${id}`)
      .then((res) => {
        return res.data;
      });
  };