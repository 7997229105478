// import { Container } from "@material-ui/core";
// import React, { useContext, useEffect, useState } from "react";
// import GoBack from "../../components/goBack/GoBack";
// import PageLayout from "../../components/Layout/PageLayout";
// import styles from "./Balance.module.css";
// import Transactions from "../../components/transaction/Transaction";

// import BalancesAlert from "../../components/alert/balancesAlert/BalancesAlert";
// import {
//   GET_ACCOUNT_SUMMARY,
//   GET_ACCOUNT_TRX,
//   GET_FILTERED_ACCOUNT_TRX,
// } from "../../API/queries/balance/Balance";
// import { useQuery, useQueryClient } from "react-query";

// const tableHeadData = [
//   "order no",
//   "received",
//   "paid",
//   "txn ref",
//   "fees",
//   "bank",
// ];

// function Balance({ match }) {
//   const { low_balance, handleCheckBalanceStatus } =
//     useContext(BalancesContextAPI);
//   const [transaction, setTransaction] = useState({});
//   const [LoadingFilteredData, setLoadingFilteredData] = useState(false);
//   const [filterData, setFilterData] = useState({});

//   const queryClient = useQueryClient();
//   const { data, isLoading } = useQuery(
//     ["account-summary", match.params.id],
//     () => GET_ACCOUNT_SUMMARY(match),
//     {
//       enabled: false,
//       refetchOnWindowFocus: false,
//     }
//   );

//   const { data: accountTrx, isLoading: loadingAccountTrx } = useQuery(
//     ["account-trx", match.params.id],
//     () =>
//       GET_ACCOUNT_TRX({
//         match,
//       }),
//     {
//       refetchOnWindowFocus: false,
//     }
//   );

//   const handleGetFilterQueries = async (data) => {
//     setLoadingFilteredData(true);
//     const { bank, crypto, error } = data;
//     setFilterData(data);
//     queryClient
//       .fetchQuery("filtered-data", () =>
//         GET_FILTERED_ACCOUNT_TRX({
//           match,
//           page: 1,
//           queryData: {
//             bank: bank ? bank : "",
//             crypto: crypto ? crypto : "",
//             error: error ? error : "",
//           },
//           trans_ref: "",
//         })
//       )
//       .then((data) => {
//         setTransaction(data);
//         setLoadingFilteredData(false);
//       })
//       .catch((error) => {
//         setLoadingFilteredData(false);
//       });
//   };

//   const handlePageChange = async (page) => {
//     setLoadingFilteredData(true);
//     queryClient
//       .fetchQuery("filtered-data", () =>
//         GET_FILTERED_ACCOUNT_TRX({
//           match,
//           page,
//           queryData: {
//             bank: filterData.bank ? filterData.bank : "",
//             crypto: filterData.crypto ? filterData.crypto : "",
//             error: filterData.error ? filterData.error : "",
//           },
//           trans_ref: "",
//         })
//       )
//       .then((data) => {
//         setTransaction(data);
//         setLoadingFilteredData(false);
//       })
//       .catch((error) => {
//         setLoadingFilteredData(false);
//       });
//   };

//   const handleSearchOrderNoSubmit = async (data) => {
//     const { trans_ref } = data;
//     setLoadingFilteredData(true);
//     queryClient
//       .fetchQuery("filtered-data", () =>
//         GET_FILTERED_ACCOUNT_TRX({
//           match,
//           trans_ref,
//           queryData: {
//             bank: filterData.bank ? filterData.bank : "",
//             crypto: filterData.crypto ? filterData.crypto : "",
//             error: filterData.error ? filterData.error : "",
//           },
//           page: 1,
//         })
//       )
//       .then((data) => {
//         setTransaction(data);
//         setLoadingFilteredData(false);
//       })
//       .catch((error) => {
//         setLoadingFilteredData(false);
//       });
//   };

//   useEffect(() => {
//     setTransaction(accountTrx);
//   }, [accountTrx]);

//   useEffect(() => {
//     handleCheckBalanceStatus();
//   }, []);

//   const handleRefresh = async () => {
//     setFilterData({});
//     queryClient.resetQueries(["account-trx"]);
//     queryClient.resetQueries(["account-summary"]);
//   };

//   return (
//     <PageLayout
//       child={
//         <div className={styles.balance}>
//           {low_balance && <BalancesAlert />}
//           <Container>
//             <div className={styles.balance_content}>
//               <GoBack text="Balances" />

//               <div className={styles.audit_content}>
//                 <Transactions
//                 setFilterData={ setFilterData}
//                   handleSearchOrderNoSubmit={handleSearchOrderNoSubmit}
//                   handleGetFilterQueries={handleGetFilterQueries}
//                   handlePageChange={handlePageChange}
//                   auditLogs={transaction?.data}
//                   handleRefresh={handleRefresh}
//                   isTrxLoading={loadingAccountTrx || LoadingFilteredData}
//                   trxSum={data?.data}
//                   trxSumLoading={isLoading}
//                   trxSummaryLoading={isLoading}
//                   trxSummary={data?.data}
//                   match={match}
//                   type={"balances"}
//                   tableHeadData={tableHeadData}
//                 />
//               </div>
//             </div>
//           </Container>
//         </div>
//       }
//     />
//   );
// }

// export default Balance;
