import React, { useState, useEffect } from "react";
import { Container, Typography, Dialog } from "@material-ui/core";
import styles from "./Modal.module.css";
import { SettingsPowerRounded } from "@material-ui/icons";
import { Scrollbars } from "react-custom-scrollbars";

const AdDetailsModal = ({ open, selectedAd, setOpen }) => {
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (selectedAd === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [selectedAd]);

  return (
    <div onClick={handleClose} className={styles.my_modal}>
      <Container>
        <div className={styles.my_modal_content}>
          <div onClick={(e) => { e.stopPropagation() }} className={styles.ad_detail_modal}>
               <Scrollbars
                style={{ height: 600 }}>
   {loading ? (
              <Typography>loading</Typography>
            ) : (
              <>
                <div className={styles.ad_details_heading}>
                  <Typography>Ad Details</Typography>
                </div>
                <div className={styles.ad_details_table}>
                  <table>
                    <tbody>
                      <tr>
                        <td>Offer Type: {selectedAd.advert_type}</td>
                        <td>Price: {selectedAd.price}</td>
                      </tr>
                      <tr>
                        <td>Advert type: {selectedAd.tradingPair}</td>
                        <td>Limit: {selectedAd.limit}</td>
                      </tr>
                      <tr>
                        <td>Available: {selectedAd.amount_limit}</td>
                        <td>Payment time: {selectedAd.payment_time}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className={styles.terms_trade}>
                  <div className={styles.option}>
                    <Typography variant="h6">Terms of trade</Typography>
                    <Typography>
                      please 100# will be deducted for charges and please stay
                      online to realese the coin immediately I pay you
                    </Typography>
                  </div>
                  <div className={styles.option}>
                    <Typography variant="h6">Trade options</Typography>
                    <Typography>
                      please 100# will be deducted for charges and please stay
                      online to realese the coin immediately I pay you
                    </Typography>
                  </div>
                </div>
                <div className={styles.conditions}>
                  <Typography>Conditions</Typography>
                  <label htmlFor="">
                    <input type="checkbox" name="" id="" />
                    Completed KYC
                  </label>
                  <label htmlFor="">
                    <input type="checkbox" name="" id="" />
                    Registered 50 days ago
                  </label>
                  <label htmlFor="">
                    <input type="checkbox" name="" id="" />
                    Holding more than 50,000 USDT
                  </label>
                </div>
              </>
            )}
                  </Scrollbars>
         
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AdDetailsModal;
