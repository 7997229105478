export const endpointFilterParser = (value, key) => {
    if (value) {
      return `&${key}=${value}`;
    } else {
      return "";
    }
  };
  
  export const endpointFilterParserStart = (value, key) => {
    if (value) {
      return `?${key}=${value}`;
    } else {
      return "";
    }
  };