import { Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styles from "./RunningBots.module.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Switch from "../../../../../components/switch/Switch";
import Reorder from "react-reorder";
import { useForm } from "react-hook-form";
import ModalFrame from "../../../../../components/frames/modalFrame/ModalFrame";
import axios from "axios";
import { AuthContextAPI } from "../../../../../Context/auth/AuthContext";
import BotGrid from "./components/botGrid/BotGrid";
import { Grid } from "@material-ui/core";

const RunningBots = ({
  platformBots,
  setShowRunningBots,
  handleGetPaymentPlatformsHighlights,
  botsStatus,
  setBotsStatus,
  FEpermissions,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [savingBotsChanges, setSavingBotsChanges] = useState(false);
  const { baseURL } = useContext(AuthContextAPI);

  const onSubmit = (data) => {
   
    let tempBotsStatus;

    Object.keys(data).map((item) => {
      tempBotsStatus = [...botsStatus];
      const stat = tempBotsStatus.find((bot) => {
        return bot.id == item;
      });
 
      stat.status = data[item];
    });
   handleSaveBotsChanges(tempBotsStatus)
    setBotsStatus(tempBotsStatus);
  };

 

  const handleSaveBotsChanges = async (data) => {
  
    setSavingBotsChanges(true);
    try {
      const response = await axios.put(
        `${baseURL}/api/v1/settings/platform/update/status`,
        {
          platform: data,
        }
      );
      handleGetPaymentPlatformsHighlights();
      setShowRunningBots(false);
    } catch (error) {
      setSavingBotsChanges(false);
      if (error.response) {
      } else if (error.request) {
      } else {
      }
    }
  };

  //new implementation

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      botsStatus,
      result.source.index,
      result.destination.index
    );
    setBotsStatus(items);
  };

  const getListStyle = (isDraggingOver) => ({
    display: "flex",
    flexDirection: "column",
    padding: 8,
    minHeight: "200px",
    overflow: "auto",
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "10px",
    background: isDragging ? "lightblue" : "white",
    ...draggableStyle,
  });

  return (
    <ModalFrame
      showConfirmBtn={FEpermissions?.includes("save-bots")}
      showCancelBtn
      btnTxt="Save changes"
      loading={savingBotsChanges}
      handleCancel={() => {
        setShowRunningBots(false);
      }}
      handleAction={handleSubmit(onSubmit)}
      child={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.running_bot_modal}
        >
          <div className={styles.running_bot_heading}>
            <Typography variant="h6">Available Bots</Typography>
            <Typography>Manage and control yout trading bots here</Typography>
          </div>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  {...provided.droppableProps}
                >
                  <Grid container spacing={2}>
                    {botsStatus?.map((item, index) => {
                      return (
                        <Grid key={item.id} item xs={6} xl={6} md={6} sm={6}>
                          <Draggable
                            key={item.id}
                            draggableId={String(item.name)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...provided.placeholder}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <BotGrid
                                  key={item.id}
                                  register={register}
                                  setValue={setValue}
                                  watch={watch}
                                  status={item.status}
                                  name={item.name}
                                  bot={item}
                                />
                              </div>
                            )}
                          </Draggable>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      }
    />
  );
};

export default RunningBots;

// import { Typography } from "@mui/material";
// import React, { useContext, useEffect, useState } from "react";
// import styles from "./RunningBots.module.css";
// import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import Switch from "../../../../../components/switch/Switch";
// import Reorder, {  } from "react-reorder";
// import { useForm } from "react-hook-form";
// import ModalFrame from "../../../../../components/frames/modalFrame/ModalFrame";
// import axios from "axios";
// import { AuthContextAPI } from "../../../../../Context/auth/AuthContext";
// import BotGrid from "./components/botGrid/BotGrid";

// const RunningBots = ({
//   platformBots,
//   setShowRunningBots,
//   handleGetPaymentPlatformsHighlights,
//   botsStatus,
//   setBotsStatus,
//   FEpermissions,
// }) => {
//   const {
//     register,
//     handleSubmit,
//     setValue,
//     watch,
//     formState: { errors },
//   } = useForm();
//   const [savingBotsChanges, setSavingBotsChanges] = useState(false);
//   const { baseURL } = useContext(AuthContextAPI);

//   const handleTest = (data) => {
//     //console.log(data)
//   };

//   const onSubmit = (data) => {
//     console.log(data);
//   };

//   const handleSwitch = (event, id) => {
//     const tempBotsStatus = [...botsStatus];

//     const selectedBot = botsStatus.find((bot) => {
//       return bot.id === id;
//     });
//     if (selectedBot) {
//       const selctedIndex = botsStatus.indexOf(selectedBot);
//       tempBotsStatus[selctedIndex].status =
//         !tempBotsStatus[selctedIndex].status;

//       setBotsStatus(tempBotsStatus);
//     }
//   };
//   const onReorder = (event, previousIndex, nextIndex, fromId, toId) => {
//     //setBotsStatus(reorder(botsStatus, previousIndex, nextIndex));
//   };

//   const handleSaveBotsChanges = async () => {
//     setSavingBotsChanges(true);
//     try {
//       const response = await axios.put(
//         `${baseURL}/api/v1/settings/platform/update/status`,
//         {
//           platform: botsStatus,
//         }
//       );
//       handleGetPaymentPlatformsHighlights();
//       setShowRunningBots(false);
//     } catch (error) {
//       setSavingBotsChanges(false);
//       if (error.response) {
//       } else if (error.request) {
//       } else {
//       }
//     }
//   };

//   //new implementation

//   return (
//     <ModalFrame
//       showConfirmBtn={FEpermissions?.includes("save-bots")}
//       showCancelBtn
//       btnTxt="Save changes"
//       loading={savingBotsChanges}
//       handleCancel={() => {
//         setShowRunningBots(false);
//       }}
//       handleAction={handleSubmit(onSubmit)}
//       child={
//         <div
//           onClick={(e) => {
//             e.stopPropagation();
//           }}
//           className={styles.running_bot_modal}
//         >
//           <div className={styles.running_bot_heading}>
//             <Typography variant="h6">Available Bots</Typography>
//             <Typography>Manage and control yout trading bots here</Typography>
//           </div>
//           <div className={styles.bots_grid}>
//             <Reorder
//               reorderId="my-list" // Unique ID that is used internally to track this list (required)
//               reorderGroup="reorder-group" // A group ID that allows items to be dragged between lists of the same group (optional)
//               //getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
//               component="ul" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
//               placeholderClassName="placeholder" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
//               draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
//               lock="" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
//               holdTime={0} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
//               //touchHoldTime={500} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
//               //mouseHoldTime={200} // Hold time before dragging begins with mouse (optional), defaults to holdTime
//               onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
//               autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
//               disabled={false} // Disable reordering (optional), defaults to false
//               disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
//               // placeholder={
//               //   <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
//               // }
//             >
//               {botsStatus?.map((bot) => {
//                 return (
//                   <BotGrid
//                     key={bot.id}
//                     register={register}
//                     setValue={setValue}
//                     watch={watch}
//                     status={bot.status}
//                     name={bot.name}
//                     bot={bot}
//                   />
//                 );
//               })}
//             </Reorder>
//           </div>
//         </div>
//       }
//     />
//   );
// };

// export default RunningBots;
