// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.GoBack_go_back__mEh-c {\n    margin-bottom: 20px;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n}\n\n\n\n.GoBack_go_back__mEh-c img {\n    margin-right: 10px;\n    width: 15px;\n    height: 15px;\n}\n\n.GoBack_go_back__mEh-c a {\n    text-decoration: none;\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #000000;\n    display: flex;\n    align-items: center;\n}", "",{"version":3,"sources":["webpack://src/components/goBack/GoBack.module.css"],"names":[],"mappings":";AACA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,aAAa;IACb,8BAA8B;AAClC;;;;AAIA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qBAAqB;;IAErB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\n.go_back {\n    margin-bottom: 20px;\n    align-items: center;\n    display: flex;\n    justify-content: space-between;\n}\n\n\n\n.go_back img {\n    margin-right: 10px;\n    width: 15px;\n    height: 15px;\n}\n\n.go_back a {\n    text-decoration: none;\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #000000;\n    display: flex;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"go_back": "GoBack_go_back__mEh-c"
};
export default ___CSS_LOADER_EXPORT___;
