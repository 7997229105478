import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./ConfirmTransaction.module.css";
import tether_icon from "./../../../../../components/assets/Group 140.svg";
import ngn_icon from "./../../../../../components/assets/ngn.svg";

import NumberFormat from "react-number-format";
import moment from "moment";

const ConfirmTransactionModal = ({

  issue,
  channels,
  expiryTime,
  setOpen,
}) => {
  return (
    <div className={styles.payment_modal}>
      <div className={styles.modal_heading}>
        <Typography>Confirm transaction</Typography>
        <div className={styles.modal_expires_in}>
          <Typography variant="h6">Expires in</Typography>
          <Typography
            style={{
              color: expiryTime < 420000 ? "red" : "unset",
            }}
          >
            {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000 / 60) % 60)} :{" "}
            {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000) % 60)}
          </Typography>
        </div>
      </div>
      <div className={styles.modal_details}>
        <div className={styles.crypto_icon_details}>
          <img
            src={issue?.crypto_currency === "USDT" ? tether_icon : ngn_icon}
            alt=""
          />
          <div>
            <Typography variant="h6">{issue?.order_no}</Typography>
            <Typography>
              {moment(issue?.created_at).format("Do MMM YYYY")}
            </Typography>
          </div>
        </div>
        <div className={styles.amount}>
          <Typography variant="h6">
            {" "}
            <NumberFormat
              value={issue?.fiat_amount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </Typography>
          <Typography>{issue?.fiat_currency}</Typography>
        </div>
      </div>

     
     
    </div>
  );
};

export default ConfirmTransactionModal;
