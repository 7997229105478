import React, {useContext} from 'react'
import styles from "./Drawer.module.css";
import close_btn from './assets/close2.svg'
import { AuthContextAPI } from '../../../Context/auth/AuthContext';


const DrawerModal = ({handleCloseModal, log}) => {
  const {baseURL} = useContext(AuthContextAPI)
    return (<>
        <div className={styles.expanded_img_div}>
          <img onClick={(e)=>{e.stopPropagation()}} className={styles.expanded_img} src={`${baseURL}/${log.proof_of_payment}`}alt="" />
          <img className={styles.close_expanded_img} onClick={handleCloseModal} src={close_btn} alt="" />
          </div>  
        </>
     );
}
 
export default DrawerModal;