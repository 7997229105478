import React, { useContext, useState } from "react";
import Drawer from "@mui/material/Drawer";
import styles from "./Drawer.module.css";
import { Container, Typography } from "@material-ui/core";
import close_btn from "./assets/close2.svg";
import expand from "./assets/expand.png";
import moment from "moment";
import AppBackdrop from "../../../components/backdrop/Backdrop";
import DrawerModal from "./DrawerModal";
import { AuthContextAPI } from "../../../Context/auth/AuthContext";

const LogDrawer = ({ open, log, setOpen, toggleDrawer }) => {
  const { baseURL } = useContext(AuthContextAPI);
  const [anchor, setAnchor] = useState("right");
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleExpandImg = () => {
    setOpenModal(true);
  };
  return (
    <Drawer anchor={anchor} open={open} onClose={toggleDrawer}>
      <div className={styles.log_drawer}>
        <Container maxWidth="sm">
          <div className={styles.log_drawer_content}>
            <div className={styles.log_drawer_heading}>
              <Typography>Uploaded by</Typography>
              <div className={styles.close_icon}>
                <img onClick={handleClose} src={close_btn} alt="" />
              </div>
            </div>
            <div className={styles.log_drawer_proof}>
              <img src={`${baseURL}/${log?.proof_of_payment}`} alt="" />
              <img
                onClick={handleExpandImg}
                className={styles.expand_img}
                src={expand}
                alt=""
              />
            </div>
            <div className={styles.log_drawer_proof_details}>
              <div className={styles.file_detail}>
                <Typography>File Name </Typography>
                <Typography>{log?.proof_of_payment_file_name}</Typography>
              </div>
              <div className={styles.file_detail}>
                <Typography>Published on </Typography>
                <Typography>
                  {" "}
                  {moment(log?.proof_of_payment_published_on).format(
                    "Do MMM YYYY"
                  )}
                </Typography>
              </div>
              <div className={styles.file_detail}>
                <Typography>Uploaded by </Typography>
                <Typography>{log?.error_resolved_by}</Typography>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <AppBackdrop
        child={<DrawerModal handleCloseModal={handleCloseModal} log={log} />}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />
    </Drawer>
  );
};

export default LogDrawer;
