// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LowBalanceModal_alert_content__HBimb {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.LowBalanceModal_alert_icon__Vex_g {\n    display: flex;\n    justify-content: center;\n}\n\n.LowBalanceModal_alert_text__R-s4y{\n    margin-top: 32px;\n    margin-bottom: 16px;\n}\n\n.LowBalanceModal_alert_text__R-s4y h6{\nfont-size: 24px;\nfont-weight: 500;\ntext-align: center;\nmargin-bottom: 8px;\ncolor: #212121;\n}\n\n.LowBalanceModal_alert_text__R-s4y p{\n    color:  #6F6F6F;\n    font-size: 15px;\n    font-weight: 400;\n    text-align: center;\n    \n}\n.LowBalanceModal_alert_link__1_YZm a{\n  \n    font-size: 15px;\n    font-weight: 500;\n   color:  #1A2CCE;\n    text-align: center;\n    \n}", "",{"version":3,"sources":["webpack://src/components/lowBalanceModal/LowBalanceModal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;AACA,eAAe;AACf,gBAAgB;AAChB,kBAAkB;AAClB,kBAAkB;AAClB,cAAc;AACd;;AAEA;IACI,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,kBAAkB;;AAEtB;AACA;;IAEI,eAAe;IACf,gBAAgB;GACjB,eAAe;IACd,kBAAkB;;AAEtB","sourcesContent":[".alert_content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.alert_icon {\n    display: flex;\n    justify-content: center;\n}\n\n.alert_text{\n    margin-top: 32px;\n    margin-bottom: 16px;\n}\n\n.alert_text h6{\nfont-size: 24px;\nfont-weight: 500;\ntext-align: center;\nmargin-bottom: 8px;\ncolor: #212121;\n}\n\n.alert_text p{\n    color:  #6F6F6F;\n    font-size: 15px;\n    font-weight: 400;\n    text-align: center;\n    \n}\n.alert_link a{\n  \n    font-size: 15px;\n    font-weight: 500;\n   color:  #1A2CCE;\n    text-align: center;\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert_content": "LowBalanceModal_alert_content__HBimb",
	"alert_icon": "LowBalanceModal_alert_icon__Vex_g",
	"alert_text": "LowBalanceModal_alert_text__R-s4y",
	"alert_link": "LowBalanceModal_alert_link__1_YZm"
};
export default ___CSS_LOADER_EXPORT___;
