import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./UserForm.module.css";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import LabeledSelects from "../../../../../../components/selects/labeledSelects/LabeledSelects";

const UserForm = ({
  userRoles,
  register,
  type,
}) => {
  return (
    <div className={styles.payment_modal_content}>
      <form action="">
        <>
          <div className={styles.white_bg}>
            <div className={styles.form_heading}>
              <Typography>{type} user</Typography>
            </div>
            <div className={styles.form_alert}>
              <Typography>
                Create a new user by adding their email and generating a
                password for them. An email invite would be sent to them{" "}
              </Typography>
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                label={"First Name"}
                placeholder={""}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"firstname"}
                handleChangeAuto={true}
                required={true}
                type="text"
              />
            </div>

            <div className={styles.form_field}>
              <LabeledInputs
                label={"Last Name"}
                placeholder={""}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"lastname"}
                handleChangeAuto={true}
                required={true}
                type="text"
              />
            </div>

            <div className={styles.form_field}>
              <LabeledInputs
                label={"Email"}
                placeholder={""}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"email"}
                handleChangeAuto={true}
                required={true}
                type="text"
              />
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                label={"phone number"}
                placeholder={""}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"phone_number"}
                handleChangeAuto={true}
                required={true}
                type="number"
              />
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                label={"Slack profile"}
                placeholder={""}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"slack"}
                handleChangeAuto={true}
                required={true}
                type="text"
              />
            </div>
           

            <div className={styles.form_text}>
              <Typography variant="h6">User roles and permissions</Typography>
              <Typography>
                Select the role type and specify the requests they can access
                within the platform
              </Typography>
            </div>
            <LabeledSelects
              placeholder="Roles"
              data={userRoles}
              label="Select Role"
              name="role"
              register={register}
              required={true}
            />
          </div>

         
        </>
      </form>
    </div>
  );
};

export default UserForm;
