import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './InputWithText.module.css'


function InputWithText({ register, required, pattern,  name,text, type}) {
  return (
    <div className={styles.input_box}>
    <input
      name={name}
      {...register(name, { required: required, maxLength: 80, pattern: pattern })}
    
      type={type}
    />
    <div className={styles.input_box_label}>
      <Typography>{text}</Typography>
    </div>
  </div>
  )
}

export default InputWithText