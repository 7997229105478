import { Container } from '@material-ui/core'
import React from 'react'
import PageLayout from '../../components/Layout/PageLayout'
import styles from './Profile.module.css'
import Security from './security/Security'
import UserInfo from './userInfo/UserInfo'

function Profile() {
    
  return (
   <PageLayout child={
       <div className={styles.profile}>
           <Container>
               <div className={styles.profile_content}>
                    <UserInfo/>
                    <Security/>
               </div>
           </Container>
       </div>
   } />
  )
}

export default Profile