import axios from "axios";
import React, { useRef, useEffect, useContext } from "react";
import { useState } from "react";
import useOpenSnackbar from "../../custom-hooks/useOpenSnackbar";
import { AuthContextAPI } from "../auth/AuthContext";
import { UserContextAPI } from "../user/UserContext";
import { support_data } from "../../components/data";
import BoopButton from "../../custom-hooks/Sound";
import P2PSnackbar from "../../components/AppSnackbars/P2PSnackbar";
import { io } from "socket.io-client";
import { GET_SUPPORTS } from "../../API/queries/support/Support";
import { useQuery } from "react-query";
export const SupportContextAPI = React.createContext();

const SupportContext = (props) => {
  const { openSnackbar, setOpenToast, message, duration, severity, openToast } =
    useOpenSnackbar();
  const soundref = useRef();
  const { user } = useContext(UserContextAPI);
  const { auth } = useContext(AuthContextAPI);
  const [support, setSupport] = useState([]);
  const [currentSolvedError, setCurrentSolvedError] = useState({});
  const [currentErrorEntry, setCurrentErrorEntry] = useState(null);

  const {
    data: API_Support_Data,
    isLoading: loadingSupports,
  } = useQuery(["supports"], GET_SUPPORTS, {
    refetchOnWindowFocus: false,
    enabled: Boolean(user?.permissions?.includes("support")),
  });

  useEffect(() => {
    if(API_Support_Data){
      setSupport(API_Support_Data?.data?.support);
    }
   
    //setSupport(support_data);
  }, [API_Support_Data]);

  useEffect(() => {
    if (currentErrorEntry !== null) {
      setSupport([...support, currentErrorEntry]);
    }
  }, [currentErrorEntry]);

  useEffect(() => {
    const tempSupport = support.filter((item) => {
      if (item.id !== currentSolvedError.id) {
        return item;
      }
    });
    setSupport(tempSupport);
  }, [currentSolvedError]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    socket.on("connect", () => {
      socket.on("p2pcollect", (data) => {
        if (data.data.event === "transaction_error") {
          handleAlertSystem(data);
        }
        if (data.event === "transaction_resolved") {
          setCurrentSolvedError(data.data);
          if (auth) {
            if (user?.partner) {
              
            } else {
              if (Object.keys(user).length > 0) {
                if (user.permissions.includes("support")) {
                  if (data.data.user === user.firstname) {
                    
                  } else {
                    openSnackbar(
                      "info",
                      `Error with the ID ${data.data.id} was solved by ${data.data.user}`
                    );
                  }
                } else {
                }
              } else {
              }
            }
          } else {
          }
        }
      });
    });

    return () => socket.disconnect();
  }, [auth, user]);

  

  const handleAlertSystem = (data) => {
    if (auth) {
      if (user?.partner) {
      } else {
        if (Object.keys(user).length > 0) {
          if (user.permissions.includes("support")) {
            soundref.current.click();

            setCurrentErrorEntry(data.data.data);

            openSnackbar(
              "info",
              `A ${data.data.data.error} Error with the ID ${data.data.data.id} just came in please resolve as soon as possible`
            );
          } else {
          }
        } else {
        }
      }
    } else {
    }
  };

  return (
    <SupportContextAPI.Provider
      value={{
        support,
        loadingSupportData: loadingSupports,
        currentSolvedError,
        currentErrorEntry,
      }}
    >
      {props.children}

      <>
        <BoopButton soundref={soundref} />

        <P2PSnackbar
          open={openToast}
          setOpen={setOpenToast}
          severity={severity}
          message={message}
          duration={duration}
        />
      </>
    </SupportContextAPI.Provider>
  );
};

export default SupportContext;
