import React, { useContext, useState } from "react";
import styles from "./Security.module.css";
import { Typography } from "@material-ui/core";
import icon from "./../../../components/assets/Group 50.png";
import icon2 from "./../../../components/assets/Group 1489.svg";
import ButtonMain from "../../../components/button/Button";
import AppBackdrop from "../../../components/backdrop/Backdrop";
import Enable2fa from "./modals/enable2fa/Enable2fa";
import Disable2FA from "./modals/disable2fa/Disable2FA";
import ChangePasswordModal from "./modals/changePassword/ChangePasswordModal";
import { GENERATE_2FA } from "../../../API/mutations/profile/security/Security";
import { useMutation } from "react-query";
import { UserContextAPI } from "../../../Context/user/UserContext";

const Security = () => {
  const { user} = useContext(UserContextAPI);

  const [showEnable2faModal, setShowEnable2faModal] = useState(false);
  const [showDisable2faModal, setShowDisable2faModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [qrData, setQrData] = useState({})
  const { mutate, isLoading } = useMutation(GENERATE_2FA, {
    onSuccess: (response) => {
      setQrData(response.data)
    },
  });
  
  const handleEnable2FA = () => {
    setShowEnable2faModal(true);
    mutate()
  };
  const handleDisable2FA = () => {
    setShowDisable2faModal(true);
  };
  const handleChangePassword = () => {
    setShowChangePasswordModal(true);
  };

  return (
    <div className={styles.security}>
      <div className={styles.security_content}>
        <div className={styles.security_heading}>
          <Typography>Security</Typography>
        </div>
        <div className={styles.security_body}>
          <div className={styles.security_text_btn}>
            <img src={icon} alt="" />
            <div className={styles.security_body_text}>
              <Typography variant="h6">Two-factor Authentication</Typography>
              <Typography>
                Add extra security on your account to authenticate your login
                and transactions.
              </Typography>
            </div>
          </div>
          <div className={styles.security_btn}>
            {user?.is_two_factor_enabled ? (
              <ButtonMain
                fullLength
                bgColor="#FFE5E5"
                textColor="#CE1A1A"
                btnText="Disable 2FA"
                handleClick={handleDisable2FA}
              />
            ) : (
              <ButtonMain
                fullLength
                bgColor="#1A2CCE"
                textColor="white"
                btnText="Enable 2FA"
                handleClick={handleEnable2FA}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles.security_content}>
        <div className={styles.security_body}>
          <div className={styles.security_text_btn}>
            <img src={icon2} alt="" />
            <div className={styles.security_body_text}>
              <Typography variant="h6">Change password</Typography>
              <Typography>
                Add extra security on your account to authenticate your login
                and transactions.
              </Typography>
            </div>
          </div>
          <div className={styles.security_btn}>
            <ButtonMain
              fullLength
              bgColor="#1A2CCE"
              textColor="white"
              btnText="Change password"
              handleClick={handleChangePassword}
            />
          </div>
        </div>
      </div>
      {showEnable2faModal && (
        <AppBackdrop
          openModal={showEnable2faModal}
          handleCloseModal={() => {
            setShowEnable2faModal(false);
          }}
          child={
            <Enable2fa
            qrData={qrData}
            loading={isLoading}
        
              setShowEnable2faModal={setShowEnable2faModal}
            />
          }
        />
      )}

      {showChangePasswordModal && (
        <AppBackdrop
          openModal={showChangePasswordModal}
          handleCloseModal={() => {
            setShowChangePasswordModal(false);
          }}
          child={
            <ChangePasswordModal
              setShowChangePasswordModal={setShowChangePasswordModal}
            />
          }
        />
      )}
      {showDisable2faModal && (
        <AppBackdrop
          openModal={showDisable2faModal}
          handleCloseModal={() => {
            setShowDisable2faModal(false);
          }}
          child={
            <Disable2FA
        
              handleCloseModal={() => {
                setShowDisable2faModal(false);
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default Security;
