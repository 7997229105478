import { Typography, Grid, LinearProgress } from "@material-ui/core";
import React, { useEffect } from "react";
import styles from "./Modal.module.css";
import searchIcon from "./../../../../components/assets/Vector (7).svg";
import { useGetRequest } from "../../../../custom-hooks/api";
import Scrollbars from "react-custom-scrollbars";

const AddPaymentMethod = ({
  handleCloseModal,
  handleAddPaymentCheckboxChange,
  paymentMethods,
  incomingPaymentMethod
}) => {
 



  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
      className={styles.add_payment_method}
    >
     
          {" "}
          <Scrollbars style={{ height: 550, padding: 16 }}>
            <div className={styles.white_bg}>
              <div className={styles.add_payment_method_heading}>
                <Typography>All payment method</Typography>
                <div className={styles.search_input_box}>
                  <div>
                    <img src={searchIcon} alt="" />
                  </div>
                  <form action="">
                    <input type="text" placeholder="Search payment method" />
                  </form>
                </div>
              </div>
              <div className={styles.add_payment_method_body}>
                <Grid container spacing={2}>
                  {incomingPaymentMethod.map((item) => {
                    return (
                      <Grid key={item.id} item xs={4}>
                        <label htmlFor="">
                          <input
                            onChange={handleAddPaymentCheckboxChange}
                            type="checkbox"
                            name={item.name}
                            id={item.id}
                           checked={paymentMethods.some((method)=>{
                            if (item.id == method.id) {
                              return true;
                            } else {
                              return false;
                            }
                           })}
                          />
                          {item.name}
                        </label>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          </Scrollbars>
          {/* <div className={styles.dark_bg}>
            <button onClick={handleCloseModal}>Cancel</button>
            <button>Add</button>
          </div> */}
      
    </div>
  );
};

export default AddPaymentMethod;
