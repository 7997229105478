import React, { useEffect, useState } from "react";
import styles from "./UploadScreenshot.module.css";
import { Typography } from "@material-ui/core";
import img_icon from "./../../../../components/assets/Group 1401.svg";
import LabeledSelects from "../../../../components/selects/labeledSelects/LabeledSelects";

const UploadScrenshootModal = ({
  channels,
  register,
  setImage,
  expiryTime,
}) => {
  const [file, setFile] = useState("");
  const [manualChannels, setManualChannels] = useState([]);

  const handleChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(URL.createObjectURL(e?.target?.files[0]));
      setImage(e.target.files[0]);
    } else {
    }
  };

  useEffect(() => {
    const tempChannels = channels.filter((channel) => {
      if (channel.name.includes("Manual")) {
        return channel;
      }
    });

    setManualChannels(tempChannels);
  }, [channels]);

  return (
    <div className={styles.processor_modal}>
      <div className={styles.modal_heading}>
        <Typography>Upload screenshot</Typography>
        <div className={styles.modal_expires_in}>
          <Typography variant="h6">Expires in</Typography>
          <Typography
            style={{
              color: expiryTime < 420000 ? "red" : "unset",
            }}
          >
            {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000 / 60) % 60)} :{" "}
            {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000) % 60)}
          </Typography>
        </div>
      </div>
      <div className={styles.processor_modal_detail}>
        <div className={styles.input_box}>
          <div>
            <img src={file ? file : img_icon} alt="" />
            <Typography>
              Drag and drop and image, or <a href="">Browse</a>{" "}
            </Typography>
          </div>
          <input type="file" name="image" onChange={handleChange} />
        </div>
      </div>
      <div className={styles.upload_screenshot_select}>
        <LabeledSelects
          required={true}
          register={register}
          name="channel"
          label=" Select payment channel"
          placeholder="Payment Channel"
          data={manualChannels}
        />
      </div>

     
    </div>
  );
};

export default UploadScrenshootModal;
