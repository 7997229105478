import { Typography, Grid } from "@material-ui/core";
import React from "react";
import styles from "./Permissions.module.css";

const Manage3rdPartyAccess = ({
  availableCryptos,
  availableFiats,
  availablePermissions,
  permission_controls,
  crypto_currencies,
  fiat_currencies,
  setPermiossionControl,
  setCryptoCurrencies,
  setFiatCurrencies,
}) => {
 
 

  const handlePermissionsCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setPermiossionControl([...permission_controls, name]);
    } else {
      const permissionsArray = permission_controls.filter((control) => {
        if (name !== control) {
          return control;
        }
      });

      setPermiossionControl(permissionsArray);
    }
  };

  const handleCryptoCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setCryptoCurrencies([...crypto_currencies, name]);
    } else {
      const cryptoArray = crypto_currencies.filter((currency) => {
        if (name !== currency) {
          return currency;
        }
      });

      setCryptoCurrencies(cryptoArray);
    }
  };

  const handleFiatCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setFiatCurrencies([...fiat_currencies, name]);
    } else {
      const fiatArray = fiat_currencies.filter((currency) => {
        if (name !== currency) {
          return currency;
        }
      });

      setFiatCurrencies(fiatArray);
    }
  };


  return (
    <div className={styles.user_role_modal_content}>
      <form action="">
        <div className={styles.user_role_heading}>
          <Typography variant="h6">Manage 3rd party access</Typography>
          <Typography>
            Specify the requests they can access within the platform
          </Typography>
        </div>
        <div className={styles.user_role_content}>
          <div className={styles.actions}>
            <div className={styles.actions_heading}>
              <Typography>Crypto currencies</Typography>
            </div>
            <div className={styles.actions_content}>
              {availableCryptos.map((crypto) => {
                return (
                  <label key={crypto.id} htmlFor="">
                    <input
                      onChange={handleCryptoCheckboxChange}
                      name={crypto.name}
                      type="checkbox"
                      checked={crypto_currencies.some((currency) => {
                        if (crypto.name == currency) {
                          return true;
                        } else {
                          return false;
                        }
                      })}
                    />{" "}
                    {crypto.name}
                  </label>
                );
              })}
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.actions_heading}>
              <Typography>Fiat currencies</Typography>
            </div>
            <div className={styles.actions_content}>
              {availableFiats.map((fiat) => {
                return (
                  <label key={fiat.id} htmlFor="">
                    <input
                      onChange={handleFiatCheckboxChange}
                      name={fiat.name}
                      type="checkbox"
                      checked={fiat_currencies.some((currency) => {
                        if (fiat.name == currency) {
                          return true;
                        } else {
                          return false;
                        }
                      })}
                    />{" "}
                    {fiat.name}
                  </label>
                );
              })}
            </div>
          </div>
          <div className={styles.actions}>
            <div className={styles.actions_heading}>
              <Typography>Permission controls</Typography>
            </div>
            <Grid container spacing={2}>
              {availablePermissions.map((permission) => {
                return (
                  <Grid item xs={6} key={permission.id}>
                    <label htmlFor="">
                      <input
                        type="checkbox"
                        onChange={handlePermissionsCheckboxChange}
                        name={permission.name}
                        id=""
                        checked={permission_controls.some(
                          (currency) => {
                            if (permission.name === currency) {
                              return true;
                            } else {
                              return false;
                            }
                          }
                        )}
                      />
                      {permission.name}
                    </label>
                  </Grid>
                );
              })}
            </Grid>

            <div className={styles.actions_content_grid}></div>
          </div>
        </div>
      </form>
     
    </div>
  );
};

export default Manage3rdPartyAccess;
