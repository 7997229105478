import { Typography } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import styles from "./TotalBalance.module.css";
import group163 from "./../../../../../../../components/assets/Group 163.svg";
import group164 from "./../../../../../../../components/assets/Group 164.svg";
import arrow from "./../../../../../../../components/assets/Polygon 1.svg";
import { Skeleton } from "@mui/material";

function TotalBalance({trxStats, loading}) {
  // const { data: trxStats, isLoading } = useQuery(
  //   ["trx-total-bal"],
  //   GET_TRX_SUM,
  //   {
  //     refetchOnWindowFocus: false,
  //     enabled: false
  //   }
  // );

  return (
    <div className={styles.blue_card}>
      <img className={styles.group163} src={group163} alt="" />
      <img className={styles.group164} src={group164} alt="" />
      <div className={styles.total_bal}>
        <Typography>TOTAL BALANCE</Typography>
      </div>
      {loading ? (
        <Skeleton
          sx={{ bgcolor: "rgb(187, 183, 183)" }}
          width={"100%"}
          height={"80px"}
        />
      ) : (
        <>
          <div className={styles.total_amount}>
            <div>
              <Typography variant="h5">$</Typography>
              <Typography variant="h6">
                <NumberFormat
                  value={trxStats?.data?.transaction?.total_balance}
                  displayType={"text"}
                  thousandSeparator={true}
                />{" "}
              </Typography>
            </div>

            <Typography>USD</Typography>
          </div>
          {false && (
            <div className={styles.trend}>
              <img src={arrow} alt="" />
              <Typography>+23% from last week</Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default TotalBalance;
