import React, { useEffect } from "react";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import styles from "./PaymentFields.module.css";
import { useForm } from "react-hook-form";

function PaymentFields({paymentPlatformID, field, handleFieldChange }) {
    const {
        register,
        setValue,
        handleSubmit,
        watch,
        formState: { errors },
      } = useForm();
  const fieldName = watch(field.name, '')

  useEffect(()=>{
    setValue(field.name, field.value)
  }, [field])

 useEffect(()=>{
    handleFieldChange(field.id, paymentPlatformID, fieldName)
 }, [fieldName])

  return (
    <div className={styles.form_field}>
      <LabeledInputs
        label={field.label}
        placeholder={field.value}
        // redOutline={errors.Website ? true : false}
        register={register}
        name={field.name}
        handleChangeAuto={true}
        required={true}
        type="text"
      />
    </div>
  );
}

export default PaymentFields;
