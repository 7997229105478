import { Container, Typography } from "@material-ui/core";
import React from "react";
import { useState, useContext } from "react";
import styles from "./Login.module.css";
import { Link, useHistory } from "react-router-dom";
import eye_icon from "./../../../components/assets/Vector (28).svg";
import eye_icon2 from "./../../../components/assets/Group 1523.svg";
import { useForm } from "react-hook-form";
import LabeledInputs from "../../../components/inputs/labeledInputs/LabeledInputs";
import ButtonMain from "../../../components/button/Button";
import useOpenSnackbar from "../../../custom-hooks/useOpenSnackbar";
import P2PSnackbar from "../../../components/AppSnackbars/P2PSnackbar";
import { AuthContextAPI } from "../../../Context/auth/AuthContext";
import { useMutation } from "react-query";
import { LOGIN } from "../../../API/mutations/auth/Login/Login";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login, baseURL } = useContext(AuthContextAPI);
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { openSnackbar, severity, message, openToast, setOpenToast, duration } =
    useOpenSnackbar();

  const { mutate, isLoading } = useMutation(LOGIN, {
    onSuccess: (response) => {
      if(response.data.is_two_factor_enabled){
        localStorage.setItem("p2ptoken", response.data.token);
        history.push('/2fa')
      }else{
        login(response.data);
      }
    },
  });


  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onSubmit = async (data) => {
    mutate(data);
  };

  return (
    <div className={styles.login}>
      <Container maxWidth="sm">
        <div className={styles.login_content}>
          <div className={styles.login_heading}>
            <Typography variant="h6">Account Login</Typography>
            <Typography>Welcome back! Log In with your Email</Typography>
          </div>
          <div className={styles.login_form}>
            <form action="">
              <LabeledInputs
                label="Email"
                name="email"
                type="text"
                required={true}
                register={register}
              />

              <label htmlFor="">
                <div className={styles.password_div}>
                  <Typography>Password</Typography>
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
                <div className={styles.input_box}>
                  <input
                    {...register("password", { required: true, maxLength: 80 })}
                    name="password"
                    type={showPassword ? "text" : "password"}
                  />
                  <div onClick={handleTogglePassword}>
                    {showPassword ? (
                      <img src={eye_icon2} alt="" />
                    ) : (
                      <img src={eye_icon} alt="" />
                    )}
                  </div>
                </div>
              </label>
              <div className={styles.login_btn}>
                <ButtonMain
                  fullLength={true}
                  textColor="white"
                  bgColor="#1a2cce"
                  btnText="Login to account"
                  loading={isLoading}
                  handleClick={handleSubmit(onSubmit)}
                />
              </div>
            </form>
          </div>
        </div>
      </Container>
      <P2PSnackbar
        severity={severity}
        message={message}
        open={openToast}
        setOpen={setOpenToast}
        duration={duration}
      />
    </div>
  );
};

export default Login;
