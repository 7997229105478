import React from "react";
import { useForm } from "react-hook-form";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import UserForm from "../../components/userForm/UserForm";
import { useMutation, useQueryClient } from "react-query";
import { ADD_USER } from "../../../../../../API/mutations/settings/userRoles/UserRoles";

const AddUserModal = ({
  type,
  setShowModal,
 
  userRoles,
}) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation(ADD_USER, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["users"]);
      queryClient.invalidateQueries(["users-roles"]);
      setShowModal(false);
    },
    onError: () => {},
  });

  const handleAddUser = async (data) => {
     mutate({
       firstname: data.firstname,
       lastname: data.lastname,
       email: data.email,
       role: data.role,
       slack_profile: data.slack,
       phone_number: data.phone_number
     });
  };

  return (
    <ModalFrame
      handleCancel={() => {
        setShowModal(false);
      }}
      handleAction={handleSubmit(handleAddUser)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt={"Add user"}
      child={<UserForm type={type} userRoles={userRoles} register={register} />}
    />
  );
};

export default AddUserModal;
