import React, { useContext, useEffect, useState } from "react";
import styles from "./Settings.module.css";
import General from "./tabs/general/General";
import Platform from "./tabs/platform/Platform";
import UserRoles from "./tabs/user-roles/UserRoles";
import UserGroups from "./tabs/user-groups/UserGroups";
import Market from "./tabs/market/Market";
import Notifications from "./tabs/notifications/Notifications";
import Banks from "./tabs/banks/Banks";
import { UserContextAPI } from "../../Context/user/UserContext";

const settingsTab = [
  "general",
  "platform",
  "user-roles",
  "user-groups",
  // "notifications",
];
const UserSettings = () => {
  const { user } = useContext(UserContextAPI);
  const [activeTab, setActiveTab] = useState(
    localStorage.hasOwnProperty("p2pactive-tab")
      ? localStorage.getItem("p2pactive-tab")
      : "general"
  );

  const handleActiveTabSelect = (activeTab) => {
    setActiveTab(activeTab);
  };

  useEffect(() => {
    localStorage.setItem("p2pactive-tab", activeTab);
  }, [activeTab]);

  return (
    <div className={styles.settings_content}>
      <div className={styles.my_tabs}>
        <div className={styles.my_tab_header}>
          {settingsTab.map((tab) => {
            if (tab === "banks") {
              return (
                user.permissions.some((permission) => {
                  return permission === "banks";
                }) && (
                  <button
                    key={tab}
                    className={activeTab === tab ? styles.tab_active : null}
                    onClick={() => {
                      handleActiveTabSelect(tab);
                    }}
                  >
                    {tab}
                  </button>
                )
              );
            } else {
              return (
                <button
                  key={tab}
                  className={activeTab === tab ? styles.tab_active : null}
                  onClick={() => {
                    handleActiveTabSelect(tab);
                  }}
                >
                  {tab}
                </button>
              );
            }
          })}
        </div>
        <div className={styles.my_tab_content}>
          {activeTab === "general" ? <General /> : null}
          {activeTab === "banks"
            ? user.permissions.some((permission) => {
                return permission === activeTab;
              }) && <Banks />
            : null}
          {activeTab === "market" ? <Market /> : null}
          {activeTab === "platform" ? <Platform /> : null}
          {activeTab === "user-roles" ? <UserRoles /> : null}
          {activeTab === "user-groups" ? <UserGroups /> : null}
          {activeTab === "notifications" ? <Notifications /> : null}
        </div>
      </div>
    </div>
  );
};

export default UserSettings;
