// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Notifications_notifications__2LzRp {\n\n}\n\n.Notifications_my_accordion_summary__3gDdx {\n    background: #F8F9FD !important;\npadding-left: 48px !important;\npadding-right: 48px;\n}\n\n.Notifications_my_accordion_summary__3gDdx p{\n     \nfont-size: 16px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 19px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #000A62;\n\n}\n\n.Notifications_my_accordion__2XmtV {\n    border: 0.4px solid #bdbdbd56;\n    box-shadow: none !important;\n    border-radius: 0 !important;\n    margin: 10px 0 !important;\n}", "",{"version":3,"sources":["webpack://src/pages/settings/tabs/notifications/Notifications.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,8BAA8B;AAClC,6BAA6B;AAC7B,mBAAmB;AACnB;;AAEA;;AAEA,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;;AAEd;;AAEA;IACI,6BAA6B;IAC7B,2BAA2B;IAC3B,2BAA2B;IAC3B,yBAAyB;AAC7B","sourcesContent":[".notifications {\n\n}\n\n.my_accordion_summary {\n    background: #F8F9FD !important;\npadding-left: 48px !important;\npadding-right: 48px;\n}\n\n.my_accordion_summary p{\n     \nfont-size: 16px;\nfont-style: normal;\nfont-weight: 700;\nline-height: 19px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #000A62;\n\n}\n\n.my_accordion {\n    border: 0.4px solid #bdbdbd56;\n    box-shadow: none !important;\n    border-radius: 0 !important;\n    margin: 10px 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notifications": "Notifications_notifications__2LzRp",
	"my_accordion_summary": "Notifications_my_accordion_summary__3gDdx",
	"my_accordion": "Notifications_my_accordion__2XmtV"
};
export default ___CSS_LOADER_EXPORT___;
