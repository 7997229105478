import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Modal.module.css";
import { LinearProgress } from "@material-ui/core";
import { usePostRequest } from "../../../../../../custom-hooks/api";
import axios from "axios";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import { useForm } from "react-hook-form";
import LabeledSelects from "../../../../../../components/selects/labeledSelects/LabeledSelects";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import { MiscContextAPI } from "../../../../../../Context/misc/MiscContext";
import { AuthContextAPI } from "../../../../../../Context/auth/AuthContext";

const MarketForm = ({
  handleCloseModal,
  market,
  setMarket,
  loading,
  handleFetchList,
  type,
}) => {
  const { handleGetPairs,loadingPairs, pairs } =
    useContext(MiscContextAPI);
const {baseURL} = useContext(AuthContextAPI)
  const { postRequest, posted, processing, setProcessing } = usePostRequest();
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const pair = watch("pair", "");
  const [currentRate, setCurrentRate] = useState({});
  const [loadingCurrentRate, setLoadingCurrentRate] = useState(false);

  const [title, setTitle] = useState("");

  useEffect(() => {
    handleGetPairs();

    if (type === "edit") {
      setTitle("Edit market");
    } else if (type === "add") {
      setTitle("Add new market");
    }
  }, []);

  useEffect(() => {
    if (type === "edit") {
      handlePrepopulate();
    }
  }, [market, pairs]);

  useEffect(() => {
    handleActionAfterPosting();
  }, [posted]);

  useEffect(() => {
    handleGetPairRate();
  }, [pair]);

  const handlePrepopulate = () => {
    setValue("pair", market.pair);
    setValue("offset", market.offset);
    setValue("buying", market.buying);
    setValue("selling", market.selling);
  };

  const handleGetPairRate = async () => {
    setLoadingCurrentRate(true);
    try {
      const response = await axios.post(`${baseURL}/api/v1/market/rate`, {
        pair,
      });
      const { data } = response.data;

      setCurrentRate(data);
      setLoadingCurrentRate(false);
    } catch (error) {
      setLoadingCurrentRate(false);
    }
  };

  const handleCreateMarket = (data) => {
    postRequest("/api/v1/market/create", {
      pair: data.pair,
      offset: data.offset,
      buying: data.buying,
      selling: data.selling,
    });
  };

  const handleEditMarket = async (data) => {
    setProcessing(true);

    try {
      const response = await axios.put(
        `${baseURL}/api/v1/market/update/${market.id}`
      );
      setProcessing(false);
      handleFetchList();
      setMarket({});
      handleCloseModal();
    } catch (error) {
      setProcessing(false);
    }
  };

  const handleActionAfterPosting = () => {
    if (posted) {
      handleFetchList();
      handleCloseModal();
    }
  };

  const handleAction = (data) => {
    if (type === "edit") {
      handleEditMarket(data);
    } else if (type === "add") {
      handleCreateMarket(data);
    }
  };

  const handleCancel = () => {
    handleCloseModal();
    if (type === "edit") {
      setMarket({});
    }
  };

  return (
    <ModalFrame
      handleCancel={() => {
        handleCloseModal();
      }}
      handleAction={handleSubmit(handleAction)}
      loading={processing}
      showCancelBtn
      showConfirmBtn={!(loadingPairs || loading)}
      btnTxt="Confirm"
      child={
        <div
          onClick={(event) => {
            event.stopPropagation();
          }}
          className={styles.add_market}
        >
          {loadingPairs ? (
            <LinearProgress />
          ) : (
            <>
              <div className={styles.add_market_heading}>
                <Typography>{title}</Typography>
              </div>
              <div className={styles.form_field}>
                <LabeledSelects
                  label="Select pair"
                  register={register}
                  name="pair"
                  data={pairs}
                  placeholder="Currency pair"
                />

                <div className={styles.current_rate}>
                  <Typography>Current Rate: </Typography>
                  <Typography>
                    {currentRate.rate} {currentRate.pair}
                  </Typography>
                </div>
              </div>

              <div className={styles.form_field}>
                <LabeledSelects
                  placeholder="Select offset"
                  label="Offset"
                  register={register}
                  name="offset"
                  data={[{ name: "flat" }, { name: "percent" }]}
                />
              </div>
              <div className={styles.form_field}>
                <LabeledInputs
                  type="text"
                  name="buying"
                  placeholder=""
                  register={register}
                  label="Buying price markup"
                  required
                />
              </div>
              <div className={styles.form_field}>
                <LabeledInputs
                  type="text"
                  name="selling"
                  placeholder=""
                  register={register}
                  label="Selling price markup"
                  required
                />
              </div>

              {/* <div className={styles.dark_bg}>
            <button onClick={handleCancel}>Cancel</button>
            <button onClick={ handleSubmit(handleAction)}>
              {" "}
              {type === "edit"
                ? processing
                  ? "Processing..."
                  : "Edit"
                : processing
                ? "Processing..."
                : "Add"}
            </button>
          </div> */}
            </>
          )}
        </div>
      }
    />
  );
};

export default MarketForm;
