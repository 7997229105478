import { Container, } from "@material-ui/core";
import React, { useContext } from "react";
import styles from "./Settings.module.css";
import PartnerSettings from "./PartnerSettings";
import UserSettings from "./UserSettings";
import PageLayout from "../../components/Layout/PageLayout";
import { UserContextAPI } from "../../Context/user/UserContext";


const Settings = () => {
  const {
    user,
  } = useContext(UserContextAPI);
 



  return (
    <PageLayout
      bgColor="#ffffff"
      child={
        <div className={styles.settings}>
          <Container>
            { user?.partner ? (
              <PartnerSettings />
            ) : (
              <UserSettings />
            )}
          </Container>
         
        </div>
      }
    />
  );
};

export default Settings;
