import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
  GET_FILTERED_TRX_LOGS,
  GET_TRX_LOGS,
  GET_TRX_SUM,
  GET_TRX_SUMMARY,
} from "../../API/queries/transactions/Transactions";
import PageLayout from "../../components/Layout/PageLayout";
import Transactions from "../../components/transaction/Transaction";
import styles from "./Audit.module.css";
import { useQueryClient } from "react-query";
const tableHeadData = [
  "order no",
  "received",
  "paid",
  "status",
  "error",
  "bank",
];

const AuditLogs = () => {

  const [transaction, setTransaction] = useState({});

  const [filterData, setFilterData] = useState({});
  const [page, setPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  
  const { data: trxSum, isLoading: trxSumLoading } = useQuery(
    ["trx-total-bal"],
    () => GET_TRX_SUM(),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: trxSummary, isLoading: trxSummaryLoading } = useQuery(
    ["trx-summary"],
    () => GET_TRX_SUMMARY(),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: auditLogs, isLoading: isTrxLoading } = useQuery(
    [`audit-logs`, page, searchValue, filterData],
    GET_TRX_LOGS,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    setTransaction(auditLogs);
  }, [auditLogs]);

  const handleGetFilterQueries = async (data) => {
    setFilterData(data);
  };

  const handlePageChange = async (page) => {
    setPage(page);
  };

  const handleSearchOrderNoSubmit = async (data) => {
    const { trans_ref } = data;
    setSearchValue(trans_ref);
  };

  const handleRefresh = async () => {
    setFilterData({});
  };

  return (
    <PageLayout
      child={
        <div className={styles.audit}>
          <Container>
            <div className={styles.audit_content}>
              <Transactions
                setFilterData={setFilterData}
                handleRefresh={handleRefresh}
                handleSearchOrderNoSubmit={handleSearchOrderNoSubmit}
                handlePageChange={handlePageChange}
                handleGetFilterQueries={handleGetFilterQueries}
                auditLogs={transaction?.data}
                isTrxLoading={isTrxLoading}
                type="audit"
                trxSum={{
                  crypto: trxSum?.data?.crypto,
                  fiat: trxSum?.data?.fiat,
                }}
                tempSummaryData={auditLogs?.data}
                trxSumLoading={isTrxLoading}
                trxSummary={trxSummary?.data?.transactions}
                trxSummaryLoading={isTrxLoading}
                tableHeadData={tableHeadData}
              />
            </div>
          </Container>
        </div>
      }
    />
  );
};

export default AuditLogs;
