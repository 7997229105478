import {
  Container,
  Typography,
  Tooltip,
  IconButton,
  Badge,

} from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";

import styles from "./Appeals.module.css";
import searchIcon from "./../../components/assets/Vector (7).svg";
import icon from "./../../components/assets/Group 83.svg";
import tether from "./../../components/assets/Group 140.svg";
import btc from "./../../components/assets/btc.svg";
import NumberFormat from "react-number-format";
import moment from "moment";
import gavel_red from './../../components/assets/gavelred.svg'
import chat from './../../components/assets/chat.svg'
import PageLayout from "../../components/Layout/PageLayout";
import { SupportContextAPI } from "../../Context/support/SupportContext";

const Appeals = () => {

  const {support} = useContext(SupportContextAPI)
  const [crypto_currency, setCryptoCurrency] = useState("");
  const [date, setDate] = useState("");
  const [limit, setLimit] = useState("10");
  const [error, setError] = useState("all");
  const [status, setStatus] = useState("");
  
 

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    if (name === "date") {
      setDate(value);
    }
    if (name === "crypto") {
      setCryptoCurrency(value);
    }
    if (name === "error") {
      setError(value);
    }
    if (name === "limit") {
      setLimit(value);
    }
  };

  const handleGoToEdit = (id, error) => {
    //history.push(`/dashboard/support/${error}/${id}`);
  };

  const handleGetFilterQueries = async () => {
    // getRequest(
    //   ``
    // );
  };

  const handleSearchOrderNoSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <PageLayout bgColor='#ffffff' child={
      <div className={styles.appeals}>
      <Container>
        <div className={styles.appeals_content}>
         
          <div className={styles.support_filter}>
            <form onSubmit={handleSearchOrderNoSubmit} action="">
              <div className={styles.search}>
                <div>
                  <img src={searchIcon} alt="" />
                </div>
                <input
                  type="text"
                  placeholder="Search order no"
                  name=""
                  id=""
                />
              </div>
            </form>

            <div className={styles.selects}>
              <select
                onChange={handleSelectChange}
                value={crypto_currency}
                name="crypto"
                id=""
              >
                <option value="">Crypto</option>
                <option value="USDT">USDT</option>
                <option value="NGN">NGN</option>
              </select>
              <select
                onChange={handleSelectChange}
                value={crypto_currency}
                name="Platform"
                id=""
              >
                <option value="">Platform</option>
                <option value="USDT">USDT</option>
                <option value="NGN">NGN</option>
              </select>
              <select
                onChange={handleSelectChange}
                value={status}
                name="status"
                id=""
              >
                <option value="">All</option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
              </select>
              <button onClick={handleGetFilterQueries}>Apply Filters</button>
            </div>
          </div>
          <div className={styles.support_data_table}>
            <table>
              <thead>
                <tr>
                  <td>
                    <div className={styles.grid_header}>
                      {" "}
                      <Typography variant="h6">order no </Typography>
                      <img src={icon} alt="" />
                    </div>
                  </td>
                  <td>
                    <div className={styles.grid_header}>
                      {" "}
                      <Typography variant="h6">quantity </Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.grid_header}>
                      {" "}
                      <Typography variant="h6">amount </Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.grid_header}>
                      {" "}
                      <Typography variant="h6">Bank </Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.grid_header}>
                      {" "}
                      <Typography variant="h6">Status </Typography>
                    </div>
                  </td>
                  <td>
                    <div className={styles.grid_header}></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {support.map((support) => {
                  return (
                    <tr
                      onClick={() => {
                        handleGoToEdit(support.id, support.error);
                      }}
                      key={support.id}
                    >
                      <td>
                        <div className={styles.order_no}>
                          <img
                            src={
                              support.crypto_currency === "USDT" ? tether : btc
                            }
                            alt=""
                          />
                          <div>
                            <Typography variant="h5">Buying USDT</Typography>
                            <Typography variant="h6">
                              {support.order_no}{" "}
                            </Typography>
                            <Typography>
                              {" "}
                              {moment(support.created_at).format(
                                "Do MMM YYYY"
                              )}{" "}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={styles.quantity}>
                          {" "}
                          <Typography variant="h6">
                            <NumberFormat
                              value={support.crypto_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />{" "}
                          </Typography>
                          <Typography>{support.crypto_currency}</Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.amount}>
                          {" "}
                          <Typography variant="h6">
                            <NumberFormat
                              value={support.fiat_amount}
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </Typography>
                          <Typography>{support.fiat_currency}</Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.bank}>
                          {" "}
                          <Typography variant="h6">
                            {support.acc_name}
                          </Typography>
                          <Typography>{support.bank_name}</Typography>
                        </div>
                      </td>
                      <td>
                        <div className={styles.error}>
                          {" "}
                          {}
                          <div className={styles.error_chip}>
                            <button className={styles.appeal_btn}>
                              Appeal
                            </button>
                            {/* <button className={styles.paid_btn}>Paid</button>
                                <button className={styles.unpaid_btn}>Unpaid</button> */}
                            {/* {support.error === "Resolution" ? (
                                  <button>{support.error}</button>
                                ) : null}
                                {support.error === "Processor" ? (
                                  <button className={styles.processor_error}>
                                    {support.error}
                                  </button>
                                ) : null}
                                {support.error === "Payment" ? (
                                  <button className={styles.payment_error}>
                                    {support.error}
                                  </button>
                                ) : null} */}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className={styles.error}>
                          <Tooltip
                            onClick={() => {
                              //handleShowAdDetailModal(ad.id);
                            }}
                            title="View"
                            arrow
                            placement="top"
                          >
                            <IconButton aria-label="" color="inherit">
                              <Badge
                                className={styles.my_badge}
                                badgeContent={3}
                              >
                               <img src={chat} alt="" />
                              </Badge>
                            </IconButton>
                          
                          </Tooltip>
                          <Tooltip
                            onClick={() => {
                              //handleGoToEdit(ad.id);
                            }}
                            title="Edit"
                            arrow
                            placement="top"
                          >
                             <IconButton aria-label="" color="inherit">
                             <img src={gavel_red} alt="" />
                          
                            </IconButton>
                         
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* {data.items.length === 0 ? null : (
                  <div className={styles.table_pagination}>
                    <div className={styles.rows_per_page}>
                      <Typography>Rows per page</Typography>
                      <select
                        onChange={handleSelectChange}
                        value={limit}
                        name="limit"
                        id=""
                      >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div className={styles.limit}>
                      <Typography>
                        Page {data.meta.currentPage} of {data.meta.totalPages}
                      </Typography>
                    </div>
                    <div className={styles.pagination_icons}>
                      <FirstPageIcon onClick={handleGoToFirst} />
                      <SkipPreviousIcon onClick={handleGotToPrev} />
                      <SkipNextIcon onClick={handleGoToNext} />
                      <LastPageIcon onClick={handleGoToLast} />
                    </div>
                  </div>
                )} */}
          </div>
        </div>
      </Container>
    </div>
    } />
   
  );
};

export default Appeals;
