import { Container } from "@material-ui/core";
import React, { useState} from "react";
import styles from "../Dashboard.module.css";
import ReportDetails from "../../../components/transaction/modals/ReportDetails";
import OverviewCards from "./components/overviewCards/OverviewCards";
import TransactionProcessed from "./components/transaction/TransactionProcessed";
import { useQuery } from "react-query";
import { GET_BOTS } from "../../../API/queries/dashboard/Dashboard";


const UserDashboard = () => {
  const [showReportDetailsModal, setShowReportDetailsModal] = useState(false);
  const { data: dashboardData, isLoading } = useQuery(["bots"], GET_BOTS, {
    refetchOnWindowFocus: false,
  });
  
  return (
    <div className={styles.dashboard}>
  
      <Container>
        <div className={styles.dashboard_content}>
          <>
            <OverviewCards dashboardData={dashboardData} loading={isLoading} />
            <TransactionProcessed dashboardData={dashboardData} loading={isLoading}  />
          </>
        </div>
      </Container>

      {showReportDetailsModal && (
        <ReportDetails setShowReportDetailsModal={setShowReportDetailsModal} />
      )}
    </div>
  );
};

export default UserDashboard;

//FOR CHARTS
{
  /* <div className={styles.dashboard_overview_chart}>
                    <div className={styles.overview_heading}>
                      <Typography>REPORTS OVERVIEW</Typography>
                      <div>
                        <select name="Months" id="">
                          <option value="">January</option>
                        </select>
                        <input type="date" name="" id="" />
                      </div>
                    </div>
                    <Grid
                      className={styles.report_parent_grid}
                      container
                      spacing={3}
                    >
                      <Grid
                        className={styles.report_chart_grid}
                        item
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        <div className={styles.chart_title}>
                          <Typography variant="h6">Gross volume</Typography>
                          <Typography>$50,000.00</Typography>
                        </div>
                        <TransactionProccessedChart
                          displayedChart={displayedChart}
                        />
                      </Grid>
                      <Grid
                        className={styles.report_chart_grid}
                        item
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        {" "}
                        <div className={styles.chart_title}>
                          <Typography variant="h6">
                            Net volume from sales
                          </Typography>
                          <Typography>$0.00</Typography>
                        </div>
                        <TransactionProccessedChart
                          displayedChart={displayedChart}
                        />
                      </Grid>
                      <Grid
                        className={styles.report_chart_grid}
                        item
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        <div className={styles.chart_title}>
                          <Typography variant="h6">
                            Successful payments
                          </Typography>
                          <Typography>$0.00</Typography>
                        </div>
                        <TransactionProccessedChart
                          displayedChart={displayedChart}
                        />
                      </Grid>

                      <Grid
                        className={styles.report_chart_grid}
                        item
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        <div className={styles.chart_title}>
                          <Typography variant="h6">
                            Failed transactions
                          </Typography>
                          <Typography>$0.00</Typography>
                        </div>
                        <TransactionProccessedChart
                          displayedChart={displayedChart}
                        />
                      </Grid>
                      <Grid
                        className={styles.report_chart_grid}
                        item
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        {" "}
                        <div className={styles.chart_title}>
                          <Typography variant="h6">
                            Number of appeals
                          </Typography>
                          <Typography>0</Typography>
                        </div>
                        <TransactionProccessedChart
                          displayedChart={displayedChart}
                        />
                      </Grid>
                      <Grid
                        className={styles.report_chart_grid}
                        item
                        sm={12}
                        md={6}
                        lg={4}
                      >
                        {" "}
                        <div className={styles.chart_title}>
                          <Typography variant="h6">
                            Number of customers
                          </Typography>
                          <Typography>0</Typography>
                        </div>
                        <TransactionProccessedChart
                          displayedChart={displayedChart}
                        />
                      </Grid>
                    </Grid>
                  </div> */
}
