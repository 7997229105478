import { Container, Typography } from "@material-ui/core";
import axios from "axios";
import React, { useContext, useState } from "react";
import styles from "./Modal.module.css";
import LabeledSelects from "../../selects/labeledSelects/LabeledSelects";
import { useForm } from "react-hook-form";
import ButtonMain from "../../button/Button";
import { UserContextAPI } from "../../../Context/user/UserContext";
import { AuthContextAPI } from "../../../Context/auth/AuthContext";
import LabeledInputs from "../../inputs/labeledInputs/LabeledInputs";
import { yupResolver } from "@hookform/resolvers/yup";
import { exportReportSchema } from "../../../schemas/transaction";

const ReportDetails = ({ setShowReportDetailsModal, error }) => {
  const { baseURL } = useContext(AuthContextAPI);
  const { user } = useContext(UserContextAPI);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(exportReportSchema),
  });
  const format = watch('format', '')
  const [date, setDate] = useState("");
  const [downloading, setDownloading] = useState(false);

 
  const handleClose = () => {
    setShowReportDetailsModal(false);
  };


  const handleExportReport = async (data) => {
    const { start_date, end_date } = data;

    setDownloading(true);
    axios({
      url: `${baseURL}/api/v1/transactions/download/file`,
      params: {
        error,
        start_date,
        end_date,
        format: data.format,
      },
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(`download`, `report.${format.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setDownloading(false);
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  };

  const handleExportReportForGroup = async (data) => {
    setDownloading(true);
    const groupCryptos = user.group.crypto_currencies.map((crypto) => {
      return crypto.name;
    });

    const groupfiat = user.group.fiat_currencies.map((fiat) => {
      return fiat.name;
    });
    axios({
      url: `${baseURL}/api/v1/transactions/group/download/file`,
      params: {
        //error,
        //date,
        format,
        crypto_currencies: groupCryptos,
        fiat_currencies: groupfiat,
      },
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        setDownloading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(`download`, `report.${format.toLowerCase()}`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        setDownloading(false);
        if (error.response) {
        } else if (error.request) {
        } else {
        }
      });
  };



  const handleSelectDownloadType = (data) => {
    if (user?.partner) {
      handleExportReportForGroup(data);
    } else {
      handleExportReport(data);
    }
  };

  return (
    <div onClick={handleClose} className={styles.my_modal}>
      <Container>
        <div className={styles.my_modal_content}>
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
            className={styles.report_details_modal}
          >
            <div className={styles.report_details_heading}>
              <Typography>View Report</Typography>
            </div>
            <div className={styles.report_details_select}>
              <LabeledSelects
                placeholder="Select format"
                register={register}
                name="format"
                data={[{ name: "PDF" }, { name: "CSV" }, { name: "XLSX" }]}
                label="Select file type"
              />

              <LabeledInputs
                type="date"
                name="start_date"
                register={register}
                label="From"
              />

              <LabeledInputs
                type="date"
                name="end_date"
                register={register}
                label="To"
              />

              {/* <LabeledSelects
                placeholder="Date"
                register={register}
                name="date"
                data={[
                  { name: "TODAY" },
                  { name: "THIS WEEK" },
                  { name: "THIS MONTH" },
                  { name: "THIS YEAR" },
                ]}
                label="Select Date"
              /> */}
            </div>

            <div className={styles.date_btn}>
              <ButtonMain
                handleClick={handleSubmit(handleSelectDownloadType)}
                bgColor="#1A2CCE"
                btnText="Export Report"
                textColor="white"
                fullLength
                loading={downloading}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ReportDetails;
