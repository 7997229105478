// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n\n.Support_suppprt_content__1GIg8 {\n    display: flex;\n    flex-direction: column;\n}\n.Support_support_heading__2bt4Z {\n    margin-top: 58px;\n    margin-bottom: 30px;\n       border-bottom: 0.4px solid #E0E0E0;\n    padding-bottom: 14px;\n}\n\n.Support_support_heading__2bt4Z p{\n     \nfont-size: 23px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 27px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #000000;\n}\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://src/pages/support/Support.module.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,gBAAgB;IAChB,mBAAmB;OAChB,kCAAkC;IACrC,oBAAoB;AACxB;;AAEA;;AAEA,eAAe;AACf,kBAAkB;AAClB,gBAAgB;AAChB,iBAAiB;AACjB,mBAAmB;AACnB,gBAAgB;AAChB,cAAc;AACd","sourcesContent":["\n\n.suppprt_content {\n    display: flex;\n    flex-direction: column;\n}\n.support_heading {\n    margin-top: 58px;\n    margin-bottom: 30px;\n       border-bottom: 0.4px solid #E0E0E0;\n    padding-bottom: 14px;\n}\n\n.support_heading p{\n     \nfont-size: 23px;\nfont-style: normal;\nfont-weight: 400;\nline-height: 27px;\nletter-spacing: 0em;\ntext-align: left;\ncolor: #000000;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"suppprt_content": "Support_suppprt_content__1GIg8",
	"support_heading": "Support_support_heading__2bt4Z"
};
export default ___CSS_LOADER_EXPORT___;
