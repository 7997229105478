import React from 'react';
import { Typography, Switch, FormControl, FormControlLabel, } from '@material-ui/core'
import styles from './EditAds.module.css'
import { withStyles } from '@material-ui/core/styles';







const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Edit1 = ({ checkedB, handleChange, handleSwitch }) => {
  


    return (
          <div className={styles.type_price}>
            <div className={styles.advert_status}>
              <div>
                <Typography variant="h6">Advert status</Typography>
                <Typography>
                  If turned off, your ad will be paused on the platform
                </Typography>
              </div>
              <div>
              <div
                    onClick={handleSwitch}
                    className={
                      checkedB ? styles.my_switch : styles.my_switch_active
                    }
                  >
                    <div
                      onClick={handleSwitch}
                      className={styles.switch_ball}
                    ></div>
                  </div>
              </div>
            </div>
            <div className={styles.advert_type}>
              <div className={styles.advert_type_left}>
                <div>
                  <Typography variant="h6">Advert type</Typography>
                  <Typography>Set the ad type</Typography>
                </div>
                <select name="" id="">
                  <option value="">Buy</option>
                </select>
              </div>
              <div className={styles.advert_type_right}>
                <div>
                  <Typography variant="h6">Offer type</Typography>
                  <Typography>Set the currency you are trading</Typography>
                </div>
                <select name="" id="">
                  <option value="">USDT/NGN</option>
                </select>
              </div>
            </div>
            <div className={styles.price}>
              <div className={styles.price_title}>
                <Typography variant="h6">Your Price</Typography>
                <Typography>This is the fixed rate you wish to buy</Typography>
              </div>
              <div className={styles.your_price}>
                <Typography variant="h6">Your Price</Typography>
                <Typography>485.50 NGN</Typography>
              </div>
              <div className={styles.your_price}>
                <Typography variant="h6">Highest order price</Typography>
                <Typography>485.50 NGN</Typography>
              </div>
            </div>
            <div className={styles.price_type}>
              <div>
                <Typography variant="h6">Price type</Typography>
                <Typography>
                  If turned off, your ad will be paused on the platform
                </Typography>
              </div>
              <label htmlFor="">
                <input type="radio" name="" id="" /> Fixed
              </label>

              <label htmlFor="">
                <input type="radio" name="" id="" /> Floating
              </label>
            </div>
          </div>
     );
}
 
export default Edit1;