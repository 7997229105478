// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TryAgain_try_again__1X9kl {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.TryAgain_try_again__1X9kl button{\n    background-color: #0066FF;\n    padding: .7rem;\n    border: none;\n    color: white;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-top: 5rem;\n\n}", "",{"version":3,"sources":["webpack://src/components/tryAgain/TryAgain.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;;AAEpB","sourcesContent":[".try_again {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.try_again button{\n    background-color: #0066FF;\n    padding: .7rem;\n    border: none;\n    color: white;\n    border-radius: 5px;\n    cursor: pointer;\n    margin-top: 5rem;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"try_again": "TryAgain_try_again__1X9kl"
};
export default ___CSS_LOADER_EXPORT___;
