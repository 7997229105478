import { Button, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import icon from "./../../../components/assets/Ellipse 40.svg";
import see from "./../../../components/assets/Vector (15).svg";
import edit from "./../../../components/assets/Vector (16).svg";
import delete_icon from "./../../../components/assets/Vector (17).svg";
import styles from "../Ads.module.css";

function AdsTable({
  ads,
  handleGoToEdit,
  handleShowAdDetailModal,
  handleOpenDeleteModal,
}) {
  return (
    <div className={styles.ads_table}>
      <table>
        <thead>
          <td>
            {" "}
            <div className={styles.grid_header}>
              {" "}
              <Typography variant="h6">account name </Typography>
            </div>
          </td>
          <td>
            {" "}
            <div className={styles.grid_header}>
              {" "}
              <Typography variant="h6">advert type</Typography>
            </div>
          </td>
          <td>
            {" "}
            <div className={styles.grid_header}>
              {" "}
              <Typography variant="h6">order no </Typography>
            </div>
          </td>
          <td>
            {" "}
            <div className={styles.grid_header}>
              {" "}
              <Typography variant="h6">price </Typography>
            </div>
          </td>
          <td>
            {" "}
            <div className={styles.grid_header}>
              {" "}
              <Typography variant="h6">amount/limit</Typography>
            </div>
          </td>
          <td></td>
        </thead>
        <tbody>
          {ads.map((ad) => {
            return (
              <tr
                key={ad.id}
                onClick={() => {
                  handleGoToEdit(ad.id);
                }}
              >
                <td>
                  <div className={styles.account_name}>
                    {" "}
                    <img src={icon} alt="" />
                    <Typography>{ad.account_name} </Typography>
                  </div>
                </td>
                <td>
                  <div className={styles.advert_type}>
                    {" "}
                    <Typography variant="h6">{ad.advert_type} </Typography>
                    <Typography>{ad.tradngPair}</Typography>
                  </div>
                </td>
                <td>
                  <div className={styles.order_no}>
                    {" "}
                    <Typography> {ad.order_no} </Typography>
                  </div>
                </td>
                <td>
                  <div className={styles.price}>
                    {" "}
                    <Typography>{ad.price} </Typography>
                  </div>
                </td>
                <td>
                  <div className={styles.amount_limit}>
                    {" "}
                    <Typography variant="h6">{ad.amount_limit} </Typography>
                    <Typography> {ad.limit} </Typography>
                  </div>
                </td>
                <td>
                  <div className={styles.actions}>
                    {" "}
                    <Tooltip
                      onClick={() => {
                        handleShowAdDetailModal(ad.id);
                      }}
                      title="View"
                      arrow
                      placement="top"
                    >
                      <Button>
                        {" "}
                        <img src={see} alt="" />{" "}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      onClick={() => {
                        handleGoToEdit(ad.id);
                      }}
                      title="Edit"
                      arrow
                      placement="top"
                    >
                      <Button>
                        {" "}
                        <img src={edit} alt="" />{" "}
                      </Button>
                    </Tooltip>
                    <Tooltip
                      onClick={() => {
                        handleOpenDeleteModal(ad.id);
                      }}
                      title="Delete"
                      arrow
                      placement="top"
                    >
                      <Button>
                        {" "}
                        <img src={delete_icon} alt="" />{" "}
                      </Button>
                    </Tooltip>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default AdsTable;
