import { Typography } from "@material-ui/core";
import React, { useState, useRef, useEffect, useContext } from "react";
import styles from "./RetryPayment.module.css";
import { io } from "socket.io-client";
import { useWebsocket } from "../../../../custom-hooks/api";
import { useForm } from "react-hook-form";
import LabeledSelects from "../../../../components/selects/labeledSelects/LabeledSelects";
import ModalFrame from "../../../../components/frames/modalFrame/ModalFrame";
import { UserContextAPI } from "../../../../Context/user/UserContext";
import { useMutation } from "react-query";
import { RESOLVE_ERROR } from "../../../../API/mutations/support/Support";
const RetryPayment = ({
  handleCloseModal,
  errorType,
  
  channels,
  expiryTime,
  issue,
}) => {
 
  const { user } = useContext(UserContextAPI);

  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const socket = useRef();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    return () => socket.current.disconnect();
  }, []);

  // const handleTestSOcket =()=>{
  //    const data = {
  //     event: "transaction_resolved",
  //     data: {
  //       id: issue.id,
  //       order_no: issue.order_no,
  //       user: user.firstname
  //     },
  //   };
  //   socket.current.emit("p2pcollect", data);
  // }

  const { mutate, isLoading } = useMutation(RESOLVE_ERROR, {
    onSuccess: (response) => {
      const data = {
        event: "transaction_resolved",
        data: {
          id: issue.id,
          order_no: issue.order_no,
          user: user?.firstname,
        },
      };
      socket.current.emit("p2pcollect", data);
    },
  });

  const handleSubmitRetryDetails = (data) => {
    mutate({
      data: {
        type: errorType,
        channel: data.channel,
      },
      id: issue.id,
    });
   
  };

  return (
    <ModalFrame
      handleCancel={() => {
        handleCloseModal();
      }}
      handleAction={handleSubmit(handleSubmitRetryDetails)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt="Confirm"
      child={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.retry_payment}
        >
          <div className={styles.retry_payment_heading}>
            <Typography>Retry payment</Typography>
            <div className={styles.expires_in}>
              <Typography variant="h6">EXPIRES IN</Typography>
              <Typography
                style={{
                  color: expiryTime < 420000 ? "red" : "black",
                }}
              >
                {Math.floor(
                  (expiryTime <= 0 ? 0 : expiryTime / 1000 / 60) % 60
                )}{" "}
                : {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000) % 60)}
              </Typography>
            </div>
          </div>
          <div className={styles.retry_payment_body}>
            <LabeledSelects
            required={true}
              register={register}
              name="channel"
              label=" Select payment channel"
              placeholder="Payment Channel"
              data={channels}
            />
          </div>
        </div>
      }
    />
  );
};

export default RetryPayment;

{
  /* <div className={styles.dark_bg}>
<button onClick={handleCloseModal}>Cancel</button>
<button onClick={handleSubmit(handleSubmitRetryDetails)}>
  {" "}
  {processing ? "Processing..." : "Confirm"}
</button>
 <button onClick={handleTestSOcket}>test websocket</button> 
</div> */
}
