import {
  Container,
  Stepper,
  Typography,
  Step,
  StepLabel,
} from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import styles from "./PostAds.module.css";
import { useState } from "react";
import Post1 from "./Post1";
import Post2 from "./Post2";
import Post3 from "./Post3";
import { usePostRequest, useGetRequest } from "../../../custom-hooks/api";

import axios from "axios";
import ButtonMain from "../../../components/button/Button";
import P2PSnackbar from "../../../components/AppSnackbars/P2PSnackbar";
import useOpenSnackbar from "../../../custom-hooks/useOpenSnackbar";
import GoBack from "../../../components/goBack/GoBack";
import { AuthContextAPI } from "../../../Context/auth/AuthContext";

function getSteps() {
  return [
    "Set type/price",
    "Set trading amount & payment method",
    "Set remarks & Automatic remarks",
  ];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return "Set type/price";
    case 1:
      return "Set trading amount & payment method";
    case 2:
      return "Set remarks & Automatic remarks";
    default:
      return "Unknown stepIndex";
  }
}

const PostAds = ({ match }) => {
  const { baseURL } = useContext(AuthContextAPI);
  
  const { postRequest, posted, processing } = usePostRequest();
  const { data, loading, getRequest } = useGetRequest();
  const {
    openSnackbar,
    setOpenToast,
    message,
    duration,
    severity,
    openToast,
  } = useOpenSnackbar();
  const steps = getSteps();
  const [checkedB, setCheckedB] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [priceType, setpriceType] = useState("fixed");
  const [floatingPrice, setFloatingPrice] = useState("100");
  const [totalAmount, setTotalAmount] = useState("");
  const [orderLimitMin, setOrderLimitMin] = useState("");
  const [orderLimitMax, setOrderLimitMax] = useState("");
  const [tradeMethods, setTrademethod] = useState([]);
  const [payTimeLimit, setpayTimeLimit] = useState("");
  const [remarks, setremarks] = useState("");
  const [autoReplyMsg, setautoReplyMsg] = useState("");
  const [onlineNow, setonlineNow] = useState(Boolean);
  const [registered, setRegistered] = useState("");
  const [holding, setHolding] = useState("");
  const [tradeType, setTradeType] = useState("BUY");
  const [asset, setAsset] = useState("USDT");
  const [withAsset, setWithAsset] = useState("NGN");

  const [incomingPrice, setIncomingPrice] = useState({});
  const [newPrice, setNewPrice] = useState("");
  const [price, setPrice] = useState("");
  const [conditions, setCondiitons] = useState([]);
  const [rules, setRules] = useState({});
  const [loadingRules, setLoadingRules] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [incomingPaymentMethod, setIncomingPaymentMethod] = useState([]);
  const [amountLimits, setAmountLimits] = useState({});
  const reg = /^-?\d*\.?\d*$/;

  useEffect(() => {
    handleFetchAmountLimit();
  }, [asset, withAsset, tradeType]);

  useEffect(() => {
    handleFetchPriceBasedOnFiatAssets();
  }, [asset, withAsset]);

  useEffect(() => {
    handlePrepopulationOfOrderLimits();
  }, [amountLimits]);
  useEffect(() => {
    handleGetPaymentMethod();
    handleGetAdvertRules();
  }, []);

  useEffect(() => {
    if (priceType === "floating") {
      if (Object.keys(incomingPrice).length > 0) {
        setNewPrice((incomingPrice.low * floatingPrice) / 100);
      }
    }
  }, [priceType, floatingPrice]);

  const handlesetActiveTradeType = (active) => {
    setTradeType(active);
  };

  const handleSelectChange = (event) => {
    const { name, value } = event.target;

    if (name === "time-limit") {
      setpayTimeLimit(value);
    }
  };

  const handleFetchAmountLimit = async () => {
    if (asset !== null && withAsset !== null) {
      setLoadingPrice(true);
      try {
        const response = await axios.post(
          `${baseURL}/api/v1/advert/amount/limit`,
          {
            asset: asset,
            fiatCurrency: withAsset,
            tradeType: tradeType,
          }
        );
        setAmountLimits(response.data.data);
        setLoadingPrice(false);
      } catch (error) {
        setLoadingPrice(false);
      }
    } else {
    }
  };

  const handlePrepopulationOfOrderLimits = () => {
    setOrderLimitMin(amountLimits.fiatTransAmountRcmdLowerLimit);
    setOrderLimitMax(amountLimits.fiatTransAmountRcmdUpperLimit);
  };

  const handleGetAdvertRules = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/advert/data/${match.params.platform.trim()}/rules`
      );
      const { data } = response.data;
  
      if (data !== undefined) {
        setRules(response.data.data);
        setLoadingRules(false);
      }
    } catch (error) {
      setLoadingRules(false);
    }
  };

  const handleFetchPriceBasedOnFiatAssets = async () => {
    if (asset !== null && withAsset !== null) {
      setLoadingPrice(true);
      try {
        const response = await axios.post(
          `${baseURL}/api/v1/advert/data/order-price`,
          {
            fiatCurrency: withAsset,
            assets: asset,
          }
        );
        const { data } = response.data;
        setIncomingPrice(data);
        setPrice(parseFloat(data.low));
        setFloatingPrice("100");
        setNewPrice(parseFloat(data.low));
        setLoadingPrice(false);
      } catch (error) {
        setLoadingPrice(false);
      }
    } else {
    }
  };

  const handleConditionCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setCondiitons(1);
    } else {
      setCondiitons(0);
    }
  };

  const handleAddPaymentCheckboxChange = (event) => {
    const { name, checked, id } = event.target;

    if (checked) {
      const currentPaymentMethod = incomingPaymentMethod.find((method) => {
        return method.id == id;
      });

      if (currentPaymentMethod) {
        setPaymentMethods([...paymentMethods, { ...currentPaymentMethod }]);
      }
    } else {
      const tempPaymentMethods = paymentMethods.filter((method) => {
        if (id != method.id) {
          return method;
        }
      });
      setPaymentMethods(tempPaymentMethods);
    }
  };

  const handleGetPaymentMethod = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/advert/data/binance/payment-method`
      );
      setIncomingPaymentMethod(response.data.data);
    } catch (error) {}
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    if (name === "price") {
      if (reg.test(value)) {
        setPrice(value);
      }
    }
    if (name === "floatingPrice") {
      if (reg.test(value)) {
        setFloatingPrice(value);
      }
    }
    if (name === "total-amount") {
      setTotalAmount(value);
    }
    if (name === "order-min") {
      setOrderLimitMin(value);
    }
    if (name === "order-max") {
      setOrderLimitMax(value);
    }
    if (name === "auto-reply") {
      setautoReplyMsg(value);
    }
    if (name === "remarks") {
      setremarks(value);
    }
    if (name === "registered") {
      setRegistered(value);
    }
    if (name === "holding") {
      setHolding(value);
    }
  };

  const handleRadioChange = (event) => {
    const { name, value } = event.target;
    if (name === "price-type") {
      setpriceType(value);
    }
    if (name === "status") {
      setonlineNow(value);
    }
    if (name === "asset") {
      setAsset(value);
    }
    if (name === "with") {
      setWithAsset(value);
    }
  };
  const handleAddAdverts = () => {
    postRequest("/api/v1/advert/create", {
      platform: match.params.platform,
      //platform_user: "Legendary",

      autoReplyMsg: autoReplyMsg,
      tradeType: tradeType,
      onlineNow: onlineNow,
      fiatUnit: withAsset,
      asset: asset,
      initAmount: totalAmount,
      maxSingleTransAmount: orderLimitMax,
      minSingleTransAmount: orderLimitMin,
      payTimeLimit: payTimeLimit,
      priceType: priceType,
      price: priceType === "fixed" ? price : newPrice,
      remarks: remarks,
      buyerKycLimit: conditions,
      onlineDelayTime: 0,
      buyerRegDaysLimit: registered,

      buyerBtcPositionLimit: holding,
      tradeMethods: [...paymentMethods],
    });
  };

  const handleNext = (event) => {
    if (activeStep < 2) {
      if (activeStep === 0) {

        if (price == 0 && newPrice === 0) {
   
          openSnackbar('error', 'Please fill all required fields before proceeding')
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else if (activeStep === 1) {
        if (paymentMethods.length === 0) {
    

          openSnackbar('error', 'Please select atleast 1 payment method before proceeding')
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }

      // if (activeStep === 0) {
      //   if (price.length === 0 && newPrice.length === 0) {
      //     setErrorMessage(
      //       `Please fill all required fields before proceeding`
      //     );
      //     setOpenSnackbar(true);
      //   } else {
      //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //   }
      // } else {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // }
      // if (activeStep === 1) {
      //   if (paymentMethods.length === 0) {
      //     setErrorMessage(
      //       `Please select atleast 1 payment method before proceeding`
      //     );
      //     setOpenSnackbar(true);
      //   } else {
      //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
      //   }
      // } else {
      //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // }
    } else {
    }
    if (event.target.textContent === "Save changes") {
      handleAddAdverts();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  // const handleChange = (event) => {
  //   setCheckedB(event.target.checked);
  // };

  const handleSwitch = () => {
    setCheckedB(!checkedB);
  };
 

  return (
    <div className={styles.edit_ads}>
      <Container>
        <div className={styles.edit_ads_content}>
          <div className={styles.go_back}>
            <GoBack text='Go Back'/>
          </div>
          <div className={styles.edit_ads_heading}>
            <Typography>Create an ad</Typography>
          </div>
          <div className={styles.steppers}>
            <Stepper
              className={styles.my_stepper}
              activeStep={activeStep}
              alternativeLabel
            >
              {steps.map((label) => (
                <Step className={styles.my_step} key={label}>
                  <StepLabel className={styles.my_step_label}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          {activeStep === 0 ? (
            <Post1
              asset={asset}
              withAsset={withAsset}
              handleRadioChange={handleRadioChange}
              handleChange={handleChange}
              price={price}
              handlesetActiveTradeType={handlesetActiveTradeType}
              setPrice={setPrice}
              checkedB={checkedB}
              handleSwitch={handleSwitch}
              floatingPrice={floatingPrice}
              tradeType={tradeType}
              priceType={priceType}
              platform={match.params.platform.trim()}
              setFloatingPrice={setFloatingPrice}
              incomingPrice={incomingPrice}
              newPrice={newPrice}
              loadingPrice={loadingPrice}
              handleGetAdvertRules={handleGetAdvertRules}
              rules={rules}
              loadingRules={loadingRules}
            />
          ) : null}
          {activeStep === 1 ? (
            <Post2
              checkedB={checkedB}
              totalAmount={totalAmount}
              orderLimitMin={orderLimitMin}
              orderLimitMax={orderLimitMax}
              tradeMethods={tradeMethods}
              handleChange={handleChange}
              payTimeLimit={payTimeLimit}
              handleSelectChange={handleSelectChange}
              handleAddPaymentCheckboxChange={handleAddPaymentCheckboxChange}
              paymentMethods={paymentMethods}
              setPaymentMethods={setPaymentMethods}
              incomingPaymentMethod={incomingPaymentMethod}
              tradeType={tradeType}
              incomingPrice={incomingPrice}
              amountLimits={amountLimits}
            />
          ) : null}
          {activeStep === 2 ? (
            <Post3
              checkedB={checkedB}
              autoReplyMsg={autoReplyMsg}
              handleRadioChange={handleRadioChange}
              remarks={remarks}
              registered={registered}
              holding={holding}
              handleConditionCheckboxChange={handleConditionCheckboxChange}
              handleChange={handleChange}
            />
          ) : null}

          <div className={styles.stepper_btns}>
            <div className={styles.stepper_btn}>
              <ButtonMain
                length="200px"
                bgColor="#ededed"
                textColor="#404040"
                btnText={"Previous"}
                handleClick={handleBack}
                loading={!activeStep === 0}
                hideLoader
              />
            </div>

            <div className={styles.stepper_btn}>
              <ButtonMain
                length="200px"
                bgColor="#1A2CCE"
                textColor="white"
                btnText={
                  activeStep === steps.length - 1 ? "Save changes" : "Next"
                }
                handleClick={handleNext}
              />
            </div>
          </div>
        </div>
      </Container>
      <P2PSnackbar
        open={openToast}
        setOpen={setOpenToast}
        severity={severity}
        message={message}
        duration={duration}
      />
    </div>
  );
};

export default PostAds;
