import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import styles from "./PostAds.module.css";
import NumberFormat from "react-number-format";
import { LinearProgress } from "@mui/material";
import { useGetRequest } from "../../../custom-hooks/api";
import CircularProgress from "@mui/material/CircularProgress";

const Post1 = ({
  price,
  setPrice,
  floatingPrice,
  setFloatingPrice,
  handleRadioChange,
  incomingPrice,
  handlesetActiveTradeType,
  handleChange,
  tradeType,
  availableAssets,
  availableFiats,
  availableTradeTypes,
  platform,
  priceType,
  newPrice,
  asset,
  withAsset,
  loadingPrice,
  rules,
  loadingRules
}) => {
  const { data, loading, getRequest } = useGetRequest();

  useEffect(() => {
    //handleGetAdvertRules();
  }, []);

  const handlePriceChange = (event) => {
    if (priceType === "fixed") {
      if (event.target.textContent === " - ") {
        if (price <= 0) {
          setPrice(0);
        } else {
          const result = +price - 0.1;
          if (price === ".") {
          } else {
            setPrice(parseFloat(result).toFixed(2));
          }
        }
      } else {
        const result = +price + 0.1;
        if (price === ".") {
        } else {
          setPrice(parseFloat(result).toFixed(2));
        }
      }
    } else {
      if (event.target.textContent === " - ") {
        if (floatingPrice <= 0) {
          setFloatingPrice(0);
        } else {
          if (floatingPrice === ".") {
          } else {
            setFloatingPrice(parseFloat(+floatingPrice - 10));
          }
        }
      } else {
        if (floatingPrice === ".") {
        } else {
          setFloatingPrice(+floatingPrice + 10);
        }
      }
    }
  };

  const handleGetAdvertRules = () => {
    getRequest(`/api/v1/advert/data/${platform}/rules`);
  };

  return (
    <>
      {loadingRules ? (
        <LinearProgress />
      ) : (
        <div className={styles.type_price}>
          <div className={styles.type_price_tabs}>
            {rules?.availableTradeTypes?.map((availableTradeType) => {
              return (
                <button
                  key={availableTradeType}
                  className={
                    tradeType === availableTradeType
                      ? styles.trade_type_active
                      : null
                  }
                  onClick={() => {
                    handlesetActiveTradeType(availableTradeType);
                  }}
                >
                  {availableTradeType}
                </button>
              );
            })}
          </div>
          <div className={styles.type_price_buy_tab}>
            <div className={styles.asset_with}>
              <div className={styles.buy_tab_asset}>
                <div className={styles.buy_tab_asset_heading}>
                  <Typography>Asset</Typography>
                </div>
                <div className={styles.buy_tab_asset_body}>
                  {rules?.availableAssets?.map((availableAsset) => {
                    return (
                      <label key={availableAsset} htmlFor="">
                        <input
                          onChange={handleRadioChange}
                          type="radio"
                          value={availableAsset}
                          name="asset"
                          checked={asset === availableAsset ? true : false }
                        />{" "}
                        {availableAsset}
                      </label>
                    );
                  })}
                </div>
              </div>
              <div className={styles.buy_tab_asset}>
                <div className={styles.buy_tab_asset_heading}>
                  <Typography>With</Typography>
                </div>
                <div className={styles.buy_tab_asset_body}>
                  {rules?.availableFiats?.map((availableFiat) => {
                    return (
                      <label key={availableFiat} htmlFor="">
                        <input
                          onChange={handleRadioChange}
                          type="radio"
                          value={availableFiat}
                          name="with"
                         
                         checked={withAsset === availableFiat ? true : false }
                        />{" "}
                        {availableFiat}
                      </label>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={styles.price_lower}>
              <div className={styles.buy_tab_price}>
                <div className={styles.buy_tab_your_price}>
                  <Typography>your price</Typography>
                  <div>
                    {loadingPrice ? null : (
                      <>
                        <Typography variant="h6">
                          {priceType === "floating" ? newPrice : price}{" "}
                        </Typography>
                        <Typography>
                          {Object.keys(incomingPrice).length === 0
                            ? "set asset and with to view price"
                            : incomingPrice.fiat}
                        </Typography>
                      </>
                    )}

                    <Typography>
                      {loadingPrice ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      ) : null}
                    </Typography>
                  </div>
                </div>
                <div className={styles.buy_tab_your_price}>
                  <Typography>Highest order price</Typography>
                  <div>
                    {loadingPrice ? null : (
                      <>
                        <Typography variant="h6">
                          {incomingPrice.high}
                        </Typography>
                        <Typography>
                          {Object.keys(incomingPrice).length === 0
                            ? "set asset and with to view price"
                            : incomingPrice.fiat}
                        </Typography>
                      </>
                    )}
                    <Typography>
                      {loadingPrice ? (
                        <CircularProgress
                          size={24}
                          sx={{
                            color: "blue",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      ) : null}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={styles.buy_tab_price_type}>
                <div className={styles.buy_tab_price_type_heading}>
                  <Typography>Price Type</Typography>
                </div>
                <div className={styles.buy_tab_price_radios}>
                  <label htmlFor="">
                    <input
                      value="fixed"
                      onChange={handleRadioChange}
                      type="radio"
                      name="price-type"
                      checked={priceType === 'fixed' ? true : false }
                    />
                    Fixed
                  </label>
                  <label htmlFor="">
                    <input
                      value="floating"
                      onChange={handleRadioChange}
                      type="radio"
                      name="price-type"
                      checked={priceType === 'floating' ? true : false }
                    />
                    Floating
                  </label>
                </div>
                <div className={styles.buy_tab_price_change_btns}>
                  <button onClick={handlePriceChange}> - </button>
                  <input
                    type="text"
                    onChange={handleChange}
                    name={priceType === "floating" ? "floatingPrice" : "price"}
                    value={priceType === "floating" ? floatingPrice : price}
                    defaultValue={"0"}
                  />

                  <button onClick={handlePriceChange}> + </button>
                </div>
                {priceType === "floating" ? (
                  <div className={styles.floating_info}>
                    <Typography>{`${floatingPrice}% of ${incomingPrice.low} `}</Typography>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.type_price_sell_tab}></div>
        </div>
      )}
    </>
  );
};

export default Post1;
