import React from "react";
import styles from "./AppSelect.module.css";

const PlatformSelect = ({ title, setSelect,value, type, data }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;

    setSelect(value);
  };
  return (
    <select onChange={handleChange} value={value} className={styles.app_select} name="" id="">
      <option value="">{title}</option>
      {data.map((platform) => {
        return (
          <option key={platform.name} value={platform.platform}>
            {platform.platform}
          </option>
        );
      })}
    </select>
  );
};

export default PlatformSelect;
