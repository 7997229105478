import { CircularProgress } from "@material-ui/core";
import React from "react";
import styles from "./Button.module.css";

function ButtonMain({
  border,
  hideLoader,

  fullLength,
  btnText,
  handleClick,
  loading,
  bgColor,
  textColor,
  icon,
}) {
  return (
    <button
      disabled={loading}
      className={`${loading ? styles.btn_disabled : null}   ${styles.button}`}
      style={{
        background: bgColor,
        color: textColor,
        width: fullLength ? "100%" : null,
        border: border,
      }}
      onClick={handleClick}
    >
      {icon && (
        <div className={btnText ? styles.btn_icon : null}>
          <img src={icon} alt="" />
        </div>
      )}

      {btnText ? btnText : null}
      {hideLoader
        ? null
        : loading && (
            <div className={styles.loader}>
              {loading ? <CircularProgress /> : btnText}
            </div>
          )}
    </button>
  );
}

export default ButtonMain;
