import { Container, Typography } from "@material-ui/core";
import React from "react";
import styles from "./UserRoles.module.css";
import { Scrollbars } from "react-custom-scrollbars";

const ManageRoleModal = ({ setRoleAccessModal }) => {
  const handleCancel = (e) => {
    e.preventDefault();

    setRoleAccessModal(false);
  };

  const handleAddUser = () => {};
  return (
    <div className={styles.user_role_modal}>
      <Container>
        <div className={styles.user_role_modal_content}>
          <form action="">
            <Scrollbars style={{ height: 600 }}>
              <div className={styles.white_bg}>
                <div className={styles.user_role_heading}>
                  <Typography variant="h6">Manage role access</Typography>
                  <Typography>
                    Specify the requests they can access within the platform
                  </Typography>
                </div>
                <div className={styles.user_role_content}>
                  <div className={styles.actions}>
                    <Typography>Dashboard</Typography>
                    <label htmlFor="">
                      <input type="checkbox" /> Can view total number of
                      transactions
                    </label>

                    <label htmlFor="">
                      <input type="checkbox" name="" id="" />
                      Can view no. of trades
                    </label>
                    <label htmlFor="">
                      <input type="checkbox" name="" id="" /> Can view all
                      payouts and payins
                    </label>
                  </div>
                  <div className={styles.actions}>
                    <Typography>Transactions</Typography>
                    <label htmlFor="">
                      <input type="checkbox" /> Can view all transactions
                    </label>

                    <label htmlFor="">
                      <input type="checkbox" name="" id="" />
                      Can view list of successful transactions
                    </label>
                    <label htmlFor="">
                      <input type="checkbox" name="" id="" /> Can view list of
                      failed transactions
                    </label>
                    <label htmlFor="">
                      <input type="checkbox" name="" id="" />
                      Can view errors
                    </label>
                  </div>
                  <div className={styles.actions}>
                    <Typography>Appeals</Typography>
                    <label htmlFor="">
                      <input type="checkbox" /> Can view number of appeals
                    </label>

                    <label htmlFor="">
                      <input type="checkbox" name="" id="" />
                      Can view list of successful transactions
                    </label>
                  </div>
                  <div className={styles.actions}>
                    <Typography>Audit Logs</Typography>

                    <label htmlFor="">
                      <input type="checkbox" name="" id="" />
                      Can view log overview
                    </label>
                    <label htmlFor="">
                      <input type="checkbox" name="" id="" /> Can view logs of
                      failed transaction
                    </label>
                    <label htmlFor="">
                      <input type="checkbox" name="" id="" />
                      Can view logs of canceled transaction
                    </label>
                  </div>
                </div>
              </div>

              <div className={styles.user_role_actions}>
                <button onClick={handleCancel}>Cancel</button>
                <button onClick={handleAddUser}>Add user</button>
              </div>
            </Scrollbars>
          </form>
        </div>
      </Container>
    </div>
  );
};

export default ManageRoleModal;
