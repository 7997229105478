// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TrxSummaryHeading_audit_overview_heading__1G5sh {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 26px 0;\n}\n\n.TrxSummaryHeading_audit_overview_heading__1G5sh a {\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #1A2CCE;\n}\n.TrxSummaryHeading_audit_overview_heading__1G5sh p {\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0.27em;\n    text-align: left;\n    color: #1A2CCE;\n    text-transform: uppercase;\n\n}\n", "",{"version":3,"sources":["webpack://src/components/transaction/trxSummaryHeading/TrxSummaryHeading.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;AAClB;;AAEA;;IAEI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,gBAAgB;IAChB,cAAc;AAClB;AACA;;IAEI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,gBAAgB;IAChB,cAAc;IACd,yBAAyB;;AAE7B","sourcesContent":[".audit_overview_heading {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin: 26px 0;\n}\n\n.audit_overview_heading a {\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0em;\n    text-align: left;\n    color: #1A2CCE;\n}\n.audit_overview_heading p {\n     \n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 17px;\n    letter-spacing: 0.27em;\n    text-align: left;\n    color: #1A2CCE;\n    text-transform: uppercase;\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"audit_overview_heading": "TrxSummaryHeading_audit_overview_heading__1G5sh"
};
export default ___CSS_LOADER_EXPORT___;
