import { Menu, MenuItem, Typography } from "@material-ui/core";
import React from "react";
import styles from "./Notifications.module.css";
import indicator_icon from "./../../assets/Ellipse 46.svg";
import alert_icon from "./../../assets/Vector (29).svg";
import moment from "moment";


function Notifications({ anchorEl2, handleClose2, handleGoToEdit, support}) {
  return (
    <Menu
      className={styles.notification_menu}
      id="menu-appbar"
      anchorEl={anchorEl2}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(anchorEl2)}
      onClose={handleClose2}
    >
        <div className={styles.notification_heading}>
          <Typography variant ='h6'>Notifications</Typography>
        </div>
        {support.length === 0 ? (
          <div className={styles.notification_heading}>
            <Typography >You have no notifications</Typography>
          </div>
        ) : support.map((item) => {
            return (
              <MenuItem
                onClick={() => {
                  handleGoToEdit(item.id, item.error);
                  handleClose2();
                }}
                key={item.id}
              >
                <div className={styles.indicator}>
                  <img src={indicator_icon} alt="" />
                </div>
                <div className={styles.alert_icon}>
                  <img src={alert_icon} alt="" />
                </div>
                <div className={styles.notification_text}>
                  <div className={styles.notification_text_heading}>
                    {item.error === "Resolution" ? (
                      <Typography variant="h6">{item.error} Error</Typography>
                    ) : null}
                    {item.error === "Processor" ? (
                      <Typography variant="h6" className={styles.processor_error}>
                        {item.error} Error
                      </Typography>
                    ) : null}
                    {item.error === "Payment" ? (
                      <Typography variant="h6" className={styles.payment_error}>
                        {item.error} Error
                      </Typography>
                    ) : null}
                    <Typography>
                      {moment(item.created_at).format("Do MMM YYYY")}
                    </Typography>
                  </div>
                  <div className={styles.notification_text_para}>
                    <Typography>
                      Transaction with Order number {item.order_no} encountered a{" "}
                      {item.error}. View Error
                    </Typography>
                  </div>
                </div>
              </MenuItem>
            );
          })}
        {
    }
     
    </Menu>
  );
}

export default Notifications;
