import { Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import LabeledSelects from "../../../../../../../components/selects/labeledSelects/LabeledSelects";
import styles from "./MarketRate.module.css";

function MarketRate({ currentMarketRate, p2p_average, otc_rate }) {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [isLoading] = useState(true);

  // const handleFetchRates = async (data) => {
  //   try {
  //     const response = await axios.post(`${baseURL}/api/v1/market`, {
  //       pair: data.pair,
  //     });

  //     const { market_rate, otc_rate, p2p_average } = response.data.data;
  //     setOtc_rate(otc_rate);
  //     setP2P_average(p2p_average);

  //     if (market_rate != undefined) {
  //       setCurrentMarketRate(market_rate);
  //     } else {
  //       setCurrentMarketRate({});
  //     }
  //   } catch (error) {}
  // };
  

//   if(isLoading){
//       return <Skeleton width={'100%'} height={'100%'} />
//   }
  return (
    <div className={styles.current_rate}>
      <div className={styles.current_rate_heading}>
        <Typography>CURRENT MARKET RATE</Typography>
        <LabeledSelects
          register={register}
          name="pairs"
          label=""
          placeholder="pairs"
          data={[{ name: "NGN/USDT" }]}
        />
      </div>
      <div className={styles.current_market_table}>
        <table>
          <tbody>
            <tr>
              <td>Market Rate</td>
              <td className={styles.rate}>
                {currentMarketRate?.market_rate === undefined
                  ? "NA"
                  : currentMarketRate?.market_rate}
              </td>
            </tr>
            <tr>
              <td>P2P Average</td>
              <td className={styles.rate}>
                {p2p_average === null ? "NA" : currentMarketRate?.p2p_average}
              </td>
            </tr>
            <tr>
              <td>OTC Rate</td>
              <td className={styles.rate}>
                {otc_rate === null ? "NA" : currentMarketRate?.otc_rate}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default MarketRate;
