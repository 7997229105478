// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyState_empty_state__1PyIN{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.EmptyState_empty_state__1PyIN h6{\n    margin-top: 23px;\nfont-size: 14px;\nfont-weight: 500;\ncolor: #0F0F0F;\n}\n\n.EmptyState_empty_state__1PyIN p{\n   margin-top: 7px;\nfont-size: 14px;\nfont-weight: 400;\nmargin-bottom: 40px;\ncolor: #9B9B9B;\n}\n\n.EmptyState_empty_state__1PyIN a {\n  \nfont-size: 16px;\nfont-weight: 500;\ncolor: #1A2CCE;\n\n}", "",{"version":3,"sources":["webpack://src/components/null state/EmptyState.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB,eAAe;AACf,gBAAgB;AAChB,cAAc;AACd;;AAEA;GACG,eAAe;AAClB,eAAe;AACf,gBAAgB;AAChB,mBAAmB;AACnB,cAAc;AACd;;AAEA;;AAEA,eAAe;AACf,gBAAgB;AAChB,cAAc;;AAEd","sourcesContent":[".empty_state{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.empty_state h6{\n    margin-top: 23px;\nfont-size: 14px;\nfont-weight: 500;\ncolor: #0F0F0F;\n}\n\n.empty_state p{\n   margin-top: 7px;\nfont-size: 14px;\nfont-weight: 400;\nmargin-bottom: 40px;\ncolor: #9B9B9B;\n}\n\n.empty_state a {\n  \nfont-size: 16px;\nfont-weight: 500;\ncolor: #1A2CCE;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"empty_state": "EmptyState_empty_state__1PyIN"
};
export default ___CSS_LOADER_EXPORT___;
