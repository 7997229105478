import React from 'react'
import styles from './TryAgain.module.css'


const TryAgain = ({handleTryAgain}) => {
    return (
    <div className={styles.try_again}>
        <button onClick={handleTryAgain} >
            Try again
        </button>
    </div> );
}
 
export default TryAgain;