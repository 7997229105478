import React from "react";
import styles from "./EmptyState.module.css";
import empty from "../../components/assets/empty.svg";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

const EmptyState = ({handleRefresh, primaryText, secondaryText}) => {

    const handleClickRefresh=(e)=>{
        e.preventDefault()
        handleRefresh()
    }
  return (
    <div className={styles.empty_state}>
      <img src={empty} alt="" />
      <Typography variant='h6' >{primaryText}</Typography>
      <Typography>{secondaryText}</Typography>
      <Link onClick={handleClickRefresh} to='/' >Refresh</Link>
    </div>
  );
};

export default EmptyState;
