import axios from "axios";


export const ADD_USER = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/staff/create`, data)
      .then((res) => {
        return res.data;
      });
  };

export const EDIT_USER = async (data) => {
  return axios
    .put(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/user/role/update/${data.id}`, data.data)
    .then((res) => {
      return res.data;
    });
};

export const DELETE_USER = async (id) => {
    return axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/user/delete/${id}`)
      .then((res) => {
        return res.data;
      });
  };