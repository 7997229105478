import React, { useEffect } from "react";
import Switch from "../../../../../components/switch/Switch";

function BankTableRow({setValue, watch,  bank, register }) {
  const checkedStatus = watch(String(bank.id), bank.status);

  useEffect(() => {
    setValue(String(bank.id), bank.status);
  }, []);

  return (
    <tr>
      <td>{bank.name}</td>
      <td>{bank.country}</td>
      <td>
        <Switch
          checkedStatus={checkedStatus}
          register={register}
          name={`${bank.id}`}
        />
      </td>
    </tr>
  );
}

export default BankTableRow;
