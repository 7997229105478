import { Typography } from "@material-ui/core";
import { Skeleton } from "@mui/material";
import React from "react";
import NumberFormat from "react-number-format";
import { useQuery } from "react-query";
import { GET_TRX_SUMMARY } from "../../../../API/queries/transactions/Transactions";
import styles from "./WhiteCardTrxSummary.module.css";
import red_arrow from "./../../../assets/Polygon 1 (1).svg";

function WhiteCardTrxSummary({tempSummaryData, type, data, isLoading }) {
  const whiteContent = [
    {
      title: "total transactions",
      value: type === "balances" ? "total_transaction" : "totalTransactions",
      trend: null,
    },
    {
      title: "successful transactions",
      value: type === "balances" ? "success_transaction" : "totalSuccessful",
      trend: null,
    },
    {
      title: "failed transactions",
      value: type === "balances" ? "fail_transaction" : "totalFailed",
      trend: null,
    },
  ];

  return (
    <div className={styles.overview_white_card}>
      {whiteContent?.map((item) => {
        return (
          <div
            key={item.title}
            className={`${styles.overview_transactions} ${
              item.title === "successful transactions"
                ? styles.successful_transaction
                : null
            } `}
          >
            <div className={styles.white_card_heading}>
              <Typography>{item.title}</Typography>
            </div>
            {isLoading ? (
              <Skeleton height="40px" width="90%" />
            ) : (
              <div className={styles.transaction_count}>
                <Typography>
                  {" "}
                  {tempSummaryData[item?.value] === undefined ? (
                    "NA"
                  ) : (
                    <NumberFormat
                      value={tempSummaryData[item?.value]}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  )}
                </Typography>
                {tempSummaryData[item?.trend] === undefined ? null : (
                  <div className={styles.trend}>
                    <img src={red_arrow} alt="" />
                    <Typography>{tempSummaryData[item?.trend]}</Typography>
                  </div>
                )}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default WhiteCardTrxSummary;
