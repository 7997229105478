import { Grid } from "@material-ui/core";
import React from "react";
import styles from "./OverviewCards.module.css";
import TotalBalance from "./components/total/TotalBalance";
import AvailableBots from "./components/bots/AvailableBots";
import MarketRate from "./components/rates/MarketRate";

function OverviewCards({loading,  dashboardData }) {

  return (
    <div className={styles.dashboard_cards}>
      <Grid className={styles.my_grid} container spacing={3}>
        <Grid item sm={12} md={4}>
          <TotalBalance  loading={loading} trxStats={dashboardData} />
        </Grid>
        <Grid item sm={12} md={8}>
          <div className={styles.white_card}>
            <Grid container spacing={3}>
              <Grid item sm={6}>
                <AvailableBots bots={dashboardData} />
              </Grid>
              <Grid item sm={6}>
                <MarketRate />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default OverviewCards;
