import { Typography } from "@material-ui/core";
import React, { useState} from "react";
import styles from "./AddNewPlatform.module.css";
import add from "./../../../../../../components//assets/Vector.svg";
import dash_icon from "./../../../../../../components//assets/Group 1527.svg";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import { useForm } from "react-hook-form";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import P2PSnackbar from "../../../../../../components/AppSnackbars/P2PSnackbar";
import useOpenSnackbar from "../../../../../../custom-hooks/useOpenSnackbar";
import Switch from "../../../../../../components/switch/Switch";
import { UPDATE_PLATFORM_SETTINGS } from "../../../../../../API/mutations/settings/platform/Platform";
import { useMutation, useQueryClient } from "react-query";

const PlatformModal = ({ setShowPlatformModal}) => {
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const { openSnackbar, setOpenToast, message, duration, severity, openToast } =
    useOpenSnackbar();

  const platformName = watch("platform_name", "");
  const platformUser = watch("platform_user", "");
  const platformLabelTitle = watch("platform_label_title", "");
  const platformLabelValue = watch("platform_label_value", "");
  const platformLabelname = watch("platform_label_name", "");
  const processor_status = watch("processor_status", false);
  const [fields, setFields] = useState([]);
  const queryClient = useQueryClient()

  const {isLoading, mutate} = useMutation(UPDATE_PLATFORM_SETTINGS, {
    onSuccess: (data)=>{
      queryClient.invalidateQueries(['platform-settings'])
        setShowPlatformModal(false)
    },
    onError: (error)=>{

    }
  })


 

  const handleAddPaymentPlatform = (e) => {
    if (fields.length > 0) {
      if (
        platformLabelTitle.length > 0 ||
        platformLabelname.length > 0 ||
        platformLabelValue.length > 0
      ) {
        if (
          platformLabelTitle !== "" &&
          platformLabelname !== "" &&
          platformLabelValue !== "" &&
          platformName !== "" &&
          platformUser !== ""
        ) {
          const validation = /^[a-z0-9]+$/i;

          const result = validation.test(platformLabelname);

          if (result) {
            mutate({
              name: platformName,
              platform_user: platformUser,
              status: processor_status,
              fields: [
                ...fields,
                {
                  name: platformLabelname,
                  label: platformLabelTitle,
                  value: platformLabelValue,
                },
              ],
            })
            // postRequest(`/api/v1/settings/platform/create`, {
            //   name: platformName,
            //   platform_user: platformUser,
            //   status: processor_status,
            //   fields: [
            //     ...fields,
            //     {
            //       name: platformLabelname,
            //       label: platformLabelTitle,
            //       value: platformLabelValue,
            //     },
            //   ],
            // });
          } else {
            openSnackbar(
              "error",
              "Only AlphaNumeric characters are allowed, special characters or empty spaces are not allowed in Name"
            );
          }
        } else {
          openSnackbar("error", "Please, fill all fields before proceeding");
        }
      } else {
        //run code to check name and user
        if (platformName.length > 0 && platformUser.length > 0) {
          mutate ({
            name: platformName,
            platform_user: platformUser,
            status: processor_status,
            fields: [...fields],
          })
         
        } else {
          openSnackbar(
            "error",
            "Please, fill all appropriate fields before proceeding"
          );
        }
      }
    } else {
      if (
        platformLabelTitle !== "" &&
        platformLabelname !== "" &&
        platformLabelValue !== "" &&
        platformName !== "" &&
        platformUser !== ""
      ) {
        const validation = /^[a-z0-9]+$/i;

        const result = validation.test(platformLabelname);

        if (result) {
          mutate ({
            name: platformName,
            platform_user: platformUser,
            status: processor_status,
            fields: [
              ...fields,
              {
                name: platformLabelname,
                label: platformLabelTitle,
                value: platformLabelValue,
              },
            ],
          })
         
        } else {
          openSnackbar(
            "error",
            "Only AlphaNumeric characters are allowed, special characters or empty spaces are not allowed in Name"
          );
        }
      } else {
        openSnackbar("error", "Please, fill all fields before proceeding");
      }
    }
    e.preventDefault();
  };

  const handlePlatformModalClose = () => {
    setShowPlatformModal(false);
  };

  const getParticularField = (id) => {
    const particularField = fields.find((field) => {
      return field.idNo === id;
    });

    setValue("platform_label_value", particularField.value);
    setValue("platform_label_title", particularField.label);
    setValue("platform_label_name", particularField.name);
  };

  const deleteParticularField = (id) => {
    const tempFields = fields.filter((field) => {
      return field.idNo !== id;
    });

    setFields(tempFields);
    setValue("platform_label_value", "");
    setValue("platform_label_title", "");
    setValue("platform_label_name", "");
  };

  const onSubmit = async (data) => {
    const exist = fields.some((field) => {
      return field.value === data.platform_label_value;
    });
    if (exist) {
      openSnackbar("error", "Sorry, you cant duplicate value");
    } else {
      const validation = /^[a-z0-9]+$/i;

      const result = validation.test(platformLabelname);

      if (result) {
        let newObject = {
          label: data.platform_label_title,
          name: data.platform_label_name,
          value: data.platform_label_value,
          idNo:
            data.platform_label_title +
            data.platform_label_name +
            data.platform_label_value,
        };

        setFields([...fields, newObject]);
        setValue("platform_label_value", "");
        setValue("platform_label_title", "");
        setValue("platform_label_name", "");
      } else {
        openSnackbar(
          "error",
          "Only AlphaNumeric characters are allowed, special characters or empty spaces are not allowed in Name"
        );
      }
    }
  };

  return (
    <ModalFrame
      handleCancel={() => {
        handlePlatformModalClose();
      }}
      handleAction={handleAddPaymentPlatform}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt={"Add"}
      child={
        <div className={styles.platform_modal_form}>
          <div className={styles.modal_form_heading}>
            <Typography>Add new platform</Typography>
          </div>

          <div
            className={
              processor_status ? styles.modal_status : styles.modal_status_red
            }
          >
            <Typography>Processor Status</Typography>
            <Switch
              register={register}
              name="processor_status"
              checkedStatus={processor_status}
            />
          </div>
          <div className={styles.form_field}>
            <LabeledInputs
              label={"Platform name"}
              placeholder={"Platform name"}
              // redOutline={errors.Website ? true : false}
              register={register}
              name={"platform_name"}
              handleChangeAuto={true}
              required={true}
              type="text"
            />
          </div>
          <div className={styles.form_field}>
            <LabeledInputs
              label={"Platform user"}
              placeholder={"Platform user"}
              // redOutline={errors.Website ? true : false}
              register={register}
              name={"platform_user"}
              handleChangeAuto={true}
              required={true}
              type="text"
            />
          </div>

          {fields.map((field) => {
            return (
              <div
                onClick={() => {
                  getParticularField(field.idNo);
                }}
                key={field.value}
                className={styles.platform_support}
              >
                <Typography>{field.label}</Typography>
                <img
                  onClick={(e) => {
                    deleteParticularField(field.idNo);
                    e.stopPropagation();
                  }}
                  src={dash_icon}
                  alt=""
                />
              </div>
            );
          })}
          <div className={styles.form_dark_bg}>
            <div className={styles.form_field}>
              <LabeledInputs
                label={"Title / Label"}
                placeholder={"Secret key"}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"platform_label_title"}
                handleChangeAuto={true}
                required={true}
                type="text"
              />
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                label={"Name"}
                placeholder={"name of secret key"}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"platform_label_name"}
                handleChangeAuto={true}
                required={true}
                type="text"
                pattern="/^[a-z0-9]+$/i"
              />
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                label={"Value"}
                placeholder={"Placeholder Secret key"}
                // redOutline={errors.Website ? true : false}
                register={register}
                name={"platform_label_value"}
                handleChangeAuto={true}
                required={true}
                type="text"
              />
            </div>
          </div>

          <button
            onClick={handleSubmit(onSubmit)}
            className={styles.add_field_btn}
          >
            <img src={add} alt="" /> Add Field
          </button>

          <P2PSnackbar
            open={openToast}
            setOpen={setOpenToast}
            severity={severity}
            message={message}
            duration={duration}
          />
        </div>
      }
    />
  );
};

export default PlatformModal;
