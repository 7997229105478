import React from "react";
import { useQuery } from "react-query";
import {
  GET_BANKS,
  GET_CHANNELS,
  GET_PAIRS,
} from "../../API/queries/misc/Misc";
export const MiscContextAPI = React.createContext();

const MiscContext = (props) => {

  const {
    data: banks,

    isLoading: loadingBanks,
    refetch: handleGetBanks,
  } = useQuery(["banks"], GET_BANKS, {
    placeholderData: [],
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: channels,
    isLoading: loadingChannels,
    refetch: handleGetChannels,
  } = useQuery(["channels"], GET_CHANNELS, {
    placeholderData: [],
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  const {
    data: pairs,
    isLoading: loadingPairs,
    refetch: handleGetPairs,
  } = useQuery(["pairs"], GET_PAIRS, {
    placeholderData: [],
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: false,
  });



  return (
    <MiscContextAPI.Provider
      value={{
        banks: banks?.data,
        channels: channels?.data,
        pairs: pairs?.data,
        loadingPairs,
        handleGetChannels,
        handleGetPairs,
        handleGetBanks,
      }}
    >
      {props.children}
    </MiscContextAPI.Provider>
  );
};

export default MiscContext;
