import { Container } from "@material-ui/core";
import { LinearProgress } from "@mui/material";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UPDATE_BANK_STATUS } from "../../../../API/mutations/settings/banks/Banks";
import { GET_BANKS_STATUS } from "../../../../API/queries/settings/banks/banks";
import P2PSnackbar from "../../../../components/AppSnackbars/P2PSnackbar";
import ButtonMain from "../../../../components/button/Button";
import PageLayout from "../../../../components/Layout/PageLayout";
import SearchInput from "../../../../components/search/SearchInput";
import useOpenSnackbar from "../../../../custom-hooks/useOpenSnackbar";
import styles from "./Banks.module.css";
import BanksTable from "./modules/BanksTable";

function Banks() {
  const { register, handleSubmit, watch, setValue } = useForm();
  const { openSnackbar, setOpenToast, message, duration, severity, openToast } =
    useOpenSnackbar();
  const formData = watch()


  const searchValue = watch("search", "");
  
  const queryClient = useQueryClient();

  const { data, isLoading, refetch } = useQuery(
    ["banks-status"],
    (data) => GET_BANKS_STATUS(data),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isLoadingUpdate } = useMutation(
    UPDATE_BANK_STATUS,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["banks-status"]);
      },
      onError: () => {},
    }
  );

  const onBanksSubmit = (data) => {
    const statusTrueArray = Object.keys(data).filter((item) => {
      if (data[item] === false) {
        return item;
      }
    });
    mutate({
      status: false,
      banks: statusTrueArray.map((item)=>{
        return item * 1
      }),
    });
    // if (statusTrueArray.length > 0) {
    //   mutate({
    //     status: false,
    //     banks: statusTrueArray,
    //   });
    // } else {
    //   openSnackbar(
    //     "info",
    //     "No changes were made, Please make a change then save"
    //   );
    // }
  };

  return (
    <PageLayout
      bgColor="#ffffff"
      child={
        <div className={styles.banks}>
          <Container>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <>
                <div className={styles.heading}>
                   <form action="">
                    <SearchInput
                      handleSubmit={handleSubmit}
                      placeholder={"Search for a bank name"}
                      register={register}
                      name="search"
                      handleSearch={""}
                    />
                  </form> 
                  <div className={styles.heading_btn}>
                    <ButtonMain
                      fullLength
                      bgColor="#1A2CCE"
                      textColor="white"
                      btnText="Save changes"
                      handleClick={handleSubmit(onBanksSubmit)}
                      loading={isLoadingUpdate}
                    />
                  </div>
                </div>
                <div className={styles.body}>
                  <BanksTable
                    formValues={formData}
                    refetch={refetch}
                    searchValue={searchValue}
                    setValue={setValue}
                    watch={watch}
                    banks={data?.data}
                    register={register}
                  />
                </div>
              </>
            )}

            <P2PSnackbar
              open={openToast}
              setOpen={setOpenToast}
              severity={severity}
              message={message}
              duration={duration}
            />
          </Container>
        </div>
      }
    />
  );
}

export default Banks;
