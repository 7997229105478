import { Typography, Container } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import AppBackdrop from "../../../components/backdrop/Backdrop";
import AddPaymentMethod from "./Modals/AddPaymentMethod";
import styles from "./PostAds.module.css";
import close from "./../../../components/assets/close2.svg";

const Post2 = ({
  handleChange,
  totalAmount,
  orderLimitMin,
  orderLimitMax,
  tradeMethods,
  paymentMethods,
  setPaymentMethods,
  handleSelectChange,
  payTimeLimit,
  handleAddPaymentCheckboxChange,
  incomingPaymentMethod,
  tradeType,
  incomingPrice,
  amountLimits
}) => {
  const [openAddPaymentMethodModal, setOpenAddPaymentMethodModal] =
    useState(false);
    const [limitMessage, setLimitMessage] = useState('')
    const [orderLimitLowerMessage, setOrderLimitLowerMessage] = useState('')
    const [orderLimitHigherMessage, setOrderLimitHigherMessage] = useState('')

  const handleOpenAddPaymentMethodModal = () => {
    setOpenAddPaymentMethodModal(true);
  };
  const handleCloseAddPaymentMethodModal = () => {
    setOpenAddPaymentMethodModal(false);
  };
  useEffect(() => {
    handleCheckTotalAmountLimits()
   
  }, [totalAmount])

  useEffect(()=>{
      handleCheckMinLimitForOrder()
handleCheckLimitCross()
  }, [orderLimitMin])

  useEffect(()=>{
    handleCheckMaxLimitForOrder()
    handleCheckLimitCross()
}, [orderLimitMax])


  const handleCheckLimitCross=()=>{
    if(orderLimitMin > orderLimitMax){
        
    }else if(orderLimitMax < orderLimitMin){

    }else{

    }
  }

  const handleCheckMaxLimitForOrder=()=>{
    if(orderLimitMax > amountLimits.fiatTransAmountUpperLimit){
      setOrderLimitHigherMessage(`Higher than the maximum amount ${amountLimits.fiatTransAmountUpperLimit}`)
    }else if(orderLimitMax > amountLimits.fiatTransAmountUpperLimit){
      setOrderLimitHigherMessage(`Higher than the maximum amount ${amountLimits.fiatTransAmountUpperLimit}`)
    }else{
      setOrderLimitHigherMessage('')
   
    }
  }
  const handleCheckMinLimitForOrder=()=>{
    if(orderLimitMin < amountLimits.fiatTransAmountLowerLimit){
      setOrderLimitLowerMessage(`lower than the minimum amount ${amountLimits.fiatTransAmountLowerLimit}`)
    }else if(orderLimitMin < amountLimits.fiatTransAmountLowerLimit){
      setOrderLimitLowerMessage(`lower than the minimum amount ${amountLimits.fiatTransAmountLowerLimit}`)
    }else{
     
      setOrderLimitLowerMessage('')
    }
  }

  const handleCheckTotalAmountLimits=()=>{
    if(totalAmount < amountLimits.assetAmountLowerLimit){
      setLimitMessage(`lower than the minimum amount ${amountLimits.assetAmountLowerLimit}`)
    
  
    }else if(totalAmount > amountLimits.assetAmountUpperLimit){
      setLimitMessage(`Higher than the maximum amount ${amountLimits.assetAmountUpperLimit}`)
    }else{
        setLimitMessage('')
    }
  }

  


  const handleRemovePaymentMethod = (id) => {
    const tempPaymentMethods = paymentMethods.filter((method) => {
      if (id !== method.id) {
        return method;
      }
    });
    setPaymentMethods(tempPaymentMethods);
  };

  return (
    <div className={styles.edit2}>
      <div className={styles.edit2_upper}>
        <Container>
          <div className={styles.edit2_upper_total_amount}>
            <div className={styles.edit2_total_amount_heading}>
              <Typography>Total amount</Typography>
            </div>
            <div className={styles.total_amount_input_box}>
              <input
                type="text"
                name="total-amount"
                onChange={handleChange}
                value={totalAmount}
                id=""
              />
              <div>
                <Typography>{incomingPrice.asset}</Typography>
              </div>
            </div>
            <div className={styles.limits}>
              <Typography variant='overline' style={{color: 'red'}} >{limitMessage}</Typography>
            </div>
            <div className={styles.total_amount_available}>
              {tradeType === "BUY" ? null : (
                <div className={styles.available}>
                  <Typography variant="h5">Available:</Typography>
                  <Typography variant="h6">2,394,495 USDT</Typography>
                  <Typography>All</Typography>
                </div>
              )}

              {tradeType === "SELL" ? null : (
                <div className={styles.estimate}>
                  <Typography>~ 2,394,495 NGN</Typography>
                </div>
              )}
            </div>
          </div>
          <div className={styles.edit2_upper_order_limit}>
            <div className={styles.edit2_total_amount_heading}>
              <Typography>Order limit</Typography>
            </div>
            <div className={styles.order_limit_inputs}>
              <div>
              <div className={styles.order_limit_input_box}>
                <input
                  type="text"
                  name="order-min"
                  onChange={handleChange}
                  value={orderLimitMin}
                />
                <div>
                  <Typography>{incomingPrice.fiat}</Typography>
                </div>
              </div>
              <div className={styles.limits}>
              <Typography variant='overline' style={{color: 'red'}} >{orderLimitLowerMessage}</Typography>
 
              </div>
              </div>
              
              <span style={{ fontSize: "1.5rem" }}>~</span>
             <div>
             <div className={styles.order_limit_input_box}>
                <input
                  name="order-max"
                  onChange={handleChange}
                  value={orderLimitMax}
                  type="text"
                />
                <div>
                  <Typography>{incomingPrice.fiat}</Typography>
                </div>
              </div>
              <div className={styles.limits}>
              <Typography variant='overline' style={{color: 'red'}} >{orderLimitHigherMessage}</Typography>
              </div>
             </div>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.edit2_middle}>
        <Container>
          <div className={styles.edit2_payment_method}>
            <Typography variant="h6">Payment methods</Typography>
            <Typography>Select up to 5</Typography>
            <div className={styles.payment_methods}>
              {paymentMethods.map((method) => {
                return (
                  <div key={method.id} className={styles.payment_method}>
                    <Typography>{method.name}</Typography>
                    <img
                      onClick={() => {
                        handleRemovePaymentMethod(method.id);
                      }}
                      src={close}
                      alt=""
                    />
                  </div>
                );
              })}
            </div>
            <button onClick={handleOpenAddPaymentMethodModal}>+ Add new</button>
          </div>
        </Container>
      </div>
      <div className={styles.edit2_lower}>
        <Container>
          <div className={styles.edit2_payment_time_limit}>
            <Typography>Payment time limit</Typography>
            <select onChange={handleSelectChange} name="time-limit" id="">
              <option value="15">15mins</option>
              <option value="30">30mins</option>
              <option value="45">45mins</option>
            </select>
          </div>
        </Container>
      </div>
      {openAddPaymentMethodModal && (
        <AppBackdrop
          child={
            <AddPaymentMethod
              handleCloseModal={handleCloseAddPaymentMethodModal}
              paymentMethods={paymentMethods}
              handleAddPaymentCheckboxChange={handleAddPaymentCheckboxChange}
              incomingPaymentMethod={incomingPaymentMethod}
            />
          }
          handleCloseModal={handleCloseAddPaymentMethodModal}
          openModal={openAddPaymentMethodModal}
        />
      )}
    </div>
  );
};

export default Post2;
