// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Banks_banks__3swts {\n  display: flex;\n  flex-direction: column;\n}\n\n.Banks_heading__11REy {\n  padding: 22px 40px;\n  background: #f8f9fd;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 36px;\n}\n.Banks_heading__11REy form {\n  width: 320px;\n}\n\n.Banks_heading_btn__29wSL {\n  width: 152px;\n}\n.Banks_body__2Euy5 {\n}\n", "",{"version":3,"sources":["webpack://src/pages/settings/tabs/banks/Banks.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;AACA;AACA","sourcesContent":[".banks {\n  display: flex;\n  flex-direction: column;\n}\n\n.heading {\n  padding: 22px 40px;\n  background: #f8f9fd;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 36px;\n}\n.heading form {\n  width: 320px;\n}\n\n.heading_btn {\n  width: 152px;\n}\n.body {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banks": "Banks_banks__3swts",
	"heading": "Banks_heading__11REy",
	"heading_btn": "Banks_heading_btn__29wSL",
	"body": "Banks_body__2Euy5"
};
export default ___CSS_LOADER_EXPORT___;
