import axios from "axios";

export const LOGIN = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/login`, data)
    .then((res) => {
      return res.data;
    });
};

export const LOGIN_2FA = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/2fa/authenticate`, data)
    .then((res) => {
      return res.data;
    });
};


