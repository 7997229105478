import React from "react";
import { Typography } from "@material-ui/core";
import styles from "./AddNewMember.module.css";
import { useForm } from "react-hook-form";
import LabeledInputs from "../../../../../../components/inputs/labeledInputs/LabeledInputs";
import ModalFrame from "../../../../../../components/frames/modalFrame/ModalFrame";
import { useMutation, useQueryClient } from "react-query";
import { ADD_MEMBER } from "../../../../../../API/mutations/settings/userGroups/UserGroups";

const AddNewMember = ({ setOpenAddMember, group_id }) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { mutate, isLoading } = useMutation(ADD_MEMBER, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["user-groups"]);
      setOpenAddMember(false);
    },
    onError: () => {},
  });

  const handleAddUserToGroup = async (data) => {
    mutate({
      data: {
        name: data.name,
        email: data.email,
      },
      id: group_id,
    });
  };

  return (
    <ModalFrame
      handleCancel={() => {
        setOpenAddMember(false);
      }}
      handleAction={handleSubmit(handleAddUserToGroup)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt="Add user"
      child={
        <div className={styles.manage_group_content}>
          <form action="">
            <div className={styles.manage_group_heading}>
              <Typography>Add a new member</Typography>
            </div>
            <div className={styles.add_new_member_alert}>
              <Typography>
                Create a new user by adding their email and generating a
                password for them. An email invite would be sent to them{" "}
              </Typography>
            </div>
            <div className={styles.form_field}>
              <LabeledInputs
                label="Accout Name"
                name="name"
                register={register}
                type="text"
                required={true}
              />
            </div>

            <div className={styles.form_field}>
              <LabeledInputs
                label="Email"
                name="email"
                register={register}
                type="text"
                required={true}
              />
            </div>
          </form>
        </div>
      }
    />
  );
};

export default AddNewMember;
