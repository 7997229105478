import React, { useState, useContext} from "react";
import { Link } from "react-router-dom";
import styles from "./SideNav.module.css";
import icon1 from "./assets/Layer 2.svg";
import icon2 from "./assets/Vector (23).svg";
import icon3 from "./assets/Vector (11).svg";
import icon4 from "./assets/Vector (12).svg";
import icon5 from "./assets/Vector (13).svg";
import icon6 from "./assets/Vector (14).svg";
import icon2_1 from "./../assets/Property 1=Dashboard-clicked.svg";
import icon1_1 from "./../assets/Property 1=Ad-clicked.svg";
import icon3_1 from "./../assets/Property 1=Group 1403.svg";
import icon4_1 from "./../assets/Property 1=Appeal-clicked.svg";
import icon5_1 from "./../assets/Property 1=clicked.svg";
import icon6_1 from "./../assets/Property 1=Group 1406.svg";
import balance_icon_idle from './../assets/balances_icon_idle.svg'
import balance_icon_selected from './../assets/Balances_icon_selected.svg'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import p2plogo from "./../assets/p2plogo.svg";
import { UserContextAPI } from "../../Context/user/UserContext";
import { ActiveLinkContextAPI } from "../../Context/activeLink/ActiveLinkContext";

const partnerLinks = [
  {
    name: "dashboard",
    icon: icon2,
    activeIcon: icon2_1,
  },
  {
    name: "settings",
    icon: icon6,
    activeIcon: icon6_1,
  },
];

const userLinks = [
  {
    name: "dashboard",
    icon: icon2,
    activeIcon: icon2_1,
  },
  // {
  //   name: "ads",
  //   icon: icon1,
  //   activeIcon: icon1_1,
  // },
  {
    name: "support",
    icon: icon3,
    activeIcon: icon3_1,
  },
  // {
  //   name: "balances",
  //   icon: balance_icon_idle,
  //   activeIcon: balance_icon_selected,
  // },
  // {
  //   name: "appeals",
  //   icon: icon4,
  //   activeIcon: icon4_1,
  // },
  {
    name: "transactions",
    icon: icon5,
    activeIcon: icon5_1,
  },
  {
    name: "banks",
    icon: icon6,
    activeIcon: icon6_1,
  },
  {
    name: "settings",
    icon: icon6,
    activeIcon: icon6_1,
  },
 
];

const SideNav = () => {
  const [showDropDown, setShowDropDown] = useState(false);
  const { user } = useContext(UserContextAPI);
  const { setActiveLink, activeLink } = useContext(ActiveLinkContextAPI);

  const handleSetActiveLink = (link) => {
    setActiveLink(link);
    if (link !== "ads") {
      setShowDropDown(false);
    } else {
    }
  };

  const handleDropDown = (e) => {
    e.preventDefault();
    setShowDropDown(!showDropDown);
  };

 

  return (
    <aside className={styles.side_nav}>
      <div className={styles.side_nav_content}>
        <div className={styles.logo}>
          <img src={p2plogo} alt="" />
        </div>

        {user?.partner ? (
          <div className={styles.nav_links}>
            <ul>
              {partnerLinks.map((partnerLink) => {
                return (
                  <li
                    key={partnerLink.name}
                    className={
                      activeLink === partnerLink.name
                        ? styles.nav_links_li_active
                        : null
                    }
                  >
                    <Link
                      className={
                        activeLink === partnerLink.name
                          ? styles.link_active
                          : null
                      }
                      onClick={() => {
                        handleSetActiveLink(partnerLink.name);
                      }}
                      to={`/${partnerLink.name}`}
                    >
                      <div>
                        {activeLink === partnerLink.name ? (
                          <img src={partnerLink.activeIcon} alt="" />
                        ) : (
                          <img src={partnerLink.icon} alt="" />
                        )}
                      </div>
                      {partnerLink.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : (
          <div className={styles.nav_links}>
            <ul>
              {userLinks.map((userLink) => {
                return (
                  user?.permissions?.some((routePermission) => {
                    return routePermission === userLink.name;
                  }) && (
                    <li
                      key={userLink.name}
                      className={
                        activeLink === userLink.name
                          ? styles.nav_links_li_active
                          : null
                      }
                    >
                      {userLink.name === "ads" ? (
                        <>
                          <Link
                            className={
                              activeLink === userLink.name
                                ? styles.link_active
                                : null
                            }
                            onClick={(e) => {
                              handleSetActiveLink(userLink.name);
                              handleDropDown(e);
                            }}
                            to="/ads"
                          >
                            <div>
                              {activeLink === userLink.name ? (
                                <img src={userLink.activeIcon} alt="" />
                              ) : (
                                <img src={userLink.icon} alt="" />
                              )}
                            </div>
                            Ads{" "}
                            {showDropDown ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </Link>
                          <div
                            className={`${styles.more_links} ${
                              showDropDown
                                ? styles.more_links_active
                                : styles.more_links
                            }`}
                          >
                            <div>
                              <Link
                                onClick={(e) => {
                                  handleSetActiveLink(userLink.name);
                                }}
                                to={`/${userLink.name}/binance`}
                              >
                                Binance
                              </Link>
                            </div>
                            <div>
                              <Link
                                onClick={(e) => {
                                  handleSetActiveLink(userLink.name);
                                }}
                                to={`/${userLink.name}/quidax`}
                              >
                                Quidax
                              </Link>
                            </div>
                            <div>
                              <Link
                                onClick={(e) => {
                                  handleSetActiveLink(userLink.name);
                                }}
                                to={`/${userLink.name}/paxful`}
                              >
                                Paxful
                              </Link>
                            </div>
                          </div>
                        </>
                      ) : (
                        <Link
                          className={
                            activeLink === userLink.name
                              ? styles.link_active
                              : null
                          }
                          onClick={() => {
                            handleSetActiveLink(userLink.name);
                          }}
                          to={`/${userLink.name}`}
                        >
                          <div>
                            {activeLink === userLink.name ? (
                              <img src={userLink.activeIcon} alt="" />
                            ) : (
                              <img src={userLink.icon} alt="" />
                            )}
                          </div>
                          {userLink.name}
                        </Link>
                      )}
                    </li>
                  )
                );
              })}
            </ul>
          </div>
        )}
      </div>
    </aside>
  );
};

export default SideNav;