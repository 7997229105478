import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { AuthContextAPI } from "../auth/AuthContext";
import { useQuery, useQueryClient } from "react-query";
import { GET_USER } from "../../API/queries/user/User";

export const UserContextAPI = React.createContext();

const UserContext = (props) => {
  const { auth, logout } = useContext(AuthContextAPI);
  const [baseURL] = useState(process.env.REACT_APP_BASE_URL);
  const queryClient = useQueryClient()
  const {
    data: user,
    isLoading: loadingUser,
    refetch,
    isError,
  } = useQuery(["user"], GET_USER, {
  
    retry: false,
    retryOnMount: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: Boolean(auth)
  });

 

  useEffect(()=>{
    if(isError){
    queryClient.removeQueries(['user'])
    }
    
  }, [isError])

  useEffect(() => {
    if (auth) {
      refetch();
    } else {
      logout()
    }
  }, [auth]);

  return (
    <UserContextAPI.Provider
      value={{
        baseURL: baseURL,
        user: user?.data,
        loadingUser,
      }}
    >
      {props.children}
    </UserContextAPI.Provider>
  );
};

export default UserContext;
