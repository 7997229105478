import React, { useEffect } from "react";
import { useState } from "react";
import { useQueryClient } from "react-query";
export const AuthContextAPI = React.createContext();

const AuthContext = (props) => {
  const [baseURL] = useState(process.env.REACT_APP_BASE_URL);
  const queryClient = useQueryClient()

  const [auth, setAuth] = useState(
    localStorage.hasOwnProperty("auth")
      ? Boolean(localStorage.getItem("auth"))
      : false
  );

  useEffect(() => {
    const getTokenFromLocalStorage = () => {
      if (localStorage.hasOwnProperty("auth")) {
        setAuth(JSON.parse(localStorage.getItem("auth")));
      } else {
        logout();
      }
    };
    getTokenFromLocalStorage();
  }, []);

  const login = (data) => {
    localStorage.setItem("p2ptoken", data.token);
    localStorage.setItem("auth", JSON.stringify(true));
    setAuth(true);
  };



  const logout = () => {
    setAuth(false);
    localStorage.removeItem("p2ptoken");
    localStorage.removeItem("partner");
    localStorage.removeItem("auth");
   
  };

  return (
    <AuthContextAPI.Provider
      value={{
        baseURL,
        login,
        logout,
        auth,
        setAuth,
      }}
    >
      {props.children}
    </AuthContextAPI.Provider>
  );
};

export default AuthContext;
