import { Container } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import styles from "./Ads.module.css";
import refresh from "./../../components/assets/Vector (2).svg";
import { useHistory } from "react-router-dom";
import AdDetailsModal from "./Modals/AdDetailsModal";
import AdDeleteModal from "./Modals/AdDeleteModal";
import AppBackdrop from "../../components/backdrop/Backdrop";
import SelectPlatform from "./Modals/SelectPlatform";
import axios from "axios";
import PageLayout from "../../components/Layout/PageLayout";
import ButtonMain from "../../components/button/Button";
import ModalFrame from "../../components/frames/modalFrame/ModalFrame";
import { useForm } from "react-hook-form";
import useOpenSnackbar from "../../custom-hooks/useOpenSnackbar";
import P2PSnackbar from "../../components/AppSnackbars/P2PSnackbar";
import LabeledSelects from "../../components/selects/labeledSelects/LabeledSelects";
import AdsTable from "./AdsTable/AdsTable";

import { AuthContextAPI } from "../../Context/auth/AuthContext";

const Ads = () => {
  const { baseURL} = useContext(AuthContextAPI);
  const history = useHistory();
  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const {
    openSnackbar,

    message,

    duration,
    severity,
    openToast,
    setOpenToast,
  } = useOpenSnackbar();

  const [platforms, setPlatforms] = useState([]);
  const [loadingPlatforms, setLoadingPlatforms] = useState(true);
  const [open, setOpen] = useState(false);

  const [selectedAd, setSelectedAd] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [OpenSelectPlatformModal, setOpenSelectPlatformModal] = useState(false);

  const [platform, setPlatform] = useState("");

  const [ads, setAds] = useState([
    {
      id: 1,
      account_name: "Toyetech",
      advert_type: "BUY",
      order_no: "36373261181273",
      price: 484.75,
      amount_limit: "22,384,493 USDT",
      tradingPair: "USDT/NGN",
      limit: "50,000-20,000,000 NGN",
      payment_time: "15mins",
    },
    {
      id: 2,
      account_name: "Hoyetech",
      advert_type: "sell",
      order_no: 26373261181272,
      price: 584.75,
      amount_limit: "22,384,493 USDT",
      tradingPair: "USDT/NGN",
      limit: "50,000-20,000,000 NGN",
      payment_time: "15mins",
    },
  ]);

  useEffect(() => {
    const controller = new AbortController();

    handleGetAdvert();
    handleGetPlatformUsersAndName();
    return () => {
      controller.abort();
    };
  }, []);

  const handleGetPlatformUsersAndName = async () => {
    try {
      const response = await axios.get(
        `${baseURL}/api/v1/settings/platform/users`
      );
      setPlatforms(response.data.data);
      setLoadingPlatforms(false);
    } catch (error) {
      setLoadingPlatforms(false);
    }
  };

  const handleGetAdvert = () => {
    //getRequest('/api/v1/advert')
  };

  const handleGoToEdit = (id) => {
    history.push(`/edit-ads/${id}`);
  };

  const handleShowAdDetailModal = (id) => {
    setOpen(true);
    const selectedAd = ads.find((ad) => {
      return ad.id === id;
    });
    setSelectedAd(selectedAd);
  };

  const handleOpenDeleteModal = (id) => {
    setShowDeleteModal(true);
    const selectedAd = ads.find((ad) => {
      return ad.id === id;
    });
    setSelectedAd(selectedAd);
  };
  const handleOpenSelectPlatformModal = () => {
    setOpenSelectPlatformModal(true);
  };

  const handleCloseSelectPlatformModal = () => {
    setOpenSelectPlatformModal(false);
  };

  const handleGotoPostScreen = () => {
    if (platform === "") {
      openSnackbar("error", "Please select a platform before you can proceed");
    } else {
      history.push(`/post-ads/${platform}`);
    }
  };

  const handleRefreshAds = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${baseURL}/api/v1/advert/refresh/data`);
    } catch (error) {}
  };

  return (
    <PageLayout
      bgColor="white"
      child={
        <div className={styles.ads}>
          <Container>
            <div className={styles.ads_content}>
              <div className={styles.ads_heading}>
                <div className={styles.ads_refresh}>
                  <ButtonMain
                    length="116px"
                    bgColor="#1A2CCE"
                    textColor="white"
                    btnText="Post an ad"
                    handleClick={handleOpenSelectPlatformModal}
                  />

                  <a href="/" onClick={handleRefreshAds}>
                    <img src={refresh} alt="" /> Refresh
                  </a>
                </div>
              </div>
              <div className={styles.ads_filter_selects}>
                <div className={styles.ads_filter_select}>
                  <LabeledSelects
                    redOutline
                    data={[]}
                    register={register}
                    name="All"
                    label=""
                    placeholder="All"
                  />
                </div>
                <div className={styles.ads_filter_select}>
                  <LabeledSelects
                    redOutline
                    data={[]}
                    register={register}
                    name="All"
                    label=""
                    placeholder="All"
                  />
                </div>
                <div className={styles.ads_filter_select}>
                  <LabeledSelects
                    redOutline
                    data={[]}
                    register={register}
                    name="All"
                    label=""
                    placeholder="All"
                  />
                </div>
              </div>
              <AdsTable
                ads={ads}
                handleGoToEdit={handleGoToEdit}
                handleOpenDeleteModal={handleOpenDeleteModal}
                handleShowAdDetailModal={handleShowAdDetailModal}
              />
            </div>
          </Container>
          {open && (
            <AdDetailsModal
              selectedAd={selectedAd}
              open={open}
              setOpen={setOpen}
            />
          )}
          {showDeleteModal && (
            <AdDeleteModal
              selectedAd={selectedAd}
              setShowDeleteModal={setShowDeleteModal}
            />
          )}
          {OpenSelectPlatformModal && (
            <AppBackdrop
              child={
                <ModalFrame
                  btnTxt="Confirm"
                  handleCancel={handleCloseSelectPlatformModal}
                  showCancelBtn
                  showConfirmBtn
                  handleAction={handleGotoPostScreen}
                  child={
                    <SelectPlatform
                      setPlatform={setPlatform}
                      platforms={platforms}
                      loadingPlatforms={loadingPlatforms}
                      handleCloseModal={handleCloseSelectPlatformModal}
                    />
                  }
                />
              }
              openModal={OpenSelectPlatformModal}
              handleCloseModal={handleCloseSelectPlatformModal}
            />
          )}
          <P2PSnackbar
            severity={severity}
            message={message}
            open={openToast}
            setOpen={setOpenToast}
            duration={duration}
          />
        </div>
      }
    />
  );
};

export default Ads;
