import React from "react";
import AlertFrame from "../frames/alertFrame/AlertFrame";
import styles from "./LowBalanceModal.module.css";
import alert_icon from "../../components/assets/big_alert_icon.svg";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

function LowBalanceModal({handleCloseModal}) {
  return (
    <AlertFrame
    handleClose={handleCloseModal}
      child={
        <div className={styles.alert_content}>
          <div className={styles.alert_icon}>
            <img src={alert_icon} alt="alert" />
          </div>
          <div className={styles.alert_text}>
            <Typography variant='h6'>Low Balance</Typography>
            <Typography>Top up account balance to continue trading</Typography>
          </div>
          <div className={styles.alert_link}>
            <Link to="/balances">View Balances</Link>
          </div>
        </div>
      }
    />
  );
}

export default LowBalanceModal;
