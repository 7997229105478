import axios from "axios";

export const GENERATE_2FA = async (data) => {
  return axios
    .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/2fa/generate`)
    .then((res) => {
      return res.data;
    });
};

export const ENABLE_2FA = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/2fa/enable`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const DISABLE_2FA = async (data) => {
    return axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/v1/auth/2fa/disable`, data)
      .then((res) => {
        return res.data;
      });
  };
