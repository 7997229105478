import { Container, Grid, Typography } from "@material-ui/core";
import { style } from "@mui/system";
import React from "react";
import styles from "../Appeals.module.css";
import TrxDetails from "./trxDetails/TrxDetails";
import Scrollbars from "react-custom-scrollbars";
import Chatroom from "./chatroom/Chatroom";
import AppealProgress from "../appeal progress/AppealProgress";
import AppealProgress2 from "../appeal progress/AppealProgress2";
const ChatBoard = () => {

  return (
    <div className={styles.chat_room}>
        <div className={styles.chat_room_content}>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <TrxDetails />
            </Grid>
            <Grid item xs={8}>
              <Chatroom/>
              {/* <AppealProgress/> */}
              {/* <AppealProgress2/> */}
            </Grid>
          </Grid>
        </div>
    </div>
  );
};

export default ChatBoard;
