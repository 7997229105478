import React, { useEffect } from "react";
import { useState } from "react";
import EmptyState from "../../../../../components/null state/EmptyState";
import Pagination from "../../../../../components/pagination/Pagination";
import Switch from "../../../../../components/switch/Switch";
import BankTableRow from "./BankTableRow";
import styles from "./Styles.module.css";

const tableHead = ["Bank Name", "Country", "Status"];

function BanksTable({
  setSearchValue,
  refetch,
  formValues,
  searchValue,
  register,
  banks,
  watch,
  setValue,
}) {
  const [formattedBanks, setFormattedBanks] = useState(banks)
  const [currentPage, setCurrentPage] = useState(1);
  const [banksPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  // Get current banks
  const indexOfLastBank = currentPage * banksPerPage;
  const indexOfFirstBank = indexOfLastBank - banksPerPage;
  const currentBanks = formattedBanks?.slice(indexOfFirstBank, indexOfLastBank);


  useEffect(()=>{
    const tempBanks =  banks?.map((bank)=>{
      
        return ({
          ...bank,
          status: formValues[bank.code] === undefined ? bank.status : formValues[bank.code]
        })
      })

      setFormattedBanks(tempBanks)
  }, [formValues])

  useEffect(() => {
    const filteredData = formattedBanks?.filter((item) =>
      item.name.toLowerCase()?.includes(searchValue?.toLowerCase())
    );
    setFilteredData(filteredData);
  }, [searchValue]);

  // Change page
  const handlePageChange = (event) => {
    setCurrentPage(Number(event));
  };

  return (
    <div className={styles.bank_table}>
      <table>
        <thead>
          <tr>
            {tableHead.map((item) => {
              return <td key={item}>{item}</td>;
            })}
          </tr>
        </thead>
        <tbody>
          {searchValue
            ? filteredData?.map((bank) => {
                return (
                  <BankTableRow
                    key={bank.id}
                    watch={watch}
                    setValue={setValue}
                    bank={bank}
                    register={register}
                  />
                );
              })
            : currentBanks?.map((bank) => {
                return (
                  <BankTableRow
                    key={bank.id}
                    watch={watch}
                    setValue={setValue}
                    bank={bank}
                    register={register}
                  />
                );
              })}
        </tbody>
      </table>
      {banks?.length > 0 ? (
        searchValue ? null : (
          <div>
            <Pagination
              meta={{
                currentPage: currentPage,
                itemCount: banksPerPage,
                itemsPerPage: banksPerPage,
                totalItems: banks.length,
                totalPages: Math.ceil(banks.length / banksPerPage),
              }}
              handlePageChange={handlePageChange}
            />
          </div>
        )
      ) : (
        <div style={{ marginTop: 50 }}>
          <EmptyState
            handleRefresh={() => {
              setSearchValue("");
              refetch();
            }}
          />
        </div>
      )}
    </div>
  );
}

export default BanksTable;
