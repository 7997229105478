import React, { useContext, useEffect, useState } from "react";
import ButtonMain from "../../../../components/button/Button";
import LabeledInputs from "../../../../components/inputs/labeledInputs/LabeledInputs";
import styles from "./EditInfo.module.css";
import camera_icon from "../../../../components/assets/camera_icon.svg";
import { useForm } from "react-hook-form";
import axios from "axios";
import { AuthContextAPI } from "../../../../Context/auth/AuthContext";
import { CircularProgress } from "@material-ui/core";
import { useQueryClient } from "react-query";

const fields = [
  {
    name: "access_type",
    label: "Access Type",
    type: "input",
    required: true,
    readOnly: true,
  },
  {
    name: "email",
    label: "Email",
    type: "input",
    required: true,
    readOnly: true,
  },
  {
    name: "firstname",
    label: "First Name",
    type: "input",
    required: true,
  },
  {
    name: "lastname",
    label: "Last Name",
    type: "input",
    required: true,
  },
  {
    name: "job_title",
    label: "Job Title",
    type: "input",
    required: true,
  },
  {
    name: "department",
    label: "Department",
    type: "input",
    required: true,
  },
  {
    name: "phone_number",
    label: "Phone Number",
    type: "input",
    required: true,
  },
  {
    name: "slack_profile",
    label: "Slack Profile",
    type: "input",
    required: true,
  },
];
function EditInfo({ user, handleUpdateProfile, savingProfile }) {
  const { baseURL } = useContext(AuthContextAPI);
  const { register, setValue, watch, handleSubmit } = useForm();
  const [savingImage, setSavingImage] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const queryClient = useQueryClient()
  useEffect(() => {
    fields.map((field) => {
      if (field.name === "access_type") {
        setValue(field.name, user?.role?.name);
      } else {
        setValue(field.name, user[field.name]);
        setImageUrl(user?.avatar);
      }
    });
  }, [user]);

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === "profile_pic") {
      handleSubmitImage(e.target.files[0]);
    }
  };

  const handleSubmitImage = async (data) => {
    setSavingImage(true);
    let formData = new FormData();
    formData.append("avatar", data);
    const response = await axios.put(
      `${baseURL}/api/v1/auth/update/profile/avatar`,
      formData
    );
    setImageUrl(response.data.data);
    if (response.status < 300 && response.status >= 200) {
      setSavingImage(false);
      queryClient.invalidateQueries(['user'])
    } else if (response.response) {
      setSavingImage(false);
    } else if (response.request) {
      setSavingImage(false);
    } else {
      setSavingImage(false);
    }
  };

  return (
    <>
      <div className={styles.profile_pic}>
        <input onChange={handleChange} type="file" name="profile_pic" id="" />
        {imageUrl !== null && (
          <img
            className={styles.incoming_avatar}
            src={`${baseURL}/${imageUrl}`}
            alt="avatar"
          />
        )}
        <div className={styles.avatar_loader}>
          {savingImage ? <CircularProgress size={40} /> : null}
        </div>
        <img src={camera_icon} className={styles.camera_icon} alt="" />
      </div>
      {fields.map((field) => {
        return (
          <div key={field.name} className={styles.field}>
            <LabeledInputs
              readOnly={field.readOnly}
              label={field.label}
              name={field.name}
              required={field.required}
              register={register}
            />
          </div>
        );
      })}
      <div className={styles.btn}>
        <ButtonMain
          loading={savingProfile}
          handleClick={handleSubmit(handleUpdateProfile)}
          btnText="Save changes"
          textColor="white"
        />
      </div>
    </>
  );
}

export default EditInfo;
