import { Typography, Grid, Chip } from "@material-ui/core";
import React, { useState, useContext } from "react";
import styles from "./UserGroups.module.css";
import { Link } from "react-router-dom";
import ManageGroupModal from "./modals/manageGroup/ManageGroup";
import AddNewMember from "./modals/addNewMember/AddNewMember";
import group_name from "./../../../../components/assets/Group 83 (1).svg";
import LinearProgress from "@mui/material/LinearProgress";
import moment from "moment";
import TryAgain from "../../../../components/tryAgain/TryAgain";
import ButtonMain from "../../../../components/button/Button";
import AppBackdrop from "../../../../components/backdrop/Backdrop";
import AppDeletePrompt from "../../../../components/delete/DeletePrompt";
import CreateGroup from "./modals/createGroup/CreateGroup";
import ManagePermissions from "./modals/managePermissions/ManagePermissions";
import { AuthContextAPI } from "../../../../Context/auth/AuthContext";
import { GET_USER_GROUPS } from "../../../../API/queries/settings/userGroups/UserGroup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  DELETE_GROUP,
  DELETE_GROUP_MEMBER,
} from "../../../../API/mutations/settings/userGroups/UserGroups";

const UserGroups = () => {
  const { baseURL } = useContext(AuthContextAPI);
  const [userGroupModal, setUserGroupModal] = useState(false);
  const [showManagePermissionsModal, setShowManagePermissionsModal] =
    useState(false);
  const [showManageGroupModal, setShowMangeGroupModal] = useState(false);
  const [openAddMember, setOpenAddMember] = useState(false);
  const [group, setGroup] = useState({});
  const [member, setMember] = useState({});

  const [openDeleteGroupPrompt, setOpenDeleteGroupPrompt] = useState(false);
  const [openDeleteMemberFromGroupPrompt, setOpenDeleteMemberFromGroupPrompt] =
    useState(false);
  const [displayTryAgain, setDisplayTryAgain] = useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["user-groups"],
    () => GET_USER_GROUPS(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isLoadingDeleteGroup } = useMutation(
    DELETE_GROUP,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["user-groups"]);
        setOpenDeleteGroupPrompt(false);
        setGroup({});
      },
    }
  );

  const {
    mutate: _handleGroupMemberDelete,
    isLoading: isLoadingDeleteGroupMember,
  } = useMutation(DELETE_GROUP_MEMBER, {
    onSuccess: () => {
      queryClient.invalidateQueries(["single-group"]);
      queryClient.invalidateQueries(["user-groups"]);
      setOpenDeleteMemberFromGroupPrompt(false);
    },
  });

  const createGroupModal = () => {
    setUserGroupModal(true);
  };

  const handleAddMember = (e, group) => {
    e.preventDefault();
    setGroup(group);
    setOpenAddMember(true);
  };

  const handleGoToEdit = () => {};

  const handleGoToManage = async (e, group) => {
    e.preventDefault();
    setGroup(group);
    setShowMangeGroupModal(true);
  };

  const handleGroupDelete = async (e) => {
    e.preventDefault();
    mutate(group.id);
  };

  const handleGroupMemberDelete = async (e) => {
    e.preventDefault();
    _handleGroupMemberDelete({
      group_id: group.id,
      member_id: member.id,
    });
  };

  const handleOpenDeletePrompt = (e, group) => {
    e.preventDefault();
    setGroup(group);
    setOpenDeleteGroupPrompt(true);
  };

  const handleOpenMemberDeletePrompt = (member, group) => {
    setMember(member);
    setGroup(group);
    setOpenDeleteMemberFromGroupPrompt(true);
  };
  const handleShowManagePermissionModal = (e, group) => {
    e.preventDefault();
    setGroup(group);
    setShowManagePermissionsModal(true);
  };
  const handleTryAgain = () => {};
  return (
    <div className={styles.user_roles}>
      {isLoading ? (
        <LinearProgress />
      ) : displayTryAgain ? (
        <TryAgain handleTryAgain={handleTryAgain} />
      ) : (
        <>
          <div className={styles.user_roles_types}>
            <div className={styles.user_types_heading}>
              <div className={styles.user_types_text}>
                <Typography variant="h6">Partner Groups</Typography>
                <Typography>
                  This allows 3rd party access to predefined menus depending on
                  the assigned role
                </Typography>
              </div>
              <ButtonMain
                handleClick={createGroupModal}
                border="1px solid #BEBEBE"
                textColor="black"
                bgColor="white"
                btnText="Create group"
                hideLoader
              />
            </div>
            <div className={styles.user_types_list}>
              <Grid container spacing={3}>
                {data?.data?.map((group) => {
                  return (
                    <Grid key={group.id} item sm={12} md={6} lg={4}>
                      <div className={styles.user_type_name}>
                        <div className={styles.user_type_icon}>
                          <img src={`${baseURL}/${group.image}`} alt="" />
                        </div>
                        <div className={styles.user_type_text}>
                          <Typography variant="h6">{group.name}</Typography>
                          <Typography>
                            Created{" "}
                            {moment(group.created_at).format("Do MMM YYYY")}
                          </Typography>
                          <div className={styles.user_type_links}>
                            <Link
                              to=""
                              onClick={(e) => {
                                handleGoToManage(e, group);
                              }}
                            >
                              Manage
                            </Link>
                            <Link
                              to=""
                              onClick={(e) => {
                                handleOpenDeletePrompt(e, group);
                              }}
                            >
                              {" "}
                              Delete{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
          <div className={styles.user_list}>
            <div className={styles.user_list_heading}>
              <Typography variant="h6">Manage user groups </Typography>
              <Typography>
                Edit access and manage groups for everyone on your team
              </Typography>
            </div>
            <div className={styles.user_role_table_grid}>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">
                  GROUP NAME <img src={group_name} alt="" />
                </Typography>
              </div>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">MEMBERS </Typography>
              </div>
              <div className={styles.grid_header}>
                {" "}
                <Typography variant="h6">PERMISSION </Typography>
              </div>

              {data?.data?.map((group) => {
                return (
                  <React.Fragment key={group.id}>
                    <div
                      className={styles.account_name}
                      onClick={() => {
                        handleGoToEdit(group);
                      }}
                    >
                      {" "}
                      <Typography>{group.name} </Typography>
                    </div>
                    <div
                      className={styles.email}
                      onClick={() => {
                        handleGoToEdit(group.id);
                      }}
                    >
                      {group.members.map((member) => {
                        return (
                          <Chip
                            key={member.id}
                            label={member.name}
                            onDelete={() => {
                              handleOpenMemberDeletePrompt(member, group);
                            }}
                            className={styles.my_chip}
                          />
                        );
                      })}
                      <Link
                        to=""
                        onClick={(e) => {
                          handleAddMember(e, group);
                        }}
                      >
                        Add new member
                      </Link>
                    </div>
                    <div
                      className={styles.role}
                      onClick={() => {
                        handleGoToEdit(group);
                      }}
                    >
                      {" "}
                      <Link
                        to=""
                        onClick={(e) => {
                          handleShowManagePermissionModal(e, group);
                        }}
                      >
                        Manage permissions
                      </Link>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </>
      )}

      {userGroupModal && (
        <AppBackdrop
          openModal={userGroupModal}
          handleCloseModal={() => {
            setUserGroupModal(false);
          }}
          child={
            <CreateGroup
              setShowMangeGroupModal={setShowMangeGroupModal}
              setUserGroupModal={setUserGroupModal}
              handleCloseModal={() => {
                setUserGroupModal(false);
              }}
            />
          }
        />
      )}

      {showManageGroupModal && (
        <AppBackdrop
          openModal={showManageGroupModal}
          handleCloseModal={() => {
            setGroup({});
            setShowMangeGroupModal(false);
          }}
          child={
            <ManageGroupModal
              setGroup={setGroup}
              group={group}
              setShowManageGroupModal={setShowMangeGroupModal}
            />
          }
        />
      )}
      {showManagePermissionsModal && (
        <AppBackdrop
          openModal={showManagePermissionsModal}
          handleCloseModal={() => {
            setShowManagePermissionsModal(false);
          }}
          child={
            <ManagePermissions
              group={group}
              setShowManagePermissionsModal={setShowManagePermissionsModal}
            />
          }
        />
      )}

      {openAddMember && (
        <AppBackdrop
          openModal={openAddMember}
          handleCloseModal={() => {
            setOpenAddMember(false);
          }}
          child={
            <AddNewMember
              group_id={group.id}
              setOpenAddMember={setOpenAddMember}
            />
          }
        />
      )}

      {openDeleteGroupPrompt && (
        <AppBackdrop
          openModal={openDeleteGroupPrompt}
          handleCloseModal={() => {
            setGroup({});
            setOpenDeleteGroupPrompt(false);
          }}
          child={
            <AppDeletePrompt
              body={`Deleting ${group?.name} will revoke group access from this
             application`}
              title={"Delete group"}
              handleDelete={handleGroupDelete}
              handleCloseModal={() => {
                setGroup({});
                setOpenDeleteGroupPrompt(false);
              }}
              loading={isLoadingDeleteGroup}
            />
          }
        />
      )}
      {openDeleteMemberFromGroupPrompt && (
        <AppBackdrop
          openModal={openDeleteMemberFromGroupPrompt}
          handleCloseModal={() => {
            setMember({});
            setGroup({});
            setOpenDeleteMemberFromGroupPrompt(false);
          }}
          child={
            <AppDeletePrompt
              body={`Deleting ${member?.name} from ${group?.name} will revoke group access from this
             application`}
              title={"Delete member"}
              handleDelete={handleGroupMemberDelete}
              handleCloseModal={() => {
                setGroup({});
                setMember({});
                setOpenDeleteMemberFromGroupPrompt(false);
              }}
              loading={isLoadingDeleteGroupMember}
            />
          }
        />
      )}
    </div>
  );
};

export default UserGroups;
