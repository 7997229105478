import { Typography } from '@material-ui/core';
import React from 'react'
import styles from "./LabeledSelects.module.css";



function LabeledSelects({readOnly, data, label,required, redOutline, placeholder, register, name}) {
  return (
    <label className={styles.labeled_select} htmlFor="">
     {
       label && <Typography>{label}</Typography>
     } 

      <select
      disabled={readOnly}
      {...register(name, { required: required })}
      name={name}
      label={label}
      className={redOutline ? styles.red_outline : null}
      >
        <option value="">{placeholder}</option>
        {data?.map((option, index) => {
          return (
            <option key={option.id || index} value={option.name}>
              {option.name || option}
            </option>
          );
        })}
      </select>
    </label>
  )
}

export default LabeledSelects