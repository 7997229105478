import axios from "axios";


export const ADD_MEMBER = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/${data.id}/member/create`, data.data)
      .then((res) => {
        return res.data;
      });
  };

 

  export const  CREATE_GROUP = async (data) => {
    return axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/create`,
        data: data.formData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        return res.data;
      });
  };

  export const  UPDATE_GROUP = async (data) => {
    return axios({
        method: "put",
        url: `${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/update/${data.id}`,
        data: data.data,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((res) => {
        return res.data;
      });
  };

  export const DELETE_GROUP = async (id) => {
    return axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/${id}/delete`)
      .then((res) => {
        return res.data;
      });
  };

  export const DELETE_GROUP_MEMBER = async (data) => {
    return axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/${data.group_id}/member/delete`, {
          member_id: data.member_id
      })
      .then((res) => {
        return res.data;
      });
  };

  export const DELETE_ALL_GROUP_MEMBER = async (data) => {
    return axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/v1/settings/group/${data.group_id}/member/delete/all`, {
          member_id: data.member_id
      })
      .then((res) => {
        return res.data;
      });
  };





