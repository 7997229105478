import { Typography } from '@material-ui/core'
import React from 'react'
import styles from './UserInfoCard.module.css'


function UserInfoCard({title, value}) {
  return (
    <div className={styles.user_info_card}>
        <Typography variant='h6'>
            {title}
        </Typography>
        <Typography>
            {value}
        </Typography>
    </div>
  )
}

export default UserInfoCard