import { Grid } from "@material-ui/core";

import React from "react";

import styles from "./TransactionProcessed.module.css";
import TransactionProccessedChart from "../charts/TransactionProcessed";

import TransactionProcessedSummary from "../transactionProcessedSummary/TransactionProcessedSummary";

function TransactionProcessed({loading, dashboardData}) {
  return (
    <div className={styles.dashboard_transaction}>
      <Grid container spacing={3}>
        <Grid item sm={12} md={9}>
          <TransactionProccessedChart
            
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <TransactionProcessedSummary loading={loading} trxStats={dashboardData}  />
        </Grid>
      </Grid>
    </div>
  );
}

export default TransactionProcessed;
