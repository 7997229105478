// import { Container, LinearProgress } from "@material-ui/core";
// import axios from "axios";
// import React, { useContext, useEffect, useState } from "react";
// import BalancesAlert from "../../components/alert/balancesAlert/BalancesAlert";
// import AppBackdrop from "../../components/backdrop/Backdrop";
// import AppDeletePrompt from "../../components/delete/DeletePrompt";
// import PageLayout from "../../components/Layout/PageLayout";
// import { AuthContextAPI } from "../../Context/auth/AuthContext";
// import { BalancesContextAPI } from "../../Context/balances/BalancesContext";
// import styles from "./Balances.module.css";
// import Filter from "./filter/Filter";
// import AddNewAccount from "./modal/AddNewAccount/AddNewAccount";
// import BalanceTable from "./table/BalanceTable";

// function Balances() {
//   const { baseURL } = useContext(AuthContextAPI);
//   const {
//     low_balance,
//     accounts,
//     handleGetAccounts,
//     handleRefreshAccount,

//     loadingAccounts,
//     handleCheckBalanceStatus,
//   } = useContext(BalancesContextAPI);

//   const [addNewAccountLoading, setAddNewAccountLoading] = useState(false);
//   const [openAddNewAccountModal, setAddNewAccountModal] = useState(false);
//   const [account, setAccount] = useState({});
//   const [loadingDelete, setLoadingDelete] = useState(false);
//   const [openDeleteModal, setOpenDeleteModal] = useState(false);
//   const [platform, setPlatform] = useState([]);

//   useEffect(() => {
//     handleCheckBalanceStatus();
//     handleGetAccounts();
//     handleGetPlatforms();
//   }, []);

//   const handleDeleteAccount = (id) => {
//     setLoadingDelete(true);
//     axios
//       .delete(`${baseURL}/api/v1/accounts/${id}/delete`)
//       .then((response) => {
//         handleRefreshAccount();
//         setLoadingDelete(false);
//         setOpenDeleteModal(false);
//       })
//       .catch((error) => {
//         setLoadingDelete(false);
//       });
//   };

//   const handleGetPlatforms = () => {
//     axios
//       .get(`${baseURL}/api/v1/accounts/platform`)
//       .then((response) => {
//         setPlatform(response.data.data);
//       })
//       .catch((error) => {});
//   };

//   return (
//     <PageLayout
//       bgColor="#ffffff"
//       child={
//         <div className={styles.balances}>
//           {low_balance && <BalancesAlert />}
//           <Container>
//             <div className={styles.balances_content}>
//               <Filter
//                 loadingRefresh={loadingAccounts}
//                 handleRefreshAccount={handleRefreshAccount}
//                 handleOpenAddNewAccountModal={() => {
//                   setAddNewAccountModal(true);
//                 }}
//               />
//               {loadingAccounts ? (
//                 <LinearProgress />
//               ) : (
//                 <BalanceTable
//                   setOpenDeleteModal={setOpenDeleteModal}
//                   account={account}
//                   setAccount={setAccount}
//                   handleDeleteAccount={handleDeleteAccount}
//                   accounts={accounts}
//                 />
//               )}
//             </div>
//           </Container>
//           {openAddNewAccountModal && (
//             <AppBackdrop
//               child={
//                 <AddNewAccount
//                   platforms={platform}
//                   handleGetAccounts={handleGetAccounts}
//                   processing={addNewAccountLoading}
//                   handleAddNewAccountModalClose={() => {
//                     setAddNewAccountModal(false);
//                   }}
//                 />
//               }
//               openModal={openAddNewAccountModal}
//               handleCloseModal={() => {
//                 setAddNewAccountModal(false);
//               }}
//             />
//           )}
//           {openDeleteModal && (
//             <AppBackdrop
//               openModal={openDeleteModal}
//               handleCloseModal={() => {
//                 setOpenDeleteModal(false);
//               }}
//               child={
//                 <AppDeletePrompt
//                   body={`Deleting ${account?.account_name}'s account will revoke account access from this
//               application`}
//                   title={"Delete account"}
//                   handleDelete={() => {
//                     handleDeleteAccount(account.id);
//                   }}
//                   handleCloseModal={() => {
//                     setOpenDeleteModal(false);
//                   }}
//                   loading={loadingDelete}
//                 />
//               }
//             />
//           )}
//         </div>
//       }
//     />
//   );
// }

// export default Balances;
