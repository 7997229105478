import React, { useState, useEffect } from "react";
import styles from "./Platform.module.css";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlatformModal from "./modals/addNewPlatform/PlatformModal";
import LinearProgress from "@mui/material/LinearProgress";
import TryAgain from "../../../../components/tryAgain/TryAgain";
import PlatformAccordionDetails from "./components/platformAccordionDetails/PlatformAccordionDetails";
import ButtonMain from "../../../../components/button/Button";
import AppBackdrop from "../../../../components/backdrop/Backdrop";
import AppDeletePrompt from "../../../../components/delete/DeletePrompt";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GET_PLATFORMS } from "../../../../API/queries/settings/platform/Platform";
import {
  DELETE_PLATFORM,
  UPDATE_PLATFORM_SETTINGS,
} from "../../../../API/mutations/settings/platform/Platform";

const Platform = () => {
  const [showPlatformModal, setShowPlatformModal] = useState(false);
  const [paymentPlatforms, setPaymentPlatforms] = useState([]);

  const [displayTryAgain, setDisplayTryAgain] = useState(false);
  const [showPlatformDeleteModal, setShowPlatformDeleteModal] = useState(false);
  const [platformId, setPlatformId] = useState("");
  const [platform, setPlatform] = useState({});
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery(
    ["platform-settings"],
    () => GET_PLATFORMS(),
    {
      refetchOnWindowFocus: false,
    }
  );

  const { isLoading: updating, mutate } = useMutation(
    UPDATE_PLATFORM_SETTINGS,
    {
      onSuccess: (data) => {},
      onError: (error) => {},
    }
  );

  const { isLoading: deleting, mutate: handleDelete } = useMutation(
    DELETE_PLATFORM,
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["platform-settings"]);
        setShowPlatformDeleteModal(false);
      },
      onError: (error) => {},
    }
  );

  useEffect(() => {
    if (data === null) {
      setDisplayTryAgain(true);
    } else {
      setPaymentPlatforms(data?.data);
      setDisplayTryAgain(false);
    }
  }, [data]);

  const handleChange = (platformID, value) => {
    const tempPlatforms = [...paymentPlatforms];

    const selectedPlatform = tempPlatforms.find((platform) => {
      return platform.id === platformID;
    });

    selectedPlatform.platform_user = value;

    setPaymentPlatforms(tempPlatforms);
  };
  const handleFieldChange = (labelID, platformID, value) => {
    const tempPlatforms = [...paymentPlatforms];

    const selectedPlatform = tempPlatforms.find((platform) => {
      return platform.id === platformID;
    });

    const selectedField = selectedPlatform.fields.find((label) => {
      return label.id === labelID;
    });

    selectedField.value = value;

    setPaymentPlatforms(tempPlatforms);
  };

  const handleShowPlatformModal = () => {
    setShowPlatformModal(true);
  };

  const handleShowPlatformDeleteModal = (id) => {
    setPlatformId(id);
    setShowPlatformDeleteModal(true);
  };

  const handleSwitch = (id) => {
    const tempBotsStatus = [...paymentPlatforms];

    const selectedBot = tempBotsStatus.find((bot) => {
      return bot.id === id;
    });
    if (selectedBot) {
      const selctedIndex = paymentPlatforms.indexOf(selectedBot);
      tempBotsStatus[selctedIndex].status =
        !tempBotsStatus[selctedIndex].status;
      setPaymentPlatforms(tempBotsStatus);
    }
  };

  const handleRemovePlatform = async (e) => {
    e.preventDefault();
    handleDelete(platformId);
    //deleteRequest(`/api/v1/settings/platform/delete/${platformId}`);
  };

  const handleUpdatePlatform = (id) => {
    const selectedPlatform = paymentPlatforms.find((platform) => {
      return platform.id === id;
    });
    if (selectedPlatform) {
      const tempFields = selectedPlatform.fields.map((item) => ({
        label: item.label,
        name: item.name,
        value: item.value,
      }));
      if (tempFields) {
        mutate({
          name: selectedPlatform.name,
          status: selectedPlatform.status,
          platform_user: selectedPlatform.platform_user,
          fields: tempFields,
        });
      }
    }
  };

  const handleTryAgain = () => {};
  return (
    <div className={styles.payment}>
      {isLoading ? (
        <LinearProgress />
      ) : displayTryAgain ? (
        <TryAgain handleTryAgain={handleTryAgain} />
      ) : (
        <>
          <div className={styles.payment_heading}>
            <div className={styles.api_configuration}>
              <Typography>Platform API configuration</Typography>
              <ButtonMain
                handleClick={handleShowPlatformModal}
                border="1px solid #BEBEBE"
                textColor="black"
                bgColor="white"
                btnText="Add new"
                hideLoader
              />
            </div>
            <div className={styles.viewing}>
              <Typography>You are viewing our live API keys</Typography>
            </div>
          </div>
          <div className={styles.payment_accordion}>
            {paymentPlatforms?.map((paymentPlatform) => {
              return (
                <Accordion
                  key={paymentPlatform.id}
                  className={styles.my_accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={styles.my_accordion_summary}
                  >
                    <Typography>{paymentPlatform.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PlatformAccordionDetails
                      handleChange={handleChange}
                      key={paymentPlatform.id}
                      handleFieldChange={handleFieldChange}
                      handleShowPlatformDeleteModal={
                        handleShowPlatformDeleteModal
                      }
                      setPlatform={setPlatform}
                      handleSwitch={handleSwitch}
                      processing={updating}
                      handleUpdatePlatform={handleUpdatePlatform}
                      paymentPlatform={paymentPlatform}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        </>
      )}

      {showPlatformModal && (
        <AppBackdrop
          openModal={showPlatformModal}
          handleCloseModal={() => {
            setShowPlatformModal(false);
          }}
          child={<PlatformModal setShowPlatformModal={setShowPlatformModal} />}
        />
      )}

      {showPlatformDeleteModal && (
        <AppBackdrop
          child={
            <AppDeletePrompt
              body={`Are you sure you want to delete ${platform?.name} platform`}
              title={"Delete Platform"}
              handleDelete={handleRemovePlatform}
              handleCloseModal={() => {
                setShowPlatformDeleteModal(false);
              }}
              loading={deleting}
            />
          }
          openModal={showPlatformDeleteModal}
          handleCloseModal={() => {
            setShowPlatformDeleteModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Platform;
