// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./fonts/SF UI Display Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\n/* @font-face {\n  font-family: \"Product Sans Bold\";\n  src: local(\"Product Sans Bold\"), url(\"./../public/SF\\ UI\\ Display\\ Regular.ttf\") format(\"truetype\");\n} */\n\nbody {\n  margin: 0;\n\n}\n\n\n\n\n@font-face {\n  font-family: SF UI Display !important;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n* {\n\tfont-family: 'SF UI Display', sans-serif !important;\n}\n\ninput:hover {\n  border: 2px solid #C6DDFF !important;\n}\ninput:active{\n  border: 2px solid #C6DDFF !important;\n}\ninput:visited{\n  border: 2px solid #C6DDFF !important;\n}\n\n\n\n\nselect:hover{\n  border: 2px solid #C6DDFF !important;\n}", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,SAAS;EACT,sBAAsB;AACxB;;AAEA;;;GAGG;;AAEH;EACE,SAAS;;AAEX;;;;;AAKA;EACE,qCAAqC;EACrC,4CAAiD;AACnD;;AAEA;CACC,mDAAmD;AACpD;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;;;;;AAKA;EACE,oCAAoC;AACtC","sourcesContent":["*{\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\n/* @font-face {\n  font-family: \"Product Sans Bold\";\n  src: local(\"Product Sans Bold\"), url(\"./../public/SF\\ UI\\ Display\\ Regular.ttf\") format(\"truetype\");\n} */\n\nbody {\n  margin: 0;\n\n}\n\n\n\n\n@font-face {\n  font-family: SF UI Display !important;\n  src: url('./fonts/SF\\ UI\\ Display\\ Regular.ttf') ;\n}\n\n* {\n\tfont-family: 'SF UI Display', sans-serif !important;\n}\n\ninput:hover {\n  border: 2px solid #C6DDFF !important;\n}\ninput:active{\n  border: 2px solid #C6DDFF !important;\n}\ninput:visited{\n  border: 2px solid #C6DDFF !important;\n}\n\n\n\n\nselect:hover{\n  border: 2px solid #C6DDFF !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
