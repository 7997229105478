// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageLayout_page_layout__3MuQF {\n    padding-left: 253px;\n    padding-bottom: 5rem;\n    padding-top: 6rem;\n}\n\n", "",{"version":3,"sources":["webpack://src/components/Layout/PageLayout.module.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,iBAAiB;AACrB","sourcesContent":[".page_layout {\n    padding-left: 253px;\n    padding-bottom: 5rem;\n    padding-top: 6rem;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page_layout": "PageLayout_page_layout__3MuQF"
};
export default ___CSS_LOADER_EXPORT___;
