// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../assets/caret-down.svg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AppSelect_app_select__noZIx {\n    height: 41px;\n    background: #FFFFFF;\n    border: 0.7px solid #BFBFBF;\n    box-sizing: border-box;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);\n    border-radius: 3px;\n    \n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 14px;\n    padding: 0 10px;\n    color: #1A1F36;\n    display: flex;\n    align-items: center;\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    background-repeat: no-repeat, repeat;\n    background-position: right 18px top 50%;\n    padding: 8px 32px 8px 16px;\n}", "",{"version":3,"sources":["webpack://src/components/appSelect/AppSelect.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,2BAA2B;IAC3B,sBAAsB;IACtB,2CAA2C;IAC3C,kBAAkB;;IAElB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,eAAe;IACf,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,qBAAqB;IACrB,wBAAwB;IACxB,yDAA+D;IAC/D,oCAAoC;IACpC,uCAAuC;IACvC,0BAA0B;AAC9B","sourcesContent":[".app_select {\n    height: 41px;\n    background: #FFFFFF;\n    border: 0.7px solid #BFBFBF;\n    box-sizing: border-box;\n    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);\n    border-radius: 3px;\n    \n    font-style: normal;\n    font-weight: normal;\n    font-size: 12px;\n    line-height: 14px;\n    padding: 0 10px;\n    color: #1A1F36;\n    display: flex;\n    align-items: center;\n    appearance: none;\n    -moz-appearance: none;\n    -webkit-appearance: none;\n    background-image: url('../../components/assets/caret-down.svg');\n    background-repeat: no-repeat, repeat;\n    background-position: right 18px top 50%;\n    padding: 8px 32px 8px 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"app_select": "AppSelect_app_select__noZIx"
};
export default ___CSS_LOADER_EXPORT___;
