import React, { useState, useEffect } from "react";
import styles from "./ChangePassword.module.css";
import { Typography } from "@material-ui/core";
import eye_icon from "./../../../../../components/assets/Vector (28).svg";
import eye_icon2 from "./../../../../../components/assets/Group 1523.svg";
import { usePostRequest } from "../../../../../custom-hooks/api";
import ButtonMain from "../../../../../components/button/Button";
import { useForm } from "react-hook-form";

const ChangePasswordModal = ({
  setShowResetPasswordModal,
  setShowChangePasswordModal,
}) => {
  const { postRequest, processing, posted } = usePostRequest();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  useEffect(() => {
    if (posted) {
      setShowChangePasswordModal(false);
    }
    return () => {
      //cleanup
    };
  }, [posted]);

  const handleUpdatePassword = async (data) => {
    postRequest(`/api/v1/auth/change/password`, {
      new_password: data.new_password,
      confirm_password: data.confirm_password,
    });
  };

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleTogglePassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.change_password_modal}
    >
      <div className={styles.change_password_modal_heading}>
        <Typography>Change your password</Typography>
      </div>
      <div className={styles.change_password_inputs}>
        <label htmlFor="">
          New Password
          <div className={styles.input_box}>
            <input
              {...register("new_password", { required: true, maxLength: 80 })}
              name="new_password"
              type={showPassword ? "text" : "password"}
            />
            <div onClick={handleTogglePassword}>
              {showPassword ? (
                <img src={eye_icon2} alt="" />
              ) : (
                <img src={eye_icon} alt="" />
              )}
            </div>
          </div>
        </label>
        <label htmlFor="">
          Confirm Password
          <div className={styles.input_box}>
            <input
              {...register("confirm_password", {
                required: true,
                maxLength: 80,
              })}
              name="confirm_password"
              type={showPassword2 ? "text" : "password"}
            />
            <div onClick={handleTogglePassword2}>
              {showPassword2 ? (
                <img src={eye_icon2} alt="" />
              ) : (
                <img src={eye_icon} alt="" />
              )}
            </div>
          </div>
        </label>
      </div>
      <div className={styles.change_password_btn}>
        <ButtonMain
          fullLength
          bgColor="#1A2CCE"
          textColor="white"
          loading={processing}
          btnText="Update password"
          handleClick={handleSubmit(handleUpdatePassword)}
        />
      </div>
    </div>
  );
};

export default ChangePasswordModal;
