import React from 'react'
import styles from './AlertFrame.module.css'
import close_icon from '../../assets/close_icon.svg'

function AlertFrame({child, handleClose}) {
  return (
    <div onClick={(e)=>{e.stopPropagation()}} className={styles.alert_frame}>
        <div className={styles.close}>
            <img onClick={handleClose} src={close_icon} alt="" />
        </div>
        {child}
    </div>
  )
}

export default AlertFrame