import { Typography } from "@material-ui/core";
import React  from "react";
import styles from "./DeletePrompt.module.css";

import ModalFrame from "../frames/modalFrame/ModalFrame";

const AppDeletePrompt = ({
  body,
  loading,
  handleDelete,
  handleCloseModal,
  title,
}) => {
  // const handleDelete = () => {
  //   deleteRequest(`${endpoint}${data.id}`);
  // };

  return (
    <ModalFrame
      handleCancel={() => {
        handleCloseModal();
      }}
      handleAction={handleDelete}
      loading={loading}
      showCancelBtn
      showConfirmBtn
      btnTxt={title}
      child={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.delete_prompt_content}
        >
          <>
            <div className={styles.form_heading}>
              <Typography>{title}</Typography>
            </div>
            <div className={styles.form_text}>
              <Typography>{body}</Typography>
            </div>
          </>
        </div>
      }
    />
  );
};

export default AppDeletePrompt;
