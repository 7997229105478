import React, { useState } from "react";
import SecurityModal from "../../components/qrCode/SecurityModal";
import OtpModal from "../../components/OtpCode/OtpModal";
import { useMutation, useQueryClient } from "react-query";
import { ENABLE_2FA } from "../../../../../API/mutations/profile/security/Security";

function Enable2fa({
  loading,
  qrData,
  setShowEnable2faModal,
  
}) {
  const [screen, setScreen] = useState("qr");
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState({});
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(ENABLE_2FA, {
    onSuccess: (response) => {
   
      setShowEnable2faModal(false);
      queryClient.invalidateQueries(["user"]);
    },
  });

  const handleChange = (otp) => {
    setOtp(otp);
    validateOtp(otp);
  };

  const validateOtp = (otp) => {
    if (otp.length < 6) {
    } else {
      delete otpError.otp;
    }
  };

  const handleAuthenticateOTP = async (type) => {
    if (otp.length < 6) {
      setOtpError({
        otp: {
          message: "",
          type: "required",
        },
      });
    } else {
      mutate({
        code: otp,
      });
    }
  };
  switch (screen) {
    case "otp":
      return (
        <OtpModal
          otp={otp}
          otpError={otpError}
          isLoading={isLoading}
          handleChange={handleChange}
          handleAuthenticateOTP={handleAuthenticateOTP}
          handleCloseModal={() => {
            setScreen("qr");
          }}
        />
      );
    case "qr":
      return (
        <SecurityModal
          qrData={qrData}
          loading={loading}
          handleNext={() => {
            setScreen("otp");
          }}
         
          setShowEnable2faModal={setShowEnable2faModal}
        />
      );
    default:
      return (
        <SecurityModal
          handleNext={() => {
            setScreen("otp");
          }}
      
          setShowEnable2faModal={setShowEnable2faModal}
        />
      );
  }
}

export default Enable2fa;
