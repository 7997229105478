// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OverviewCards_dashboard_cards__2yuqi {\n  display: flex;\n}\n\n.OverviewCards_my_grid__3mp94 {\n  display: flex !important;\n  align-items: center !important;\n}\n\n.OverviewCards_white_card__LyN1T {\n  display: flex;\n  width: 100%;\n  background: #ffffff;\n  justify-content: space-between;\n  border-radius: 5px;\n  padding: 30px 20px;\n  align-items: center;\n  height: 187px;\n}\n\n", "",{"version":3,"sources":["webpack://src/pages/home/userHome/components/overviewCards/OverviewCards.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,8BAA8B;EAC9B,kBAAkB;EAClB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;AACf","sourcesContent":[".dashboard_cards {\n  display: flex;\n}\n\n.my_grid {\n  display: flex !important;\n  align-items: center !important;\n}\n\n.white_card {\n  display: flex;\n  width: 100%;\n  background: #ffffff;\n  justify-content: space-between;\n  border-radius: 5px;\n  padding: 30px 20px;\n  align-items: center;\n  height: 187px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_cards": "OverviewCards_dashboard_cards__2yuqi",
	"my_grid": "OverviewCards_my_grid__3mp94",
	"white_card": "OverviewCards_white_card__LyN1T"
};
export default ___CSS_LOADER_EXPORT___;
