// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditInfo_profile_pic__3aLVH {\n    height: 72px;\n    width: 72px;\n    position: relative;\n    border-radius: 50%;\n    border: 1px solid blue;\n    margin-bottom: 32px;\n  \n}\n.EditInfo_profile_pic__3aLVH input{\n    height: 100%;\n    width: 100%;\n    opacity: 0;\n    position: absolute;\n    z-index: 2;\n    cursor: pointer;\n}\n.EditInfo_camera_icon__2ho3v{\n    position: absolute;\n    top: 27px;\n    left: 26px;\n}\n.EditInfo_incoming_avatar__3c6MD{\n    height: 100%;\n    width: 100%;\n    padding: 10px;\n    border-radius: 50%;\n}\n\n.EditInfo_avatar_loader__35VqN {\n    position: absolute;\n    top: 17px;\n    left: 16px;\n}\n.EditInfo_field__NIe-r {\n    margin-bottom: 24px;\n    width: 400px;\n}", "",{"version":3,"sources":["webpack://src/pages/profile/userInfo/editInfo/EditInfo.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,sBAAsB;IACtB,mBAAmB;;AAEvB;AACA;IACI,YAAY;IACZ,WAAW;IACX,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;AACA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB","sourcesContent":[".profile_pic {\n    height: 72px;\n    width: 72px;\n    position: relative;\n    border-radius: 50%;\n    border: 1px solid blue;\n    margin-bottom: 32px;\n  \n}\n.profile_pic input{\n    height: 100%;\n    width: 100%;\n    opacity: 0;\n    position: absolute;\n    z-index: 2;\n    cursor: pointer;\n}\n.camera_icon{\n    position: absolute;\n    top: 27px;\n    left: 26px;\n}\n.incoming_avatar{\n    height: 100%;\n    width: 100%;\n    padding: 10px;\n    border-radius: 50%;\n}\n\n.avatar_loader {\n    position: absolute;\n    top: 17px;\n    left: 16px;\n}\n.field {\n    margin-bottom: 24px;\n    width: 400px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profile_pic": "EditInfo_profile_pic__3aLVH",
	"camera_icon": "EditInfo_camera_icon__2ho3v",
	"incoming_avatar": "EditInfo_incoming_avatar__3c6MD",
	"avatar_loader": "EditInfo_avatar_loader__35VqN",
	"field": "EditInfo_field__NIe-r"
};
export default ___CSS_LOADER_EXPORT___;
