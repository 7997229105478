import { Container, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";
import styles from "./Resolve.module.css";
import { useEffect } from "react";
import ngn_icon from "./../../components/assets/ngn.svg";
import tetherIcon from "./../../components/assets/Group 140.svg";
import alert_icon from "./../../components/assets/Vector (20).svg";
import copy from "copy-text-to-clipboard";
import P2PSnackbar from "../../components/AppSnackbars/P2PSnackbar";
import LinearProgress from "@mui/material/LinearProgress";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router";
import AppBackdrop from "../../components/backdrop/Backdrop";
import RetryPayment from "./modals/retryPayment/RetryPayment";
import AdvancedModal from "./modals/advanced/AdvancedModal";
import BarcodeModal from "./modals/barcode/Barcode";
import ResolveActions from "./components/resolveActions/ResolveActions";
import ResolutionModal from "./modals/resolution/ResolutionModal";
import PageLayout from "../../components/Layout/PageLayout";
import ConfirmTransaction from "./modals/confirmTransaction/ConfirmTransaction";
import useOpenSnackbar from "../../custom-hooks/useOpenSnackbar";
import GoBack from "../../components/goBack/GoBack";
import { SupportContextAPI } from "../../Context/support/SupportContext";
import { MiscContextAPI } from "../../Context/misc/MiscContext";
import { useQuery } from "react-query";
import { GET_SINGLE_SUPPORT } from "../../API/queries/support/Support";

const ErrorTab = ({ match }) => {

  const { channels, handleGetChannels, handleGetBanks } =
    useContext(MiscContextAPI);
  const { currentSolvedError } = useContext(SupportContextAPI);
  const history = useHistory();
  const { openSnackbar, severity, message, openToast, setOpenToast, duration } =
    useOpenSnackbar();
  const [displayTryAgain, setDisplayTryAgain] = useState(false);
  const [open, setOpen] = useState(false);
  const [openResolutionModal, setOpenResolutionModal] = useState(false);
  const [openRetry, setOpenRetry] = useState(false);

  const [expiryTime, setExpiryTime] = useState("");

  const [openActions, setOpenActions] = useState(false);
  const [openAdvanced, setOpenAdvanced] = useState(false);
  const [openBarcodeModal, setOpenBarcodeModal] = useState(false);
  const [openUploadScreenshot, setOpenUploadScreenshot] = useState(false);

  const { data: support, isLoading} = useQuery(
    ["support", match.params.id],
    () => GET_SINGLE_SUPPORT(match.params.id),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const controller = new AbortController();
    handleGetBanks();
    handleGetChannels();
    //getParticularData();
    return () => {
      controller.abort();
    };
  }, []);

 

  useEffect(() => {
    if (support != null) {
      const expiryTimes = support?.data?.expiry_time - Date.now();
      setExpiryTime(expiryTimes);
      setDisplayTryAgain(false);
    } else {
      setDisplayTryAgain(true);
    }
  }, [support]);

  useEffect(() => {
    const timer = setInterval(() => {
      const tempTime = expiryTime - 1000;
      setExpiryTime(tempTime);
    }, 1000);
    if (expiryTime < 0) {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [expiryTime]);

  useEffect(() => {
    if (currentSolvedError?.id == match.params.id) {
      history.push("/support");
    }
  }, [currentSolvedError]);

  const getParticularData = async () => {
    //getRequest(`/api/v1/transactions/find/${match.params.id}`);
  };
  const openModal = () => {
    if (support?.data?.error === "Resolution") {
      setOpenResolutionModal(true);
    } else {
      setOpen(true);
    }
  };
  const handleOpenRetryPaymentModal = () => {
    setOpenRetry(true);
  };

  const handleCloseRetryPaymentModal = () => {
    setOpenRetry(false);
  };

  const handleOpenAdvancedModal = () => {
    setOpenAdvanced(true);
  };

  const handleCloseAdvancedModal = () => {
    setOpenAdvanced(false);
  };

  const handleCopyToClipBoard = () => {
    const response = copy(support?.data?.acc_no);
    if (response) {
      openSnackbar("success", "copied to clipboard");
    }
  };

  const handleTryAgain = () => {
    getParticularData();

  };

  const handleOpenActions = () => {
    setOpenActions(!openActions);
  };
  const handleCloseActions = () => {
    setOpenActions(false);
  };

  const handleOpenBarcodeModal = () => {
    setOpenBarcodeModal(true);
  };
  const handleCloseBarcodeModal = () => {
    setOpenBarcodeModal(false);
  };

  return (
    <PageLayout
      bgColor="#ffffff"
      child={
        <div onClick={handleCloseActions} className={styles.resolve}>
          <Container>
            {isLoading ? (
              <LinearProgress />
            ) : (
              <div className={styles.resolve_content}>
                <div className={styles.resolve_heading}>
                  <GoBack text="Go Back" />
                  <div className={styles.resolve_tab}>
                    <div className={styles.resolve_tab_text}>
                      <img
                        src={
                          support?.data?.crypto_currency === "USDT"
                            ? tetherIcon
                            : ngn_icon
                        }
                        alt=""
                      />
                      <div>
                        <Typography
                          style={{
                            color: expiryTime < 420000 ? "red" : "unset",
                          }}
                          variant="h6"
                        >
                          EXPIRES IN{" "}
                          {Math.floor(
                            (expiryTime <= 0 ? 0 : expiryTime / 1000 / 60) % 60
                          )}{" "}
                          :{" "}
                          {Math.floor(
                            (expiryTime <= 0 ? 0 : expiryTime / 1000) % 60
                          )}
                        </Typography>
                        <Typography>{support?.data?.order_no}</Typography>
                      </div>
                    </div>
                    <div className={styles.resolve_tab_btn}>
                      <ResolveActions
                        handleOpenActions={handleOpenActions}
                        handleOpenBarcodeModal={handleOpenBarcodeModal}
                        openActions={openActions}
                        openModal={openModal}
                        handleCloseActions={handleCloseActions}
                        handleOpenModal2={handleOpenRetryPaymentModal}
                        handleOpenAdvancedModal={handleOpenAdvancedModal}
                        issue={support?.data}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.big_info_chip}>
                  <img src={alert_icon} alt="" />
                  <Typography>
                    Please make payment to the designated account number before
                    the timer expires. Click mark as paid after transaction is
                    proceeseed{" "}
                  </Typography>
                </div>
                <Container maxWidth="md" className={styles.my_container}>
                  <div className={styles.payment_tab}>
                    <div className={styles.payment_tab_heading}>
                      <Typography>PAYMENT INFO</Typography>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Pay amount</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_bold}>
                              <Typography>
                                <NumberFormat
                                  value={support?.data?.fiat_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={support?.data?.fiat_currency}
                                />
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Account Number</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_bold}>
                              <Typography>{support?.data?.acc_no}</Typography>
                              <button
                                onClick={handleCopyToClipBoard}
                                className={styles.copy_chip}
                              >
                                copy
                              </button>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              {" "}
                              <Typography>Account Name</Typography>{" "}
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_uppercase}>
                              {" "}
                              <Typography>
                                {" "}
                                {support?.data?.acc_name}
                              </Typography>{" "}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Bank Name </Typography>
                            </div>
                          </td>
                          <td>
                            <div className={styles.grid_result_uppercase}>
                              {" "}
                              <Typography>
                                {support?.data?.bank_name}{" "}
                              </Typography>{" "}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className={styles.transaction_tab}>
                    <div className={styles.transaction_tab_heading}>
                      <Typography>TRANSACTION DETAILS</Typography>
                    </div>
                    <table>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Quantity</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_uppercase}>
                              <Typography>
                                <NumberFormat
                                  value={support?.data?.crypto_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                {support?.data?.crypto_currency}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Amount</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_uppercase}>
                              <Typography>
                                <NumberFormat
                                  value={support?.data?.fiat_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                {support?.data?.fiat_currency}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Price</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_uppercase}>
                              <Typography>{support?.data?.price}</Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Error type</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.resolution_error_tab}>
                              {" "}
                              <div
                                className={`${styles.error_ellipse}  ${
                                  support?.data?.error === "Processor"
                                    ? styles.processor_ellipse
                                    : null
                                }  ${
                                  support?.data?.error === "Payment"
                                    ? styles.payment_ellipse
                                    : null
                                }  ${
                                  support?.data?.error === "Manual"
                                    ? styles.manual_ellipse
                                    : null
                                }  ${
                                  support?.data?.error === "Bank"
                                    ? styles.bank_ellipse
                                    : null
                                } ${
                                  support?.data?.error === "Resolution"
                                    ? styles.resolution_ellipse
                                    : null
                                } `}
                              ></div>
                              <Typography
                                className={`${
                                  support?.data?.error === "Processor"
                                    ? styles.processor_para
                                    : null
                                } ${
                                  support?.data?.error === "Payment"
                                    ? styles.payment_para
                                    : null
                                } ${
                                  support?.data?.error === "Manual"
                                    ? styles.manual_para
                                    : null
                                } ${
                                  support?.data?.error === "Bank"
                                    ? styles.bank_para
                                    : null
                                }`}
                              >
                                {support?.data?.error}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Channel</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_uppercase}>
                              <Typography>{support?.data?.channel}</Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_title}>
                              <Typography>Note</Typography>
                            </div>
                          </td>
                          <td>
                            {" "}
                            <div className={styles.grid_result_uppercase}>
                              <Typography>{support?.data?.note}</Typography>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            {" "}
                            <div className={styles.grid_footer}>
                              <Typography>Total amount involved</Typography>
                            </div>
                          </td>
                          <td>
                            <div
                              className={`${styles.grid_footer} ${styles.text_end}`}
                            >
                              <Typography>
                                <NumberFormat
                                  value={support?.data?.fiat_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                />
                                {support?.data?.fiat_currency}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </Container>
              </div>
            )}
          </Container>
          {open && (
            <AppBackdrop
              openModal={open}
              handleCloseModal={() => {
                setOpen(false);
              }}
              child={
                <ConfirmTransaction
                  channels={channels}
                  expiryTime={expiryTime}
                  setOpen={setOpen}
                  issue={support?.data}
                  openUploadScreenshot={openUploadScreenshot}
                />
              }
            />
          )}
          {openResolutionModal && (
            <AppBackdrop
              openModal={openResolutionModal}
              handleCloseModal={() => {
                setOpenResolutionModal(false);
              }}
              child={
                <ResolutionModal
                  expiryTime={expiryTime}
                  setOpen={setOpenResolutionModal}
                  issue={support?.data}
                />
              }
            />
          )}
          {openAdvanced && (
            <AppBackdrop
              child={
                <AdvancedModal
                  issue={support?.data}
                  channels={channels}
                  expiryTime={expiryTime}
                  handleCloseModal={handleCloseAdvancedModal}
                />
              }
              openModal={openAdvanced}
              handleCloseModal={handleCloseAdvancedModal}
            />
          )}

          {openBarcodeModal && (
            <AppBackdrop
              child={
                <BarcodeModal
                  issue={support?.data}
                  channels={channels}
                  expiryTime={expiryTime}
                  handleCloseModal={handleCloseBarcodeModal}
                />
              }
              openModal={openBarcodeModal}
              handleCloseModal={handleCloseBarcodeModal}
            />
          )}

          {openRetry && (
            <AppBackdrop
              child={
                <RetryPayment
                  issue={support?.data}
                  errorType={support?.data?.error}
                  channels={channels}
                  expiryTime={expiryTime}
                  handleCloseModal={handleCloseRetryPaymentModal}
                />
              }
              openModal={openRetry}
              handleCloseModal={handleCloseRetryPaymentModal}
            />
          )}

          <P2PSnackbar
            severity={severity}
            message={message}
            open={openToast}
            setOpen={setOpenToast}
            duration={duration}
          />
        </div>
      }
    />
  );
};

export default ErrorTab;
