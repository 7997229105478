// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ManagePermissions_manage_permissions__2zAjl{\n    background-color: white;\n    width: 660px;\n}", "",{"version":3,"sources":["webpack://src/pages/settings/tabs/user-groups/modals/managePermissions/ManagePermissions.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;AAChB","sourcesContent":[".manage_permissions{\n    background-color: white;\n    width: 660px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manage_permissions": "ManagePermissions_manage_permissions__2zAjl"
};
export default ___CSS_LOADER_EXPORT___;
