import React from "react";
import { useForm } from "react-hook-form";
import ButtonMain from "../../../../components/button/Button";
import styles from "./SupportFilter.module.css";
import searchIcon from "./../../../../components/assets/Vector (7).svg";
import LabeledSelect from "../../../../components/selects/labeledSelects/LabeledSelects";

function SupportFilter({
  handleSearchOrderNoSubmit,

  handleGetFilterQueries,
}) {
  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm();

  return (
    <div className={styles.support_filter}>
      <form onSubmit={handleSearchOrderNoSubmit} action="">
        <div className={styles.search}>
          <div>
            <img src={searchIcon} alt="" />
          </div>
          <input type="text" placeholder="Search order no" name="" id="" />
        </div>
      </form>

      <div className={styles.selects}>
        <LabeledSelect
        required={false}
          name="crypto"
          redOutline
          label=""
          placeholder="Crypto"
          register={register}
          data={[{ name: "USDT" }, { name: "NGN" }]}
        />

        <LabeledSelect
        required={false}
          name="error"
          redOutline
          label=""
          placeholder="Error"
          register={register}
          data={[
            { name: "Resolution" },
            { name: "Processor" },
            { name: "Payment" },
            { name: "Bank" },
            { name: "Manual" },
          ]}
        />

        <ButtonMain
          border="0.4px solid #1A2CCE"
          length="105px"
          bgColor="#E7F1FF"
          textColor="#1A2CCE"
          btnText={"Apply Filters"}
          handleClick={handleSubmit(handleGetFilterQueries)}
          hideLoader
        />
      </div>
    </div>
  );
}

export default SupportFilter;
