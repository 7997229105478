import { Typography, LinearProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./Modal.module.css";

import { useHistory } from "react-router";

import PlatformSelect from "../../../components/appSelect/PlatformSelect";


const SelectPlatform = ({
  setPlatform,

  handleCloseModal,
  loadingPlatforms,
  platforms,
}) => {
  const [, setPlatformNames] = useState([]);


  useEffect(() => {
    const tempPlatform = platforms.map((platform) => {
      return { name: platform.platform_user };
    });

    setPlatformNames(tempPlatform);
  }, [platforms]);

  return (
    <>
      {loadingPlatforms ? (
        <LinearProgress />
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={styles.select_platform}
        >
          <div className={styles.select_platform_heading}>
            <Typography> Select a platform to post ad</Typography>
          </div>
          <div className={styles.select_platform_body}>
          
            <label htmlFor="">
              <p>Platform</p>
              <PlatformSelect
                title="Select Platform"
                setSelect={setPlatform}
                type="dynamic"
                data={platforms}
              />
            </label>
          </div>

          {/* <div className={styles.dark_bg}>
            <button onClick={handleCloseModal}>Cancel</button>
            <button onClick={handleGotoPostScreen}>Confirm</button>
          </div> */}
        </div>
      )}
    </>
  );
};

export default SelectPlatform;
