import { Container, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styles from "./ResetPassword.module.css";
import eye_icon from "./../../../components/assets/Vector (28).svg";
import eye_icon2 from "./../../../components/assets/Group 1523.svg";

import { usePostRequest } from "../../../custom-hooks/api";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
 
  const { processing, postRequest, posted } = usePostRequest();
  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [token, setToken] = useState();
  const location = useLocation();
  const history = useHistory()
  useEffect(() => {
    const parsed = queryString.parse(location.search);

    setToken(parsed.token);
  }, []);

  useEffect(()=>{
      if(posted){
        history.push('/')
      }
  }, [posted])

  const handleTogglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const handleTogglePassword2 = (e) => {
    e.preventDefault();
    setShowPassword2(!showPassword2);
  };

 

  const handleResetPassword = async (data) => {

   
    postRequest("/api/v1/auth/recover/password", {
      new_password: data.new_password,
      retype_password: data.confirm_password,
      token
    });
  };

  return (
    <div className={styles.reset_password}>
      <Container>
        <div className={styles.reset_password_content}>
          <div className={styles.reset_password_heading}>
            <Typography variant="h6">Reset Password</Typography>
            <Typography>Enter a new password to secure your account</Typography>
          </div>
          <div className={styles.reset_password_form}>
            <form action="">
              <label htmlFor="">
                Enter new password
                <div className={styles.input_box}>
                  <input
                    {...register('new_password', { required: true, maxLength: 80})}
                    name="new_password"
                   
                    type={showPassword ? "text" : "password"}
                  />
                  <div onClick={handleTogglePassword}>
                    {showPassword ? (
                      <img src={eye_icon2} alt="" />
                    ) : (
                      <img src={eye_icon} alt="" />
                    )}
                  </div>
                </div>
              </label>
              <label htmlFor="">
                Confirm password
                <div className={styles.input_box}>
                  <input
                   {...register('confirm_password', { required: true, maxLength: 80})}
                   name="confirm_password"
                 
                    type={showPassword2 ? "text" : "password"}
                  />
                  <div onClick={handleTogglePassword2}>
                    {showPassword2 ? (
                      <img src={eye_icon2} alt="" />
                    ) : (
                      <img src={eye_icon} alt="" />
                    )}
                  </div>
                </div>
              </label>
              <div className={styles.reset_password_btn}>
                <button onClick={handleSubmit(handleResetPassword)}>
                  {" "}
                  {processing ? "Processing..." : "Reset Password"}{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ResetPassword;
