import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./Pagination.module.css";
import doubleArrow from "../../components/assets/doubleArrow.svg";
import backarrow from "../../components/assets/backarrow.svg";


const Pagination = ({ meta, type, handlePageChange }) => {
  // const {
  //   openSnackbar,
  //   message,
  //   severity,
  //   duration,
  //   setOpenToast,
  //   openToast,
  // } = useOpenSnackbar();

  const [mapper] = useState([1, 2, 3]);
  const [toDisplay, setTodisplay] = useState([]);
  const [goto, setGoto] = useState("");

  useEffect(() => {
    let i;
    const tempTodisplay = [];
    if (meta?.currentPage === meta?.totalPages) {
      mapper.map((item, index) => {
        i = meta?.currentPage;
        if (i - index >= 1) {
          tempTodisplay.push(i - index);
        }
        return "";
      });
      setTodisplay(tempTodisplay.sort((a, b) => a - b));
    } else {
      mapper.map((item, index) => {
        i = meta?.currentPage;
        if (index === 0) {
          if (i + index <= meta?.totalPages) {
            if (i === 1) {           
              if(meta?.totalPages >= 3){
                tempTodisplay.push(3);
              }
            } else {
              tempTodisplay.push(i - 1);
            }
          }
        } else if (index === 1) {
          if (i + index <= meta?.totalPages) {
            tempTodisplay.push(i);
          }
        } else if (index === 2) {
          if (i + index <= meta?.totalPages) {
            tempTodisplay.push(i + 1);
          }
        }
      });
      setTodisplay(tempTodisplay.sort((a, b) => a - b));
    }
  }, [meta, mapper]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "page") {
      setGoto(value);
    }
  };

  const handleValidatePageNumber = (e) => {
    e.preventDefault();
    if (goto > meta?.totalPages) {
      //openSnackbar("error", "invalid page", 1000);
    } else {
      if (goto <= 0) {
        //openSnackbar("error", "invalid page", 1000);
      } else {
        handlePageChange(goto, type);
      }
    }
  };
  return (
    <div className={styles.pagination}>
      <div className={styles.pagination_left}>
        <div className={styles.showing_numbers}>
          <Typography>
            Showing {(meta?.currentPage * meta?.itemsPerPage - meta?.itemsPerPage )+ 1} to{" "}
            {meta?.currentPage * meta?.itemsPerPage > meta?.totalItems
              ? meta?.totalItems
              : meta?.currentPage * meta?.itemCount}{" "}
            of {meta?.totalItems}
          </Typography>
        </div>
        <div className={styles.goto}>
          <Typography>Go to:</Typography>
          <form
            onSubmit={(e) => {
              handleValidatePageNumber(e);
            }}
            action=""
          >
            <input
              name="page"
              value={goto}
              onChange={handleChange}
              type="text"
            />
          </form>
        </div>
      </div>
      <div className={styles.pagination_right}>
        {toDisplay.at(0) !== 1 && (
          <button
            onClick={() => {
              handlePageChange(1, type);
            }}
          >
            1
          </button>
        )}
        {toDisplay.at(0) >= 3 ? (
          <button
            onClick={() => {
              handlePageChange(toDisplay[0] - 1, type);
            }}
          >
            <img
              style={{ transform: "rotate(180deg)" }}
              src={backarrow}
              alt=""
            />
          </button>
        ) : null}

        {toDisplay.map((item) => {
          return (
            <button
              className={item === meta?.currentPage ? styles.active_page : null}
              onClick={() => {
                handlePageChange(item, type);
              }}
              key={item}
            >
              {item}
            </button>
          );
        })}
        {toDisplay.at(-1) + 1 >= meta?.totalPages ? null : (
          <button
            onClick={() => {
              handlePageChange(toDisplay[2] + 1, type);
            }}
          >
            <img src={doubleArrow} alt="" />
          </button>
        )}
        {toDisplay.at(-1) >= Math.ceil(meta?.totalItems / meta?.itemsPerPage) ? null : (
          <button
            onClick={() => {
              handlePageChange(Math.ceil(meta?.totalItems / meta.itemsPerPage), type);
            }}
          >
            {Math.ceil(meta?.totalItems / meta?.itemsPerPage)}
          </button>
        )}
      </div>
      {/* <SwapstaSnackbar
        open={openToast}
        message={message}
        severity={severity}
        setOpen={setOpenToast}
        duration={duration}
      /> */}
    </div>
  );
};

export default Pagination;
