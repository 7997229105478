import { useState, useContext } from "react";
import axios from "axios";
import { io } from "socket.io-client";
import { AuthContextAPI } from "../Context/auth/AuthContext";

export const usePostRequest = () => {
  const [processing, setProcessing] = useState(false);
  const { baseURL } = useContext(AuthContextAPI);
  const [posted, setPosted] = useState(false);

  const postRequest = (endpoint, data) => {
    setPosted(false);
    setProcessing(true);
    axios
      .post(`${baseURL}${endpoint}`, {
        ...data,
      })

      .then((res) => {
        setProcessing(false);
        setPosted(true);
      })
      .catch((err) => {
        setPosted(false);
        setProcessing(false);
      });
  };

  return {
    postRequest,
    processing,
    setProcessing,
    posted,
  };
};

export const useGetRequest = () => {
  const { baseURL } = useContext(AuthContextAPI);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const getRequest = (endpoint) => {
    setLoading(true);
    axios
      .get(`${baseURL}${endpoint}`)
      .then((res) => {
        setData(res.data.data);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return {
    data,
    getRequest,
    loading,
    setLoading,
  };
};

export const useDeleteRequest = () => {
  const [processingDelete, setProcessingDelete] = useState(false);
  const { baseURL } = useContext(AuthContextAPI);
  const [deleted, setDeleted] = useState(false);

  const deleteRequest = (endpoint, data) => {
    setProcessingDelete(true);
    setDeleted(false);
    axios
      .delete(`${baseURL}${endpoint}`, {
        ...data,
      })

      .then((res) => {
        setProcessingDelete(false);

        setDeleted(true);
      })
      .catch((err) => {
        setDeleted(false);
        setProcessingDelete(false);
      });
  };

  return {
    deleted,
    deleteRequest,
    processingDelete,
    setProcessingDelete,
  };
};

export const usePutRequest = () => {
  const [processing, setProcessing] = useState(false);
  const { baseURL } = useContext(AuthContextAPI);
  const [posted, setPosted] = useState(false);

  const putRequest = (endpoint, data) => {
    setProcessing(true);
    axios
      .put(`${baseURL}${endpoint}`, {
        ...data,
      })

      .then((res) => {
        setProcessing(false);
        setPosted(true);
      })
      .catch((err) => {
       
        setPosted(false);
        setProcessing(false);
      });
  };

  return {
    putRequest,
    processing,
    setProcessing,
    posted,
  };
};

export const useWebsocket = () => {
 
  const socketCall = io(process.env.REACT_APP_BASE_URL, {
    transports: ["websocket", "polling", "flashsocket"],
  });



  return {
    socketCall
  };
};
