import { Typography } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Resolution.module.css";
import tether_icon from "./../../../../components/assets/Group 140.png";
import btc_icon from "./../../../../components/assets/Group 145.png";
import moment from "moment";
import { io } from "socket.io-client";
import ModalFrame from "../../../../components/frames/modalFrame/ModalFrame";
import LabeledSelects from "../../../../components/selects/labeledSelects/LabeledSelects";
import { useForm } from "react-hook-form";
import { UserContextAPI } from "../../../../Context/user/UserContext";
import { MiscContextAPI } from "../../../../Context/misc/MiscContext";
import { useMutation } from "react-query";
import { RESOLVE_ERROR } from "../../../../API/mutations/support/Support";
import NumberFormat from "react-number-format";

const ResolutionModal = ({ issue, setOpen, expiryTime }) => {
  const {
    register,

    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const { user } = useContext(UserContextAPI);
  const { banks } = useContext(MiscContextAPI);
  const bank_name = watch("bank_name", "");

  const [bankID, setBankID] = useState("");
  const socket = useRef();

  const { mutate, isLoading } = useMutation(RESOLVE_ERROR, {
    onSuccess: (response) => {
      //setOpen(false)
      const data = {
        event: "transaction_resolved",
        data: {
          id: issue.id,
          order_no: issue.order_no,
          user: user?.firstname,
        },
      };
  
      socket.current.emit("p2pcollect", data);
    },
    onError: ()=>{

    }
  });

  useEffect(() => {
    handleSelectBankCode(bank_name);
  }, [bank_name]);

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_BASE_URL, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    return () => socket.current.disconnect();
  }, []);

  const handleConfirm = async () => {
    mutate({
      data: {
        value: bankID,
        type: issue.error,
      },
      id: issue.id,
    });
  };

  const handleSelectBankCode = (bank_name) => {
    const selectedBank = banks.find((bank) => {
      if (bank.name === bank_name) {
        return bank;
      }
    });

    if (selectedBank) {
      setBankID(selectedBank.code);
    }
  };

  return (
    <ModalFrame
      handleCancel={() => {
        setOpen(false);
      }}
      handleAction={handleSubmit(handleConfirm)}
      loading={isLoading}
      showCancelBtn
      showConfirmBtn
      btnTxt="Confirm"
      child={
        <div className={styles.resolution_modal}>
          <div className={styles.modal_heading}>
            <Typography>Resolve Transaction</Typography>
            <div className={styles.modal_expires_in}>
              <Typography variant="h6">Expires in</Typography>
              <Typography
                style={{
                  color: expiryTime < 420000 ? "red" : "unset",
                }}
              >
                {Math.floor(
                  (expiryTime <= 0 ? 0 : expiryTime / 1000 / 60) % 60
                )}{" "}
                : {Math.floor((expiryTime <= 0 ? 0 : expiryTime / 1000) % 60)}
              </Typography>
            </div>
          </div>
          <div className={styles.modal_details}>
            <div className={styles.crypto_icon_details}>
              <img
                src={issue.crypto_currency === "USDT" ? tether_icon : btc_icon}
                alt=""
              />
              <div>
                <Typography variant="h6">{issue.order_no}</Typography>
                <Typography>
                  {moment(issue.created_at).format("DD MMM YYYY")}
                </Typography>
              </div>
            </div>
            <div className={styles.amount}>
              <Typography variant="h6">
                <NumberFormat
                  value={issue.fiat_amount}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </Typography>
              <Typography>{issue.fiat_currency}</Typography>
            </div>
          </div>
          <div className={styles.resolution_modal_input}>
            <LabeledSelects
              required={true}
              register={register}
              name="bank_name"
              label="Choose Bank"
              placeholder="Bank"
              data={banks}
            />
            {/* <label htmlFor="">
              Select Bank
              <select
                onChange={handleSelectChange}
                value={bank_name}
                name="bank_name"
                id=""
              >
                <option value="">Bank</option>
                {banks.map((bank) => {
                  return (
                    <option key={bank.name} name={bank.name} value={bank.name}>
                      {bank.name}
                    </option>
                  );
                })}
              </select>
            </label> */}
          </div>

          {/* <div className={styles.resolution_modal_trans_bg}>
              <button disabled={processing} onClick={handleModalClose}>Cancel</button>
              <button disabled={processing} onClick={handleConfirm}>{processing ? 'Processing...': 'Confirm'} </button>
            </div> */}
        </div>
      }
    />
  );
};

export default ResolutionModal;
