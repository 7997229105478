import axios from "axios";

export const GET_USERS = async () => {
  return axios
    .get(
      `${process.env.REACT_APP_BASE_URL}/api/v1/settings/user`
    )
    .then((response) => {
      return response.data;
    });
};

export const GET_USER_ROLES_LIST = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/user/role/auth`
      )
      .then((response) => {
        return response.data;
      });
  };

 

  export const GET_USER_ROLES = async () => {
    return axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/settings/user/role`
      )
      .then((response) => {
        return response.data;
      });
  };