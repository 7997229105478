import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styles from "./ForgotPassword.module.css";

import { usePostRequest } from "../../../custom-hooks/api";
import { useForm } from "react-hook-form";
import LabeledInputs from "../../../components/inputs/labeledInputs/LabeledInputs";

const ForgotPassword = () => {
  const { processing,  postRequest } = usePostRequest();
  const {
    register,

    handleSubmit,

    formState: { errors },
  } = useForm();



 

  const handleLoginToAccount = async (data) => {

    postRequest("/api/v1/auth/forgot/password", {
      email: data.email
    });
  };

 

  return (
    <div className={styles.forgot_password}>
      <Container>
        <div className={styles.forgot_password_content}>
          <div className={styles.forgot_password_heading}>
            <Typography variant="h6">Account Login</Typography>
            <Typography>Welcome back! Log In with your Email</Typography>
          </div>
          <div className={styles.forgot_password_form}>
            <form action="">
              <div className={styles.form_field}>
                <LabeledInputs
                  label="Email"
                  name="email"
                  type="text"
                  required={true}
                  register={register}
                />
              </div>
              
              <div className={styles.forgot_password_btn}>
                <button onClick={handleSubmit(handleLoginToAccount)}>
                  {" "}
                  {processing ? "Processing..." : "Send me a reset link"}{" "}
                </button>
              </div>
              <div className={styles.return_div}>
                <Link to="/">Return to Login</Link>
              </div>
            </form>
          </div>
        </div>
      </Container>
      
    </div>
  );
};

export default ForgotPassword;
