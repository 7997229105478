import React, { Component } from "react";
import styles from "./Default.module.css";
import { Container, Typography } from "@material-ui/core";
import error_icon from "./../../components/assets/undraw_page_not_found_su7k.svg";
import { Link } from "react-router-dom";
const Default = (props) => {
    
    
  return (
    <div className={styles.default_page}>
      <Container>
        <div className={styles.default_page_content}>
          <img src={error_icon} alt="" />
          <h3 className={styles.text_center}>
            the path <b>{props.location.pathname} </b>  does not exist on this website
          </h3>
          <Typography>
          Go <Link to='/dashboard' >Home</Link>
          </Typography>
        </div>
      </Container>
    </div>
  );
  }

export default Default;
