// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AlertFrame_alert_frame__3Om2H{\n    background-color: white;\n    width: 452px;\n    position: relative;\n    padding: 60px 0 40px 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.AlertFrame_close__39WVf {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    border-radius: 5px;\n}\n\n.AlertFrame_close__39WVf img {\n    cursor: pointer;\n}", "",{"version":3,"sources":["webpack://src/components/frames/alertFrame/AlertFrame.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,kBAAkB;IAClB,sBAAsB;IACtB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".alert_frame{\n    background-color: white;\n    width: 452px;\n    position: relative;\n    padding: 60px 0 40px 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.close {\n    position: absolute;\n    top: 20px;\n    right: 20px;\n    border-radius: 5px;\n}\n\n.close img {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"alert_frame": "AlertFrame_alert_frame__3Om2H",
	"close": "AlertFrame_close__39WVf"
};
export default ___CSS_LOADER_EXPORT___;
