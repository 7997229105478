import axios from "axios";

export const GET_STATISTICS = async () => {
 return axios
    .get(`${process.env.REACT_APP_BASE_URL}/api/v1/transactions/dashboard/statistics`)
    .then((response) => {
      return response.data;
    });
};


export const GET_BOTS = async () => {
    return axios
       .get(`${process.env.REACT_APP_BASE_URL}/api/v1/dashboard`)
       .then((response) => {
         return response.data;
       });
   };

  
