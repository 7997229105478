import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import styles from "./TrxDetails.module.css";
import arrow from "./../../../../components/assets/Vector (8).svg";
import tether from "./../../../../components/assets/Group 140.png";

const TrxDetails = () => {
  return (
    <div className={styles.trx_details}>
      <div className={styles.gold_div}>
        <Container>
          <div className={styles.go_back}>
            <img src={arrow} alt="" /> <Typography>Go Back</Typography>
          </div>
          <div className={styles.trx_highlight}>
            <div className={styles.trx_highlight_text}>
              <img src={tether} alt="" />
              <div>
                <Typography variant="h6">Buying USDT</Typography>
                <Typography>NGN 1,428,540.40</Typography>
              </div>
            </div>
            <div className={styles.trx_highlight_btn}>
              <button>Appeal</button>
            </div>
          </div>
        </Container>
      </div>
      <div className={styles.trx_details_full}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <div className={styles.trx_details_full_item}>
                <Typography variant="h6">ASSET TYPE</Typography>
                <Typography>USDT</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.trx_details_full_item}>
                <Typography variant="h6">ORDER NO.</Typography>
                <Typography>203944657389402074</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.trx_details_full_item}>
                <Typography variant="h6">RATE</Typography>
                <Typography>551</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.trx_details_full_item}>
                <Typography variant="h6">QUANTITY</Typography>
                <Typography>551</Typography>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={styles.trx_details_full_item}>
                <Typography variant="h6">DATE</Typography>
                <Typography>25th June, 2021</Typography>
                <span>12:00pm</span>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>

      <Container>
        <div className={styles.trx_details_bottom}>
          <div className={styles.trx_details_bottom_item}>
            <Typography variant="h6">Payment Info</Typography>
            <Typography>Bank Transfer</Typography>
          </div>
          <div className={styles.trx_details_bottom_item}>
            <Typography variant="h6">Account Name</Typography>
            <div className={styles.trx_details_bottom_item_value}>
              <Typography>Faith Samuel Okon</Typography>
              <button>Copy</button>
            </div>
          </div>
          <div className={styles.trx_details_bottom_item}>
            <Typography variant="h6">Bank Name</Typography>
            <div className={styles.trx_details_bottom_item_value}>
              <Typography>Access Bank (Diamond)</Typography>
              <button>Copy</button>
            </div>
          </div>
          <div className={styles.trx_details_bottom_item}>
            <Typography variant="h6">Account Number</Typography>
            <div className={styles.trx_details_bottom_item_value}>
              <Typography>0037485937</Typography>
              <button>Copy</button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default TrxDetails;
