import { useState } from "react";

const useOpenSnackbar = () => {

  const [message, setMessage] = useState('')
  const [duration, setDuration] = useState('')
  const [severity, setSeverity] =useState('')
  const [openToast, setOpenToast] = useState(false)
  
  
  const openSnackbar = (type, message,  duration) => {
  
      setMessage(message)
      setDuration(duration)
      setOpenToast(true)
      setSeverity(type)
   
  };

  return {
    setOpenToast,
    openSnackbar,
    message,
    duration,
    severity, 
    openToast
  };
};

export default useOpenSnackbar;
